import { AppDispatch } from '@config/reduxStore';
import axios from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';
export const GetUnidades = function (ativo: boolean, user?: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_UNIDADES_PENDING',
    });
    axios.get(apiEndpoints.UNIDADES.GET_UNIDADES, { params: { ativo: ativo, user: user } }).then((res) => {
      dispatch({
        type: 'GET_UNIDADES_FULFILLED',
        payload: res,
      });
      if (res.data.length === 1)
        dispatch({
          type: 'SET_UNIDADE_ONLY',
          payload: res.data[0].id,
        });
    });
  };
};
