import { useEffect } from 'react';
import { Table, Button } from 'antd';

import { pickContexto, SetPageHeader } from '@actions/userActions';
import { getContextos } from '@actions/contextosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
const { Column } = Table;

function Contextos() {
  const dispatch = useAppDispatch();
  const { contextos, waiting } = useAppSelector((state) => state.contextos);
  const { loadingContextos } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(getContextos());
    dispatch(
      SetPageHeader({
        title: 'Contas',
        subTitle: 'Seu usuário está inserido em mais de uma conta. Escolha qual delas deseja navegar.',
      })
    );
  }, []);

  return (
    <Table size="small" loading={waiting || loadingContextos} dataSource={contextos} rowKey="id" bordered>
      <Column
        title="Conta"
        dataIndex="nomeRazao"
        render={(text, row: any) => {
          return (
            <Button onClick={() => dispatch(pickContexto(row.id, row.perfil, row.tipoContexto, row.nomeRazao))}>
              {text}
            </Button>
          );
        }}
      />
      <Column title="Perfil" dataIndex="perfil" render={(v) => v.toUpperCase()} />
      <Column title="Tipo de Conta" dataIndex="tipoContexto" />
    </Table>
  );
}

export default Contextos;
