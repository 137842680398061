import { Card, Table, Typography, Form, Input, Button } from 'antd';
import { useEffect } from 'react';
import { GetPlanos, SetPageHeader, SetSubscription } from '@actions/userActions';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { moneyMask } from '@utils/masks';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { useNavigate } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';

function Planos() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { planos, loadingPlanos, user } = useAppSelector((state) => state.user);
  const { paypal } = useAppSelector((state) => state.user);
  const [processando, SetProcessando] = useState(false);
  const [statusSubscription, SetStatusSubscription] = useState({ error: false, status: 'NULL' });
  const funcionalidades = [
    {
      funcionalidade: 'Período de avaliação',
      basico: '30 dias',
      intermediario: '30 dias',
      completo: '30 dias',
    },
    {
      funcionalidade: 'Limite de cadastro de unidades de negócio',
      basico: '1',
      intermediario: '5',
      completo: '100',
    },
    {
      funcionalidade: 'Limite de cadastro de empresas',
      basico: '1',
      intermediario: '5',
      completo: '100',
    },
    {
      funcionalidade: 'Importação de OFX',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Importação de Arquivos Excel',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Cadastro de Contas Bancárias',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Controle de Estoque',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Balanço',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'DRE',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Extratos',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Contas a Pagar e Receber',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Orçamentos',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Fluxo de Caixa',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Implantação de Saldos',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Adição de Usuários',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
    {
      funcionalidade: 'Controle de Perfis',
      basico: <CheckCircleTwoTone />,
      intermediario: <CheckCircleTwoTone />,
      completo: <CheckCircleTwoTone />,
    },
  ];
  useEffect(() => {
    dispatch(GetPlanos(true));
    dispatch(SetPageHeader({ title: 'Planos', subTitle: 'Contratação de plano' }));
  }, [dispatch]);
  const columns: any = planos && [
    {
      dataIndex: 'funcionalidade',
      title: user.isAuthenticated ? (
        <Card style={{ margin: 20 }}>
          <Typography.Text>
            Se você já realizou o pagamento e ainda continua vendo essa página, entre com o
            <strong> ID do perfil</strong> recebido por email para validar o pagamento
          </Typography.Text>
          <Form
            onFinish={(values) => {
              SetProcessando(true);
              const params = new URLSearchParams();
              params.append('grant_type', 'client_credentials');
              axios
                .post(`${paypal.api}/v1/oauth2/token`, params, {
                  headers: { Accept: 'application/json', 'Accept-Language': 'en_US' },
                  auth: {
                    username: paypal.clientid,
                    password: paypal.secret,
                  },
                })
                .then((res) => {
                  axios
                    .get(`${paypal.api}/v1/billing/subscriptions/${values.subscriptionID}`, {
                      headers: {
                        Authorization: `Bearer ${res.data.access_token}`,
                        'Content-Type': 'application/json',
                      },
                    })
                    .then((c) => {
                      SetProcessando(false);
                      SetStatusSubscription({ ...c.data, error: false });
                      if (c.data.status === 'ACTIVE') {
                        if (c.data.custom_id == user.grupoid)
                          dispatch(SetSubscription({ subscriptionID: c.data.id, planoID: c.data.custom_id }, paypal));
                      }
                    })
                    .catch((c) => {
                      SetProcessando(false);
                      SetStatusSubscription({ ...c.response.data, error: true, status: 'NULL' });
                    });
                });
            }}
          >
            <Form.Item
              label="ID do perfil"
              name="subscriptionID"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { len: 14, message: 'Formato errado' },
              ]}
            >
              <Input placeholder="I-T6TVFE8G1VCF" />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={processando}>
              Processar
            </Button>
          </Form>
          {statusSubscription.error && <Typography.Text type="danger">Compra não encontrada</Typography.Text>}
          {!statusSubscription.error && statusSubscription.status !== 'NULL' && (
            <Typography.Text type="warning">Compra se encontra com status: {statusSubscription.status}</Typography.Text>
          )}
        </Card>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" onClick={() => navigate('/Contratar')}>
            Experimentar Grátis por 30 dias
          </Button>
        </div>
      ),
    },
    ...planos.map((c, i) => ({
      dataIndex: i === 0 ? 'basico' : i === 1 ? 'intermediario' : 'completo',
      align: 'center',
      width: '20%',
      title: (
        <div>
          <Typography.Title level={1} style={{ marginBottom: 10 }}>
            {c.descricao}
          </Typography.Title>
          {user.isAuthenticated && (
            <PayPalButtons
              style={{ shape: 'rect', color: 'blue', layout: 'vertical', label: 'subscribe' }}
              createSubscription={(_, actions) =>
                actions.subscription.create({
                  plan_id: c.planoID,
                  custom_id: user.grupoid.toString(),
                })
              }
              onApprove={(data) => {
                dispatch(SetSubscription({ ...data, planoID: c.id }, paypal));
                return Promise.resolve();
              }}
            />
          )}
          <Typography.Title level={3} style={{ color: '#3b7ccc' }}>
            {moneyMask.format(c.valor)}
            <span style={{ fontSize: 12 }}>/mês</span>
          </Typography.Title>
        </div>
      ),
    })),
  ];
  return (
    <div>
      <Table
        size="small"
        loading={loadingPlanos}
        dataSource={funcionalidades}
        pagination={false}
        rowKey="id"
        columns={columns}
      />
    </div>
  );
}

export default Planos;
