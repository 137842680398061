import { Button } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { ResetContasPG } from '@actions/contasPGActions';
import { GetUnidades } from '@actions/generalActions';
import { GetPessoasGrouped } from '@actions/pessoasActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import ContasPGForm from './ContasPGForm';
import TabelaContasPGnova from './TabelaContasPGNova';

function ContasPGNovoView() {
  const dispatch = useAppDispatch();
  const [gerar, setGerar] = useState(true);
  useEffect(() => {
    dispatch(ResetContasPG());
    dispatch(GetPessoasGrouped());
    dispatch(GetUnidades(true, true));
  }, []);
  useEffect(() => {
    document.title = 'Nambi - Contas a Pagar/Receber';
    dispatch(
      SetPageHeader({
        title: 'Contas a pagar e receber',
        subTitle: 'Visualize as contas a pagar e receber em aberto',
        extras: [
          <Button key={0} size="small" type="primary" onClick={() => setGerar(true)}>
            Buscar
          </Button>,
        ],
      })
    );
  }, [dispatch]);
  return (
    <div>
      <ContasPGForm visible={gerar} setVisible={setGerar} />
      <TabelaContasPGnova />
    </div>
  );
}

export default ContasPGNovoView;
