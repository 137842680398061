import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { GetGruposDespesas } from '@actions/dashBoardActions';
import moment from 'moment';
import { Spin, Card, Empty } from 'antd';
import { doubleMask } from '@utils/masks';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ApexOptions } from 'apexcharts';

interface CustomApexOptions extends ApexOptions {
  toolbar: any;
}
function GruposDespesas() {
  const dispatch = useAppDispatch();
  const dash = useAppSelector((state) => state.dash);
  const [data, setData] = useState<{ series: number[]; options: CustomApexOptions }>({
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 350,
        height: 350,
        type: 'pie',
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp: any) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: 'zoom',
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return doubleMask.format(val) + '%';
          },
        },
      },
      labels: ['Categoria 1', 'Categoria 2', 'Categoria 3', 'Categoria 4', 'Categoria 5'],
      legend: {
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });
  useEffect(() => {
    dispatch(GetGruposDespesas(dash.unidades, moment(dash.competencia)));
  }, []);
  useEffect(() => {
    if (dash.gruposdespesas) {
      setData({
        ...data,
        options: {
          ...data.options,
          labels: dash.gruposdespesas.map((c) => c.grupo),
        },
        series: dash.gruposdespesas.map((c) => c.percent),
      });
    }
  }, [dash.gruposdespesas]);
  return (
    <Card
      title=" 10 Maiores despesas por grupo"
      size="small"
      style={{ maxWidth: 600, maxHeight: 400, minHeight: 400, minWidth: 600, verticalAlign: 'middle' }}
    >
      <Spin spinning={dash.gruposdespesasloading}>
        {dash.gruposdespesas.length > 0 && (
          <ReactApexChart options={data.options} series={data.series} type="pie" height={380} />
        )}
        {dash.gruposdespesas.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Não foram encontradas despesas lançadas para a competência e unidades escolhidas"
          >
            <a href="/Lancar">Clique aqui para realizar lançamentos</a>
          </Empty>
        )}
      </Spin>
    </Card>
  );
}

export default GruposDespesas;
