import {
  AddGrupo,
  AddSubgrupo,
  ChangeNovoGrupo,
  DeleteProdutos,
  EditarGrupo,
  EditarSubGrupo,
  ExcluirGrupo,
  ExcluirSubGrupo,
  GetGrupos,
  GetSaldosPorConta,
  HideProdutoForm,
  LoadProduto,
  NovoGrupoVisible,
  NovoSubGrupoVisible,
  SalvarProduto,
  SetSubgruposGrupoid,
  ShowProdutoForm,
  ShowProdutos,
  ShowSaldoPorConta,
} from '@actions/produtosActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Permission } from '@hooks/usePermissions';
import { Button, notification } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import cliente from '@config/axios';
import moment from 'moment';
import { Produto } from '@models/Produto';
import { ProdutosControllerInterface } from '@controllers/controllers';

export const ProdutosController: ProdutosControllerInterface = () => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    produtosAll,
    produtosloading,
    saldosPorConta,
    novogrupo,
    addgrupoloading,
    grupos,
    waiting,
    formvisible,
    produto,
    novogrupovisible,
    excluirgrupoloading,
    novosubgrupovisible,
    subgruposgrupoid,
  } = useAppSelector((state) => state.produtos);
  const ShowForm: () => void = () => dispatch(ShowProdutoForm());
  const HideForm: () => void = () => dispatch(HideProdutoForm());
  const NovoGrupo: () => void = () => dispatch(NovoGrupoVisible());
  const SetSubGrupoGrupoID: (id: number) => void = (id) => dispatch(SetSubgruposGrupoid(id));
  const NovoSubGrupo: () => void = () => dispatch(NovoSubGrupoVisible());
  const Load: (id: number) => void = (id) => dispatch(LoadProduto(id));
  const SaldoPorConta: (id: number, visible: boolean) => void = (produtoid, visible) =>
    dispatch(ShowSaldoPorConta({ visible: visible, produtoid: produtoid }));
  const GetSaldos: (id: number) => void = (id) => dispatch(GetSaldosPorConta(id));
  const Delete: (id: number) => void = (id) => dispatch(DeleteProdutos(id));
  const LoadGrupos: () => void = () => dispatch(GetGrupos());
  const NovoGrupoChange: (value: string) => void = (value) => dispatch(ChangeNovoGrupo(value));
  const Salvar: (values: Produto) => void = (values) => dispatch(SalvarProduto(values));
  const CriarGrupo: () => void = () => dispatch(AddGrupo(novogrupo));
  const CriarSubgrupo: () => void = () => dispatch(AddSubgrupo(novogrupo, subgruposgrupoid));
  const Excluir: (id: number) => void = (id) => dispatch(ExcluirGrupo(id));
  const ExcluirSubgrupo: (id: number) => void = (id) => dispatch(ExcluirSubGrupo(subgruposgrupoid, id));
  const Editar: (id: number, value: string) => void = (id, value) => dispatch(EditarGrupo(id, value));
  const EditarSubgrupo: (id: number, value: string) => void = (id, value) =>
    dispatch(EditarSubGrupo(id, subgruposgrupoid, value));
  useEffect(() => {
    document.title = 'Nambi - Produtos';
    dispatch(ShowProdutos());
    dispatch(GetGrupos());
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Produtos',
        subTitle: 'Cadastro de produtos para controle de estoque',
        extras: [
          <Permission key="0" permission="produto.list">
            <Button
              icon={<FileExcelOutlined />}
              size="small"
              type="default"
              loading={downloading}
              onClick={() => {
                setDownloading(true);
                cliente
                  .post(
                    '/api/Produtos/Exportar',
                    { produtos: produtosAll },
                    {
                      responseType: 'blob',
                    }
                  )
                  .then((res) => {
                    setDownloading(false);
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Produtos_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch(() => {
                    notification.error({ message: 'Erro ao gerar arquivo!' });
                    setDownloading(false);
                  });
              }}
            >
              Exportar
            </Button>
          </Permission>,
          <Permission key="1" permission="produto.add">
            <Button size="small" type="primary" onClick={() => ShowForm()}>
              <PlusOutlined /> Novo Produto
            </Button>
          </Permission>,
          <Permission key="2" permission="produto.add">
            <Button size="small" type="primary" onClick={() => NovoGrupo()}>
              Grupos
            </Button>
          </Permission>,
          <Permission key="3" permission="produto.add">
            <Button size="small" type="primary" onClick={() => NovoSubGrupo()}>
              Subgrupos
            </Button>
          </Permission>,
        ],
      })
    );
  }, [produtosAll, downloading]);

  return {
    data: {
      produtosAll,
      produtosloading,
      saldosPorConta,
      novogrupo,
      addgrupoloading,
      grupos,
      waiting,
      formvisible,
      produto,
      novogrupovisible,
      excluirgrupoloading,
      novosubgrupovisible,
      subgruposgrupoid,
    },
    actions: {
      ShowForm,
      HideForm,
      NovoGrupo,
      NovoSubGrupo,
      Load,
      SaldoPorConta,
      Delete,
      GetSaldos,
      LoadGrupos,
      NovoGrupoChange,
      CriarGrupo,
      Salvar,
      SetSubGrupoGrupoID,
      Excluir,
      Editar,
      CriarSubgrupo,
      ExcluirSubgrupo,
      EditarSubgrupo,
    },
  };
};
