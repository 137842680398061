import QuemSomos from './QuemSomos';
import Franqueado from './Franqueado';
import ContactForm from './Contato';
import Home from './Home';
import LoginForm from './LoginForm';
import ConfirmarEmail from './Contratar/ConfirmarEmail';
import ResetPassword from './ResetPassword';
import { Route, Routes } from 'react-router-dom';
import ContratarEmpresa from './Contratar/ContratarEmpresa';
import PrivacyPolicy from './PrivacyPolicy';
import Termos from './Termos';
import About from './About';
import Planos from './Contratar/Plano';

const HomePage = () => {
  return (
    <Routes>
      <Route path="Franqueado" element={<Franqueado />} />
      <Route path="QuemSomos" element={<QuemSomos />} />
      <Route path="Contratar" element={<ContratarEmpresa />} />
      <Route path="Empresa" element={<ContratarEmpresa />} />
      <Route path="Consultor" element={<ContratarEmpresa />} />
      <Route path="Contabilidade" element={<ContratarEmpresa />} />
      <Route path="Contato" element={<ContactForm />} />
      <Route path="Entrar" element={<LoginForm />} />
      <Route path="ConfirmEmail" element={<ConfirmarEmail />} />
      <Route path="ResetPassword" element={<ResetPassword />} />
      <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="Termos" element={<Termos />} />
      <Route path="About" element={<About />} />
      <Route path="Planos" element={<Planos />} />
      <Route path="/" element={<Home />} />
      <Route path="*">Page not Found</Route>
    </Routes>
  );
};

export default HomePage;
