import { Button, Space, Table, Tag, Tooltip } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatNumber } from '@utils/masks';
import moment from 'moment';
import { naturalSorter, orderDate } from '@utils/filterandsort';
import { EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@config/reduxStore';
import { Lancamento } from '@models/Lancamento';

function TabelaContasPGnova() {
  const { extrato, loadingTitulos, filtros } = useAppSelector((state) => state.contaspg);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (filtros.modo === 'Geral') {
      setData(extrato);
    } else if (filtros.modo === 'Pessoa') {
      let pessoas = [...new Set(extrato.map((c) => c.pessoa))];
      let unidades = [...new Set(extrato.map((c) => c.unidade))];
      let tempData = pessoas.map((p) => ({
        id: 'pessoa' + p,
        bid: '',
        status: '',
        baixadoPor: '',
        descricaoBloco: '',
        pessoa: p,
        nivel: 1,
        unidade: '',
        dataBaixa: null,
        dataVencimento: null,
        valor: extrato.filter((c) => c.pessoa === p).reduce((a, b) => a + b.valor, 0),
        children: unidades
          .filter((c) =>
            extrato
              .filter((d) => d.pessoa === p)
              .map((d) => d.unidade)
              .includes(c)
          )
          .map((u) => ({
            id: 'undiade' + u,
            bid: '',
            status: '',
            baixadoPor: '',
            descricaoBloco: '',
            pessoa: p,
            unidade: u,
            dataBaixa: null,
            dataVencimento: null,
            nivel: 2,
            valor: extrato.filter((c) => c.pessoa === p && c.unidade === u).reduce((a, b) => a + b.valor, 0),
            children: extrato
              .filter((c) => c.pessoa === p && c.unidade === u)
              .map((l) => ({
                ...l,
                id: 'lancamento' + l.id,
                nivel: 3,
              })),
          })),
      }));

      setData(tempData);
    } else if (filtros.modo === 'Unidade') {
      let pessoas = [...new Set(extrato.map((c) => c.pessoa))];
      let unidades = [...new Set(extrato.map((c) => c.unidade))];
      let tempData = unidades.map((u) => ({
        id: 'unidade' + u,
        bid: '',
        status: '',
        baixadoPor: '',
        descricaoBloco: '',
        unidade: u,
        nivel: 1,
        pessoa: '',
        dataBaixa: null,
        dataVencimento: null,
        valor: extrato.filter((c) => c.unidade === u).reduce((a, b) => a + b.valor, 0),
        children: pessoas
          .filter((c) =>
            extrato
              .filter((d) => d.unidade === u)
              .map((d) => d.pessoa)
              .includes(c)
          )
          .map((p) => ({
            id: 'pessoa' + p,
            bid: '',
            status: '',
            baixadoPor: '',
            descricaoBloco: '',
            pessoa: p,
            nivel: 2,
            unidade: u,
            dataBaixa: null,
            dataVencimento: null,
            valor: extrato.filter((c) => c.pessoa === p && c.unidade === u).reduce((a, b) => a + b.valor, 0),
            children: extrato
              .filter((c) => c.pessoa === p && c.unidade === u)
              .map((l) => ({
                ...l,
                id: 'lancamento' + l.id,
                nivel: 3,
              })),
          })),
      }));
      setData(tempData);
    }
  }, [extrato, filtros]);
  return (
    <div>
      <Table
        size="small"
        className="contaspg"
        dataSource={data}
        loading={loadingTitulos}
        rowKey="id"
        rowClassName={(row) => `nivel${row.nivel}`}
        scroll={{ x: 1000, y: 'calc(100vh - 250px)' }}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={11} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {formatNumber(data.reduce((a, b) => a + b.valor, 0))}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          dataIndex="id"
          fixed="left"
          width={120}
          render={(v, row: Lancamento) =>
            (row.nivel === 3 || filtros.modo === 'Geral') && (
              <Space>
                <Tooltip title="Alterar">
                  <Link to={`/Lancar?bloco=${row.bid}`}>
                    <Button icon={<EditOutlined />} size="small" />
                  </Link>
                </Tooltip>
                {!row.baixadoPor && (
                  <Tooltip title="Baixar">
                    <Link to={`/Lancar?Titulos=[${filtros.modo === 'Geral' ? v : v.replace(/lancamento/g, '')}]`}>
                      <Button icon={<DownloadOutlined />} size="small" />
                    </Link>
                  </Tooltip>
                )}
              </Space>
            )
          }
        />
        <Table.Column title="Bloco" dataIndex="bid" width={100} sorter={(a, b: Lancamento) => a.bid - b.bid} />
        <Table.Column
          title="Status"
          width={100}
          dataIndex="status"
          sorter={(a, b: Lancamento) => naturalSorter(a.status, b.status)}
          render={(v) => v && <Tag color={v === 'Aberto' ? 'yellow' : v === 'Vencido' ? 'red' : 'green'}>{v}</Tag>}
        />
        {false && (
          <Table.Column
            title="Conta"
            width={250}
            dataIndex="analitica"
            sorter={(a, b: Lancamento) => naturalSorter(a.analitica, b.analitica)}
          />
        )}
        <Table.Column
          title="Baixado Por"
          width={100}
          dataIndex="baixadoPor"
          sorter={(a, b: Lancamento) => naturalSorter(a.baixadoPor, b.baixadoPor)}
          render={(v) => v && <Link to={`/Lancar?bloco=${v}`}>{v}</Link>}
        />
        <Table.Column
          title="Descrição"
          width={200}
          dataIndex="descricaoBloco"
          sorter={(a, b: Lancamento) => naturalSorter(a.descricaoBloco, b.descricaoBloco)}
        />
        <Table.Column
          title="Vencimento"
          width={100}
          dataIndex="dataVencimento"
          sorter={(a, b: Lancamento) => orderDate(a.dataVencimento, b.dataVencimento)}
          render={(v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : '')}
        />
        <Table.Column
          title="Baixa"
          width={100}
          dataIndex="dataBaixa"
          sorter={(a, b: Lancamento) => orderDate(a.dataBaixa, b.dataBaixa)}
          render={(v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : '')}
        />
        <Table.Column
          title="Pessoa"
          width={200}
          dataIndex="pessoa"
          sorter={(a, b: Lancamento) => naturalSorter(a.pessoa as string, b.pessoa as string)}
        />
        <Table.Column
          title="Unidade"
          width={150}
          dataIndex="unidade"
          sorter={(a, b: Lancamento) => naturalSorter(a.unidade, b.unidade)}
        />
        <Table.Column
          title="Documento"
          width={150}
          dataIndex="documento"
          sorter={(a, b: Lancamento) => naturalSorter(a.documento, b.documento)}
        />
        <Table.Column
          title="Parcela"
          width={50}
          dataIndex="parcela"
          sorter={(a, b: Lancamento) => naturalSorter(a.parcela, b.parcela)}
        />
        <Table.Column
          title="Valor"
          width={150}
          dataIndex="valor"
          align="right"
          render={(v) => formatNumber(v)}
          sorter={(a, b: Lancamento) => a.valor - b.valor}
        />
      </Table>
    </div>
  );
}

export default TabelaContasPGnova;
