import { GetBancarias } from '@actions/bancariasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Select, SelectProps } from 'antd';
import { useEffect } from 'react';

function BancariaSelect(props: SelectProps) {
  const { bancarias, waiting } = useAppSelector((state) => state.bancarias);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetBancarias());
  }, []);
  return (
    <Select
      {...props}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth || false}
      loading={props.loading || waiting}
      optionFilterProp={props.optionFilterProp || 'children'}
      style={{ width: props?.style?.width || '100%' }}
    >
      {bancarias.map((c) => (
        <Select.Option value={c.id} key={c.id}>
          {`Banco: ${c.banco} AG: ${c.agencia} Conta: ${c.conta}`}
        </Select.Option>
      ))}
    </Select>
  );
}
export default BancariaSelect;
