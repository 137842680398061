import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  GetContasAnaliticas,
  GetImportacoes,
  Lancar,
  Load,
  Reset,
  Save,
  SetCampos,
  SetColumns,
  SetDescricao,
  SetSelectConta,
  SetSelectPessoa,
  SetSelectUnidade,
  SetSettings,
  SetValues,
} from '@actions/importarActions';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { ImportarReducer } from '@reducers/reducers';

const initialState: ImportarReducer = {
  importacoes: [],
  loadingImportacoes: false,
  loadingContas: false,
  contas: [],
  settings: {
    tipo: 0,
    dateFormat: 'DD/MM/YYYY',
    precision: 50,
    quando: 0,
  },
  campos: {},
  values: [],
  columns: [],
  loading: false,
  saving: false,
  descricao: '',
  data: moment(),
  importadas: 0,
  totalLinhas: 0,
  loaded: false,
  lancando: false,
  lancado: false,
  selectConta: {
    dataIndex: null,
    key: null,
    visible: false,
    index: null,
    tipo: null,
    unidadeID: null,
    position: 0,
    fluxo: 0,
  },
  selectUnidade: {
    visible: false,
    index: null,
    tipo: null,
    dataIndex: null,
  },
  selectPessoa: {
    visible: false,
    index: null,
    dataIndex: null,
  },
};

export default createReducer(initialState, (builder) => {
  builder.addCase(GetImportacoes.pending, (state) => {
    return {
      ...state,
      loadingImportacoes: true,
    };
  });
  builder.addCase(GetImportacoes.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar importações!' });
    return {
      ...state,
      loadingImportacoes: false,
    };
  });
  builder.addCase(GetImportacoes.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingImportacoes: false,
      importacoes: action.payload.data,
    };
  });
  builder.addCase(GetContasAnaliticas.pending, (state) => {
    return {
      ...state,
      loadingContas: true,
    };
  });
  builder.addCase(GetContasAnaliticas.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar contas!' });
    return {
      ...state,
      loadingContas: false,
    };
  });
  builder.addCase(GetContasAnaliticas.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingContas: false,
      contas: [...state.contas.filter((c) => c.unidadeID !== action.payload.unidadeID), action.payload],
    };
  });
  builder.addCase(SetSettings, (state, action) => {
    return {
      ...state,
      settings: action.payload,
    };
  });
  builder.addCase(SetValues, (state, action) => {
    return {
      ...state,
      totalLinhas: action.payload.length,
      values: action.payload,
    };
  });
  builder.addCase(SetCampos, (state, action) => {
    return {
      ...state,
      campos: action.payload,
    };
  });
  builder.addCase(SetColumns, (state, action) => {
    return {
      ...state,
      columns: action.payload,
    };
  });
  builder.addCase(SetDescricao, (state, action) => {
    return {
      ...state,
      descricao: action.payload,
    };
  });
  builder.addCase(SetSelectConta, (state, action) => {
    return {
      ...state,
      selectConta: action.payload,
    };
  });
  builder.addCase(SetSelectUnidade, (state, action) => {
    return {
      ...state,
      selectUnidade: action.payload,
    };
  });
  builder.addCase(SetSelectPessoa, (state, action) => {
    return {
      ...state,
      selectPessoa: action.payload,
    };
  });
  builder.addCase(Save.pending, (state) => {
    return {
      ...state,
      saving: true,
    };
  });
  builder.addCase(Save.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar contas!' });
    return {
      ...state,
      saving: false,
    };
  });
  builder.addCase(Save.fulfilled, (state, action: extendedAction) => {
    notification.success({ message: 'Importação salva com sucesso!' });
    if (action.payload.data.novo) {
      window.location.href = '/NovaImportacao/' + action.payload.data.id;
    }
    return {
      ...state,
      saving: false,
    };
  });
  builder.addCase(Load.pending, (state) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(Load.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar contas!' });
    return {
      ...state,
      loading: false,
    };
  });
  builder.addCase(Load.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      campos: action.payload.data.campos,
      settings: action.payload.data.settings,
      columns: action.payload.data.columns,
      values: action.payload.data.values,
      totalLinhas: action.payload.data.totalLinhas,
      importadas: action.payload.data.importadas,
      precision: action.payload.data.precision,
    };
  });
  builder.addCase(Lancar.pending, (state) => {
    return {
      ...state,
      lancando: true,
      lancado: false,
    };
  });
  builder.addCase(Lancar.rejected, (state) => {
    notification.error({ message: 'Erro ao importar lançamento!' });
    return {
      ...state,
      lancando: false,
    };
  });
  builder.addCase(Lancar.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      lancado: true,
      lancando: false,
      importadas: state.importadas + 1,
      values: state.values.map((c) =>
        c.key === action.payload.data.key ? { ...c, bloco: action.payload.data.bloco, done: true } : c
      ),
    };
  });
  builder.addCase(Reset, (state) => {
    return {
      ...state,
      ...initialState,
    };
  });
});
