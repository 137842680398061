export const Bancos = {
  ['Banco do Brasil']: 'Banco do Brasil',
  ['Santander']: 'Santander',
  ['Bradesco']: 'Bradesco',
  ['Itau']: 'Itau',
  ['Nubank']: 'Nubank',
  ['XP']: 'XP',
  ['Sicred']: 'Sicred',
  ['Safra']: 'Safra',
  ['Caixa Econômica Federal']: 'Caixa Econômica Federal',
  ['Outro']: 'Outro',
};
