import { Typography } from 'antd';
import { useAppSelector } from '@config/reduxStore';
import FormCadastro from './FormCadastro';

function ContratarEmpresa() {
  const { signUp } = useAppSelector((state) => state.user);
  return (
    <div>
      {signUp.success ? (
        <Typography.Title level={4}>Um email de confirmação foi enviado para o email cadastrado</Typography.Title>
      ) : (
        <FormCadastro />
      )}
    </div>
  );
}

export default ContratarEmpresa;
