import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import App from '@views/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from '@config/reduxStore';
import ptBR from 'antd/es/locale/pt_BR';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { PermissionProvider } from '@hooks/usePermissions';
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: true,
    },
  },
});
root.render(
  <BrowserRouter>
    <ConfigProvider locale={ptBR}>
      <Provider store={store}>
        <PermissionProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </PermissionProvider>
      </Provider>
    </ConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
