import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Typography, Tooltip } from 'antd';
import { moneyMaskNeg } from '@utils/masks';
import '../../../assets/scss/Balanco.scss';
import { ColumnsType } from 'antd/lib/table';
import { BalancoControllerInterface } from '@controllers/controllers';

const { Text } = Typography;

function TabelaBalanco({ controller }: { controller: BalancoControllerInterface }) {
  const { actions, data } = controller();
  const formatNumber = (number: number, nivel: number, id: number, tempo: number) => {
    return (
      <Tooltip title="Ver lançamentos">
        <Text
          style={{ cursor: 'pointer' }}
          type={number < 0 ? 'danger' : 'secondary'}
          onClick={() => actions.ShowLancamentos(nivel, id, tempo)}
        >
          {moneyMaskNeg.format(number)}
        </Text>
      </Tooltip>
    );
  };
  const columns: ColumnsType<any> = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      width: 130,
      render: (_, record) => (record.nivel === 6 ? '' : record.codigo),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      width: 400,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title="Ir para extrato">
          <Link to={`/Extrato?ID=${record.id}&Nivel=${record.nivel}&Unidades=${JSON.stringify(data.balanco.unidades)}`}>
            {record.nivel === 5
              ? record.bancaria
                ? record.unidade + ' | ' + text + ' - ' + record.bancaria
                : record.unidade + ' | ' + text
              : text}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: 'Saldo ' + moment(data.balanco.competencia).subtract(1, 'year').format('YYYY'),
      dataIndex: 'saldoAnoAnterior',
      align: 'right',
      render: (text, row) => formatNumber(text, row.nivel, row.id, 1),
    },
    {
      title: 'Créditos até ' + moment(data.balanco.competencia).format('MMM/YYYY'),
      dataIndex: 'creditosCompetencia',
      align: 'right',
      render: (text, row) => formatNumber(text, row.nivel, row.id, 2),
    },
    {
      title: 'Débitos até ' + moment(data.balanco.competencia).format('MMM/YYYY'),
      dataIndex: 'debitosCompetencia',
      align: 'right',
      render: (text, row) => formatNumber(text, row.nivel, row.id, 3),
    },
    {
      title: 'Saldo Total',
      dataIndex: 'saldoTotal',
      align: 'right',
      render: (text, row) => formatNumber(text, row.nivel, row.id, 4),
    },
  ];
  return (
    <div>
      <Table
        size="small"
        style={{ fontSize: 9 }}
        rowKey="id"
        scroll={{ y: 'calc(100vh - 190px)', x: 1000 }}
        columns={columns}
        className="balancoprint"
        pagination={false}
        loading={data.balanco.waiting}
        dataSource={data.balanco.balanco.map((c: any) => c)}
        rowClassName={(record) => `nivel${record.nivel}`}
      />
    </div>
  );
}
export default TabelaBalanco;
