import { apiEndpoints } from '@config/apiEndpoints';
import { cliente } from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { ContasPGFormData } from '@views/EmpresaContext/ContasPG/ContasPGForm';

export const GetContasContasPG = function (unidadeid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_CONTASPG',
      payload: unidadeid,
    });
  };
};
export const ChangeTituloContasPG = function (value: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_TITULOS_CONTASPG',
      payload: value,
    });
  };
};
export const GetContasExtrato = function (unidades: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLEAR_CONTA_TITLE',
    });
    dispatch({
      type: 'GET_CONTAS_EXTRATO',
      payload: unidades,
    });
    dispatch({
      type: 'GET_CONTAS_EXTRATO',
      payload: cliente.post(apiEndpoints.CONTASPG.GET_CONTAS, { unidades: unidades, selectall: false }),
    });
  };
};
export const GetTitulosContasPG = function (values: ContasPGFormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_TITULOS_CONTASPG',
      payload: cliente.post(apiEndpoints.CONTASPG.GET_TITULOS_CONTASPG, values),
    });
  };
};
export const SetFiltros = function (value: ContasPGFormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_FILTROS',
      payload: value,
    });
  };
};
export const SetContaExtrato = function (value: number) {
  return function (dispatch: AppDispatch) {
    if (!value)
      dispatch({
        type: 'CLEAR_CONTA_TITLE',
      });
    dispatch({
      type: 'SET_CONTA_EXTRATO',
      payload: {
        value: value,
      },
    });
  };
};
export const SetContaTitle = function (title: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_CONTA_TITLE',
      payload: {
        title: title,
      },
    });
  };
};
export const SetFilterPessoas = function (values: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_FILTER_PESSOAS_CONTASPG',
      payload: values,
    });
  };
};
export const ResetContasPG = function () {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'RESET_CONTASPG' });
  };
};
