import { useEffect } from 'react';
import moment from 'moment';
import { Table, Tabs, Card, Typography, Tooltip } from 'antd';
import { GetContasPagar } from '@actions/dashBoardActions';
import { formatNumber } from '@utils/masks';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Lancamento } from '@models/Lancamento';
const { Text } = Typography;
const { Column } = Table;

const Lancamentos = ({ data }: { data: Lancamento[] }) => (
  <Table
    scroll={{ y: 210 }}
    dataSource={data}
    size="small"
    rowKey="id"
    pagination={{
      position: ['bottomRight'],
      size: 'small',
      showSizeChanger: true,
      pageSizeOptions: [10, 25, 50],
      showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
    }}
  >
    <Column
      title="Bloco"
      dataIndex="bloco"
      render={(text, row: Lancamento) => (
        <Tooltip title="Baixar">
          <a href={`/Lancar?Titulos=[${row.id}]`}>{text}</a>
        </Tooltip>
      )}
    />
    <Column title="Vencimento" dataIndex="dataVencimento" render={(text) => moment(text).format('DD/MM/YYYY')} />
    <Column title="Descrição" width="40%" dataIndex="descricaoBloco" ellipsis={true} />
    <Column title="Valor" align="right" dataIndex="valor" render={(text) => formatNumber(text)} width={120} />
  </Table>
);

function ContasPagar() {
  const dispatch = useAppDispatch();
  const dash = useAppSelector((state) => state.dash);
  useEffect(() => {
    dispatch(GetContasPagar(dash.unidades));
  }, []);
  return (
    <Card
      size="small"
      loading={dash.contaspagarloading}
      title={
        <Text>
          Contas a pagar{' '}
          <Text style={{ float: 'right' }} type="danger">
            <ArrowDownOutlined />
            {formatNumber(
              dash.contaspagar.reduce(function (a, b) {
                return a + b.valor;
              }, 0)
            )}
          </Text>
        </Text>
      }
      style={{
        maxWidth: 600,
        maxHeight: 400,
        minHeight: 400,
        minWidth: 600,
      }}
    >
      <Tabs
        size="small"
        items={[
          {
            label: 'A vencer',
            key: '1',
            children: (
              <Lancamentos data={dash.contaspagar.filter((item) => moment(item.dataVencimento).isAfter(moment()))} />
            ),
          },
          {
            label: 'Vencidos',
            key: '2',
            children: (
              <Lancamentos data={dash.contaspagar.filter((item) => moment(item.dataVencimento).isBefore(moment()))} />
            ),
          },
          {
            label: 'Todos',
            key: '3',
            children: <Lancamentos data={dash.contaspagar} />,
          },
        ]}
      />
    </Card>
  );
}

export default ContasPagar;
