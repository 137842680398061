import { notification } from 'antd';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { DasBoardReducer } from '@reducers/reducers';

var initialState: DasBoardReducer = {
  waiting: false,
  contaspagarloading: false,
  contasreceberloading: false,
  contaspagar: [],
  contasreceber: [],
  unidades: [],
  resultado: [],
  competencia: moment(new Date()),
  resultadoloading: false,
  gruposdespesasloading: false,
  gruposdespesas: [],
};

const dashBoardReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SET_UNIDADE_ONLY':
      state = {
        ...state,
        unidades: [action.payload],
      };
      break;
    case 'GET_RESULTADO_HOME_PENDING':
      state = {
        ...state,
        resultadoloading: true,
      };
      break;
    case 'GET_RESULTADO_HOME_REJECTED':
      notification.error({ message: 'Erro ao carregar resultados!' });
      state = {
        ...state,
        resultadoloading: false,
      };
      break;
    case 'SET_COMPETENCIA_HOME':
      state = {
        ...state,
        competencia: action.payload,
      };
      break;
    case 'SELECT_UNIDADES_HOME':
      state = {
        ...state,
        unidades: action.payload,
      };
      break;
    case 'GET_RESULTADO_HOME_FULFILLED':
      state = {
        ...state,
        resultadoloading: false,
        resultado: action.payload.data,
      };
      break;
    case 'GET_CONTAS_PAGAR_HOME_PENDING':
      state = {
        ...state,
        contaspagarloading: true,
      };
      break;
    case 'GET_CONTAS_RECEBER_HOME_PENDING':
      state = {
        ...state,
        contasreceberloading: true,
      };
      break;
    case 'GET_CONTAS_PAGAR_HOME_FULFILLED':
      state = {
        ...state,
        contaspagarloading: false,
        contaspagar: action.payload.data,
      };
      break;
    case 'GET_CONTAS_PAGAR_HOME_REJECTED':
      notification.error({ message: 'Erro ao carregar contas a pagar!' });
      state = {
        ...state,
        contaspagarloading: false,
      };
      break;
    case 'GET_CONTAS_RECEBER_HOME_REJECTED':
      notification.error({ message: 'Erro ao carregar contas a receber!' });
      state = {
        ...state,
        contasreceberloading: false,
      };
      break;
    case 'GET_CONTAS_RECEBER_HOME_FULFILLED':
      state = {
        ...state,
        contasreceberloading: false,
        contasreceber: action.payload.data,
      };
      break;
    case 'GET_GRUPOS_DESPESAS_HOME_FULFILLED':
      state = {
        ...state,
        gruposdespesasloading: false,
        gruposdespesas: action.payload.data.result,
      };
      break;
    case 'GET_GRUPOS_DESPESAS_HOME_PENDING':
      state = {
        ...state,
        gruposdespesasloading: true,
      };
      break;
    case 'GET_GRUPOS_DESPESAS_HOME_REJECTED':
      notification.error({ message: 'Erro ao carregar grupos de despesas!' });
      state = {
        ...state,
        gruposdespesasloading: false,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default dashBoardReducer;
