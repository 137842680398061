import { ILancamento, ImportData } from '@models/Conciliar';
import { Button, Modal } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Lancar } from '@actions/conciliarActions';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

function ConciliarTableMenu({ v, row }: { v: string; row?: ImportData; index?: number }) {
  const dispatch = useAppDispatch();
  const { data, settings } = useAppSelector((state) => state.conciliar);
  const disable = !settings.bancariaID || !settings.contaID || !row.unidadeID || !row.contaID;
  const handleLancamento = () => {
    const tempData = data.find((c) => c.id === v);
    const valorTitulos = tempData?.titulos?.reduce((a, b) => a + b.valor, 0);
    const lancar = () => {
      const lancamento: ILancamento = {
        total0: tempData.valor,
        rowID: tempData.id,
        descricao: tempData.descricao,
        dataTransacao: tempData.data,
        padrao: tempData.transferencia ? 1 : 0,
        inverter: tempData.valor >= 0 ? false : true,
        bloco: '',
        total1: tempData.valor,
        saldo: 0,
        lancamentotipo0:
          tempData?.titulos?.length > 0
            ? tempData?.titulos.map((c) => ({
                valor: -1 * c.valor,
                tipo: c.valor < 0 ? 2 : 3,
                contaID: Number(String(c.analiticaID).slice(2, String(c.analiticaID).length)),
                multa: 0,
                desconto: 0,
                juros: 0,
                id: c.id,
                documento: tempData.documento,
                quantidade: 0,
                pessoaID: c.pessoaID,
                competencia: c.dataVencimento,
                titulo: true,
                unidadeID: c.unidadeNegocioID,
                baixaEstoque: false,
                baixou: '',
                estoque: false,
              }))
            : [
                {
                  valor: -1 * tempData.valor,
                  tipo: tempData.transferencia ? 4 : tempData.valor < 0 ? 0 : 1,
                  contaID: tempData.contaID,
                  multa: 0,
                  desconto: 0,
                  juros: 0,
                  documento: tempData.documento,
                  quantidade: 0,
                  pessoaID: tempData.pessoaID,
                  competencia: tempData.data,
                  titulo: false,
                  unidadeID: tempData.unidadeID,
                  baixaEstoque: false,
                  baixou: '',
                  estoque: false,
                },
              ],
        lancamentotipo1: [
          {
            valor: tempData.valor,
            quando: 0,
            unidadeID: settings.unidadeID,
            quantidade: 0,
            parcela: [],
            vparcela: 0,
            parcelas: 0,
            periodoparcela: 0,
            ondeID: settings.contaID,
          },
        ],
      };
      dispatch(Lancar(lancamento));
    };
    if (tempData.valor !== valorTitulos && tempData?.titulos?.length > 0) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: 'Altere o título para fazer o lançamento',
        title: 'Valor do título difere do lançamento',
        cancelButtonProps: {
          hidden: true,
        },
      });
    } else lancar();
  };
  if (row.bloco)
    return (
      <Link to={`/Lancar?bloco=${row.bloco}`} target="_blank">
        {row.bloco}
      </Link>
    );
  return (
    <div>
      <Button
        size="small"
        icon={<CheckCircleTwoTone twoToneColor={disable ? 'red' : 'green'} />}
        disabled={disable}
        onClick={() => handleLancamento()}
      />
    </div>
  );
}
export default ConciliarTableMenu;
