import { notification } from 'antd';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { ExtratoReducer } from '@reducers/reducers';

var initialState: ExtratoReducer = {
  unidades: [],
  nivel: null,
  id: null,
  extrato: [],
  totaldebito: 0,
  totalcredito: 0,
  saldototal: 0,
  contas: [],
  contaid: null,
  periodoinicio: moment(new Date()).subtract(30, 'days'),
  periodofim: moment(new Date()),
  waiting: false,
  validateunidade: '',
  validadeperiodo: '',
  validateconta: '',
  unidade: null,
  validatebancaria: '',
  selectedpessoas: [],
  selectedlancamentos: [],
  choseunidademodal: false,
  unidadeschoose: [],
  lancamentosresultado: [],
  deleteids: [],
  confirmdeletemodal: false,
  tipoconta: 'bancaria',
  bancarias: [],
  bancaria: null,
  contatitle: '',
  contasmoverview: false,
  contasmover: [],
  loadingcontas: false,
  unidademover: null,
  selectedcontamover: [],
  resultadosmover: [],
};

const extratoReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SET_TIPO_NORMAL_EXTRATO_DEFAULT_EXTRATOS':
      state = {
        ...state,
        tipoconta: 'normal',
      };
      break;
    case 'MOVER_LANCAMENTOS_EXTRATO_PENDING':
      state = {
        ...state,
        contasmoverview: false,
        waiting: true,
      };
      break;
    case 'MOVER_LANCAMENTOS_EXTRATO_REJECTED':
      notification.error({ message: 'Erro ao mover lançamentos!' });
      state = {
        ...state,
        contasmoverview: false,
        waiting: false,
      };
      break;
    case 'MOVER_LANCAMENTOS_EXTRATO_FULFILLED':
      state = {
        ...state,
        waiting: false,
        resultadosmover: action.payload,
      };
      break;
    case 'SET_CONTA_MOVER_EXTRATO':
      state = {
        ...state,
        selectedcontamover: action.payload,
      };
      break;
    case 'GET_CONTAS_MOVER_EXTRATO_PENDING':
      state = {
        ...state,
        loadingcontas: true,
      };
      break;
    case 'GET_CONTAS_MOVER_EXTRATO_FULFILLED':
      state = {
        ...state,
        loadingcontas: false,
        contasmover: action.payload.data,
      };
      break;
    case 'CHANGE_UNIADE_MOVER_EXTRATO':
      state = {
        ...state,
        unidademover: action.payload,
      };
      break;
    case 'SHOW_CONTAS_MOVER_EXTRATO':
      state = {
        ...state,
        contasmoverview: action.payload,
      };
      break;
    case 'SET_UNIDADE_ONLY':
      state = {
        ...state,
        unidades: [action.payload],
        unidademover: Number(action.payload),
      };
      break;
    case 'SET_CONTAS_BANCARIA_EXTRATO':
      state = {
        ...state,
        bancaria: action.payload.value,
        contatitle: action.payload.title,
      };
      break;
    case 'GET_CONTAS_BANCARIAS_EXTRATO_FULFILLED':
      state = {
        ...state,
        bancarias: action.payload.data,
      };
      break;
    case 'CHANGE_TIPO_CONTA_EXTRATO':
      state = {
        ...state,
        tipoconta: action.payload,
        bancaria: action.payload === 'bancaria' ? state.bancaria : null,
        contaid: action.payload === 'bancaria' ? null : state.contaid,
        nivel: action.payload === 'bancaria' ? null : state.nivel,
        unidades: action.payload === 'bancaria' ? [] : state.unidades,
        contas: action.payload === 'bancaria' ? [] : state.contas,
        contatitle: '',
      };
      break;
    case 'GET_CONTAS_EXTRATO_FULFILLED':
      state = {
        ...state,
        contas: action.payload.data,
        //contaid: null,
        waiting: false,
      };
      break;
    case 'GET_CONTAS_EXTRATO':
      state = {
        ...state,
        unidades: action.payload,
      };
      break;
    case 'GET_CONTAS_EXTRATO_PENDING':
      state = {
        ...state,
        validateunidade: '',
        waiting: true,
      };
      break;
    case 'SET_CONTA_EXTRATO':
      state = {
        ...state,
        contaid: action.payload.value,
        nivel: action.payload.nivel,
        contatitle: action.payload.title,
      };
      break;
    case 'GERAR_EXTRATO_PENDING':
      state = {
        ...state,
        validateconta: '',
        waiting: true,
      };
      break;
    case 'GERAR_EXTRATO_REJECTED':
      notification.error({ message: 'Erro ao gerar extrato!' });
      state = {
        ...state,
        validateconta: '',
        waiting: false,
      };
      break;
    case 'GERAR_EXTRATO_FULFILLED':
      state = {
        ...state,
        extrato: action.payload.data.result,
        totalcredito: action.payload.data.totalcredito,
        totaldebito: action.payload.data.totaldebito,
        saldototal: action.payload.data.saldo,
        waiting: false,
      };
      break;
    case 'SET_COMPETENCIA_INICIO_EXTRATO':
      state = {
        ...state,
        periodoinicio: action.payload,
        validadeperiodo: '',
      };
      break;
    case 'SET_COMPETENCIA_FIM_EXTRATO':
      state = {
        ...state,
        periodofim: action.payload,
        validadeperiodo: '',
      };
      break;
    case 'SET_FILTER_PESSOAS_EXTRATO':
      state = {
        ...state,
        selectedpessoas: action.payload,
      };
      break;
    case 'SET_FILTER_PESSOAS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'SET_FILTER_PESSOAS_FULFILLED':
      state = {
        ...state,
        extrato: action.payload.data.result,
        totalcredito: action.payload.data.totalcredito,
        totaldebito: action.payload.data.totaldebito,
        saldototal: action.payload.data.saldo,
        waiting: false,
      };
      break;
    case 'RESET_EXTRATO':
      state = {
        ...initialState,
      };
      break;
    case 'SET_ERROR_EXTRATO':
      state = {
        ...state,
        validadeperiodo: action.payload.validadeperiodo,
        validateconta: action.payload.validateconta,
        validateunidade: action.payload.validateunidade,
        validatebancaria: action.payload.validatebancaria,
      };
      break;
    case 'SELECTED_LANCAMENTOS_EXTRATO':
      state = {
        ...state,
        selectedlancamentos: action.payload,
      };
      break;
    case 'GET_UNIDADES_CHOOSE_FULFILLED':
      state = {
        ...state,
        unidadeschoose: action.payload.data,
      };
      break;
    case 'EXCLUIR_LANCAMENTO_EXTRATO_PENDING':
      state = {
        ...state,
        deleteids: action.payload,
        waiting: true,
      };
      break;
    case 'CONFIRM_DELETE_LANCAMENTOS_EXTRATO':
      state = {
        ...state,
        waiting: false,
        extrato: state.extrato.filter((c) => !action.payload.data.includes(c.id)),
        confirmdeletemodal: true,
      };
      break;
    case 'CLOSE_MODAL_CONFIRM_DELETE_EXTRATO':
      state = {
        ...state,
        waiting: false,
        confirmdeletemodal: false,
        selectedlancamentos: [],
      };
      break;
    default:
      return state;
  }

  return state;
};

export default extratoReducer;
