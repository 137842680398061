import { useEffect } from 'react';
import { Button, Typography, Space, Alert } from 'antd';
import { Permission } from '@hooks/usePermissions';
import { LoadEmpresas, Reset, HideEmpresaForm, ChangeContextoName } from '@actions/empresaActions';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import EmpresaForm from './EmpresaForm';
import EmpresasTabs from './EmpresasTabs';
import UnidadeForm from './UnidadeForm';
import { GetUnidades } from '@actions/generalActions';
import { SetPageHeader } from '@actions/userActions';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { Paragraph } = Typography;

function EmpresaView() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { unidades } = useAppSelector((state) => state.general);
  const { empresas } = useAppSelector((state) => state.empresa);
  useEffect(() => {
    document.title = 'Nambi - Empresa';
    dispatch(LoadEmpresas());
    dispatch(Reset());
    dispatch(GetUnidades(false));
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Empresa',
        subTitle: 'Configure aqui a estrutura da sua empresa ou grupo empresarial',
        extras: [
          <Space direction="horizontal" key={0} align="baseline">
            <Permission permission="empresa.edit">
              <Paragraph
                editable={{
                  onChange: (value) => dispatch(ChangeContextoName(value)),
                }}
              >
                {user.nome}
              </Paragraph>
            </Permission>
            <Permission permission="empresa.add" key={0}>
              <Button
                size="small"
                type="primary"
                onClick={() => dispatch(HideEmpresaForm())}
                disabled={empresas.length >= user.plano.empresas}
              >
                <PlusOutlined /> Nova Empresa
              </Button>
            </Permission>
            <Link to={'/PlanoContas'} target="_blank" key={1}>
              <Button size="small" type="default" icon={<SettingOutlined />}>
                Plano de Contas
              </Button>
            </Link>
          </Space>,
        ],
      })
    );
  }, [user.nome, empresas, user.plano]);
  return (
    <div style={{ width: '100%' }}>
      {empresas.length >= user.plano.empresas && (
        <Alert
          description="Você atingiu o limite máximo de empresas do seu plano"
          type="info"
          showIcon
          closable
          action={
            <Link key={0} to="/Planos">
              Alterar Plano
            </Link>
          }
        />
      )}
      {unidades.length >= user.plano.unidades && (
        <Alert
          description="Você atingiu o limite máximo de unidades de negócio do seu plano"
          type="info"
          closable
          showIcon
          action={
            <Link key={0} to="/Planos">
              Alterar Plano
            </Link>
          }
        />
      )}
      <EmpresaForm />
      <EmpresasTabs />
      <UnidadeForm />
    </div>
  );
}

export default EmpresaView;
