import axios from '@config/axios';
import moment from 'moment';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { AppDispatch } from '@config/reduxStore';
import { UnidadeNegocio } from '@models/UnidadeNegocio';
import { apiEndpoints } from '@config/apiEndpoints';
import { TipoConta } from '@reducers/reducers';

export const MoverLancamentos = function (
  lancamentos: number[],
  destino: number,
  nivel: number,
  contaid: number,
  datainicial: moment.Moment,
  datafinal: moment.Moment,
  pessoas: number[],
  unidades: number[],
  bancaria: number,
  tipoconta: TipoConta
) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'MOVER_LANCAMENTOS_EXTRATO_PENDING',
    });
    axios
      .post(apiEndpoints.EXTRATO.MOVER, {
        lancamentos: lancamentos,
        destino: destino,
      })
      .then((res) => {
        dispatch({
          type: 'MOVER_LANCAMENTOS_EXTRATO_FULFILLED',
          params: res.data,
        });
        confirm({
          title: 'Lançamentos movidos com sucesso!',
          content: res.data.map((c: any) => <div key={c}>{c}</div>),
          okText: 'OK',
          cancelButtonProps: { hidden: true },
        });
        dispatch(SelectedLancamentos([]));
        dispatch(GerarExtrato(nivel, contaid, datainicial, datafinal, pessoas, unidades, bancaria, tipoconta));
      })
      .catch(() => dispatch({ type: 'MOVER_LANCAMENTOS_EXTRATO_REJECTED' }));
  };
};
export const SetContaMoverExtrato = function (value: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_CONTA_MOVER_EXTRATO',
      payload: value,
    });
  };
};
export const ShowContasMover = function (value: boolean, unidade?: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_CONTAS_MOVER_EXTRATO',
      payload: value,
    });
    if (unidade && value) dispatch(GetContasMover(unidade));
  };
};
export const ChangeUnidadeMover = function (value: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_UNIADE_MOVER_EXTRATO',
      payload: value,
    });
    dispatch(GetContasMover(value));
    dispatch(SetContaMoverExtrato([]));
  };
};
export const GetContasMover = function (unidade: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_MOVER_EXTRATO',
      payload: axios.get(apiEndpoints.EXTRATO.GET_CONTAS_MOVER, { params: { unidade: unidade } }),
    });
  };
};
export const SetBancariaExtrato = function (value: number, title?: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_CONTAS_BANCARIA_EXTRATO',
      payload: {
        value: value,
        title: title,
      },
    });
  };
};
export const GetBancariasExtrato = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_BANCARIAS_EXTRATO',
      payload: axios.get(apiEndpoints.EXTRATO.GET_BANCARIAS),
    });
  };
};
export const ChangeTipoConta = function (value: TipoConta, unidades?: UnidadeNegocio[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_TIPO_CONTA_EXTRATO',
      payload: value,
    });
    if (value === 'normal' && unidades.length === 1) {
      dispatch(GetContasExtrato([]));
    }
  };
};
export const GetContasExtrato = function (unidades: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_EXTRATO',
      payload: unidades,
    });
    dispatch({
      type: 'GET_CONTAS_EXTRATO',
      payload: axios.post(apiEndpoints.EXTRATO.GET_CONTAS, {
        unidades: unidades,
        selectall: true,
      }),
    });
  };
};
export const SetContaExtrato = function (value: number, nivel?: number, title?: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_CONTA_EXTRATO',
      payload: {
        value: value,
        nivel: nivel,
        title: title,
      },
    });
  };
};

export const GerarExtrato = function (
  nivel: number,
  contaid: number,
  datainicial: moment.Moment,
  datafinal: moment.Moment,
  pessoas: number[],
  unidades: number[],
  bancaria: number,
  tipoconta: TipoConta
) {
  return function (dispatch: AppDispatch) {
    if (contaid === null && tipoconta === 'normal' && pessoas.length === 0) {
      dispatch({
        type: 'SET_ERROR_EXTRATO',
        payload: {
          validateunidade: '',
          validateconta: 'Escolha uma conta ou pessoa',
          validateperiodo: '',
          validatebancaria: '',
        },
      });
    } else if (bancaria === null && tipoconta === 'bancaria') {
      dispatch({
        type: 'SET_ERROR_EXTRATO',
        payload: {
          validateunidade: '',
          validateconta: '',
          validateperiodo: '',
          validatebancaria: 'Escolha uma conta',
        },
      });
    } else if (datafinal === null || datainicial === null) {
      dispatch({
        type: 'SET_ERROR_EXTRATO',
        payload: {
          validateunidade: '',
          validateconta: '',
          validadeperiodo: 'Escolha o período',
          validatebancaria: '',
        },
      });
    } else {
      dispatch({
        type: 'GERAR_EXTRATO',
        payload: axios.post('/api/Extrato/GetExtrato', {
          nivel: nivel,
          contaid: contaid,
          datainicial: moment(datainicial).format('YYYY-MM-DD'),
          datafinal: moment(datafinal).format('YYYY-MM-DD'),
          pessoas: pessoas,
          unidades: unidades || [],
          bancaria: bancaria,
        }),
      });
      dispatch(SelectedLancamentos([]));
    }
  };
};
export const SetCompetenciaInicioExtrato = function (value: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_COMPETENCIA_INICIO_EXTRATO',
      payload: value,
    });
  };
};
export const SetCompetenciaFimExtrato = function (value: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_COMPETENCIA_FIM_EXTRATO',
      payload: value,
    });
  };
};
export const SetFilterPessoas = function (values: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_FILTER_PESSOAS_EXTRATO',
      payload: values,
    });
  };
};

export const ResetExtrato = function () {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'RESET_EXTRATO' });
  };
};

export const SelectedLancamentos = function (values: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_LANCAMENTOS_EXTRATO',
      payload: values,
    });
  };
};

export const SetDefault = function (
  nivel: number,
  contaid: number,
  datainicial: moment.Moment,
  datafinal: moment.Moment,
  pessoas: number[],
  unidades: number[],
  bancaria: number,
  tipoconta: TipoConta
) {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'SET_TIPO_NORMAL_EXTRATO_DEFAULT_EXTRATOS' });
    dispatch(GetContasExtrato(unidades));
    dispatch(SetContaExtrato(contaid, nivel));
    dispatch(GerarExtrato(nivel, contaid, datainicial, datafinal, pessoas, unidades, bancaria, tipoconta));
  };
};
export const SetDefault2 = function (
  nivel: number,
  contaid: number,
  datainicial: moment.Moment,
  datafinal: moment.Moment,
  pessoas: number[],
  unidades: number[],
  bancaria: number,
  tipoconta: TipoConta
) {
  return function (dispatch: AppDispatch) {
    dispatch(ChangeTipoConta(tipoconta));
    dispatch(SetBancariaExtrato(bancaria));
    dispatch(GerarExtrato(nivel, contaid, datainicial, datafinal, pessoas, unidades, bancaria, tipoconta));
  };
};

export const ExcluirLancamentoExtrato = function (
  ids: number[],
  force: boolean,
  contaid: number,
  nivel: number,
  datainicial: moment.Moment,
  datafinal: moment.Moment,
  pessoas: number[],
  unidades: number[],
  bancaria: number,
  tipoconta: TipoConta
) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_LANCAMENTO_EXTRATO_PENDING',
      payload: ids,
    });
    axios.post(apiEndpoints.LANCAMENTOS.EXCLUIR_LANCAMENTOS, { ids: ids, force: force }).then(() => {
      message.success('Lançamentos excluídos com sucesso!');
      dispatch(GerarExtrato(nivel, contaid, datainicial, datafinal, pessoas, unidades, bancaria, tipoconta));
      dispatch(SelectedLancamentos([]));
    });
  };
};
export const CloseModalConfirmDelete = function () {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'CLOSE_MODAL_CONFIRM_DELETE_EXTRATO' });
  };
};
