import { Button, Input, Modal, Table } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { filterobject } from '@utils/filterandsort';
import { CheckOutlined } from '@ant-design/icons';
import { GetPessoasList } from '@actions/pessoasActions';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Pessoa } from '@models/Pessoa';
interface SelectPessoaProps {
  data: {
    visible: boolean;
    index: null | number;
    tipo: null | number;
  };
  setData: Dispatch<SetStateAction<{ visible: boolean; index: null | number; tipo: null | number }>>;
  setPessoa: (index: number, id: number, nome: string, tipo: number) => void;
}
function SelectPessoa({ data, setData, setPessoa }: SelectPessoaProps) {
  const dispatch = useAppDispatch();
  const { pessoas } = useAppSelector((state) => state.pessoas);
  const [filtro, setFiltro] = useState('');
  const ref = useRef<any>();
  useEffect(() => {
    if (data.visible) {
      ref.current.focus();
      dispatch(GetPessoasList());
    }
  }, [data.visible]);
  return (
    <Modal
      open={data.visible}
      onCancel={() => setData({ visible: false, index: null, tipo: null })}
      title="Selecionar Pessoa"
      okButtonProps={{ hidden: true }}
    >
      <Input ref={ref} value={filtro} onChange={(e) => setFiltro(e.target.value)} placeholder="Buscar..." />
      <Table dataSource={filterobject(pessoas, filtro)} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          render={(v, row: Pessoa) => (
            <Button
              onClick={() => {
                setPessoa(data.index as number, v, row.nomeRazao, data.tipo as number);
                setData({ visible: false, tipo: null, index: null });
              }}
              size="small"
              icon={<CheckOutlined />}
            />
          )}
          width={30}
        />
        <Table.Column title="Nome" dataIndex="nomeRazao" />
      </Table>
    </Modal>
  );
}

export default SelectPessoa;
