import { extendedAction } from '@config/reduxStore';
import { ContextosReducer } from '@reducers/reducers';

const initialState: ContextosReducer = {
  contextos: [],
  waiting: false,
  error: null,
};

const contextosReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'GET_CONTEXTOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_CONTEXTOS_REJECTED':
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'GET_CONTEXTOS_FULFILLED':
      state = {
        ...state,
        contextos: action.payload.data,
        waiting: false,
      };
      break;
    case 'REGISTER_CONTEXTO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'REGISTER_CONTEXTO_FULFILLED':
      state = {
        ...state,
        waiting: false,
        contextos: [...state.contextos, action.payload.data],
      };
      break;
    case 'REGISTER_CONTEXTO_ERROR':
      state = {
        ...state,
        waiting: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default contextosReducer;
