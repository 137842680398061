import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { HistoricoReducer } from '@reducers/reducers';

var initialState: HistoricoReducer = {
  waiting: false,
  historico: [],
  usuarios: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  filters: null,
  sorter: {
    order: null,
    field: null,
  },
  users: [],
};

const historicoReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SET_PAGINATION_HISTORICO':
      state = {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.payload.current,
        },
        waiting: true,
      };
      break;
    case 'GET_HISTORICO_HISTORICO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_HISTORICO_HISTORICO_REJECTED':
      notification.error({ message: 'Erro ao carregar histórico' });
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'GET_HISTORICO_HISTORICO_FULFILLED':
      state = {
        ...state,
        historico: action.payload.data.result,
        pagination: {
          ...state.pagination,
          total: action.payload.data.totalCount,
        },
        usuarios: action.payload.data.usuarios,
        waiting: false,
      };
      break;
    case 'GET_USER_LIST_HISTORICO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_USER_LIST_HISTORICO_FULFILLED':
      state = {
        ...state,
        waiting: false,
        users: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default historicoReducer;
