import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { ProdutosReducer } from '@reducers/reducers';

var initialState: ProdutosReducer = {
  waiting: false,
  produto: null,
  produtos: [],
  produtosAll: [],
  selectedprodutos: [],
  produtospagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  filters: {},
  sorter: {},
  produtosloading: false,
  formvisible: false,
  searchloading: false,
  savingloading: false,
  grupos: [],
  subgrupos: [],
  novogrupo: '',
  addgrupoloading: false,
  novogrupovisible: false,
  novosubgrupovisible: false,
  excluirgrupoloading: false,
  subgruposgrupoid: null,
  saldosPorConta: {
    visible: false,
    produtoid: null,
    loadingSaldosPorConta: false,
    saldosPorConta: [],
  },
};

const produtosReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'SHOW_SALDOS_POR_CONTA':
      state = {
        ...state,
        saldosPorConta: {
          ...state.saldosPorConta,
          visible: action.payload.visible,
          produtoid: action.payload.produtoid,
        },
      };
      break;
    case 'GET_SALDOS_POR_CONTA_PENDING':
      state = {
        ...state,
        saldosPorConta: {
          ...state.saldosPorConta,
          loadingSaldosPorConta: true,
        },
      };
      break;
    case 'GET_SALDOS_POR_CONTA_REJECTED':
      notification.error({ message: 'Erro ao carregar saldos por conta!' });
      state = {
        ...state,
        saldosPorConta: {
          ...state.saldosPorConta,
          loadingSaldosPorConta: false,
        },
      };
      break;
    case 'GET_SALDOS_POR_CONTA_FULFILLED':
      state = {
        ...state,
        saldosPorConta: {
          ...state.saldosPorConta,
          loadingSaldosPorConta: false,
          saldosPorConta: action.payload.data,
        },
      };
      break;
    case 'SET_SUBGRUPO_GRUPOID_PRODUTOS':
      state = {
        ...state,
        subgruposgrupoid: action.payload,
      };
      break;
    case 'EDITAR_SUBGRUPO_PRODUTOS_PENDING':
      state = {
        ...state,
        excluirgrupoloading: true,
      };
      break;
    case 'EDITAR_SUBGRUPO_PRODUTOS_REJECTED':
      notification.error({ message: 'Erro ao atualizar subgrupo!' });
      state = {
        ...state,
        excluirgrupoloading: false,
      };
      break;
    case 'EDITAR_SUBGRUPO_PRODUTOS_FULFILLED':
      notification.success({ message: 'Subgrupo atualizado com sucesso!' });
      state = {
        ...state,
        excluirgrupoloading: false,
        grupos: state.grupos.map((c) => {
          if (c.id === action.payload.data.grupoProdutoID) {
            c.subgrupoProdutos = c.subgrupoProdutos.map((s) => {
              if (s.id === action.payload.data.id) {
                return action.payload.data;
              } else return s;
            });
            return c;
          } else return c;
        }),
      };
      break;
    case 'EDITAR_GRUPO_PRODUTOS_PENDING':
      state = {
        ...state,
        excluirgrupoloading: true,
      };
      break;
    case 'EDITAR_GRUPO_PRODUTOS_REJECTED':
      notification.error({ message: 'Erro ao salvar grupo!' });
      state = {
        ...state,
        excluirgrupoloading: false,
      };
      break;
    case 'EDITAR_GRUPO_PRODUTOS_FULFILLED':
      state = {
        ...state,
        excluirgrupoloading: false,
        grupos: state.grupos.map((c) => (c.id === action.payload.data.id ? action.payload.data : c)),
      };
      break;
    case 'EXCLUIR_SUBGRUPO_PRODUTOS_PENDING':
      state = {
        ...state,
        excluirgrupoloading: true,
      };
      break;
    case 'EXCLUIR_SUBGRUPO_PRODUTOS_REJECTED':
      notification.error({ message: action.payload.response.data });
      state = {
        ...state,
        excluirgrupoloading: false,
      };
      break;
    case 'EXCLUIR_SUBGRUPO_PRODUTOS_FULFILLED':
      state = {
        ...state,
        excluirgrupoloading: false,
        grupos: state.grupos.map((c) => {
          if (c.id === action.payload.data.grupoid) {
            c.subgrupoProdutos = action.payload.data.subgrupos;
            return c;
          } else return c;
        }),
        produtosAll: state.produtosAll.filter((c) => c.subgrupoProdutoID !== action.payload.data.id),
      };
      break;
    case 'EXCLUIR_GRUPO_PRODUTOS_PENDING':
      state = {
        ...state,
        excluirgrupoloading: true,
      };
      break;
    case 'EXCLUIR_GRUPO_PRODUTOS_REJECTED':
      notification.error({ message: action.payload.response.data });
      state = {
        ...state,
        excluirgrupoloading: false,
      };
      break;
    case 'EXCLUIR_GRUPO_PRODUTOS_FULFILLED':
      state = {
        ...state,
        excluirgrupoloading: false,
        grupos: action.payload.data.grupos,
        produtosAll: state.produtosAll.filter((c) => c.grupoID !== action.payload.data.grupoid),
      };
      break;
    case 'NOVO_GRUPO_VISIBLE_PRODUTOS':
      state = {
        ...state,
        novogrupovisible: !state.novogrupovisible,
      };
      break;
    case 'NOVO_SUBGRUPO_VISIBLE_PRODUTOS':
      state = {
        ...state,
        novosubgrupovisible: !state.novosubgrupovisible,
      };
      break;
    case 'ADD_SUBGRUPO_PRODUTOS_PENDING':
      state = {
        ...state,
        addgrupoloading: true,
      };
      break;
    case 'ADD_SUBGRUPO_PRODUTOS_REJECTED':
      notification.error({ message: 'Erro ao cadastrar subgrupo!' });
      state = {
        ...state,
        addgrupoloading: false,
      };
      break;
    case 'ADD_SUBGRUPO_PRODUTOS_FULFILLED':
      notification.success({ message: 'Subgrupo cadastrado com sucesso!' });
      state = {
        ...state,
        addgrupoloading: false,
        grupos: state.grupos.map((c) => {
          if (c.id === action.payload.data.grupoProdutoID) {
            c.subgrupoProdutos = [...c.subgrupoProdutos, action.payload.data];
            return c;
          } else return c;
        }),
      };
      break;
    case 'ADD_GRUPO_PRODUTOS_PENDING':
      state = {
        ...state,
        addgrupoloading: true,
      };
      break;
    case 'ADD_GRUPO_PRODUTOS_REJECTED':
      notification.error({ message: 'Erro ao cadastrar grupo!' });
      state = {
        ...state,
        addgrupoloading: true,
      };
      break;
    case 'ADD_GRUPO_PRODUTOS_FULFILLED':
      notification.success({ message: 'Grupo cadastrado com sucesso!' });
      state = {
        ...state,
        addgrupoloading: false,
        grupos: [...state.grupos, action.payload.data],
      };
      break;
    case 'CHANGE_NOVO_GRUPO_PRODUTOS':
      state = {
        ...state,
        novogrupo: action.payload,
      };
      break;
    case 'GET_GRUPOS_PRODUTOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_GRUPOS_PRODUTOS_FULFILLED':
      state = {
        ...state,
        waiting: false,
        grupos: action.payload.data,
      };
      break;
    case 'GET_GRUPOS_PRODUTOS_REJECTED':
      notification.error({ message: 'Erro ao carregar grupos!' });
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'GET_PRODUTOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_PRODUTOS_FULFILLED':
      state = {
        ...state,
        waiting: false,
        produtos: action.payload.data,
      };
      break;
    case 'SHOW_PRODUTOS_FULFILLED':
      state = {
        ...state,
        waiting: false,
        produtosAll: action.payload.data,
        produtosloading: false,
      };
      break;
    case 'SHOW_PRODUTOS_PENDING':
      state = {
        ...state,
        produtosloading: true,
      };
      break;
    case 'SHOW_PRODUTOS_REJECTED':
      notification.error({ message: 'Erro ao carregar produtos!' });
      state = {
        ...state,
        produtosloading: false,
      };
      break;
    case 'SET_SELECTED_PRODUTOS':
      state = {
        ...state,
        selectedprodutos: action.payload,
      };
      break;
    case 'SET_PAGINATION_PRODUTOS':
      var pager = { ...action.payload.pagination };
      pager.current = action.payload.pagination.current;
      state = {
        ...state,
        produtospagination: pager,
        produtosloading: true,
        filters: action.payload.filters,
        sorter: action.payload.sorter,
        waiting: true,
      };
      break;
    case 'FETCH_PRODUTOS_FULFILLED':
      state = {
        ...state,
        produtosAll: action.payload.data.result,
        produtosloading: false,
        produtospagination: {
          ...state.produtospagination,
          total: action.payload.data.totalCount,
        },
        waiting: false,
      };
      break;
    case 'HIDE_PRODUTO_FORM':
      state = {
        ...state,
        formvisible: false,
        produto: null,
      };
      break;
    case 'SHOW_PRODUTO_FORM':
      state = {
        ...state,
        formvisible: true,
      };
      break;
    case 'LOAD_PRODUTO':
      state = {
        ...state,
        produto: action.payload,
        waiting: false,
        formvisible: true,
      };
      break;
    case 'EXCLUIR_PRODUTO_PENDING':
      state = {
        ...state,
        produtosloading: true,
      };
      break;
    case 'EXCLUIR_PRODUTO_REJECTED':
      state = {
        ...state,
        produtosloading: false,
      };
      notification.error({ message: action.payload.response.data });
      break;
    case 'EXCLUIR_PRODUTO_FULFILLED':
      state = {
        ...state,
        produtosloading: false,
        formvisible: false,
        produtosAll: action.payload.data,
      };
      notification.success({ message: 'Produto excluído com sucesso!' });
      break;
    case 'SALVAR_PRODUTO_PENDING':
      state = {
        ...state,
        produtosloading: true,
      };
      break;
    case 'SALVAR_PRODUTO_REJECTED':
      state = {
        ...state,
        produtosloading: false,
      };
      notification.error({ message: 'Erro ao salvar item' });
      break;
    case 'SALVAR_PRODUTO_FULFILLED':
      state = {
        ...state,
        produtosloading: false,
        produto: null,
        formvisible: false,
        produtosAll: state.produtosAll.map((c) =>
          c.id === action.payload.data.produto.id ? action.payload.data.produto : c
        ),
      };
      notification.success({ message: 'Produto salvo com sucesso!' });
      break;
    case 'ADD_PRODUTO_PENDING':
      state = {
        ...state,
        produtosloading: true,
      };
      break;
    case 'ADD_PRODUTO_REJECTED':
      state = {
        ...state,
        produtosloading: false,
      };
      notification.error({ message: 'Erro ao salvar item' });
      break;
    case 'ADD_PRODUTO_FULFILLED':
      state = {
        ...state,
        produto: null,
        produtosloading: false,
        formvisible: false,
        produtosAll: [...state.produtosAll, action.payload.data.produto],
      };
      notification.success({ message: 'Produto salvo com sucesso!' });
      break;
    case 'RESET':
      state = initialState;
      break;
    default:
      return state;
  }
  return state;
};

export default produtosReducer;
