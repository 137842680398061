import { useEffect } from 'react';
import { Form, Input, Button, Spin, Tabs, Descriptions, Popconfirm, notification, Space } from 'antd';
import { isAuth } from '@actions/userActions';
import ChangePasswordForm from './ChangePasswordForm';
import moment from 'moment';
import { moneyMask, phoneMobMask } from '@utils/masks';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@config/reduxStore';
import { UserController } from '@controllers/UserController';

function UsuarioView() {
  const controller = UserController;
  const { data, actions } = controller();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [cancelando, setCancelando] = useState(false);
  useEffect(() => {
    if (data.usuario) form.setFieldsValue(data.usuario);
  }, [data.usuario, form]);
  const { subscription } = data.user;
  return (
    <div style={{ margin: 'auto', width: 300 }}>
      <Tabs
        defaultActiveKey="0"
        items={[
          {
            label: 'Usuário',
            key: '0',
            children: (
              <Spin spinning={data.usuario.loadinguser}>
                <Form
                  form={form}
                  layout="horizontal"
                  wrapperCol={{ span: 14 }}
                  labelCol={{ span: 10 }}
                  onFinish={(v) => actions.Update(v)}
                >
                  <Form.Item name="id" label="Nome" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="nome" label="Nome">
                    <Input />
                  </Form.Item>
                  <Form.Item name="email" label="Email">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label="Telefone"
                    normalize={(v, pv) => phoneMobMask.format(phoneMobMask.normalize(v, pv))}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="lancCod" label="Cód. Lançamento">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 10 }}>
                    <Button size="small" type="primary" htmlType="submit">
                      Salvar
                    </Button>
                    <Button size="small" type="default" onClick={() => actions.ResendSenha(true)}>
                      Redefinir Senha
                    </Button>
                  </Form.Item>
                  <Button
                    size="small"
                    block
                    onClick={() => (window.location.href = '/Usuario/Contratar')}
                    type="primary"
                  >
                    Contratar Novo Plano
                  </Button>
                </Form>

                <ChangePasswordForm controller={controller} />
              </Spin>
            ),
          },
          {
            label: 'Conta',
            key: '1',
            disabled: !actions.HasRole('administrador'),
            children: (
              <div>
                <Descriptions size="small" bordered column={1}>
                  <Descriptions.Item label="Status">{subscription.status}</Descriptions.Item>
                  <Descriptions.Item label="Data Criação">
                    {moment(subscription.create_time).format('DD/MM/YYYY')}
                  </Descriptions.Item>
                  <Descriptions.Item label="ID Assinatura">{subscription.id}</Descriptions.Item>
                  <Descriptions.Item label="Valor Pago">
                    {moneyMask.format(subscription.shipping_amount?.value || '')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Data Início">
                    {moment(subscription.start_time).format('DD/MM/YYYY')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Data Atualização">
                    {moment(subscription.update_time).format('DD/MM/YYYY')}
                  </Descriptions.Item>
                </Descriptions>
                <Space>
                  <Link to="/Planos">
                    <Button block type="primary">
                      Alterar Plano
                    </Button>
                  </Link>
                  <Popconfirm
                    title="Está cergto disso?"
                    onConfirm={() => {
                      setCancelando(true);
                      const params = new URLSearchParams();
                      params.append('grant_type', 'client_credentials');
                      axios
                        .post(`${data.paypal.api}/v1/oauth2/token`, params, {
                          headers: { Accept: 'application/json', 'Accept-Language': 'en_US' },
                          auth: {
                            username: data.paypal.clientid,
                            password: data.paypal.secret,
                          },
                        })
                        .then((res) =>
                          axios
                            .post(subscription.links?.find((c) => c.rel === 'cancel')?.href || '', null, {
                              headers: {
                                Authorization: `Bearer ${res.data.access_token}`,
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(() => dispatch(isAuth(data.paypal)))
                        )
                        .catch(() => notification.error({ message: 'Erro ao cancelar conta!' }));
                    }}
                  >
                    <Button block type="default" loading={cancelando}>
                      Cancelar Plano
                    </Button>
                  </Popconfirm>
                </Space>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default UsuarioView;
