import { cliente } from '@config/axios';
import moment from 'moment';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const CloseModalConfirmDelete = function () {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'CLOSE_MODAL_CONFIRM_DELETE_LANCAMENTOS' });
  };
};
export const ExcluirLancamento = function (ids: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_LANCAMENTO_LANCAMENTOS',
      payload: cliente.post(apiEndpoints.LANCAMENTOS.EXCLUIR_LANCAMENTOS, { ids }),
    });
  };
};
export const SetUnidadesLancamentos = function (unidadesid: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_UNIDADES_LANCAMENTOS',
      payload: unidadesid,
    });
  };
};

export const SetCompetenciaLancamentos = function (competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_COMPETENCIA_LANCAMENTOS',
      payload: competencia,
    });
  };
};
export const SetDataTransacaoLancamentos = function (inicio: moment.Moment, tipo: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_TRANSACAO_LANCAMENTOS',
      payload: {
        data: inicio,
        tipo: tipo,
      },
    });
  };
};
export const ViewForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'VIEW_FORM_LANCAMENTOS',
    });
  };
};

export const ResetLancamentos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'RESET_LANCAMENTOS',
    });
  };
};
export const HideDownload = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_DOWNLOAD_EXPORT',
    });
  };
};
export const ExportLancamentos = function (
  unidades: any,
  transacaoinicial: any,
  transacaofim: any,
  pessoas: any,
  bloco: any,
  descricao: string,
  valor: any,
  documento: string
) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_LANCAMENTOS_EXPORT_PENDING',
    });
    cliente
      .post(
        apiEndpoints.LANCAMENTOS.GET_LANCAMENTOS_EXPORT,
        {
          unidades: unidades || [],
          transacaoinicial: transacaoinicial ? moment(transacaoinicial).format('YYYY-MM-DD') : null,
          transacaofim: transacaofim ? moment(transacaofim).format('YYYY-MM-DD') : null,
          pessoas: pessoas || [],
          bloco,
          descricao: descricao,
          valor: valor,
          documento: documento,
        },
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        dispatch({
          type: 'GET_LANCAMENTOS_EXPORT_FULFILLED',
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Lancamentos_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((res) => {
        dispatch({
          type: 'GET_LANCAMENTOS_EXPORT_REJECTED',
          payload: res.response.data.toString(),
        });
      });
  };
};
export const MostarLancamentos2 = function (pagination: any, filters: any, sorter: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'MOSTRAR_LANCAMENTOS',
      payload: cliente.post(apiEndpoints.LANCAMENTOS.GET_LANCAMENTOS, { pagination, filters, sorter }),
    });
  };
};
export const SetFilterPessoas = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_FILTER_PESSOAS_LANCAMENTOS',
      payload: values,
    });
  };
};

export const SetBlocoFilter = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_BLOCO_LANCAMENTOS',
      payload: value,
    });
  };
};

export const SetDescricaoFilter = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_DESCRICAO_LANCAMENTOS',
      payload: value,
    });
  };
};
export const SetValorFilter = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_VALOR_FILTER_LANCAMENTOS',
      payload: value,
    });
  };
};
export const CheckFormatBloco = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHECK_FORMAT_BLOCO_LANCAMENTOS',
      payload: value,
    });
  };
};

export const SelectedLancamentos = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_LANCAMENTOS_LANCAMENTOS',
      payload: values,
    });
  };
};

export const GetLancamentosExpand = function (blocoid: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_LANCAMENTOS_EXPAND_LANCAMENTOS',
      payload: cliente.get(apiEndpoints.LANCAMENTOS.GET_LANCAMENTOS_BLOCO, {
        params: { blocoid: blocoid },
      }),
    });
  };
};

export const PopLancamentosExpand = function (blocoid: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'POP_LANCAMENTOS_EXPAND_LANCAMENTOS',
      payload: blocoid,
    });
  };
};
export const SetLancamentosFiltros = function (filtros: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_LANCAMENTOS_FILTRO',
      payload: filtros,
    });
  };
};
export const SetLancamentosFiltrosForm = function (filtros: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_LANCAMENTOS_FILTRO_FORM',
      payload: filtros,
    });
  };
};
