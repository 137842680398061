import './text.scss';
function PrivacyPolicy() {
  return (
    <div className="textBox">
      <h3>POLÍTICA DE PRIVACIDADE</h3>
      <article>
        <p>
          A NAMBI EFICIENCIA EM GESTAO LTDA ("Nambi") está comprometida com a privacidade e a segurança dos dados
          pessoais de seus usuários do sistema Nambi - Gestão Financeira Inteligente ("Sistema").
        </p>
        <ol>
          <li>
            COLETA DE DADOS
            <ol>
              <li>
                A Nambi coleta os seguintes dados pessoais do usuário: nome, endereço de e-mail, senha, informações
                financeiras da empresa (como receitas, despesas, contas a pagar e receber, entre outros) e qualquer
                outra informação que o usuário escolha compartilhar com o Sistema.
              </li>
              <li>
                A coleta de dados é necessária para o funcionamento do Sistema e para a prestação dos serviços
                oferecidos pela Nambi.
              </li>
            </ol>
          </li>
          <li>
            USO DE DADOS
            <ol>
              <li>
                A Nambi utiliza os dados coletados para permitir o uso do Sistema pelo usuário, gerar relatórios
                financeiros e auxiliar o usuário na gestão financeira da sua empresa.
              </li>
              <li>
                A Nambi não compartilha os dados do usuário com terceiros, exceto nos seguintes casos: (i) quando o
                usuário autoriza expressamente o compartilhamento; (ii) quando a Nambi é obrigada por lei ou ordem
                judicial a compartilhar os dados; (iii) quando necessário para a prestação dos serviços, como o
                processamento de pagamentos ou o envio de comunicações relacionadas ao Sistema.
              </li>
              <li>
                A Nambi adota medidas de segurança adequadas para proteger os dados do usuário contra perda, uso
                indevido, acesso não autorizado, divulgação ou alteração.
              </li>
            </ol>
          </li>
          <li>
            ARMAZENAMENTO DE DADOS
            <ol>
              <li>A Nambi armazena os dados do usuário em servidores seguros, localizados no Brasil.</li>
              <li>
                A Nambi mantém os dados do usuário armazenados enquanto for necessário para a prestação dos serviços, ou
                até que o usuário solicite a exclusão dos dados.
              </li>
            </ol>
          </li>
          <li>
            ACESSO, RETIFICAÇÃO E EXCLUSÃO DE DADOS
            <ol>
              <li>
                O usuário tem o direito de acessar, retificar e excluir seus dados pessoais, bem como de solicitar a
                portabilidade dos dados para outro serviço.
              </li>
              <li>
                O usuário pode atualizar seus dados pessoais no Sistema ou entrar em contato com a Nambi para solicitar
                a retificação ou exclusão dos dados.
              </li>
              <li>
                A Nambi pode reter alguns dados do usuário por um período de tempo necessário para cumprir obrigações
                legais ou regulatórias, bem como para a defesa de seus direitos.
              </li>
            </ol>
          </li>
          <li>
            ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE
            <ol>
              <li>
                A Nambi pode alterar esta política de privacidade a qualquer momento, mediante aviso prévio ao usuário.
              </li>
              <li>
                O uso contínuo do Sistema pelo usuário após a entrada em vigor das alterações constitui aceitação dos
                termos da nova política de privacidade.
              </li>
            </ol>
          </li>
          <li>
            CONTATO
            <ol>
              <li>
                Em caso de dúvidas sobre esta política de privacidade, o usuário pode entrar em contato com a Nambi pelo
                e-mail suporte@nambi.com.br.
              </li>
            </ol>
          </li>
        </ol>
      </article>
    </div>
  );
}
export default PrivacyPolicy;
