import { Button, Checkbox, Typography, Card, Input, Form } from 'antd';
import { Login } from '@actions/userActions';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
const { Text } = Typography;

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const { loginmessage, waiting } = useAppSelector((state) => state.user);
  return (
    <div>
      <Card style={{ width: 350, margin: 'auto' }}>
        <h4>Informe suas credenciais para acessar sua conta</h4>
        <hr />
        <Text type="danger">{loginmessage}</Text>
        <Form
          layout="vertical"
          onFinish={(values) => dispatch(Login(values.userName, values.password, values.remember))}
        >
          <Form.Item name="userName" label="Usuário" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Usuário" />
          </Form.Item>
          <Form.Item name="password" label="Senhas" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'inline-grid' }}>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Lembrar</Checkbox>
              </Form.Item>
            </div>
            <div style={{ float: 'right', display: 'inline-grid' }}>
              <Link to="/ResetPassword">Esqueci a senha</Link>
            </div>
            <Button type="primary" style={{ width: '100%' }} htmlType="submit" loading={waiting}>
              Entrar
            </Button>
            ou <Link to="/Contratar">Registrar-se agora!</Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
