import { AppDispatch } from '@config/reduxStore';
import axios from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const ShowOrcamentoForm = function (unidades: any[], tipo?: any, descricao?: string, ano?: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_ORCAMENTO_FORM',
    });
    if (unidades.length === 1) dispatch(GetContasAnaliticas(unidades[0].id, tipo, descricao, ano));
  };
};

export const HideOrcamentoForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_ORCAMENTO_FORM',
    });
  };
};
export const GetOrcamentos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTOS',
      payload: axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS),
    });
  };
};
export const ExcluirOrcamento = function (orcamentoid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_ORCAMENTO',
      payload: axios.get(apiEndpoints.ORCAMENTOS.EXCLUIR_ORCAMENTO, {
        params: { orcamentoid: orcamentoid },
      }),
    });
  };
};
export const GetContasAnaliticas = function (unidade: number, tipo: any, descricao: string, ano: any) {
  return function (dispatch: AppDispatch) {
    dispatch(SelectUnidadeOrcamento(unidade, tipo, descricao, ano));
    dispatch({
      type: 'GET_CONTAS_ANALITICAS',
      payload: axios.get(apiEndpoints.UNIDADES.GET_CONTAS_ANALITICAS, {
        params: { unidade: unidade },
      }),
    });
  };
};
export const SelectUnidadeOrcamento = function (unidade: number, tipo: any, descricao: string, ano: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECT_UNIDADE_ORCAMENTO',
      payload: {
        unidade: unidade,
        tipo: tipo,
        descricao: descricao,
        ano: ano,
      },
    });
  };
};
export const GetUnidades = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_UNIDADES',
      payload: axios.get(apiEndpoints.UNIDADES.GET_UNIDADES),
    });
  };
};

export const CriaOrcamento = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CRIAR_ORCAMENTO',
      payload: axios.post(apiEndpoints.ORCAMENTOS.CRIAR_ORCAMENTO, {
        ...values,
        id: values.id,
      }),
    });
  };
};

export const LoadOrcamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_ORCAMENTO',
      payload: axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO, {
        params: { id: id },
      }),
    });
  };
};

export const LoadOrcamentoView = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_ORCAMENTO_VIEW',
      payload: axios.get(apiEndpoints.ORCAMENTOS.LOAD_ORCAMENTO_VIEW, {
        params: { orcamentoid: id },
      }),
    });
  };
};
