import { useEffect } from 'react';
import { Button, notification } from 'antd';
import { Permission } from '@hooks/usePermissions';
import TabelaBancarias from './TabelaBancarias';
import FormBancaria from './FormBancaria';
import { PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { SetPageHeader } from '@actions/userActions';
import { useState } from 'react';
import cliente from '@config/axios';
import moment from 'moment';
import { useAppDispatch } from '@config/reduxStore';
import { BancariasController } from '@controllers/BancariasController';

function BancariasView() {
  const controller = BancariasController;
  const { data, actions } = controller();
  const dispatch = useAppDispatch();
  const [downloading, setDownloading] = useState<boolean>();
  useEffect(() => {
    document.title = 'Nambi - Contas Bancárias';
    actions.LoadContas();
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Contas Bancárias',
        subTitle: 'Cadastros de contas bancárias',
        extras: [
          <Permission permission="bancaria.list" key={1}>
            <Button
              icon={<FileExcelOutlined />}
              size="small"
              type="default"
              loading={downloading}
              onClick={() => {
                setDownloading(true);
                cliente
                  .post(
                    '/api/Bancarias/Exportar',
                    { bancarias: data.bancarias },
                    {
                      responseType: 'blob',
                    }
                  )
                  .then((res) => {
                    setDownloading(false);
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Bancarias_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch(() => {
                    notification.error({ message: 'Erro ao gerar arquivo!' });
                    setDownloading(false);
                  });
              }}
            >
              Exportar
            </Button>
          </Permission>,
          <Permission key={0} permission="bancaria.add">
            <Button key="1" type="primary" size="small" onClick={() => actions.Nova()}>
              <PlusOutlined /> Nova Conta Bancária
            </Button>
          </Permission>,
        ],
      })
    );
  }, [downloading, data.bancarias]);
  return (
    <div>
      <Permission permission="bancaria.list">
        <TabelaBancarias controller={controller} />
      </Permission>
      <FormBancaria controller={controller} />
    </div>
  );
}

export default BancariasView;
