import { GetUserData, ResetSenhaModal, SetPageHeader, UpdateUserInfo } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { UserControllerInterface } from '@controllers/controllers';
import { usePermission } from '@hooks/usePermissions';
import { useEffect } from 'react';

export const UserController: UserControllerInterface = () => {
  const dispatch = useAppDispatch();
  const { user, usuario, paypal, waiting } = useAppSelector((state) => state.user);
  const Update: (v: any) => void = (v) => dispatch(UpdateUserInfo(v));
  const ResendSenha: (v: boolean) => void = (v) => dispatch(ResetSenhaModal(v));
  const { HasRole } = usePermission();
  useEffect(() => {
    dispatch(GetUserData());
    dispatch(
      SetPageHeader({
        title: 'Perfil',
        subTitle: 'Configuração do perfil de usuário',
      })
    );
  }, [dispatch]);
  return {
    data: {
      user,
      usuario,
      paypal,
      waiting,
    },
    actions: {
      Update,
      ResendSenha,
      HasRole,
    },
  };
};
