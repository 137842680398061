import { Button, notification, Select, Space, Table, Upload, Popconfirm, Tooltip, Spin } from 'antd';
import { useEffect } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { ImportOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { getCookie } from '@utils/localStorage';
import cliente from '@config/axios';
import { useState } from 'react';
import { naturalSorter } from '@utils/filterandsort';
import { disableCampo, renderCell } from './ImportacaoUtils';
import { GetUnidades } from '@actions/generalActions';
import { GetPessoas } from '@actions/pessoasActions';
import { GetContasAnaliticas, Load, Reset, Save, SetCampos, SetColumns, SetValues } from '@actions/importarActions';
import compare from 'string-similarity';
import { Link, useParams } from 'react-router-dom';
import ImportarPainel from './ImportarPainel';
import SelectConta from './SelectConta';
import SelectUnidade from './SelectUnidade';
import { copy, usePrevious } from '@utils/queryString';
import SelectPessoa from './SelectPessoa';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Dictionary } from '@reduxjs/toolkit';
import { UnidadeNegocio } from '@models/UnidadeNegocio';
import { ContaAnaliticaTree } from '@models/ContaAnalitica';
import { Pessoa } from '@models/Pessoa';

function NovaImportacao() {
  const dispatch = useAppDispatch();
  const { unidades } = useAppSelector((state) => state.general);
  const { pessoas } = useAppSelector((state) => state.pessoas);
  const {
    contas,
    settings,
    values,
    campos,
    loading,
    saving,
    columns,
    totalLinhas,
    importadas,
    descricao,
    loaded,
    lancando,
    lancado,
    selectConta,
  } = useAppSelector((state) => state.importar);
  const [file, setFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { id } = useParams();
  const [painel, setPainel] = useState(false);
  const previousCampos = usePrevious(campos);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (
      campos &&
      previousCampos &&
      Object.values(previousCampos).find((c) => c === 'valor') &&
      !Object.values(campos).find((c) => c === 'valor')
    ) {
      dispatch(SetValues(copy(values).map((c: any) => ({ ...c, oque: null, oqueID: null }))));
    }
  }, [previousCampos, campos, values, dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(Load(id));
    } else {
      dispatch(Reset());
    }
  }, [id, dispatch]);
  useEffect(() => {
    if (loaded) {
      let unds = [...new Set([...values.map((c) => c.unidadeOqueID), ...values.map((c) => c.unidadeOndeID)])];
      unds.filter((c) => c !== null).forEach((c) => dispatch(GetContasAnaliticas(c)));
    }
  }, [loaded]);
  useEffect(() => {
    if (lancado) {
      dispatch(Save({ campos, values, columns, settings, id, totalLinhas, importadas, descricao }));
    }
  }, [lancado, dispatch]);
  useEffect(() => {
    dispatch(GetUnidades(true, true));
    dispatch(GetPessoas());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Nova Importação',
        subTitle: 'Envie um arquivo e configure as colunas de importação',
        extras: [
          <Button key={1} size="small" onClick={() => setPainel(true)}>
            Configurações
          </Button>,
          <Button
            key={0}
            loading={saving}
            size="small"
            type="primary"
            onClick={() =>
              dispatch(Save({ campos, values, columns, settings, id, totalLinhas, importadas, descricao }))
            }
          >
            Salvar
          </Button>,
        ],
      })
    );
  }, [dispatch, saving, campos, values, columns, settings, id, totalLinhas, importadas, descricao]);
  useEffect(() => {
    dispatch(Save({ campos, values, columns, settings, id, totalLinhas, importadas, descricao }));
  }, [values, columns, settings, campos]);
  if (values.length === 0)
    return (
      <Upload.Dragger
        key={0}
        accept=".xlsx"
        fileList={file}
        onChange={() => setFile([])}
        multiple={false}
        customRequest={(options) => {
          const { file } = options;
          const fmData = new FormData();
          fmData.append('file', file);
          setUploading(true);
          cliente
            .post('/api/Importar/PostFile', fmData)
            .then((res) => {
              notification.success({ message: 'Arquivo carregado com sucesso!' });
              setUploading(false);
              dispatch(
                SetColumns(
                  res.data.columns.map((c: any) => {
                    return {
                      dataIndex: c,
                      deleted: false,
                    };
                  })
                )
              );
              dispatch(SetValues(res.data.data.map((c: any, i: number) => ({ ...c, key: i }))));
              setFile([]);
              //onSuccess();
            })
            .catch((res) => {
              notification.error({ message: res.response.data });
              setUploading(false);
              setFile([]);
              //onError(res.response.data);
            });
        }}
        headers={{ Authorization: `Bearer ${getCookie('token')}` }}
      >
        <Spin spinning={uploading}>
          <p className="ant-upload-drag-icon">
            <ImportOutlined />
          </p>
          <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para realizar o upload</p>
          <p className="ant-upload-hint">
            O arquivo deve ser do formato .xlsx (excel) e e os dados formatados como tabela
          </p>
        </Spin>
      </Upload.Dragger>
    );

  return (
    <Spin spinning={loading || lancando}>
      <ImportarPainel visible={painel} setVisible={setPainel} />
      <SelectConta />
      <SelectUnidade />
      <SelectPessoa />
      <Space direction="vertical" style={{ width: '100%' }}>
        <Table
          size="small"
          dataSource={values}
          scroll={{ x: 'max-content', y: 'calc(100vh - 220px)' }}
          rowKey="key"
          tableLayout="auto"
          pagination={{
            position: ['bottomRight'],
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: [10, 25, 50],
            showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          }}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (keys: any) => setSelectedRows(keys),
            columnWidth: 50,
            hideSelectAll: true,
            getCheckboxProps: (row) => row.done && { disabled: true },
            columnTitle: (
              <Link to={`/Lancar?importid=${id}&keys=${JSON.stringify(selectedRows)}`}>
                <Button size="small" type="primary" disabled={selectedRows.length === 0}>
                  Lançar em Tela
                </Button>
              </Link>
            ),
          }}
          components={{
            header: {
              cell: ({ minWidth, ...restProps }: any) => <th {...restProps} style={{ minWidth: minWidth }} />,
            },
            body: {
              cell: (props: any) => <td {...props} style={{ minWidth: 140 }} />,
            },
          }}
        >
          {[...columns]
            .filter((c) => c.deleted === false)
            .filter((c) => (settings.tipo !== 3 ? true : c.dataIndex !== 'Menu'))
            .map((c) => (
              <Table.Column
                minWidth={140}
                onHeaderCell={(column: any) => ({
                  ...column,
                  minWidth: column.minWidth,
                  width: column.width,
                })}
                key={c.dataIndex}
                {...c}
                align={campos[c.dataIndex] === 'valor' ? 'right' : c.dataIndex === 'Done' ? 'center' : undefined}
                sorter={(a: any, b: Dictionary<any>) =>
                  c.dataIndex !== 'Action' && c.dataIndex !== 'Done' && c.dataIndex !== 'Menu'
                    ? naturalSorter(a[c.dataIndex], b[c.dataIndex])
                    : null
                }
                filterMultiple
                fixed={c.dataIndex === 'Action' || c.dataIndex === 'Done' ? 'left' : undefined}
                filterSearch
                showSorterTooltip={false}
                onFilter={(value, row: Dictionary<any>) => row[c.dataIndex] === value}
                title={
                  c.dataIndex !== 'Done' && c.dataIndex !== 'Action' && c.dataIndex !== 'Menu' ? (
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>{c.dataIndex}</span>
                          <div>
                            {campos[c.dataIndex] === 'unidade' && (
                              <Tooltip title="Sincronizar">
                                <Button
                                  size="small"
                                  icon={<SyncOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let tempValues = [...values];
                                    tempValues = tempValues.map((d) => {
                                      if (!d.done) {
                                        let temp = Object.assign({}, d);
                                        let max = 0;
                                        let unidade: UnidadeNegocio | null = null;
                                        unidades.forEach((e) => {
                                          const level = compare.compareTwoStrings(e.nome, d[c.dataIndex]);
                                          if (level > max) {
                                            max = level;
                                            unidade = e;
                                          } else unidade = null;
                                        });
                                        if (unidade === null && max > settings.precision / 100) {
                                          if (settings.tipo === 0) {
                                            temp.unidadeOqueID = unidade.id;
                                            temp.unidadeOndeID = null;
                                            temp.unidadeOnde = null;
                                            temp.unidadeOque = unidade.nome;
                                            temp.oque = null;
                                            temp.oqueID = null;
                                          } else {
                                            temp.unidadeOndeID = unidade.id;
                                            temp.unidadeOnde = unidade.nome;
                                            temp.unidadeOqueID = null;
                                            temp.unidadeOque = null;
                                            temp.onde = null;
                                            temp.ondeID = null;
                                          }
                                          if (!contas.find((ca) => ca.unidadeID === unidade.id))
                                            dispatch(GetContasAnaliticas(unidade.id));
                                          return temp;
                                        } else {
                                          if (settings.tipo === 0) {
                                            temp.unidadeOqueID = null;
                                            temp.unidadeOque = null;
                                            temp.oque = null;
                                            temp.oqueID = null;
                                          } else {
                                            temp.unidadeOndeID = null;
                                            temp.unidadeOnde = null;
                                            temp.onde = null;
                                            temp.ondeID = null;
                                          }
                                          return temp;
                                        }
                                      }
                                      return d;
                                    });

                                    dispatch(SetValues(tempValues));
                                  }}
                                />
                              </Tooltip>
                            )}
                            {(campos[c.dataIndex] === 'oque' || campos[c.dataIndex] === 'onde') && (
                              <Tooltip title="Sincronizar">
                                <Button
                                  size="small"
                                  icon={<SyncOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let tempValues = [...values];
                                    tempValues = tempValues.map((d) => {
                                      let temp = Object.assign({}, d);
                                      let max = 0;
                                      let conta: ContaAnaliticaTree | undefined;
                                      if (
                                        (settings.tipo === 0 && d.unidadeOqueID) ||
                                        (settings.tipo === 1 && d.unidadeOndeID)
                                      ) {
                                        contas
                                          .find(
                                            (u) =>
                                              u.unidadeID === (settings.tipo === 0 ? d.unidadeOqueID : d.unidadeOndeID)
                                          )
                                          ?.contas.forEach((n1) => {
                                            n1.children.forEach((n2) => {
                                              n2.children.forEach((n3) => {
                                                n3.children.forEach((cs) => {
                                                  cs.children.forEach((ca) => {
                                                    const level = compare.compareTwoStrings(ca.title, d[c.dataIndex]);
                                                    if (level > max) {
                                                      max = level;
                                                      conta = ca;
                                                    }
                                                  });
                                                });
                                              });
                                            });
                                          });
                                        if (conta && max > settings.precision / 100) {
                                          if (settings.tipo === 0) {
                                            temp.oqueID = conta.value;
                                            temp.oque = conta.title;
                                          } else {
                                            temp.ondeID = conta.value;
                                            temp.onde = conta.title;
                                          }
                                        } else {
                                          if (settings.tipo === 0) {
                                            temp.oqueID = null;
                                            temp.oque = null;
                                          } else {
                                            temp.ondeID = null;
                                            temp.onde = null;
                                          }
                                        }
                                        return temp;
                                      } else return temp;
                                    });
                                    dispatch(SetValues(tempValues));
                                  }}
                                />
                              </Tooltip>
                            )}
                            {campos[c.dataIndex] === 'pessoa' && (
                              <Tooltip title="Sincronizar">
                                <Button
                                  size="small"
                                  icon={<SyncOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let tempValues = [...values];
                                    tempValues = tempValues.map((d) => {
                                      let temp = Object.assign({}, d);
                                      let max = 0;
                                      let pessoa: Pessoa | null = null;
                                      pessoas.forEach((n1) => {
                                        n1.pessoas.forEach((p) => {
                                          const level = compare.compareTwoStrings(p.nomeRazao, d[c.dataIndex]);
                                          if (level > max) {
                                            max = level;
                                            pessoa = p;
                                          }
                                        });
                                      });
                                      if (pessoa !== null) {
                                        if (max > settings.precision / 100) {
                                          temp.pessoa = pessoa.nomeRazao;
                                          temp.pessoaID = pessoa.id;
                                        } else {
                                          temp.pessoa = null;
                                          temp.pessoaID = null;
                                        }
                                      }
                                      return temp;
                                    });
                                    dispatch(SetValues(tempValues));
                                  }}
                                />
                              </Tooltip>
                            )}
                            <Popconfirm
                              title="Está certo disso?"
                              onConfirm={(e) => {
                                e.stopPropagation();
                                if (Object.keys(campos).includes(c.dataIndex)) {
                                  let temp = { ...campos };
                                  delete temp[c.dataIndex];
                                  dispatch(SetCampos(temp));
                                }
                                dispatch(
                                  SetColumns(
                                    columns.map((d) => (d.dataIndex === c.dataIndex ? { ...d, deleted: true } : d))
                                  )
                                );
                              }}
                            >
                              <Button icon={<DeleteOutlined />} size="small" onClick={(e) => e.stopPropagation()} />
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                      <Select
                        size="small"
                        placeholder="Campo"
                        allowClear
                        style={{ width: '100%' }}
                        onClick={(e) => e.stopPropagation()}
                        value={campos[c.dataIndex]}
                        onChange={(v) => {
                          let temp = { ...campos };
                          delete temp[c.dataIndex];
                          if (v) temp[c.dataIndex] = v;
                          dispatch(SetCampos(temp));
                          let tempValues = [...values];
                          if (v === 'unidade') {
                            tempValues = tempValues.map((d) => {
                              let temp = Object.assign({}, d);
                              const unidade = unidades.find((e) => e.nome === d[c.dataIndex]);
                              if (unidade) {
                                if (settings.tipo === 0) {
                                  temp.unidadeOqueID = unidade.id;
                                  temp.unidadeOque = unidade.nome;
                                  temp.unidadeOndeID = null;
                                  temp.oque = null;
                                } else {
                                  temp.unidadeOndeID = unidade.id;
                                  temp.unidadeOnde = unidade.nome;
                                  temp.unidadeOqueID = null;
                                  temp.onde = null;
                                }

                                if (!contas.find((ca) => ca.unidadeID === unidade.id))
                                  dispatch(GetContasAnaliticas(unidade.id));
                                return temp;
                              } else return temp;
                            });
                            dispatch(SetValues(tempValues));
                          }
                          if (v === 'pessoa') {
                            tempValues = tempValues.map((d) => {
                              let temp = Object.assign({}, d);
                              let pessoa: Pessoa | null = null;
                              pessoas.forEach((p) => {
                                const pe = p.pessoas.find((t) => t.nomeRazao === d[c.dataIndex]);
                                if (pe) {
                                  pessoa = pe;
                                }
                              });
                              if (pessoa) {
                                temp.pessoaID = pessoa.id;
                                temp.pessoa = pessoa.nomeRazao;
                                return temp;
                              } else return temp;
                            });
                            dispatch(SetValues(tempValues));
                          }
                          if (v === 'valor') {
                            tempValues = tempValues.map((d) => {
                              let temp = Object.assign({}, d);
                              if (temp[c.dataIndex] < 0) {
                                //d.total = pessoa;
                                return temp;
                              } else return temp;
                            });
                            dispatch(SetValues(tempValues));
                          }
                        }}
                      >
                        <Select.Option value="unidade" disabled={disableCampo('unidade', campos)}>
                          Unidade
                        </Select.Option>
                        <Select.Option value="valor" disabled={disableCampo('valor', campos)}>
                          Valor
                        </Select.Option>
                        {settings.tipo === 0 && (
                          <Select.Option
                            value="oque"
                            disabled={
                              disableCampo('oque', campos) ||
                              !Object.values(campos).find((c) => c === 'unidade') ||
                              !Object.values(campos).find((c) => c === 'valor')
                            }
                          >
                            O Que
                          </Select.Option>
                        )}
                        {settings.tipo === 1 && (
                          <Select.Option
                            value="onde"
                            disabled={
                              disableCampo('onde', campos) ||
                              !Object.values(campos).find((c) => c === 'unidade') ||
                              !Object.values(campos).find((c) => c === 'valor')
                            }
                          >
                            Onde
                          </Select.Option>
                        )}
                        <Select.Option value="descricao" disabled={disableCampo('descricao', campos)}>
                          Descrição
                        </Select.Option>
                        <Select.Option value="pessoa" disabled={disableCampo('pessoa', campos)}>
                          Pessoa
                        </Select.Option>

                        <Select.Option value="documento" disabled={disableCampo('documento', campos)}>
                          Documento
                        </Select.Option>
                        {settings.quando === 1 && (
                          <Select.Option value="dataVencimento" disabled={disableCampo('dataVencimento', campos)}>
                            Data Vencimento
                          </Select.Option>
                        )}
                        <Select.Option value="dataTransacao" disabled={disableCampo('dataTransacao', campos)}>
                          Data Transação
                        </Select.Option>
                        <Select.Option value="juros" disabled={disableCampo('juros', campos)}>
                          Juros
                        </Select.Option>
                        <Select.Option value="multa" disabled={disableCampo('multa', campos)}>
                          Multa
                        </Select.Option>
                        <Select.Option value="desconto" disabled={disableCampo('desconto', campos)}>
                          Desconto
                        </Select.Option>
                      </Select>
                    </Space>
                  ) : c.dataIndex === 'Menu' && settings.tipo === 0 ? (
                    'Onde'
                  ) : c.dataIndex === 'Menu' && settings.tipo === 1 ? (
                    'O que'
                  ) : c.dataIndex === 'Done' ? (
                    'Preenchido'
                  ) : (
                    ''
                  )
                }
                render={(v, row, index) =>
                  renderCell(
                    v,
                    row,
                    index,
                    c.dataIndex,
                    settings.dateFormat,
                    values,
                    campos,
                    settings,
                    dispatch,
                    settings.tipo,
                    selectConta
                  )
                }
              />
            ))}
        </Table>
      </Space>
    </Spin>
  );
}

export default NovaImportacao;
