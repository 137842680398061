import { Select, Button, DatePicker, Space } from 'antd';
import moment from 'moment';
import { SelectUnidades, SetCompetencia, GetNumbers } from '@actions/fluxocaixaActions';
import { DoubleRightOutlined, DoubleLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Permission } from '@hooks/usePermissions';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { MonthPicker } = DatePicker;

function Unidades() {
  const dispatch = useAppDispatch();
  const fluxo = useAppSelector((state) => state.fluxocaixa);
  const general = useAppSelector((state) => state.general);
  const handleSelectAll = (value: any[]) => {
    if (value && value.length && value.filter((u) => u === 'all').length) {
      if (value.length === general.unidades.map((u) => u.id).length + 1) {
        return [];
      }
      return [...general.unidades.map((u) => u.id)];
    }
    return value;
  };
  var anos = [];
  anos.length = 20;
  useEffect(() => {
    if (general.unidades.length === 1) {
      dispatch(SelectUnidades([general.unidades[0].id]));
    }
  }, [general.unidades]);
  return (
    <Permission permission="fluxocaixa.add">
      <Space direction="horizontal">
        <Select
          size="small"
          mode="multiple"
          maxTagCount={1}
          disabled={general.unidades.length === 1}
          optionFilterProp="label"
          style={{ maxWidth: 300, minWidth: 300 }}
          placeholder="Unidades de Negócio"
          value={fluxo.unidades}
          onChange={(value) => {
            dispatch(SelectUnidades(handleSelectAll(value)));
          }}
        >
          {general.unidades.length > 1 && (
            <Select.Option label="todos" key="all">
              TODOS
            </Select.Option>
          )}
          {general.unidades.map((unidade) => (
            <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
              {unidade.nome}
            </Select.Option>
          ))}
        </Select>
        <Button
          size="small"
          type="default"
          icon={<DoubleLeftOutlined />}
          onClick={() => {
            dispatch(SetCompetencia(moment(fluxo.competencia).add(-1, 'year')));
          }}
        />
        <MonthPicker
          value={fluxo.competencia}
          allowClear={false}
          style={{ width: 85 }}
          format="YYYY"
          size="small"
          onChange={(v) => {
            dispatch(SetCompetencia(v));
          }}
        />
        <Button
          size="small"
          type="default"
          icon={<DoubleRightOutlined />}
          onClick={() => {
            dispatch(SetCompetencia(moment(fluxo.competencia).add(1, 'year')));
          }}
        />
        <Button
          key="2"
          size="small"
          style={{ float: 'right' }}
          loading={fluxo.fluxosloading}
          type="primary"
          disabled={fluxo.unidades.length === 0}
          onClick={() => dispatch(GetNumbers(fluxo.unidades, moment(fluxo.competencia).format('YYYY')))}
        >
          Gerar Fluxo
        </Button>
        <Link
          key="4"
          to={`/PrintFluxo?unidades=${JSON.stringify(fluxo.unidades)}&ano=${moment(fluxo.competencia).format('YYYY')}`}
          target="_blank"
        >
          <Button size="small" icon={<PrinterOutlined />}>
            Imprimir
          </Button>
        </Link>
      </Space>
    </Permission>
  );
}

export default Unidades;
