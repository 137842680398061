import moment from 'moment';
import { message } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { LancarReducer } from '@reducers/reducers';

var initialState: LancarReducer = {
  waiting: false,
  viewtitulos: false,
  loadingtitulos: false,
  loadingbloco: false,
  titulos: [],
  selectedtitulos: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  lancamentos: [],
  loadingcontas: false,
  total0: 0,
  total1: 0,
  contasanaliticas: [],
  showsuccess: false,
  produtos: [],
  produtosbaixa: [],
  loadingProdutosLancar: false,
  loadingProdutosBaixa: false,
};

const lancarReducer = function (state = initialState, action: extendedAction) {
  if (action.type.includes('REJECTED')) {
    state = {
      ...state,
      loadingbloco: false,
      loadingtitulos: false,
      loadingcontas: false,
      waiting: false,
    };
  }

  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SET_SELECTED_TITULOS_LANCAR':
      state = {
        ...state,
        selectedtitulos: action.payload,
      };
      break;
    case 'GET_PRODUTOS_BAIXA_LANCAR_PENDING':
      state = {
        ...state,
        loadingProdutosBaixa: true,
      };
      break;
    case 'GET_PRODUTOS_BAIXA_LANCAR_REJECTED':
      state = {
        ...state,
        loadingProdutosBaixa: false,
      };
      break;
    case 'GET_PRODUTOS_BAIXA_LANCAR_FULFILLED':
      state = {
        ...state,
        loadingProdutosBaixa: false,
        produtosbaixa: action.payload.data,
      };
      break;
    case 'GET_PRODUTOS_LANCAR_PENDING':
      state = {
        ...state,
        loadingProdutosLancar: true,
      };
      break;
    case 'GET_PRODUTOS_LANCAR_REJECTED':
      state = {
        ...state,
        loadingProdutosLancar: false,
      };
      break;
    case 'GET_PRODUTOS_LANCAR_FULFILLED':
      state = {
        ...state,
        produtos: action.payload.data,
        loadingProdutosLancar: false,
      };
      break;
    case 'REMOVE_TITULO_LANCAR':
      state = {
        ...state,
        selectedtitulos: state.selectedtitulos.filter((c) => c !== action.payload),
      };
      break;
    case 'REMOVE_TITULOS_LANCAR':
      state = {
        ...state,
        selectedtitulos: [],
      };
      break;
    case 'SALVAR_LANCAMENTO_LANCAR_PENDING':
      state = {
        ...state,
        loadingbloco: true,
      };
      break;
    case 'SALVAR_LANCAMENTO_LANCAR_FULFILLED':
      state = {
        ...state,
        loadingbloco: false,
      };
      break;
    case 'LOAD_BLOCO_LANCAR_PENDING':
      state = {
        ...state,
        loadingbloco: true,
      };
      break;
    case 'LOAD_BLOCO_LANCAR_FULFILLED':
      var data = action.payload.data;
      data.datatransacao = moment(data.datatransacao);
      state = {
        ...state,
        loadingbloco: false,
        lancamentos: data,
      };
      break;
    case 'GET_TITULOS_LANCAR_PENDING':
      state = {
        ...state,
        loadingtitulos: true,
        viewtitulos: true,
      };
      break;
    case 'GET_TITULOS_LANCAR_REJECTED':
      state = {
        ...state,
        loadingtitulos: false,
      };
      message.error('Erro ao buscar títulos');
      break;
    case 'GET_TITULOS_LANCAR_FULFILLED':
      state = {
        ...state,
        loadingtitulos: false,
        titulos: action.payload.data,
      };
      break;
    case 'SHOW_HIDE_TITULOS_LANCAR':
      state = {
        ...state,
        viewtitulos: action.payload,
      };
      break;
    case 'GET_CONTAS_ANALITICAS_PENDING':
      state = {
        ...state,
        loadingcontas: true,
      };
      break;
    case 'GET_CONTAS_ANALITICAS_FULFILLED':
      state = {
        ...state,
        loadingcontas: false,
        contasanaliticas: action.payload.data,
      };
      break;
    case 'SET_TOTAL_0':
      state = {
        ...state,
        total0: action.payload,
      };
      break;
    case 'SET_TOTAL_1':
      state = {
        ...state,
        total1: action.payload,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default lancarReducer;
