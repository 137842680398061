import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import TituloExtrato from './TituloExtrato';
import { formatNumber } from '@utils/masks';
import { useSearchParams } from 'react-router-dom';
import { GerarExtrato } from '@actions/extratoActions';
import moment from 'moment';
import { Spin } from 'antd';
import logo from '../../../assets/img/logo.png';
import store, { useAppDispatch, useAppSelector } from '@config/reduxStore';
import '../../../assets/scss/extrato.scss';
import { GetPessoas } from '@actions/pessoasActions';
import { TipoConta } from '@reducers/reducers';

function PrintExtrato() {
  const dispatch = useAppDispatch();
  const extrato = useAppSelector((state) => state.extrato);
  const pessoas = useAppSelector((state) => state.pessoas);
  const [searchParams] = useSearchParams();
  const data = {
    unidades: (JSON.parse(searchParams.get('Unidades')) as number[]) || [],
    pessoas: (JSON.parse(searchParams.get('Pessoas')) as number[]) || [],
    nivel: Number(searchParams.get('Nivel')) || null,
    periodoinicio: moment(searchParams.get('PeriodoInicio')) || null,
    periodofim: moment(searchParams.get('PeriodoFim')) || null,
    bancaria: searchParams.get('Bancaria') === 'null' ? null : Number(searchParams.get('Bancaria')),
    tipoconta: searchParams.get('TipoConta') ? (searchParams.get('TipoConta') as TipoConta) : null,
    contaid: Number(searchParams.get('ContaID')) || null,
    contatitle: searchParams.get('ContaTitle'),
  };
  useEffect(() => {
    document.title = 'Nambi - Extrato';
    dispatch(GetPessoas());
    dispatch(
      GerarExtrato(
        data.nivel,
        data.contaid,
        data.periodoinicio,
        data.periodofim,
        data.pessoas,
        data.unidades,
        data.bancaria,
        data.tipoconta
      )
    );
  }, []);
  useEffect(() => {
    if (extrato.extrato && pessoas.pessoas) {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Provider store={store}>
          <Spin spinning={extrato.waiting}>
            <div style={{ width: '100%' }} className="extratoprint">
              <table className="report-container printme" style={{ width: '100%' }}>
                <thead className="report-header">
                  <tr>
                    <th>
                      <img src={logo} height={30} className="logo" alt="logo" />
                    </th>
                    <th colSpan={10}>
                      <TituloExtrato />
                    </th>
                  </tr>
                  <tr style={{ fontWeight: 'strong' }}>
                    <th>Bloco</th>
                    <th>Data Transação</th>
                    <th>Descrição</th>
                    <th>Unidade</th>
                    <th>Conta</th>
                    <th>Documento</th>
                    <th>Parcela</th>
                    <th>Pessoa</th>
                    <th className="valor">Crédito</th>
                    <th className="valor">Débito</th>
                    <th className="valor">Saldo</th>
                  </tr>
                </thead>
                <tbody className="report-content">
                  {extrato.extrato.map((c, index) => {
                    return (
                      <tr key={index}>
                        <td>{c.userCod + c.bid}</td>
                        <td>{moment(c.dataTransacao).isValid() ? moment(c.dataTransacao).format('DD/MM/YYYY') : ''}</td>
                        <td>{c.descricaoBloco}</td>
                        <td>{c.unidade}</td>
                        <td>{`${c.analitica} ${c.bancaria ? ' - ' + c.bancaria : ''}`}</td>
                        <td>{c.documento}</td>
                        <td>{c.parcela}</td>
                        <td>{c.pessoa as string}</td>
                        <td className="valor">{formatNumber(c.credito)}</td>
                        <td className="valor">{formatNumber(c.debito)}</td>
                        <td className="valor">{formatNumber(c.saldo)}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="report-footer">
                  <tr style={{ fontWeight: 'bold' }}>
                    <td colSpan={8} align="right">
                      TOTAL
                    </td>
                    <td className="valor">{formatNumber(extrato.totalcredito)}</td>
                    <td className="valor">{formatNumber(extrato.totaldebito)}</td>
                    <td className="valor">{formatNumber(extrato.saldototal)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Spin>
        </Provider>
      );
    }
  }, [extrato.extrato, pessoas.pessoas]);
  return <div></div>;
}
export default PrintExtrato;
