import { Routes, Route } from 'react-router-dom';
import Avisos from './Home/Avisos';
import Contas from './Home/Contas';
import Home from './Home/Home';
import Planos from './Home/Planos';

function SistemaContexto() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Planos" element={<Planos />} />
      <Route path="/Contas" element={<Contas />} />
      <Route path="/Avisos" element={<Avisos />} />
    </Routes>
  );
}

export default SistemaContexto;
