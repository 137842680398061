import { AppDispatch } from '@config/reduxStore';
import axios from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const SetUnidade = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_UNIDADE_IMPLANTACAO',
      payload: id,
    });
  };
};
export const SetUnidadeImplantacao = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_UNIDADE_IMPLANTACAO_IMPLANTACAO',
      payload: id,
    });
  };
};
export const SetDataImplantacao = function (data: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_DATA_IMPLANTACAO',
      payload: data,
    });
  };
};
export const GetContas = function (unidadeid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_CONTAS_IMPLANTACAO',
      payload: axios.get(apiEndpoints.UNIDADES.GET_CONTAS, { params: { unidadeid } }),
    });
  };
};
export const ExpandRow = function (id: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXPAND_ROW_IMPLANTACAO',
      payload: id,
    });
  };
};

export const ImplantarSaldos = function (unidade: number, unidadeimplantacao: number, data: any, contas: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'IMPLANTAR_SALDOS_IMPLANTACAO',
      payload: axios.post(apiEndpoints.IMPLANTACAO.IMPLANTAR, {
        unidade,
        unidadeimplantacao,
        data,
        contas: contas,
      }),
    });
  };
};
export const GetUnidades = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_UNIDADES_PENDING',
    });
    axios.get(apiEndpoints.UNIDADES.GET_UNIDADES).then((res) => {
      dispatch({
        type: 'GET_UNIDADES_FULFILLED',
        payload: res,
      });
      if (res.data.length === 1) {
        dispatch({
          type: 'SET_UNIDADE_ONLY',
          payload: String(res.data[0].id),
        });
        dispatch(GetContas(res.data[0].id));
      }
    });
  };
};
