import { Button, Input, Progress, Table } from 'antd';
import { useEffect } from 'react';
import { GetImportacoes } from '@actions/importarActions';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { orderDate, naturalSorter, filterobject } from '@utils/filterandsort';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Importacao } from '@models/Importacao';

function ImportarView() {
  const dispatch = useAppDispatch();
  const { importacoes, loadingImportacoes } = useAppSelector((state) => state.importar);
  const [filtro, setFiltro] = useState('');
  useEffect(() => {
    dispatch(GetImportacoes());
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Importação',
        subTitle: 'Importação de lançamentos',
        extras: [
          <Link to="/NovaImportacao" key={0}>
            <Button size="small" type="primary" icon={<PlusOutlined />}>
              Nova Importação
            </Button>
          </Link>,
        ],
      })
    );
  }, []);

  return (
    <div>
      <Input placeholder="Busca..." style={{ width: 200 }} value={filtro} onChange={(e) => setFiltro(e.target.value)} />
      <Table
        size="small"
        dataSource={filterobject(importacoes, filtro)}
        loading={loadingImportacoes}
        rowKey="id"
        scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
      >
        <Table.Column
          dataIndex="id"
          width={30}
          render={(v) => (
            <Link to={`/NovaImportacao/${v}`}>
              <Button size="small" icon={<EditOutlined />} />
            </Link>
          )}
        />
        <Table.Column
          title="Data"
          dataIndex="data"
          render={(v) => moment(v).format('DD/MM/YYYY HH:mm')}
          sorter={(a: Importacao, b: Importacao) => orderDate(a.data, b.data)}
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          sorter={(a: Importacao, b: Importacao) => naturalSorter(a.importedFile, b.importedFile)}
        />
        <Table.Column
          title="Total Linhas"
          dataIndex="totalLinhas"
          align="center"
          sorter={(a: Importacao, b: Importacao) => a.totalLinhas - b.totalLinhas}
        />
        <Table.Column
          title="Total Importadas"
          dataIndex="importadas"
          align="center"
          sorter={(a: Importacao, b: Importacao) => a.importadas - b.importadas}
        />
        <Table.Column
          title="Total Importado (%)"
          render={(_, row: Importacao) => <Progress percent={(row.importadas / row.totalLinhas) * 100} />}
        />
      </Table>
    </div>
  );
}

export default ImportarView;
