import {
  PickConta,
  PickPessoa,
  PickUnidade,
  SetDescricao,
  SetDocumento,
  SetTitulos,
  SetTransferencia,
} from '@actions/conciliarActions';
import AnaliticaSelect from '@components/AnaliticaSelect';
import PessoaSelect from '@components/PessoaSelect';
import UnidadeSelect from '@components/UnidadeSelect';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ImportData } from '@models/Conciliar';
import { formatNumber } from '@utils/masks';
import { Button, Checkbox, Table, Typography } from 'antd';
import moment from 'moment';
import ConciliarProgress from './Conciliar.Progress';
import ConciliarTableMenu from './Conciliar.Table.Menu';
import { useState } from 'react';
import ConciliarTitulos from './Conciliar.Titulos';
import { Lancamento } from '@models/Lancamento';

const TitulosList = ({ titulos, id }: { titulos: Lancamento[]; id: string }) => {
  const dispatch = useAppDispatch();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {titulos.map((c, i) => (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'space-between' }}>
          <div>{c.bloco}</div>
          <div>{formatNumber(c.valor)}</div>
          <div
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => dispatch(SetTitulos({ titulos: titulos.filter((_, id) => i !== id), id }))}
          >
            X
          </div>
        </div>
      ))}
    </div>
  );
};

function ConciliarTable() {
  const { data, lancando } = useAppSelector((state) => state.conciliar);
  const dispatch = useAppDispatch();
  const [viewTitulos, setViewTitulos] = useState(false);
  const [tipoTitulo, setTipoTitulo] = useState<0 | 1>(0);
  const [idTitulo, setIdTitulo] = useState<string>(null);
  return (
    <div>
      <ConciliarProgress />
      <ConciliarTitulos visible={viewTitulos} setVisible={setViewTitulos} tipo={tipoTitulo} id={idTitulo} />
      <Table
        size="small"
        dataSource={data}
        rowKey="id"
        loading={lancando}
        scroll={{ y: 'calc(100vh - 210px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={100}
          align="center"
          dataIndex="id"
          render={(v: string, row: ImportData, index) => <ConciliarTableMenu v={v} row={row} index={index} />}
        />
        <Table.Column
          dataIndex="id"
          title="Transferência"
          width={90}
          align="center"
          render={(v, row: ImportData) => (
            <Checkbox checked={row.transferencia} onChange={() => dispatch(SetTransferencia(v))} />
          )}
        />
        <Table.Column
          dataIndex="data"
          title="Data"
          align="center"
          width={100}
          render={(v) => moment(v).format('DD/MM/YYYY')}
        />
        <Table.Column dataIndex="valor" title="Valor" width={120} render={(v) => formatNumber(v)} align="right" />
        <Table.Column
          dataIndex="descricao"
          title="Descrição"
          width={160}
          render={(v, row: ImportData) => (
            <Typography.Text editable={{ onChange: (value) => dispatch(SetDescricao({ v: value, id: row.id })) }}>
              {v}
            </Typography.Text>
          )}
        />
        <Table.Column
          dataIndex="documento"
          title="Documento"
          width={140}
          render={(v, row: ImportData) => (
            <Typography.Text editable={{ onChange: (value) => dispatch(SetDocumento({ v: value, id: row.id })) }}>
              {v}
            </Typography.Text>
          )}
        />
        <Table.Column
          dataIndex="pessoaID"
          width={150}
          title="Pessoa"
          render={(v, row: ImportData) => (
            <PessoaSelect
              value={v}
              placeholder="Pessoa..."
              onChange={(v) => dispatch(PickPessoa({ v: v, id: row.id }))}
            />
          )}
        />
        <Table.Column
          dataIndex="unidadeID"
          title="Unidade"
          width={200}
          render={(v, row: ImportData) => (
            <UnidadeSelect
              placeholder="Unidade de negócio..."
              value={v}
              onChange={(v) => dispatch(PickUnidade({ v: v, id: row.id }))}
            />
          )}
        />
        <Table.Column
          dataIndex="id"
          title="O que"
          width={250}
          render={(_, row: ImportData) => (
            <AnaliticaSelect
              placeholder="O que..."
              transferencia={row.transferencia}
              tipo={row.valor < 0 ? 0 : 1}
              unidadeID={row.unidadeID}
              value={row.contaID ? 'n5' + row.contaID : undefined}
              onChange={(v) => dispatch(PickConta({ v: Number(v.slice(2, v.length)), id: row.id }))}
            />
          )}
        />
        <Table.Column
          dataIndex="id"
          title="Título"
          width={150}
          render={(v, row: ImportData) =>
            row?.titulos?.length > 0 ? (
              <TitulosList titulos={row.titulos} id={v} />
            ) : (
              <Button
                size="small"
                disabled={row.transferencia}
                onClick={() => {
                  setTipoTitulo(row.valor < 0 ? 0 : 1);
                  setViewTitulos(true);
                  setIdTitulo(v);
                }}
              >
                Baixar Título
              </Button>
            )
          }
        />
      </Table>
    </div>
  );
}
export default ConciliarTable;
