import { Table, Button, Popconfirm, Tooltip } from 'antd';
import { Permission } from '@hooks/usePermissions';
import moment from 'moment';
import { moneyMaskNeg, sortAlpha } from '@utils/masks';
import { naturalSorter } from '@utils/filterandsort';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { orderDate } from '@utils/filterandsort';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Lancamento } from '@models/Lancamento';
import { LancamentosControllerInterface } from '@controllers/controllers';

function TabelaLancamentos({ controller }: { controller: LancamentosControllerInterface }) {
  const { data, actions } = controller();
  const confirm = () => {
    actions.Excluir();
  };
  const columns: ColumnsType<Lancamento> = [
    {
      title: 'Ação',
      width: '50px',
      align: 'center',
      render: (_, row) => {
        return (
          <Permission permission="lancamento.edit">
            <Tooltip title="Editar">
              <Link to={`/Lancar?bloco=${row.bloco}`}>
                <Button icon={<EditOutlined />} size="small" />
              </Link>
            </Tooltip>
          </Permission>
        );
      },
    },
    {
      title: 'Bloco',
      dataIndex: 'bloco',
      width: 80,
      sorter: (a, b) => naturalSorter(a.bloco, b.bloco),
      render: (text) => text,
    },
    {
      title: 'Transação',
      width: 90,
      dataIndex: 'dataTransacao',
      sorter: (a, b) => orderDate(a.dataTransacao, b.dataTransacao),
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricaoBloco',
      width: '15%',
      sorter: (a, b) => sortAlpha(a.descricaoBloco, b.descricaoBloco),
      render: (text) => text,
    },
    {
      title: 'Competência',
      dataIndex: 'competencia',
      width: 100,
      sorter: (a, b) => orderDate(a.competencia, b.competencia),
      render: (text) => moment(text).format('MM/YYYY'),
    },
    {
      title: 'Vencimento',
      dataIndex: 'dataVencimento',
      width: 90,
      render: (text) => moment(text).format('DD/MM/YYYY'),
      sorter: (a, b) => orderDate(a.dataVencimento, b.dataVencimento),
    },
    {
      title: 'Unidade',
      dataIndex: 'unidade',
      sorter: (a, b) => sortAlpha(a.unidade, b.unidade),
    },
    {
      title: 'Conta',
      dataIndex: 'conta',
      sorter: (a, b) => sortAlpha(a.conta, b.conta),
      render: (text, row) => (row.bancaria ? row.conta + ' - ' + row.bancaria : text),
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      sorter: (a, b) => sortAlpha(a.documento, b.documento),
    },
    {
      title: 'Pessoa',
      dataIndex: 'pessoa',
      sorter: (a, b) => sortAlpha(a.pessoa, b.pessoa),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      width: 130,
      render: (text) => <span style={{ color: text < 0 ? 'red' : 'blue' }}> {moneyMaskNeg.format(text)}</span>,
      align: 'right',
      sorter: (a, b) => a.valor - b.valor,
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: any) => {
      actions.Select(selectedRowKeys);
    },
    selectedRowKeys: data.selectedlancamentos,
  };

  const hasSelected = data.selectedlancamentos.length > 0;
  return (
    <div>
      <Permission permission="lancamento.delete">
        <Popconfirm
          disabled={!hasSelected}
          title="Tem certeza que deseja excluir? Remover um lançamento remove o bloco inteiro."
          onConfirm={() => confirm()}
          okText="Sim"
          cancelText="Não"
        >
          <Button size="small" type="default" disabled={!hasSelected} icon={<DeleteOutlined />} />
        </Popconfirm>
      </Permission>
      <span style={{ marginLeft: 8 }}>{hasSelected ? `${data.selectedlancamentos.length} selecionados` : ''}</span>
      <Permission permission="lancamento.list">
        <Table
          bordered
          tableLayout="fixed"
          size="small"
          scroll={{ y: 'calc(100vh - 220px)', x: 1200 }}
          rowKey="id"
          onChange={(pagination, filters, sorter) => {
            actions.SetFiltros({ pagination, filters, sorter });
          }}
          columns={columns}
          loading={data.waiting}
          dataSource={data.lancamentos}
          rowSelection={rowSelection}
          pagination={{
            size: 'small',
            total: data.lancamentosFiltro.pagination.total,
            showSizeChanger: true,
            pageSize: data.lancamentosFiltro.pagination.pageSize,
            current: data.lancamentosFiltro.pagination.current,
            pageSizeOptions: [10, 25, 50],
            showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          }}
        />
      </Permission>
    </div>
  );
}

export default TabelaLancamentos;
