import { SetPageHeader } from '@actions/userActions';
import {
  AddUser,
  AddUserToRole,
  FindUser,
  GetPerfils,
  GetUsuariosContexto,
  HideUsuarioForm,
  RemoveUsers,
  SetSelectedUsuarios,
} from '@actions/usuariosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Button } from 'antd';
import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { UsuariosControllerInterface } from '@controllers/controllers';

export const UsuariosController: UsuariosControllerInterface = () => {
  const dispatch = useAppDispatch();
  const {
    loadingusuarios,
    usuarios,
    selectedUsuarios,
    perfis,
    savingusuario,
    usuario,
    finduser,
    formvisible,
    addusermsg,
    editing,
  } = useAppSelector((state) => state.usuarios);
  useEffect(() => {
    dispatch(GetUsuariosContexto());
    dispatch(GetPerfils());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Usuários',
        subTitle: 'Gerencie os usuários que tem acesso a esta conta',
        extras: [
          <Button key="1" type="primary" size="small" onClick={() => dispatch(HideUsuarioForm(null, true))}>
            <PlusOutlined style={{ marginLeft: '8px' }} /> Novo Usuário
          </Button>,
        ],
      })
    );
  }, [dispatch]);
  const Remove: () => void = () => dispatch(RemoveUsers(selectedUsuarios));
  const SelectUsers: (keys: React.Key[]) => void = (keys) => dispatch(SetSelectedUsuarios(keys));
  const HideForm: (id: string, value: boolean) => void = (id, value) => dispatch(HideUsuarioForm(id, value));
  const AddUserRole: (userid: string, role: string) => void = (user, role) => dispatch(AddUserToRole(user, role));
  const Add: (nome: string, email: string, perfil: string) => void = (nome, email, perfil) =>
    dispatch(AddUser(nome, email, perfil));
  const Find: (email: string) => void = (email) => dispatch(FindUser(email));

  return {
    data: {
      loadingusuarios,
      usuarios,
      selectedUsuarios,
      perfis,
      savingusuario,
      usuario,
      finduser,
      formvisible,
      addusermsg,
      editing,
    },
    actions: {
      Remove,
      SelectUsers,
      HideForm,
      AddUserRole,
      Add,
      Find,
    },
  };
};
