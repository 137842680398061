import { notification } from 'antd';
import moment from 'moment';
import { Orcamento, OrcamentoConta, OrcamentoMensal } from '@models/Orcamento';
import { extendedAction } from '@config/reduxStore';
import { TipoOrcamento } from '@constants/models';
import { OrcamentosReducer } from '@reducers/reducers';

var initialState: OrcamentosReducer<OrcamentoConta | OrcamentoMensal> = {
  waiting: false,
  orcamentoformvisible: false,
  orcamentos: [],
  unidades: [],
  savingloading: false,
  orcamento: {
    unidade: null,
    descricao: '',
    ano: moment(new Date()),
    tipoOrcamento: TipoOrcamento.Global,
    orcamentoConta: [],
    id: '',
  },
  orcamentoview: {
    unidade: null,
    descricao: '',
    ano: moment(new Date()),
    tipoOrcamento: TipoOrcamento.Global,
    orcamentoConta: [],
    id: '',
  },
};

const orcamentosReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SHOW_ORCAMENTO_FORM':
      state = {
        ...state,
        orcamentoformvisible: true,
      };
      break;
    case 'SET_UNIDADE_ONLY':
      state = {
        ...state,
        orcamento: {
          ...state.orcamento,
          unidade: action.payload,
        },
      };
      break;
    case 'HIDE_ORCAMENTO_FORM':
      state = {
        ...state,
        orcamentoformvisible: false,
        orcamento: {
          ...initialState.orcamento,
        },
      };
      break;
    case 'GET_ORCAMENTOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_ORCAMENTOS_FULFILLED':
      state = {
        ...state,
        waiting: false,
        orcamentos: action.payload.data,
      };
      break;
    case 'EXCLUIR_ORCAMENTO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'EXCLUIR_ORCAMENTO_REJECTED':
      notification.error({ message: 'Erro ao excluir orçamento!' });
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'EXCLUIR_ORCAMENTO_FULFILLED':
      notification.success({ message: 'Orçamento excluído com sucesso!' });
      state = {
        ...state,
        waiting: false,
        orcamentos: state.orcamentos.filter((c: Orcamento) => c.id !== action.payload.data),
      };
      break;
    case 'GET_CONTAS_ANALITICAS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_CONTAS_ANALITICAS_FULFILLED':
      state = {
        ...state,
        orcamento: {
          ...state.orcamento,
          orcamentoConta: action.payload.data,
        },
        waiting: false,
      };
      break;
    case 'GET_UNIDADES_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_UNIDADES_FULFILLED':
      state = {
        ...state,
        unidades: action.payload.data,
        waiting: false,
      };
      break;
    case 'SELECT_UNIDADE_ORCAMENTO':
      state = {
        ...state,
        orcamento: {
          ...state.orcamento,
          unidade: action.payload.unidade,
          tipoOrcamento: action.payload.tipo,
          ano: action.payload.ano,
          descricao: action.payload.descricao,
        },
      };
      break;
    case 'CRIAR_ORCAMENTO_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'CRIAR_ORCAMENTO_FULFILLED':
      notification.success({ message: 'Orçamento salvo com sucesso!' });
      state = {
        ...state,
        savingloading: false,
        orcamentos: action.payload.data.novo
          ? [...(state.orcamentos as Orcamento[]), action.payload.data.orcamento as Orcamento[]]
          : state.orcamentos.map((c: Orcamento) => {
              if (c.id === action.payload.data.orcamento.id) return action.payload.data.orcamento;
              else return c as never;
            }),
        orcamentoformvisible: false,
        orcamento: action.payload.data.orcamento,
      };
      break;
    case 'LOAD_ORCAMENTO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'LOAD_ORCAMENTO_REJECTED':
      notification.error({ message: 'Erro ao carregar orçamento!' });
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'LOAD_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        waiting: false,
        orcamento: action.payload.data,
        orcamentoformvisible: true,
      };
      break;
    case 'LOAD_ORCAMENTO_VIEW_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'LOAD_ORCAMENTO_VIEW_FULFILLED':
      state = {
        ...state,
        waiting: false,
        orcamentoview: {
          orcamentoConta: action.payload.data.result,
          tipoOrcamento: action.payload.data.tipo,
          id: action.payload.data.tipo,
          unidade: action.payload.data.unidade,
          ano: action.payload.data.ano,
          descricao: action.payload.data.descricao,
        },
      };
      break;
    default:
      return state;
  }
  return state;
};

export default orcamentosReducer;
