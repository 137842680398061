import { Table, Input } from 'antd';
import { GetContasSistema } from '@actions/sistemaActions';
import { useEffect } from 'react';
import { filterobject, naturalSorter, sortAlpha } from '@utils/filterandsort';
import { useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';

function Contas() {
  const dispatch = useAppDispatch();
  const [filtro, setFiltro] = useState<string>('');
  const { contas, loadingContas } = useAppSelector((state) => state.sistema);

  useEffect(() => {
    document.title = 'Nambi - Gestão Financeira Inteligente';
    dispatch(GetContasSistema());
  }, [dispatch]);
  useEffect(() => {
    dispatch(SetPageHeader({ title: 'Suporte', subTitle: 'Contas' }));
  }, [dispatch]);
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'nomeRazao',
      sorter: (a: any, b: any) => naturalSorter(a.nomeRazao, b.nomeRazao),
    },
    {
      title: 'Contrato',
      dataIndex: 'status',
    },
    {
      title: 'Lancamentos',
      dataIndex: 'lancamentos',
      sorter: (a: any, b: any) => a.lancamentos - b.lancamentos,
    },
    {
      title: 'Usuários',
      dataIndex: 'usuarios',
      sorter: (a: any, b: any) => a.usuarios - b.usuarios,
    },
    {
      title: 'Unidades',
      dataIndex: 'unidades',
      sorter: (a: any, b: any) => a.unidades - b.unidades,
    },
    {
      title: 'Empresas',
      dataIndex: 'empresas',
      sorter: (a: any, b: any) => a.empresas - b.empresas,
    },
    {
      title: 'Assinatura',
      dataIndex: 'subscriptionID',
      sorter: (a: any, b: any) => sortAlpha(a.subscriptionID, b.subscriptionID),
    },
  ];
  return (
    <div>
      <Input.Search
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
        placeholder="Buscar..."
        style={{ width: 200 }}
      />
      <Table
        loading={loadingContas}
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={filterobject(contas, filtro)}
        scroll={{ x: 1000, y: 'calc(100vh - 280px)' }}
      />
    </div>
  );
}

export default Contas;
