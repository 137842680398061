import { Permission } from '@hooks/usePermissions';
import TabelaPessoas from './TabelaPessoas';
import FormPessoa from './FormPessoa';
import { PessoasController } from '@controllers/PessoasController';

function PessoasView() {
  const pessoasController = PessoasController;
  return (
    <div>
      <Permission permission="pessoa.list">
        <TabelaPessoas pessoasController={pessoasController} />
      </Permission>
      <FormPessoa pessoasController={pessoasController} />
    </div>
  );
}

export default PessoasView;
