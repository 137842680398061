import { GetUnidades } from '@actions/generalActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Select, SelectProps } from 'antd';
import { useEffect } from 'react';

function UnidadeSelect(props: SelectProps) {
  const { unidades, loadingUnidades } = useAppSelector((state) => state.general);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetUnidades(true, true));
  }, []);
  return (
    <Select
      {...props}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth || false}
      loading={props.loading || loadingUnidades}
      optionFilterProp={props.optionFilterProp || 'children'}
      style={{ width: props?.style?.width || '100%' }}
      allowClear={props.allowClear || true}
      showSearch={props.showSearch || true}
    >
      {unidades.map((c) => (
        <Select.Option value={c.id} key={c.id}>
          {c.nome}
        </Select.Option>
      ))}
    </Select>
  );
}
export default UnidadeSelect;
