import { ProdutosControllerInterface } from '@controllers/controllers';
import { Modal, Table } from 'antd';
import { useEffect } from 'react';

function SaldosPorConta({ controller }: { controller: ProdutosControllerInterface }) {
  const { data, actions } = controller();
  useEffect(() => {
    if (data.saldosPorConta.produtoid) actions.GetSaldos(data.saldosPorConta.produtoid);
  }, [data.saldosPorConta.produtoid]);
  return (
    <Modal
      title="Saldos por Conta"
      open={data.saldosPorConta.visible}
      onCancel={() => actions.SaldoPorConta(null, false)}
      cancelButtonProps={{ hidden: true }}
    >
      <Table
        size="small"
        dataSource={data.saldosPorConta.saldosPorConta}
        loading={data.saldosPorConta.loadingSaldosPorConta}
        rowKey="id"
      >
        <Table.Column title="Descrição" dataIndex="descricao" />
      </Table>
    </Modal>
  );
}

export default SaldosPorConta;
