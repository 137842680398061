import { Reset } from '@actions/conciliarActions';
import AnaliticaBancariaSelect from '@components/AnaliticaBancariaSelect';
import BancariaSelect from '@components/BancariaSelect';
import { apiEndpoints } from '@config/apiEndpoints';
import cliente from '@config/axios';
import { useAppDispatch } from '@config/reduxStore';
import { Form, Input, Modal, notification } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ConciliarNovoProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
interface FormData {
  bancariaID: number;
  unidadeID: number;
  contaID: number;
}
function ConciliarNovo({ visible, setVisible }: ConciliarNovoProps) {
  const [form] = Form.useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleOk = (values: FormData) => {
    setLoading(true);
    cliente
      .post<number>(apiEndpoints.CONCILIAR.ADD, values)
      .then((res) => {
        setLoading(false);
        dispatch(Reset());
        navigate(`/Conciliar/${res.data}`);
      })
      .catch(() => {
        setLoading(false);
        notification.error({ message: 'Erro ao criar conciliação' });
      });
  };
  return (
    <Modal
      title="Nova Conciliação"
      open={visible}
      onOk={() => form.submit()}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: loading }}
    >
      <Form form={form} layout="vertical" onFinish={handleOk}>
        <Form.Item shouldUpdate noStyle>
          {({ setFieldValue }) => (
            <Form.Item
              label="Conta Bancária"
              name="bancariaID"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <BancariaSelect
                placeholder="Conta Bancária..."
                onChange={() => {
                  setFieldValue('contaID', null);
                  setFieldValue('unidadeID', null);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldValue }) => (
            <Form.Item
              label="Conta Analítica"
              name="contaID"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <AnaliticaBancariaSelect
                bancariaID={getFieldValue('bancariaID')}
                placeholder="Conta Analítica..."
                onChangeGetUnidadeID={(_, unidadeID) => setFieldValue('unidadeID', unidadeID)}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="unidadeID" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ConciliarNovo;
