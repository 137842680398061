import { Drawer, Form } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import BancariaSelect from '@components/BancariaSelect';
import AnaliticaBancariaSelect from '@components/AnaliticaBancariaSelect';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { SetSettings, UpdateSettings } from '@actions/conciliarActions';
import { useParams } from 'react-router-dom';

interface ConciliarMenuProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

function ConciliarMenu(props: ConciliarMenuProps) {
  const { visible, setVisible } = props;
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { settings } = useAppSelector((state) => state.conciliar);
  return (
    <Drawer title="Configurações da Conciliação" open={visible} onClose={() => setVisible(false)}>
      <Form layout="vertical">
        <Form.Item label="Conta Bancária">
          <BancariaSelect
            placeholder="Conta Bancária..."
            value={settings.bancariaID}
            onChange={(v) => dispatch(SetSettings({ ...settings, bancariaID: v, contaID: null, unidadeID: null }))}
          />
        </Form.Item>
        <Form.Item label="Conta Analítica">
          <AnaliticaBancariaSelect
            bancariaID={settings.bancariaID}
            placeholder="Conta Analítica..."
            value={settings.contaID}
            onChangeGetUnidadeID={(contaID, unidadeID) =>
              dispatch(UpdateSettings({ ...settings, id: id, contaID: contaID, unidadeID: unidadeID }))
            }
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
export default ConciliarMenu;
