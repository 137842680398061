import { useEffect } from 'react';
import moment from 'moment';
import { Form, Button, Input, Select, Radio, Table, Modal, Row, DatePicker, Col, Divider } from 'antd';
import { GetContasAnaliticas, HideOrcamentoForm, CriaOrcamento } from '@actions/orcamentosActions';

import { GetUnidades } from '@actions/generalActions';
import { formatNumber, moneyMaskNeg } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrcamentoConta } from '@models/Orcamento';
import { TipoOrcamento } from '@constants/models';

function OrcamentoForm() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { orcamentoformvisible, waiting, orcamento, savingloading } = useAppSelector((state) => state.orcamentos);
  const general = useAppSelector((state) => state.general);
  const [orcamentoForm] = Form.useForm();

  useEffect(() => {
    dispatch(GetUnidades(true, true));
  }, [user]);
  useEffect(() => {
    if (orcamento) {
      orcamentoForm.setFieldsValue({
        ...orcamento,
        ano: orcamento.ano ? moment(orcamento.ano, 'YYYY') : null,
        orcamentoConta: orcamento.orcamentoConta?.map((c) => ({
          ...c,
          valor: moneyMaskNeg.format(c.valor),
        })),
      });
    }
  }, [orcamento]);
  useEffect(() => {
    if (general.unidades.length === 1) {
      orcamentoForm.setFieldValue('unidade', general.unidades[0].id);
      dispatch(
        GetContasAnaliticas(
          general.unidades[0].id,
          orcamentoForm.getFieldValue('tipoOrcamento'),
          orcamentoForm.getFieldValue('descricao'),
          orcamentoForm.getFieldValue('ano')
        )
      );
    }
  }, [general.unidades]);
  return (
    <Modal
      title="Orçamentos"
      open={orcamentoformvisible}
      width="80%"
      onCancel={() => dispatch(HideOrcamentoForm())}
      onOk={() => dispatch(HideOrcamentoForm())}
    >
      <Form
        form={orcamentoForm}
        onFinish={(values: any) =>
          dispatch(
            CriaOrcamento({
              ...values,
              ano: values.tipoOrcamento === TipoOrcamento.Mensal ? moment(values.ano).format('YYYY') : null,
              orcamentoConta: values.orcamentoConta?.map((c: any) => ({
                ...c,
                valor: moneyMaskNeg.normalize(c.valor, ''),
              })),
            })
          )
        }
        layout="vertical"
      >
        <div style={{ display: 'inline' }}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Row gutter={20}>
            <Col span={5}>
              <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="tipoOrcamento" label="Tipo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Radio.Group size="small">
                  <Radio value={0}>Global</Radio>
                  <Radio value={1}>Mensal</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                shouldUpdate={(prevValues, newValues) => prevValues.tipoOrcamento !== newValues.tipoOrcamento}
                noStyle
              >
                {({ getFieldValue }) =>
                  getFieldValue('tipoOrcamento') === TipoOrcamento.Mensal && (
                    <Form.Item
                      name="ano"
                      label="Ano"
                      initialValue={moment(new Date())}
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <DatePicker picker="year" size="small" />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="unidade"
                label="Unidade de Negócio"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  size="small"
                  style={{ width: 200 }}
                  disabled={general.unidades.length === 1}
                  onChange={(value) =>
                    dispatch(
                      GetContasAnaliticas(
                        value,
                        orcamentoForm.getFieldValue('tipoOrcamento'),
                        orcamentoForm.getFieldValue('descricao'),
                        orcamentoForm.getFieldValue('ano')
                      )
                    )
                  }
                >
                  {general.unidades.map((unidade) => (
                    <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
                      {unidade.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.List name="orcamentoConta">
            {() => (
              <div>
                <Table
                  size="small"
                  scroll={{ y: '50vh' }}
                  loading={waiting}
                  rowKey="id"
                  dataSource={orcamento.orcamentoConta as OrcamentoConta[]}
                  pagination={false}
                >
                  <Table.Column title="Código" dataIndex="codigo" />
                  <Table.Column title="Natureza" dataIndex="nivel1" />
                  <Table.Column title="Grupo" dataIndex="nivel2" />
                  <Table.Column title="Subgrupo" dataIndex="nivel3" />
                  <Table.Column title="Conta Sintética" dataIndex="nivel4" />
                  <Table.Column title="Descrição" dataIndex="descricao" />
                  <Table.Column
                    title="Saldo Atual"
                    align="right"
                    dataIndex="saldo"
                    render={(text) => formatNumber(text)}
                  />
                  <Table.Column
                    title="Orçamento"
                    render={(_, __, index) => (
                      <div>
                        <Form.Item name={[index, 'id']} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[index, 'valor']}
                          normalize={(v) => moneyMaskNeg.format(moneyMaskNeg.normalize(v, ''))}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    )}
                  />
                </Table>
              </div>
            )}
          </Form.List>
        </div>
        <hr />
        <br />
        <Row justify="end">
          <Button type="primary" key="salvar" loading={savingloading} htmlType="submit">
            Salvar
          </Button>
          <Button type="default" key="fechar" onClick={() => dispatch(HideOrcamentoForm())}>
            Fechar
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

export default OrcamentoForm;
