import { ChangeEventHandler, Key, useEffect, useState } from 'react';
import { Layout, Row, Col, Input, Tree, Skeleton, Button, Select, notification } from 'antd';
import { GetPlanoContas, GetAnaliticas, GetSinteticas } from '@actions/planocontasActions';
import TabelaSinteticas from './TabelaSinteticas';
import { GetBancarias } from '@actions/bancariasActions';
import TabelaAnaliticas from './TabelaAnaliticas';
import { GetUnidades } from '@actions/generalActions';
import { SetPageHeader } from '@actions/userActions';
import { FileExcelOutlined } from '@ant-design/icons';
import cliente from '@config/axios';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface DataList {
  key: Key;
  title: string;
}
const { Search } = Input;
const { TreeNode } = Tree;
function PlanoContasView() {
  const dispatch = useAppDispatch();
  const { planocontas, loadingplano } = useAppSelector((state) => state.planocontas);
  const { unidades } = useAppSelector((state) => state.general);
  const [selectedUnidades, setSelectedUnidades] = useState([]);
  const [downloading, setDownloading] = useState(false);
  useEffect(() => {
    dispatch(GetPlanoContas());
    dispatch(GetAnaliticas());
    dispatch(GetSinteticas());
    dispatch(GetBancarias());
    dispatch(GetUnidades(false));
  }, []);
  const handleSelectAll = (value: any[]) => {
    if (value && value.length && value.filter((u) => u === 'all').length) {
      if (value.length === unidades.map((u) => u.id).length + 1) {
        return [];
      }
      return [...unidades.map((u) => u.id)];
    }
    return value;
  };
  useEffect(() => {
    if (unidades.length === 1) setSelectedUnidades([unidades[0].id]);
  }, [unidades]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Empresa',
        subTitle: 'Plano de contas',
        extras: [
          <Input.Group key={0}>
            <Select
              showSearch
              optionFilterProp="children"
              size="small"
              placeholder="Unidades de Negócio"
              mode="multiple"
              maxTagCount={3}
              value={selectedUnidades}
              disabled={downloading || unidades.length === 1}
              onChange={(values) => setSelectedUnidades(handleSelectAll(values))}
              style={{ width: 300 }}
            >
              {unidades.length > 1 && (
                <Select.Option key="all" value="all">
                  TODOS
                </Select.Option>
              )}
              {unidades.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
            <Button
              icon={<FileExcelOutlined />}
              size="small"
              loading={downloading}
              onClick={() => {
                setDownloading(true);
                cliente
                  .post(
                    '/api/Planocontas/ExportPlanoContas',
                    { unidades: selectedUnidades },
                    {
                      responseType: 'blob',
                    }
                  )
                  .then((res) => {
                    setDownloading(false);
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `PlanoContas_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch(() => {
                    notification.error({ message: 'Erro ao gerar arquivo!' });
                    setDownloading(false);
                  });
              }}
            >
              Exportar
            </Button>
          </Input.Group>,
        ],
      })
    );
  }, [unidades, downloading, selectedUnidades]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandedParent] = useState(true);
  const [selected, setSelected] = useState({ nivel: null, id: null });

  var dataList: DataList[] = [];
  const generateList = (data: any) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      dataList.push({ key, title: title });
      if (node.children) {
        generateList(node.children);
      }
    }
  };
  const getParentKey = (key: Key, tree: any) => {
    let parentKey: any;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];

      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    if (dataList.length === 0) generateList(planocontas);
    const expandedKeys = dataList
      .map((item) => {
        if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return getParentKey(item.key, planocontas);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(expandedKeys);
    setSearchValue(value);
    setAutoExpandedParent(true);
  };
  const onExpand = (expandedKeys: Key[]) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandedParent(false);
  };
  const loop = (data: any) =>
    data.map((item: any) => {
      const index = item.title.indexOf(searchValue);
      const beforeStr = item.title.substr(0, index);
      const originalValue = item.title.substr(index, searchValue.length);
      const afterStr = item.title.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{originalValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return (
          <TreeNode key={item.key} title={title} selectable={item.nivel > 2}>
            {loop(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={title} />;
    });
  return (
    <Layout style={{ minHeight: '90vh', overflowY: 'scroll', width: '100%' }}>
      <Layout>
        <Layout.Content style={{ marginLeft: 12, marginTop: 0, marginRight: 12, minHeight: 300 }}>
          <Row gutter={8}>
            <Col span={8}>
              <Skeleton loading={loadingplano} active>
                <Search style={{ marginBottom: 8, width: 300 }} placeholder="Buscar" onChange={onChange} />
                <Tree
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  onSelect={(selectedKeys) =>
                    setSelected({
                      nivel: String(selectedKeys[0]).substring(0, 2),
                      id: String(selectedKeys[0]).slice(2),
                    })
                  }
                >
                  {loop(planocontas)}
                </Tree>
              </Skeleton>
            </Col>
            <Col span={16}>
              {selected.nivel === 'n3' && <TabelaSinteticas nivel3id={selected.id} />}
              {selected.nivel === 'n4' && <TabelaAnaliticas sinteticaid={selected.id} />}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default PlanoContasView;
