import { useEffect, useState } from 'react';
import { Button, Input, Form, Typography, Card, Checkbox, Modal } from 'antd';
import { SignUp } from '@actions/userActions';
import { phoneMobMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import Termos from '../Termos';
const { Text } = Typography;

const FormCadastro = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [termos, setTermos] = useState(false);
  const { signUp } = useAppSelector((state) => state.user);
  useEffect(() => {
    document.title = 'Nambi - Cadastro Empresa';
  }, []);
  return (
    <div>
      <Modal
        title="Termos de Uso"
        open={termos}
        onCancel={() => setTermos(false)}
        cancelText="Fechar"
        okButtonProps={{ hidden: true }}
      >
        <Termos />
      </Modal>
      <Card title="Crie sua conta" style={{ width: 300, margin: 'auto' }}>
        <Form
          onFinish={(values) => dispatch(SignUp(values.Nome, values.Email, values.Telefone, values.Senha))}
          layout="vertical"
          form={form}
        >
          <Form.Item name="Nome" label="Nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="Email"
            label="Email"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              { type: 'email', message: 'Formato de email incorreto' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Telefone"
            label="Telefone"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            normalize={(v) => phoneMobMask.format(phoneMobMask.normalize(v, ''))}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Senha"
            label="Senha"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              {
                pattern: /^(?=.{6,})/,
                message: 'A senha deve conter no mínimo 6 dígitos',
              },
              {
                pattern: /^(?=.*[a-z])/,
                message: 'A senha deve conter ao menos uma letra minúscula',
              },
              {
                pattern: /^(?=.*[A-Z])/,
                message: 'A senha deve conter ao menos uma letra maiúscula',
              },
              {
                pattern: /^(?=.*[0-9])/,
                message: 'A senha deve conter ao menos um número',
              },
              {
                pattern: /^(?=.*[!@#$%^&*])/,
                message: 'A senha deve conter ao menos um caractére especial',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="ConfirmarSenha"
            label="Confirmar Senha"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Senha') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('As senhas não conferem!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="termos"
            valuePropName="checked"
            rules={[{ required: true, message: 'Você precisa concordar com os termos' }]}
            label={
              <Button type="link" onClick={() => setTermos(true)}>
                Termos de Uso
              </Button>
            }
          >
            <Checkbox>Concordo com os termos de uso</Checkbox>
          </Form.Item>
          <Button type="primary" block htmlType="submit">
            Cadastrar-se
          </Button>
          <Text type="danger">{signUp.errors}</Text>
        </Form>
      </Card>
    </div>
  );
};

export default FormCadastro;
