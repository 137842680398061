import { notification } from 'antd';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { FluxoBody, FluxoReducer, LancamentoFluxo } from '@reducers/reducers';

var initialFluxo: FluxoBody[] = [
  {
    codigo: '',
    grupo: 1.1,
    id: 1,
    tipo: 'subtotal',
    descricao: 'RECEITAS LÍQUIDAS',
  },
  {
    codigo: '',
    grupo: 1.2,
    id: 2,
    tipo: 'subtotal',
    descricao: 'CUSTOS DAS MERCADORIAS, PRODUTOS E SERVIÇOS',
  },
  {
    codigo: '=',
    grupo: 1,
    id: 3,
    tipo: 'total',
    descricao: 'LUCRO BRUTO (MARGEM)',
  },
  {
    codigo: '',
    grupo: 2.1,
    id: 4,
    tipo: 'subtotal',
    descricao: 'DESPESAS OPERACIONAIS (OPEX)',
  },
  {
    codigo: '',
    grupo: 2.2,
    id: 5,
    tipo: 'subtotal',
    descricao: 'DESPESAS PRÉ-OPERACIONAIS',
  },
  {
    codigo: '=',
    grupo: 2,
    id: 6,
    tipo: 'total',
    descricao: 'RESULTADO DA OPERAÇÃO (EBTIDA)',
  },
  {
    codigo: '=',
    grupo: 3,
    id: 7,
    tipo: 'total',
    descricao: 'RESULTADO COM O FINANCEIRO OPERACIONAL',
  },
  {
    codigo: '=',
    grupo: 4,
    id: 8,
    tipo: 'total',
    descricao: 'LUCRO ANTES DO IR E CSLL (LAIR)',
  },
  {
    codigo: '=',
    grupo: 5,
    id: 9,
    tipo: 'total',
    descricao: 'LUCRO LÍQUIDO OPERACIONAL (NOPAT)',
  },
  {
    codigo: '',
    grupo: 6.1,
    id: 10,
    tipo: 'subtotal',
    descricao: '(DEVOLUÇÃO) CUSTO FINANCEIRO DOS FINANCIAMENTOS NÃO OPERACIONAIS',
  },
  {
    codigo: '',
    grupo: '6.1c',
    id: 11,
    fsubtotal2: '6.1',
    tipo: 'conta',
    descricao: '(DEVOLUÇÃO) - ESTIMATIVA DOS IMPOSTOS SOBRE O LUCRO',
  },
  {
    codigo: '=',
    grupo: 6,
    id: 12,
    tipo: 'total',
    descricao: 'LUCRO LÍQUIDO SEM ALAVANCAGEM FINANCEIRA',
  },
  {
    codigo: '',
    grupo: 7.1,
    id: 13,
    tipo: 'subtotal',
    descricao: 'VARIAÇÕES NA NECESSIDADE DE CAPITAL DE GIRO',
  },
  {
    codigo: '=',
    grupo: 7,
    id: 14,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA OPERACIONAL',
  },
  {
    codigo: '',
    grupo: 8.1,
    id: 15,
    tipo: 'subtotal',
    descricao: 'INVESTIMENTOS EM BENS DE CAPITAL (CAPEX)',
  },
  {
    codigo: '=',
    grupo: 8,
    id: 16,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA DA EMPRESA',
  },
  {
    codigo: '=',
    grupo: 9,
    id: 17,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA DA EMPRESA APÓS INVESTIMENTOS',
  },
  {
    codigo: '=',
    grupo: 10,
    id: 18,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA APÓS FINANCIAMENTOS DE CURTO PRAZO',
  },
  {
    codigo: '',
    grupo: 11.1,
    id: 19,
    tipo: 'subtotal',
    descricao: 'CUSTO FINANCEIRO DOS FINANCIAMENTOS NÃO OPERACIONAIS',
  },
  {
    codigo: '',
    grupo: '11.1c',
    id: 20,
    fsubtotal1: 11.1,
    tipo: 'conta',
    descricao: 'ESTIMATIVA DOS IMPOSTOS SOBRE O LUCRO',
  },
  {
    codigo: '=',
    grupo: 11,
    id: 21,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA DO ACIONISTA',
  },
  {
    codigo: '',
    grupo: 12.1,
    id: 22,
    tipo: 'subtotal',
    descricao: 'DIVIDENDOS EFETIVAMENTE PAGOS',
  },
  {
    codigo: '=',
    grupo: 12,
    id: 23,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA GLOBAL',
  },
  {
    codigo: '=',
    grupo: 13,
    id: 24,
    tipo: 'total',
    descricao: 'FLUXO DE CAIXA GLOBAL ACUMULADO',
  },
];

var initialState: FluxoReducer = {
  fluxosloading: false,
  fluxos: [],
  fluxoformvisible: false,
  fluxo: [],
  sinteticasloading: false,
  sinteticas: [],
  editfluxoloading: false,
  checkfluxoloading: false,
  getcontasloading: false,
  progress: 0,
  saldoanteior: 0,
  fluxocaixa: initialFluxo,
  unidades: [],
  competencia: moment(new Date()),
  fluxoteste: [],
  competenciafluxo: moment(),
  nivel3: [],
  nivel4: [],
  lancamentos: [],
};

const fluxocaixaReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'GET_NUMBERS_FLUXO_PENDING':
      state = {
        ...state,
        fluxosloading: true,
        fluxoteste: [],
        fluxocaixa: initialFluxo,
        competenciafluxo: state.competencia,
      };
      break;
    case 'GET_NUMBERS_FLUXO_REJECTED':
      notification.error({ message: 'Erro ao gerar fluxo!' });
      state = {
        ...state,
        fluxosloading: false,
        fluxoteste: [],
        fluxocaixa: initialFluxo,
        nivel3: [],
        nivel4: [],
        lancamentos: [],
      };
      break;
    case 'GET_NUMBERS_FLUXO_FULFILLED1':
      state = {
        ...state,
        nivel3: action.payload.data.nivel3,
        nivel4: action.payload.data.nivel4,
      };
      break;
    case 'GET_NUMBERS_FLUXO_FULFILLED2':
      let fluxo: any[] = [];
      var lancamentos: LancamentoFluxo[] = action.payload.data;
      state.fluxocaixa.forEach((c) => {
        var grupo1 = state.nivel3.filter((d) => d.fsubtotal1 == c.grupo);
        var grupo1ids = grupo1.map((d) => d.id);
        fluxo.push({
          ...c,
          id: 'c' + c.id,
          mes1: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 1)
            .reduce((a, b) => a + b.valor, 0),
          mes2: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 2)
            .reduce((a, b) => a + b.valor, 0),
          mes3: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 3)
            .reduce((a, b) => a + b.valor, 0),
          mes4: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 4)
            .reduce((a, b) => a + b.valor, 0),
          mes5: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 5)
            .reduce((a, b) => a + b.valor, 0),
          mes6: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 6)
            .reduce((a, b) => a + b.valor, 0),
          mes7: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 7)
            .reduce((a, b) => a + b.valor, 0),
          mes8: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 8)
            .reduce((a, b) => a + b.valor, 0),
          mes9: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 9)
            .reduce((a, b) => a + b.valor, 0),
          mes10: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 10)
            .reduce((a, b) => a + b.valor, 0),
          mes11: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 11)
            .reduce((a, b) => a + b.valor, 0),
          mes12: lancamentos
            .filter((f) => grupo1ids.includes(f.nivel3ID) && f.mes == 12)
            .reduce((a, b) => a + b.valor, 0),
          total: lancamentos.filter((f) => grupo1ids.includes(f.nivel3ID)).reduce((a, b) => a + b.valor, 0),
          media: lancamentos.filter((f) => grupo1ids.includes(f.nivel3ID)).reduce((a, b) => a + b.valor, 0) / 12,
        });
        fluxo.push(
          ...state.nivel3
            .filter((d) => d.fsubtotal1 == c.grupo)
            .map((e) => ({
              ...e,
              id: 'c' + e.id,
              mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
              mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
              mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
              mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
              mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
              mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
              mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
              mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
              mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
              mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
              mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
              mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
              total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
              media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
              children: state.nivel4
                .filter((f) => f.nivel3ID === e.id)
                .map((f) => ({
                  ...f,
                  mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                  mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                  mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                  mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                  mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                  mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                  mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                  mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                  mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                  mes10: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 10).reduce((a, b) => a + b.valor, 0),
                  mes11: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 11).reduce((a, b) => a + b.valor, 0),
                  mes12: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 12).reduce((a, b) => a + b.valor, 0),
                  total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                  media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                })),
            }))
        );
        if (c.grupo == 2) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == 3)
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 3) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == 4)
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 4) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == 5)
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 6.1) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.fsubtotal1 == '11.1')
              .map((e) => ({
                ...e,
                id: 'a' + e.id,
                mes1:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0) * -1,
                mes2:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0) * -1,
                mes3:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0) * -1,
                mes4:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0) * -1,
                mes5:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0) * -1,
                mes6:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0) * -1,
                mes7:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0) * -1,
                mes8:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0) * -1,
                mes9:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0) * -1,
                mes10:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0) * -1,
                mes11:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0) * -1,
                mes12:
                  lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0) * -1,
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) * -1,
                media: (lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) * -1) / 12,
                descricao: '(Devolução) - ' + e.descricao,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    id: 'a' + f.id,
                    mes1:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes2:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes3:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes4:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes5:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes6:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes7:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes8:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes9:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes10:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 10).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes11:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 11).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    mes12:
                      lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 12).reduce((a, b) => a + b.valor, 0) *
                      -1,
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) * -1,
                    media: (lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) * -1) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 6) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal2 == '7')
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 8) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == '9')
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 9) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == '10')
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 10) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == '11')
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 11) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == '12')
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0),
                media: lancamentos.filter((f) => f.nivel3ID === e.id).reduce((a, b) => a + b.valor, 0) / 12,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0),
                    media: lancamentos.filter((g) => g.nivel4ID === f.id).reduce((a, b) => a + b.valor, 0) / 12,
                  })),
              }))
          );
        }
        if (c.grupo == 13) {
          fluxo.push(
            ...state.nivel3
              .filter((d) => d.ftotal == '13')
              .map((e) => ({
                ...e,
                id: 'c' + e.id,
                mes1: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 1).reduce((a, b) => a + b.valor, 0),
                mes2: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 2).reduce((a, b) => a + b.valor, 0),
                mes3: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 3).reduce((a, b) => a + b.valor, 0),
                mes4: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 4).reduce((a, b) => a + b.valor, 0),
                mes5: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 5).reduce((a, b) => a + b.valor, 0),
                mes6: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 6).reduce((a, b) => a + b.valor, 0),
                mes7: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 7).reduce((a, b) => a + b.valor, 0),
                mes8: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 8).reduce((a, b) => a + b.valor, 0),
                mes9: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 9).reduce((a, b) => a + b.valor, 0),
                mes10: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 10).reduce((a, b) => a + b.valor, 0),
                mes11: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 11).reduce((a, b) => a + b.valor, 0),
                mes12: lancamentos.filter((f) => f.nivel3ID === e.id && f.mes == 12).reduce((a, b) => a + b.valor, 0),
                total: 0,
                media: 0,
                children: state.nivel4
                  .filter((f) => f.nivel3ID === e.id)
                  .map((f) => ({
                    ...f,
                    mes1: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 1).reduce((a, b) => a + b.valor, 0),
                    mes2: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 2).reduce((a, b) => a + b.valor, 0),
                    mes3: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 3).reduce((a, b) => a + b.valor, 0),
                    mes4: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 4).reduce((a, b) => a + b.valor, 0),
                    mes5: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 5).reduce((a, b) => a + b.valor, 0),
                    mes6: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 6).reduce((a, b) => a + b.valor, 0),
                    mes7: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 7).reduce((a, b) => a + b.valor, 0),
                    mes8: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 8).reduce((a, b) => a + b.valor, 0),
                    mes9: lancamentos.filter((g) => g.nivel4ID === f.id && g.mes == 9).reduce((a, b) => a + b.valor, 0),
                    mes10: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 10)
                      .reduce((a, b) => a + b.valor, 0),
                    mes11: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 11)
                      .reduce((a, b) => a + b.valor, 0),
                    mes12: lancamentos
                      .filter((g) => g.nivel4ID === f.id && g.mes == 12)
                      .reduce((a, b) => a + b.valor, 0),
                    total: 0,
                    media: 0,
                  })),
              }))
          );
        }
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 1) {
          const f = fluxo.filter((d) => d.grupo === 1.1 || d.grupo === 1.2);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 2) {
          const f = fluxo.filter((d) => d.grupo === 2.1 || d.grupo === 2.2 || d.grupo === 1);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 3) {
          const f = fluxo.filter((d) => d.grupo === 2 || d.ftotal == 3);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 4) {
          const f = fluxo.filter((d) => d.grupo === 3 || d.ftotal == 4);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 5) {
          const f = fluxo.filter((d) => d.grupo === 4 || d.ftotal == 5);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });

      fluxo = fluxo.map((c) => {
        if (c.grupo === 6.1) {
          const f = fluxo.filter((d) => d.fsubtotal2 == 6.1);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0) * -1,
            mes2: f.reduce((a, b) => a + b.mes2, 0) * -1,
            mes3: f.reduce((a, b) => a + b.mes3, 0) * -1,
            mes4: f.reduce((a, b) => a + b.mes4, 0) * -1,
            mes5: f.reduce((a, b) => a + b.mes5, 0) * -1,
            mes6: f.reduce((a, b) => a + b.mes6, 0) * -1,
            mes7: f.reduce((a, b) => a + b.mes7, 0) * -1,
            mes8: f.reduce((a, b) => a + b.mes8, 0) * -1,
            mes9: f.reduce((a, b) => a + b.mes9, 0) * -1,
            mes10: f.reduce((a, b) => a + b.mes10, 0) * -1,
            mes11: f.reduce((a, b) => a + b.mes11, 0) * -1,
            mes12: f.reduce((a, b) => a + b.mes12, 0) * -1,
            total: f.reduce((a, b) => a + b.total, 0) * -1,
            media: (f.reduce((a, b) => a + b.media, 0) * -1) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 6) {
          const f = fluxo.filter((d) => d.grupo === 5 || d.grupo == 6.1);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 7) {
          const f = fluxo.filter((d) => d.grupo === 6 || d.grupo == 7.1 || d.ftotal2 == 7);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 8) {
          const f = fluxo.filter((d) => d.grupo === 7 || d.grupo == 8.1);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 9) {
          const f = fluxo.filter((d) => d.grupo === 8 || d.ftotal == 9);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 10) {
          const f = fluxo.filter((d) => d.grupo === 9 || d.ftotal == 10);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === '11.1c') {
          return {
            ...c,
            mes1:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes2:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes3:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes4:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes5:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes6:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes7:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes8:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes9:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes10:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes11:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            mes12:
              (fluxo.find((f) => f.codigo === '4.6.1')?.mes1 / fluxo.find((f) => f.grupo == 4).mes1) *
                (fluxo.find((f) => f.codigo === '4.5.1')?.mes1 + fluxo.find((f) => f.codigo === '4.5.2')?.mes1) || 0,
            total: 0,
            media: 0,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 11) {
          const f = fluxo.filter((d) => d.grupo === 10 || d.fsubtotal1 == 11.1 || d.ftotal == 11);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 12.1) {
          const f = fluxo.filter((d) => d.fsubtotal1 == 12.1);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 12) {
          const f = fluxo.filter((d) => d.grupo === 11 || d.grupo == 12.1);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: f.reduce((a, b) => a + b.total, 0),
            media: f.reduce((a, b) => a + b.media, 0) / 12,
          };
        } else return c;
      });
      fluxo = fluxo.map((c) => {
        if (c.grupo === 13) {
          const f = fluxo.filter((d) => d.ftotal == 13);
          return {
            ...c,
            mes1: f.reduce((a, b) => a + b.mes1, 0),
            mes2: f.reduce((a, b) => a + b.mes2, 0),
            mes3: f.reduce((a, b) => a + b.mes3, 0),
            mes4: f.reduce((a, b) => a + b.mes4, 0),
            mes5: f.reduce((a, b) => a + b.mes5, 0),
            mes6: f.reduce((a, b) => a + b.mes6, 0),
            mes7: f.reduce((a, b) => a + b.mes7, 0),
            mes8: f.reduce((a, b) => a + b.mes8, 0),
            mes9: f.reduce((a, b) => a + b.mes9, 0),
            mes10: f.reduce((a, b) => a + b.mes10, 0),
            mes11: f.reduce((a, b) => a + b.mes11, 0),
            mes12: f.reduce((a, b) => a + b.mes12, 0),
            total: 0,
            media: 0,
          };
        } else return c;
      });
      state = {
        ...state,
        fluxoteste: fluxo,
        fluxosloading: false,
      };
      break;

    case 'SET_UNIDADE_ONLY':
      state = {
        ...state,
        unidades: [action.payload],
      };
      break;
    case 'SELECT_UNIDADES_FLUXO':
      state = {
        ...state,
        unidades: action.payload,
      };
      break;
    case 'SET_COMPETENCIA_FLUXO':
      state = {
        ...state,
        competencia: action.payload,
      };
      break;
    case 'SET_CONTAS_GRUPO_PENDING':
      state = {
        ...state,
        getcontasloading: true,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default fluxocaixaReducer;
