import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { UsuariosReducer } from '@reducers/reducers';

const initialState: UsuariosReducer = {
  waiting: false,
  loadingusuarios: false,
  searchinguser: false,
  savingusuario: false,
  addusermsg: '',
  usuarios: [],
  usuario: null,
  selectedUsuarios: [],
  formvisible: false,
  finduser: {
    existe: null,
    nome: '',
  },
  perfis: [],
  loadingperfis: false,
  editing: false,
  feedback: '',
};

const usuariosReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'ADD_USER_TO_ROLE_PENDING':
      state = {
        ...state,
        savingusuario: true,
      };
      break;
    case 'ADD_USER_TO_ROLE_REJECTED':
      notification.error({ message: 'Erro ao modificar perfil!' });
      state = {
        ...state,
        savingusuario: false,
      };
      break;
    case 'ADD_USER_TO_ROLE_FULFILLED':
      notification.success({ message: 'Perfil atualizado com sucesso!' });
      state = {
        ...state,
        savingusuario: false,
        usuarios: state.usuarios.map((c) => {
          if (c.id === action.payload.userid) {
            c.perfilID = action.payload.role;
            return c;
          } else return c;
        }),
      };
      break;
    case 'REMOVE_USERS_USUARIOS_PENDING':
      state = {
        ...state,
        loadingusuarios: true,
      };
      break;
    case 'REMOVE_USERS_USUARIOS_REJECTED':
      notification.error({ message: 'Erro ao remover usuário!' });
      state = {
        ...state,
        loadingusuarios: true,
      };
      break;
    case 'REMOVE_USERS_USUARIOS_FULFILLED':
      notification.success({ message: 'Usuário removido com sucesso!' });
      state = {
        ...state,
        loadingusuarios: false,
        usuarios: action.payload.data,
        selectedUsuarios: [],
      };
      break;
    case 'CREATE_USER_CRIADO_USUARIOS':
      state = {
        ...state,
        savingusuario: false,
        addusermsg: 'Usuário adicionado com sucesso',
      };
      break;
    case 'CREATE_USER_JACADASTRADO_USUARIOS':
      state = {
        ...state,
        savingusuario: false,
        addusermsg: 'Usuário já existe nesta conta',
      };
      break;
    case 'CREATE_USER_USUARIOS_PENDING':
      state = {
        ...state,
        savingusuario: true,
      };
      break;
    case 'CREATE_USER_REJECTED':
      notification.error({ message: 'Erro ao criar usuário!' });
      state = {
        ...state,
        savingusuario: false,
      };
      break;
    case 'RESET':
      state = initialState;
      break;
    case 'GET_USUARIOS_USUARIOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_USUARIOS_USUARIOS_FULFILLED':
      state = {
        ...state,
        usuarios: action.payload.data,
        waiting: false,
      };
      break;
    case 'SELECTED_USUARIOS_USUARIOS':
      state = {
        ...state,
        selectedUsuarios: action.payload,
      };
      break;
    case 'HIDE_USUARIO_FORM_USUARIOS':
      state = {
        ...state,
        formvisible: action.payload.open,
        usuario: action.payload.id ? action.payload.id : null,
        finduser: {
          ...state.finduser,
          existe: null,
          nome: '',
        },
        addusermsg: '',
        editing: action.payload.id ? true : false,
      };
      break;
    case 'DELETE_USUARIO_PENDING':
      state = {
        ...state,
        loadingusuarios: true,
        feedback: '',
      };
      break;
    case 'DELETE_DELETE_REJECTED':
      state = {
        ...state,
        loadingusuarios: false,
        feedback: action.payload.response.data,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_USUARIO_FULFILLED':
      state = {
        ...state,
        loadingusuarios: false,
        feedback: 'Usuário inativado com sucesso!',
        usuarios: state.usuarios.map((c) => {
          if (c.id === action.payload.data.id) {
            return action.payload.data;
          } else return c;
        }),
      };
      notification.success({ message: state.feedback });
      break;
    case 'FIND_USER_USUARIOS_PENDING':
      state = {
        ...state,
        searchinguser: true,
      };
      break;
    case 'FIND_USER_USUARIOS_YES_FULFILLED':
      state = {
        ...state,
        searchinguser: false,
        finduser: {
          ...state.finduser,
          existe: true,
          nome: action.payload,
        },
      };
      break;
    case 'FIND_USER_USUARIOS_NO_FULFILLED':
      state = {
        ...state,
        searchinguser: false,
        finduser: {
          existe: false,
          nome: '',
        },
      };
      break;
    case 'GET_PERFIS_USUARIOS_FULFILLED':
      state = {
        ...state,
        perfis: action.payload.data,
      };
      break;
    case 'GET_ROLES_PENDING':
      state = {
        ...state,
        loadingperfis: true,
      };
      break;
    case 'GET_ROLES_REJECTED':
      state = {
        ...state,
        loadingperfis: false,
      };
      break;
    case 'GET_ROLES_FULFILLED':
      state = {
        ...state,
        loadingperfis: false,
        perfis: action.payload.data,
      };
      break;
    default:
      return state;
  }

  return state;
};

export default usuariosReducer;
