import { ChangeEventHandler, Key, useEffect, useState } from 'react';
import { Typography, Skeleton, Spin, Input, Tree } from 'antd';
import { GetContasUnidade } from '@actions/empresaActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { Text } = Typography;

interface DataList {
  key: Key;
  title: string;
}
var dataList: DataList[] = [];
const generateList = (data: any) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key: Key, tree: any) => {
  let parentKey: any;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};
const { Search } = Input;
const { TreeNode } = Tree;

interface ContasFormProps {
  unidadeid: number;
}
function ContasForm(props: ContasFormProps) {
  const dispatch = useAppDispatch();
  const empresa = useAppSelector((state) => state.empresa);
  const [filterData, setFilterData] = useState({
    expandedKeys: [],
    searchValue: '',
    autoExpandParent: true,
  });

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    if (dataList.length === 0)
      generateList(
        empresa.contasunidade.find((c) => c.unidade === props.unidadeid)
          ? empresa.contasunidade.find((c) => c.unidade === props.unidadeid).contas
          : []
      );

    const expandedKeys = dataList
      .map((item) => {
        if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return getParentKey(
            item.key,
            empresa.contasunidade.find((c) => c.unidade === props.unidadeid)
              ? empresa.contasunidade.find((c) => c.unidade === props.unidadeid).contas
              : []
          );
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setFilterData({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };
  const onExpand = (expandedKeys: Key[]) => {
    setFilterData({
      ...filterData,
      expandedKeys,
      autoExpandParent: false,
    });
  };
  useEffect(() => {
    dispatch(GetContasUnidade(props.unidadeid));
  }, []);
  const loop = (data: any) =>
    data.map((item: any) => {
      const index = item.title.indexOf(filterData.searchValue);
      const beforeStr = item.title.substr(0, index);
      const originalValue = item.title.substr(index, filterData.searchValue.length);
      const afterStr = item.title.substr(index + filterData.searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{originalValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );

      if (item.children) {
        return (
          <TreeNode key={item.key} title={title}>
            {loop(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={title} />;
    });
  return (
    <Skeleton loading={empresa.openloading} active>
      <Spin spinning={empresa.savingloading}>
        <Search style={{ marginBottom: 8, width: 300 }} placeholder="Buscar" onChange={onChange} />
        <p>
          <Text>Plano de Contas</Text>
        </p>
        <Tree onExpand={onExpand} expandedKeys={filterData.expandedKeys} autoExpandParent={filterData.autoExpandParent}>
          {loop(
            empresa.contasunidade.find((c) => c.unidade === props.unidadeid)
              ? empresa.contasunidade.find((c) => c.unidade === props.unidadeid).contas
              : []
          )}
        </Tree>
      </Spin>
    </Skeleton>
  );
}

export default ContasForm;
