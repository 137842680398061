import { AppDispatch } from '@config/reduxStore';
import axios from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetRoles = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ROLES',
      payload: axios.get(apiEndpoints.ROLE.GET_ROLES_FULL),
    });
  };
};
export const GetPermissions = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PERMISSIONS_ROLES',
      payload: axios.get(apiEndpoints.ROLE.GET_PERMISSIONS),
    });
  };
};
export const AddPermissionToRole = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_PERMISSION_TO_ROLE_ROLES',
      payload: axios.post(apiEndpoints.ROLE.ADD_PERMISSION_TO_ROLE, values),
    });
  };
};
export const FormPerfil = function (id: any, open: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_PERFIL',
      payload: { id, open },
    });
  };
};
export const SalvarPerfil = function (values: any) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_PERFIL',
        payload: axios.post(apiEndpoints.ROLE.SALVAR_PERFIL, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_PERFIL',
        payload: axios.post(apiEndpoints.ROLE.ADD_ROLE, { ...values }),
      });
    }
  };
};
export const DeletePerfil = function (id: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_PERFIL',
      payload: axios.get(apiEndpoints.ROLE.DELETE_PERFIL, { params: { id } }),
    });
  };
};
