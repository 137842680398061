import { DatePicker, Drawer, Form, Select } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { GetContas, SetDataImplantacao, SetUnidade, SetUnidadeImplantacao } from '@actions/implantacaoActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface MenuImplantacao {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
function MenuImplantacao({ visible, setVisible }: MenuImplantacao) {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.general);
  const implantacao = useAppSelector((state) => state.implantacao);
  const [form] = Form.useForm();

  return (
    <Drawer open={visible} onClose={() => setVisible(false)} title="Seleção">
      <Form style={{ padding: 10 }} form={form} layout="vertical">
        {general.unidades.length > 1 && (
          <Form.Item label="Unidade de Negócio">
            <Select
              showSearch
              size="small"
              maxTagCount={3}
              optionFilterProp="label"
              style={{ width: '100%' }}
              onChange={(value) => {
                //props.array.removeAll('contas');
                dispatch(SetUnidade(value));
                dispatch(GetContas(value));
              }}
              placeholder="Unidade de Negócio"
              value={implantacao.unidade}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {general.unidades.map((unidade) => (
                <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
                  {unidade.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {general.unidades.length > 1 && (
          <Form.Item label="Conta de Implantação">
            <Select
              showSearch
              size="small"
              maxTagCount={3}
              optionFilterProp="label"
              style={{ width: '100%' }}
              onChange={(value) => dispatch(SetUnidadeImplantacao(value))}
              placeholder="Unidade de Negócio"
              value={implantacao.unidadeImplantacao}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {general.unidades.map((unidade) => (
                <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
                  {unidade.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Data">
          <DatePicker
            style={{ minWidth: 180 }}
            size="small"
            onChange={(value) => dispatch(SetDataImplantacao(value))}
            format="DD/MM/YYYY"
            value={implantacao.data || null}
            placeholder="Data"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default MenuImplantacao;
