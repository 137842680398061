import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { formatNumber } from '@utils/masks';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { GetDRE } from '@actions/dreActions';
import { GetUnidades } from '@actions/generalActions';
import logo from '../../../assets/img/logo.png';
import { Spin, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Nivel3 } from '@models/Nivel3';

function PrintDRE() {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.general);
  const dre = useAppSelector((state) => state.dre);
  const [drefinal, setDreFinal] = useState([]);
  const [searchParams] = useSearchParams();
  const [data] = useState({
    unidades: JSON.parse(searchParams.get('Unidades')) as number[],
    competencia: moment(searchParams.get('Competencia')),
  });
  useEffect(() => {
    let data: Nivel3[] = [];
    if (dre.dre) {
      dre.dre.forEach((c) => {
        data.push(c);
        c.children.forEach((d) => {
          data.push(d);
          d.children.forEach((e) => {
            data.push(e);
          });
        });
      });
      setDreFinal(data);
    }
  }, [dre.dre]);
  useEffect(() => {
    dispatch(GetUnidades(false));
    dispatch(GetDRE(data.unidades, data.competencia));
  }, []);
  useEffect(() => {
    if (general.unidades && drefinal) {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Spin spinning={dre.loadingDRE}>
          <div className="dreprinttable">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>
                    <img src={logo} height={30} className="logo" alt="logo" />
                  </th>
                  <th colSpan={7}>
                    {general.unidades.length > 0 && (
                      <Typography.Title level={5}>{`Fluxo de Caixa ${moment(data.competencia).format(
                        'MM/YYYY'
                      )} - Unidades: ${data.unidades.map(
                        (id) => ' ' + general.unidades.find((c) => c.id === id).nome
                      )}`}</Typography.Title>
                    )}
                  </th>
                </tr>
                <tr style={{ fontWeight: 'strong' }}>
                  <th>{moment(data.competencia).add(-3, 'M').format('MM/YYYY')}</th>
                  <th>{moment(data.competencia).add(-2, 'M').format('MM/YYYY')}</th>
                  <th>{moment(data.competencia).add(-1, 'M').format('MM/YYYY')}</th>
                  <th>Descrição</th>
                  <th>{moment(data.competencia).format('MM/YYYY')}</th>
                  <th className="valor">{moment(data.competencia).add(-1, 'year').format('MM/YYYY')}</th>
                  <th className="valor">{`Acumulado ${moment(data.competencia).format('YYYY')}`}</th>
                  <th>{`Acumulado ${moment(data.competencia).add(-1, 'year').format('YYYY')}`}</th>
                </tr>
              </thead>
              <tbody>
                {drefinal.map((row, index) => {
                  return (
                    <tr key={index} className={row.class}>
                      <td className="valor">{formatNumber(row.mes1)}</td>
                      <td className="valor">{formatNumber(row.mes2)}</td>
                      <td className="valor">{formatNumber(row.mes3)}</td>
                      <td>{row.descricao}</td>
                      <td className="valor">{formatNumber(row.mes4)}</td>
                      <td className="valor">{formatNumber(row.mesPassado)}</td>
                      <td className="valor">{formatNumber(row.acumuladoAtual)}</td>
                      <td className="valor">{formatNumber(row.acumuladoPassado)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr style={{ fontWeight: 'bold' }}>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.mes1, 0))}</td>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.mes2, 0))}</td>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.mes3, 0))}</td>
                  <td>TOTAL</td>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.mes4, 0))}</td>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.mesPassado, 0))}</td>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.acumuladoAtual, 0))}</td>
                  <td className="valor">{formatNumber(dre.dre.reduce((a, b: any) => a + b.acumuladoPassado, 0))}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Spin>
      );
    }
  }, [general.unidades, drefinal]);
  return <div></div>;
}
export default PrintDRE;
