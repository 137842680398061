import {
  ShowPessoaForm,
  ShowPessoas,
  SetPessoasFiltros,
  LoadPessoa,
  SearchCNPJ,
  HidePessoaForm,
  SalvarPessoa,
  DeletePessoa,
} from '@actions/pessoasActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Permission } from '@hooks/usePermissions';
import { Button, Space } from 'antd';
import { useEffect } from 'react';
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';
import { Pessoa } from '@models/Pessoa';
import { buscaCNPJ } from '@services/BuscaCNPJ';
import { buscaCEP } from '@services/BuscaCEP';
import { SearchCNPJ_CEP } from '@actions/empresaActions';
import { PessoasControllerInterface, TableFilters } from '@controllers/controllers';
import { BuscaCEPReturn, BuscaCNPJReturn } from '@services/interfaces';
import { Link } from 'react-router-dom';

export const PessoasController: PessoasControllerInterface = () => {
  const dispatch = useAppDispatch();
  const { pessoaspagination, pessoasloading, pessoasCadastro, searchloading, savingloading, pessoa, formvisible } =
    useAppSelector((state) => state.pessoas);
  useEffect(() => {
    document.title = 'Nambi - Pessoas';
    dispatch(ShowPessoas(pessoaspagination));
    dispatch(
      SetPageHeader({
        title: 'Pessoas',
        subTitle: 'Cadastros de forcenedores, clientes, colaboradores e sócios',
        extras: [
          <Permission key="1" permission="pessoa.add">
            <Space>
              {false && (
                <Link to="/ImportarPessoas">
                  <Button type="primary" size="small" ghost icon={<ImportOutlined />}>
                    Importar
                  </Button>
                </Link>
              )}
              <Button type="primary" size="small" onClick={() => dispatch(ShowPessoaForm())}>
                <PlusOutlined />
                Nova Pessoa
              </Button>
            </Space>
          </Permission>,
        ],
      })
    );
  }, []);
  const GetPessoa: (id: any) => void = (id) => dispatch(LoadPessoa(id));
  const Delete: (id: any) => void = (id) => dispatch(DeletePessoa(id));
  const GetPessoas: (data: TableFilters<Pessoa>) => void = (data) => dispatch(ShowPessoas(data));
  const SetFiltros: (data: TableFilters<Pessoa>) => void = (data) => dispatch(SetPessoasFiltros(data));
  const HideForm: () => void = () => dispatch(HidePessoaForm());
  const Salvar: (values: Pessoa) => void = (values) => dispatch(SalvarPessoa(values));
  const BuscaCNPJ: (data: string) => Promise<BuscaCNPJReturn> | null = async (data) => {
    dispatch(SearchCNPJ(true));
    const result = await buscaCNPJ(data);
    dispatch(SearchCNPJ(false));
    return result;
  };
  const BuscaCEP: (data: string) => Promise<BuscaCEPReturn> | null = async (data) => {
    dispatch(SearchCNPJ_CEP());
    const result = await buscaCEP(data);
    dispatch(SearchCNPJ_CEP());
    return result;
  };
  return {
    data: {
      pessoaspagination,
      pessoasloading,
      pessoasCadastro,
      searchloading,
      savingloading,
      pessoa,
      formvisible,
    },
    actions: {
      GetPessoa,
      Delete,
      GetPessoas,
      SetFiltros,
      BuscaCNPJ,
      BuscaCEP,
      HideForm,
      Salvar,
    },
  };
};
