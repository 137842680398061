import { useEffect } from 'react';
import { Spin, Typography } from 'antd';
import { ConfirmEmail } from '@actions/userActions';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function ConfirmarEmail() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { pending, success, errors } = useAppSelector((state) => state.user.confirmEmail);
  useEffect(() => {
    dispatch(ConfirmEmail(searchParams.get('userId'), searchParams.get('code')));
  }, [dispatch, searchParams]);

  if (pending) {
    return <Spin spinning>Confirmando email, por favor aguarde</Spin>;
  }
  if (errors.length > 0) {
    return (
      <Typography.Title level={4}>Não foi possível confirmar seu email. Por favor, tente novamente</Typography.Title>
    );
  }
  if (success) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={4}>
          Email confirmado com sucesso. Por favor realize o login para finalizar o cadastro.
        </Typography.Title>
        <a href="/">Finalizar Cadastro</a>
      </div>
    );
  }
  return <span>Confirmação de email</span>;
}

export default ConfirmarEmail;
