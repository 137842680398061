import { externalAPIs } from '@config/apiEndpoints';
import jsonp from 'jsonp';
import { BuscaCNPJ } from './interfaces';

export const buscaCNPJ: BuscaCNPJ = async (value) => {
  if (value !== '') {
    var cnpj = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '');
    jsonp(externalAPIs.RECEITAWS + cnpj, (_: any, data: any) => {
      if (data.status === 'OK') {
        return Promise.resolve(data);
      } else return Promise.reject(data);
    });
  }
  return Promise.reject();
};
