import { useEffect } from 'react';
import { Button, Typography, Input, Form } from 'antd';
import { ForgotPassword, SetPageHeader } from '@actions/userActions';
import ChangePasswordForm from './ChangePasswordForm';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { Text } = Typography;

function ResetPassword() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { forgotPassword } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Redefinição de Senha',
        backButton: true,
        subTitle: 'Redefina sua senha',
      })
    );
  }, [dispatch]);
  return (
    <div>
      {!location.search && (
        <div style={{ width: 300, height: '80vh', margin: 'auto' }}>
          <Form onFinish={(values) => dispatch(ForgotPassword(values.email))}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { type: 'email', message: 'Email inválido' },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button loading={forgotPassword.sending} block htmlType="submit" type="primary">
                Enviar
              </Button>
            </Form.Item>
          </Form>

          <Text type="danger">{forgotPassword.msg}</Text>
        </div>
      )}
      {location.search && (
        <div style={{ width: 400, height: '80vh', margin: 'auto' }}>
          <ChangePasswordForm query={location.search} />
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
