import { useState } from 'react';
import { Table, Modal, Button } from 'antd';
import moment from 'moment';
import { ShowHideTitulos, SetSelectedTitulos } from '@actions/lancarActions';
import { formatNumber } from '@utils/masks';
import { getColumnSearchProps, sortAlpha, orderDate, naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Lancamento } from '@models/Lancamento';

function Titulos() {
  const dispatch = useAppDispatch();
  const { viewtitulos, loadingtitulos, titulos } = useAppSelector((state) => state.lancar);
  const { unidades } = useAppSelector((state) => state.general);
  const [filters, setFilters] = useState<any>(null);
  const { selectedtitulos } = useAppSelector((state) => state.lancar);
  const columns: ColumnsType<Lancamento> = [
    {
      title: 'Bloco',
      dataIndex: 'bloco',
      sorter: (a, b) => naturalSorter(a.bloco, b.bloco),
      ...getColumnSearchProps('bloco', 'string', 'Bloco', filters),
    },
    {
      title: 'Unidades',
      dataIndex: 'unidade',
      sorter: (a, b) => naturalSorter(a.unidade, b.unidade),
      filterMode: 'menu',
      filterSearch: true,
      filteredValue: filters ? filters.filters.unidade : null,
      filters: unidades.map((c) => ({ text: c.nome, value: c.nome })),
      onFilter: (value, row) => row.unidade === value,
    },
    {
      title: 'Vencimento',
      dataIndex: 'dataVencimento',
      width: 120,
      render: (text) => moment(text).format('DD/MM/YYYY'),
      sorter: (a, b) => orderDate(a.dataVencimento, b.dataVencimento),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricaoBloco',
      width: '25%',
      sorter: (a, b) => sortAlpha(a.descricaoBloco, b.descricaoBloco),
      ...getColumnSearchProps('descricaoBloco', 'string', 'Descriçao', filters),
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      sorter: (a, b) => sortAlpha(a.documento, b.documento),
      ...getColumnSearchProps('documento', 'string', 'Documento', filters),
    },
    {
      title: 'Pessoa',
      dataIndex: 'pessoa',
      sorter: (a, b) => sortAlpha(a.pessoa as string, b.pessoa as string),
      ...getColumnSearchProps('pessoa', 'string', 'Pessoa', filters),
    },
    {
      title: 'Parcela',
      dataIndex: 'parcela',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      sorter: (a, b) => a.valor - b.valor,
      render: (text) => formatNumber(text),
      align: 'right',
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: any[]) => {
      dispatch(SetSelectedTitulos(selectedRowKeys));
    },
    selectedRowKeys: selectedtitulos,
  };
  return (
    <Modal
      title="Títulos em Aberto"
      width={'80%'}
      open={viewtitulos}
      onCancel={() => dispatch(ShowHideTitulos(false))}
      footer={[
        <Button key="1" type="primary" onClick={() => dispatch(ShowHideTitulos(false))}>
          Ok
        </Button>,
      ]}
    >
      <div>
        <Table
          rowSelection={rowSelection}
          style={{ minHeight: 300 }}
          bordered
          size="small"
          scroll={{ y: '60vh' }}
          rowKey="id"
          columns={columns}
          loading={loadingtitulos}
          onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
          pagination={{
            position: ['bottomRight'],
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: [10, 25, 50],
            showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          }}
          dataSource={titulos}
        />
      </div>
    </Modal>
  );
}

export default Titulos;
