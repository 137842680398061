import { SearchCNPJ_CEP } from '@actions/empresaActions';
import { SearchCNPJ } from '@actions/pessoasActions';
import { RegisterContexto, SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { PlanoControllerInterface } from '@controllers/controllers';
import { buscaCEP } from '@services/BuscaCEP';
import { buscaCNPJ } from '@services/BuscaCNPJ';
import { BuscaCEPReturn, BuscaCNPJReturn } from '@services/interfaces';
import { useEffect } from 'react';

export const PlanoController: PlanoControllerInterface = () => {
  const dispatch = useAppDispatch();
  const { user, waiting } = useAppSelector((state) => state.user);
  const empresa = useAppSelector((state) => state.empresa);
  useEffect(() => {
    dispatch(SetPageHeader({ title: 'Criar Novo Plano', subTitle: 'Contrate um novo plano dentro de sua conta' }));
  }, [dispatch]);
  const BuscaCNPJ: (data: string) => Promise<BuscaCNPJReturn> | null = async (data) => {
    dispatch(SearchCNPJ(true));
    const result = await buscaCNPJ(data);
    dispatch(SearchCNPJ(false));
    return result;
  };
  const BuscaCEP: (data: string) => Promise<BuscaCEPReturn> | null = async (data) => {
    dispatch(SearchCNPJ_CEP());
    const result = await buscaCEP(data);
    dispatch(SearchCNPJ_CEP());
    return result;
  };
  const Register: (values: any) => void = (values) => dispatch(RegisterContexto(values));

  return { data: { user, waiting, empresa }, actions: { BuscaCEP, BuscaCNPJ, Register } };
};
