import { Link } from 'react-router-dom';
import './text.scss';
function Termos() {
  return (
    <div className="textBox">
      <h3>TERMOS DE USO DO SISTEMA FINANCEIRO WEB NAMBI - GESTÃO FINANCEIRA INTELIGENTE</h3>
      <article>
        <ol>
          <li>
            INTRODUÇÃO
            <ol>
              <li>
                O presente documento estabelece os termos de uso do sistema financeiro web denominado Nambi - Gestão
                Financeira Inteligente, operado pela empresa NAMBI EFICIENCIA EM GESTAO LTDA, inscrita no CNPJ
                29.823.743/0001-43, doravante denominada "Nambi".
              </li>
              <li>
                Ao utilizar o sistema Nambi, o usuário concorda com os termos e condições descritos neste documento, bem
                como com a Política de Privacidade do sistema, disponível no endereço{' '}
                <Link to="/PrivacyPolicy">https://www.nambi.com.br/PrivacyPolicy</Link>.
              </li>
            </ol>
          </li>
          <li>
            FUNCIONAMENTO DO SISTEMA
            <ol>
              <li>
                O sistema Nambi permite que os usuários realizem lançamentos financeiros, gerenciem as finanças da sua
                empresa e gerem relatórios como balanço, DRE e fluxo de caixa.
              </li>
              <li>
                O usuário reconhece que a precisão e integridade dos dados financeiros registrados no sistema dependem
                da sua correta inserção e atualização, bem como da manutenção da segurança das suas credenciais de
                acesso.
              </li>
              <li>
                A Nambi não se responsabiliza por perdas ou danos decorrentes de erros na inserção ou atualização de
                dados financeiros pelo usuário, bem como pelo uso não autorizado ou indevido das credenciais de acesso.
              </li>
              <li>
                A Nambi se reserva o direito de suspender ou interromper temporariamente o acesso do usuário ao sistema,
                por razões de manutenção ou atualização, sem que isso implique em qualquer responsabilidade da Nambi
                perante o usuário.
              </li>
            </ol>
          </li>
          <li>
            CONDIÇÕES DE USO
            <ol>
              <li>
                O usuário se compromete a utilizar o sistema Nambi de forma diligente e em conformidade com a lei,
                abstendo-se de realizar quaisquer atividades que possam prejudicar a Nambi, outros usuários ou
                terceiros.
              </li>
              <li>
                O usuário reconhece que é o único responsável pelo conteúdo dos dados financeiros inseridos no sistema e
                pela sua conformidade com as leis e regulamentos aplicáveis.
              </li>
              <li>
                O usuário se compromete a não utilizar o sistema Nambi para fins ilícitos, como a lavagem de dinheiro, o
                financiamento ao terrorismo, a evasão de divisas ou qualquer outra atividade criminosa.
              </li>
              <li>
                O usuário reconhece que a Nambi pode disponibilizar links para outros sites ou serviços de terceiros,
                mas não se responsabiliza pelo conteúdo, segurança ou privacidade desses sites ou serviços.
              </li>
              <li>
                A Nambi se reserva o direito de modificar estes termos de uso a qualquer momento, mediante aviso prévio
                ao usuário, por meio do sistema ou por outros meios adequados.
              </li>
            </ol>
          </li>
          <li>
            PROPRIEDADE INTELECTUAL
            <ol>
              <li>
                O sistema Nambi e todo o seu conteúdo, incluindo mas não se limitando a software, textos, gráficos,
                imagens, fotografias, sons, música, vídeos, recursos interativos e códigos, são de propriedade exclusiva
                da Nambi e estão protegidos pelas leis de propriedade intelectual aplicáveis.
              </li>
              <li>
                O usuário reconhece que não adquire qualquer direito de propriedade intelectual sobre o sistema Nambi ou
                qualquer conteúdo associado a ele, exceto pelo direito limitado de utilização concedido por estes termos
                de uso.
              </li>
              <li>
                O usuário se compromete a não reproduzir, distribuir, modificar, adaptar, traduzir, criar obras
                derivadas, exibir publicamente, vender, alugar, sublicenciar ou transferir quaisquer direitos sobre o
                sistema Nambi ou seu conteúdo, exceto conforme expressamente permitido por estes termos de uso.
              </li>
              <li>
                A Nambi respeita os direitos de propriedade intelectual de terceiros e se compromete a remover qualquer
                conteúdo que viole esses direitos, mediante notificação adequada.
              </li>
            </ol>
          </li>
          <li>
            LIMITAÇÃO DE RESPONSABILIDADE
            <ol>
              <li>O usuário reconhece que o uso do sistema Nambi é por sua conta e risco exclusivos.</li>
              <li>
                A Nambi não se responsabiliza por quaisquer perdas ou danos diretos, indiretos, incidentais, especiais,
                consequenciais ou punitivos decorrentes do uso ou da impossibilidade de uso do sistema Nambi, incluindo,
                mas não se limitando a perda de lucro, perda de dados, falhas de segurança, indisponibilidade do sistema
                ou atrasos na transmissão de informações.
              </li>
              <li>
                A Nambi não garante que o sistema Nambi seja livre de erros, omissões, vírus ou outros elementos
                prejudiciais, e não se responsabiliza por qualquer dano causado por esses elementos.
              </li>
              <li>
                A Nambi não se responsabiliza por quaisquer atos ou omissões de terceiros, incluindo outros usuários,
                que possam afetar o uso do sistema Nambi pelo usuário.
              </li>
            </ol>
          </li>
          <li>
            RESCISÃO
            <ol>
              <li>
                A Nambi se reserva o direito de rescindir ou suspender o acesso do usuário ao sistema Nambi a qualquer
                momento, sem necessidade de aviso prévio, em caso de violação destes termos de uso.
              </li>
              <li>
                A rescisão ou suspensão do acesso do usuário ao sistema Nambi não afeta as obrigações e
                responsabilidades do usuário perante a Nambi decorrentes do uso anterior do sistema.
              </li>
            </ol>
          </li>
          <li>
            DISPOSIÇÕES GERAIS
            <ol>
              <li>
                Estes termos de uso representam o acordo integral entre o usuário e a Nambi em relação ao uso do sistema
                Nambi, substituindo todos os acordos anteriores ou contemporâneos, orais ou escritos, em relação a este
                assunto.
              </li>
              <li>
                Estes termos de uso são regidos pelas leis brasileiras e quaisquer litígios decorrentes ou relacionados
                a estes termos de uso serão submetidos à jurisdição exclusiva dos tribunais brasileiros.
              </li>
              <li>
                Se qualquer disposição destes termos de uso for considerada inválida ou inexequível, as demais
                disposições permanecerão em pleno vigor e efeito.
              </li>
              <li>
                A falta de exercício ou o atraso no exercício de qualquer direito ou recurso previsto nestes termos de
                uso não constituirá renúncia a esses direitos ou recursos.
              </li>
              <li>O usuário reconhece que leu estes termos de uso e concorda em cumpri-los integralmente.</li>
            </ol>
          </li>
        </ol>
      </article>
    </div>
  );
}
export default Termos;
/*
<li>
            INTRODUÇÃO
            <ol>
              <li></li>
              <li></li>
            </ol>
          </li> 
 */
