import { Modal, TreeSelect } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { SetSelectConta, SetValues, GetContasAnaliticas, SetColumns } from '@actions/importarActions';
import { ContaAnalitica, ContasAnaliticasImportar } from '@models/ContaAnalitica';
import { copy } from '@utils/queryString';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function SelectConta() {
  const dispatch = useAppDispatch();
  const { contas, loadingContas, values, selectConta, columns } = useAppSelector((state) => state.importar);
  const [selectedConta, setSelectedConta] = useState<{ id: number; name: any; conta: ContaAnalitica }>({
    id: null,
    name: '',
    conta: null,
  });
  const [contasFilter, setContasFilter] = useState([]);
  const ref = useRef<any>();
  useEffect(() => {
    setSelectedConta({ id: null, name: '', conta: null });
  }, [selectConta.visible]);
  useEffect(() => {
    if (selectConta.visible) {
      ref.current.focus();
      dispatch(GetContasAnaliticas(selectConta.unidadeID));
    }
  }, [selectConta.visible]);

  useEffect(() => {
    const copyData: ContasAnaliticasImportar[] = copy<ContasAnaliticasImportar[]>(contas);
    const tempData = copyData
      .find((c) => c.unidadeID === selectConta.unidadeID)
      ?.contas.map((n1) => {
        n1.children.map((n2) => {
          n2.children.map((n3) => {
            if (selectConta.tipo === 0 || selectConta.tipo === 1) {
              n3['children'] = [...n3.children].filter((c) => c.tipo === selectConta.tipo);
            } else if (selectConta.tipo === 2 || selectConta.tipo === 3) {
            } else {
              n3['children'] = n3.children.filter(
                (c) => c.saiDinheiro === selectConta.fluxo || c.entraDinheiro === selectConta.fluxo
              );
            }
            return n3;
          });
          return n2;
        });
        return n1;
      });
    setContasFilter(tempData);
  }, [contas, selectConta]);

  return (
    <Modal
      open={selectConta.visible}
      onCancel={() =>
        dispatch(
          SetSelectConta({
            visible: false,
            index: null,
            tipo: null,
            fluxo: 0,
            unidadeID: selectConta.unidadeID,
          })
        )
      }
      title="Selecionar Conta"
      onOk={() => {
        dispatch(
          SetValues(
            values.map((c) => {
              let temp = Object.assign({}, c);
              if (temp.key === selectConta.key) {
                if (selectConta.position === 0) {
                  temp.oqueID = selectedConta.id;
                  temp.oque = selectedConta.name;
                } else {
                  temp.ondeID = selectedConta.id;
                  temp.onde = selectedConta.name;
                }
                const copyData = copy<any[]>(columns);
                dispatch(
                  SetColumns(
                    copyData.map((c: any) => {
                      if (c.dataIndex === selectConta.dataIndex) {
                        c.width = Math.max(c.width, selectedConta.name.length * 8);
                      }
                      return c;
                    })
                  )
                );
              }

              return temp;
            })
          )
        );
        setSelectedConta({ id: null, name: '', conta: null });
        dispatch(
          SetSelectConta({
            visible: false,
            index: null,
            tipo: null,
            fluxo: 0,
            unidadeID: selectConta.unidadeID,
            conta: selectedConta,
          })
        );
      }}
    >
      <TreeSelect
        treeData={contasFilter}
        showSearch
        ref={ref}
        loading={loadingContas}
        placeholder="Buscar..."
        treeNodeFilterProp="title"
        style={{ width: '100%' }}
        value={selectedConta.id}
        onChange={(v, labels, extra) => {
          setSelectedConta({ name: labels[0], id: v, conta: extra.triggerNode.props });
        }}
      />
    </Modal>
  );
}

export default SelectConta;
