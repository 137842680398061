import { useEffect, useState } from 'react';
import jsonp from 'jsonp';
import { Button, Input, Form, Radio, Select, Spin, Typography, Card } from 'antd';
import { RegisterContexto, ResendConfirmEmail, SetPageHeader } from '@actions/userActions';
import { SearchCNPJ_CEP } from '@actions/empresaActions';
import { cnpjMask, cpfMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Personalidade } from '@constants/models';

const { Text } = Typography;

const { Option } = Select;

function PlanoForm() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useAppSelector((state) => state.user);
  const empresa = useAppSelector((state) => state.empresa);
  const [personalidade, setPersonalidade] = useState(Personalidade.PessoaJuridica);

  useEffect(() => {
    dispatch(
      SetPageHeader({
        backButton: false,
        title: user.user.confirmemail ? 'Finalizar Cadastro' : 'Confirmação de Email',
        subTitle: user.user.confirmemail
          ? 'Finalize seu cadastro preenchendo os campos abaixo'
          : 'Verifiquei sua caixa de emails e confirme sua conta',
      })
    );
  }, [dispatch, user.user.confirmemail]);
  const buscaCNPJ = (value: string) => {
    if (value !== '') {
      dispatch(SearchCNPJ_CEP());
      var cnpj = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '');

      jsonp(
        'https://www.receitaws.com.br/v1/cnpj/' + cnpj,
        { Authorization: '5921809e3cc408e9ec16cfd40287e228b99db9bea03c66b551b3726eb8d23a62' },
        (_: any, data: any) => {
          form.setFieldsValue({
            NomeRazao: data.nome,
            Bairro: data.bairro,
            CEP: data.cep,
            Cidade: data.municipio,
            Complemento: data.complemento,
            Endereco: data.logradouro,
            Estado: data.uf,
            Numero: data.numero,
          });
          dispatch(SearchCNPJ_CEP());
        }
      );
    }
  };

  const buscaCEP = (value: string) => {
    if (value !== '') {
      dispatch(SearchCNPJ_CEP());
      var cep = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '');
      jsonp('https://viacep.com.br/ws/' + cep + '/json/', null, (_: any, data: any) => {
        form.setFieldsValue({
          Bairro: data.bairro,
          CEP: data.cep,
          Cidade: data.municipio,
          Complemento: data.complemento,
          Endereco: data.logradouro,
          Estado: data.uf,
          Numero: data.numero,
        });
        dispatch(SearchCNPJ_CEP());
      });
    }
  };

  if (user.user.confirmemail) {
    return (
      <div>
        <Spin spinning={user.waiting} tip="Estruturando plano de contas. O processo pode durar cerca de dois minutos.">
          <Card title="Preencha os dados da sua empresa" style={{ width: 350, margin: 'auto' }}>
            <Form form={form} onFinish={(values) => dispatch(RegisterContexto(values))} layout="vertical">
              <Form.Item
                name="Personalidade"
                label="Personalidade"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Radio.Group
                  defaultValue={Personalidade.PessoaJuridica}
                  onChange={(e) => setPersonalidade(e.target.value)}
                >
                  <Radio value={Personalidade.PessoaJuridica}>Pessoa Jurídica</Radio>
                  <Radio value={Personalidade.PessoaFisica}>Pessoa Física</Radio>
                </Radio.Group>
              </Form.Item>
              {personalidade === Personalidade.PessoaJuridica && (
                <Form.Item
                  name="CPF_CNPJ"
                  label="CNPJ"
                  normalize={(v) => cnpjMask.format(cnpjMask.normalize(v, ''))}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input.Search onBlur={(e) => buscaCNPJ(e.target.value)} onSearch={buscaCNPJ} />
                </Form.Item>
              )}
              {personalidade === Personalidade.PessoaFisica && (
                <Form.Item
                  name="CPF_CNPJ"
                  normalize={(v) => cpfMask.format(cpfMask.normalize(v, ''))}
                  label="CPF"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              )}
              {personalidade === Personalidade.PessoaJuridica && (
                <Form.Item
                  name="NomeRazao"
                  label="Razão Social"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              )}
              {personalidade === Personalidade.PessoaFisica && (
                <Form.Item name="NomeRazao" label="Nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input />
                </Form.Item>
              )}
              <Form.Item name="CEP" label="CEP" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input.Search onBlur={(e) => buscaCEP(e.target.value)} onSearch={buscaCEP} />
              </Form.Item>
              <Form.Item name="Endereco" label="Rua" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="Numero" label="Número" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="Bairro" label="Bairro" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="Complemento" label="Complemento">
                <Input />
              </Form.Item>
              <Form.Item name="Cidade" label="Cidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="Estado" label="Estado" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Select showSearch optionFilterProp="children">
                  <Option value="AC">Acre</Option>
                  <Option value="AL">Alagoas</Option>
                  <Option value="AP">Amapá</Option>
                  <Option value="AM">Amazonas</Option>
                  <Option value="BA">Bahia</Option>
                  <Option value="CE">Ceará</Option>
                  <Option value="DF">Distrito Federal</Option>
                  <Option value="ES">Espírito Santo</Option>
                  <Option value="GO">Goiás</Option>
                  <Option value="MA">Maranhão</Option>
                  <Option value="MT">Mato Grosso</Option>
                  <Option value="MS">Mato Grosso do Sul</Option>
                  <Option value="MG">Minas Gerais</Option>
                  <Option value="PA">Pará</Option>
                  <Option value="PB">Paraíba</Option>
                  <Option value="PR">Paraná</Option>
                  <Option value="PE">Pernambuco</Option>
                  <Option value="PI">Piauí</Option>
                  <Option value="RJ">Rio de Janeiro</Option>
                  <Option value="RN">Rio Grande do Norte</Option>
                  <Option value="RS">Rio Grande do Sul</Option>
                  <Option value="RO">Rondônia</Option>
                  <Option value="RR">Roraima</Option>
                  <Option value="SC">Santa Catarina</Option>
                  <Option value="SP">São Paulo</Option>
                  <Option value="SE">Sergipe</Option>
                  <Option value="TO">Tocantins</Option>
                  <Option value="EX">Estrangeiro</Option>
                </Select>
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button style={{ float: 'right' }}>Limpar</Button>
                <Button style={{ float: 'right' }} type="primary" loading={empresa.savingloading} htmlType="submit">
                  Avançar
                </Button>
              </div>
            </Form>
          </Card>
        </Spin>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <Button loading={user.resendConfirmEmail.sending} onClick={() => dispatch(ResendConfirmEmail())}>
            Enviar email novamente
          </Button>
        </div>
        <div>
          <Text type="danger">{user.resendConfirmEmail.msg}</Text>
        </div>
      </div>
    );
  }
}

export default PlanoForm;
