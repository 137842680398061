import { useState } from 'react';
import { Table, Button, Popconfirm, Input, Tooltip, Space } from 'antd';
import { Permission } from '@hooks/usePermissions';
import { Link } from 'react-router-dom';
import { sortAlpha, getColumnSearchProps, filterobject } from '@utils/filterandsort';
import { EditOutlined, BarsOutlined, DeleteOutlined } from '@ant-design/icons';
import { moneyMaskNeg } from '@utils/masks';
import { ColumnsType } from 'antd/lib/table';
import { ContaBancaria } from '@models/ContaBancaria';
import { BancariasControllerInterface } from '@controllers/controllers';

function TabelaBancarias({ controller }: { controller: BancariasControllerInterface }) {
  const { data, actions } = controller();
  const [filter, setFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState<any>(null);
  const columns: ColumnsType<ContaBancaria> = [
    {
      width: 100,
      render: (_, record) => (
        <Space direction="horizontal">
          <Permission permission="bancaria.edit">
            <Tooltip title="Editar">
              <Button
                key={`${record.id}editar`}
                size="small"
                icon={<EditOutlined />}
                onClick={() => actions.LoadConta(record.id)}
              />
            </Tooltip>
          </Permission>
          <Tooltip title="Extrato">
            <Link to={`/Extrato?Bancaria=${record.id}`}>
              <Button key={`${record.id}extrato`} size="small" icon={<BarsOutlined />} />
            </Link>
          </Tooltip>
          <Permission permission="bancaria.delete">
            <Tooltip title="Excluir">
              <Popconfirm title="Está certo disso?" onConfirm={() => actions.Delete(record.id)}>
                <Button key={`${record.id}delete`} size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Permission>
        </Space>
      ),
    },
    {
      title: 'Banco',
      dataIndex: 'banco',
      sorter: (a, b) => sortAlpha(a.banco, b.banco),
      ...getColumnSearchProps('banco', 'string', 'banco', filters),
    },
    {
      title: 'Agência',
      dataIndex: 'agencia',
      sorter: (a, b) => sortAlpha(a.agencia, b.agencia),
      ...getColumnSearchProps('agencia', 'string', 'Agência', filters),
    },
    {
      title: 'Conta',
      dataIndex: 'conta',
      sorter: (a, b) => sortAlpha(a.conta, b.conta),
      ...getColumnSearchProps('conta', 'string', 'Conta', filters),
    },
    {
      title: 'Saldo',
      dataIndex: 'saldo',
      sorter: (a, b) => a.saldo - b.saldo,
      render: (text) => <span style={{ color: text < 0 ? 'red' : 'blue' }}>{moneyMaskNeg.format(text)}</span>,
    },
  ];
  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        scroll={{ y: 'calc(100vh - 230px)', x: 1024 }}
        rowKey="id"
        columns={columns}
        loading={data.savingloading || data.waiting}
        onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
        dataSource={filterobject(data.bancarias, filter)}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}

export default TabelaBancarias;
