import { apiEndpoints } from '@config/apiEndpoints';
import cliente from '@config/axios';
import { ContaAnalitica } from '@models/ContaAnalitica';
import { notification, TreeSelect, TreeSelectProps } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

const GetAnaliticas = (unidadeID: number, tipo: number, transferencia: boolean) => {
  return useQuery<ContaAnalitica[], AxiosError<any>>(
    ['analiticas', unidadeID, tipo, transferencia],
    () =>
      cliente
        .get<ContaAnalitica[]>(apiEndpoints.CONCILIAR.GET_CONTAS_OQUE, {
          params: { unidadeID: unidadeID, tipo: tipo, transferencia },
        })
        .then((res) => {
          return res.data;
        }),
    {
      enabled: !!unidadeID,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      staleTime: 0,
      initialData: [],
    }
  );
};
interface AnaliticaBancariaSelectProps extends TreeSelectProps {
  unidadeID: number;
  tipo: number;
  transferencia: boolean;
}
function AnaliticaSelect({ unidadeID, tipo, transferencia = false, ...restProps }: AnaliticaBancariaSelectProps) {
  const { data, isLoading } = GetAnaliticas(unidadeID, tipo, transferencia);
  return (
    <TreeSelect
      {...restProps}
      showSearch
      disabled={restProps.disabled || !unidadeID}
      treeData={data}
      treeNodeFilterProp={restProps.treeNodeFilterProp || 'title'}
      loading={restProps.loading || isLoading}
      style={{ width: restProps?.style?.width || '100%' }}
    />
  );
}
export default AnaliticaSelect;
