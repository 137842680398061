import { externalAPIs } from '@config/apiEndpoints';
import jsonp from 'jsonp';
import { BuscaCEP } from './interfaces';

export const buscaCEP: BuscaCEP = async (value) => {
  if (value !== '') {
    var cep = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '');
    jsonp(externalAPIs.VIACEP + cep + '/json/', undefined, (_: any, data: any) => {
      if (data.status === 'OK') {
        return Promise.resolve(data);
      } else return Promise.reject(data);
    });
  }
  return Promise.reject();
};
