import { Table, Modal, Button, Select, Popconfirm } from 'antd';
import { ChangeUnidadeMover, ShowContasMover, SetContaMoverExtrato, MoverLancamentos } from '@actions/extratoActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { getColumnSearchProps } from '@utils/filterandsort';
import { ColumnsType } from 'antd/lib/table';
import { ContaAnalitica } from '@models/ContaAnalitica';
import { TableRowSelection } from 'antd/lib/table/interface';

function JanelaContasMover() {
  const dispatch = useAppDispatch();
  const extrato = useAppSelector((state) => state.extrato);
  const general = useAppSelector((state) => state.general);

  const confirm = () => {
    dispatch(
      MoverLancamentos(
        extrato.selectedlancamentos,
        extrato.selectedcontamover[0],
        extrato.nivel,
        extrato.contaid,
        extrato.periodoinicio,
        extrato.periodofim,
        extrato.selectedpessoas,
        extrato.unidade,
        extrato.bancaria,
        extrato.tipoconta
      )
    );
  };
  const rowSelection: TableRowSelection<ContaAnalitica> = {
    onChange: (selectedRowKeys) => {
      return dispatch(SetContaMoverExtrato(selectedRowKeys as number[]));
    },
    type: 'radio',
    selectedRowKeys: extrato.selectedcontamover,
  };
  const columns: ColumnsType<ContaAnalitica> = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      width: 100,
      ...getColumnSearchProps('codigo', 'string', 'Código', null),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      render: (text, row) => (row.bancaria ? text + ' - ' + row.bancaria : text),
      ...getColumnSearchProps('descricao', 'string', 'Descrição', null),
    },
  ];
  return (
    <div>
      <Modal
        title="Escolher conta destino"
        open={extrato.contasmoverview}
        onCancel={() => dispatch(ShowContasMover(false, extrato.unidademover))}
        footer={[
          <Button key="1" type="default" onClick={() => dispatch(ShowContasMover(false))}>
            Cancelar
          </Button>,
          <Popconfirm
            key="2"
            title="Tem certeza que deseja mover para essa conta?"
            onConfirm={() => confirm()}
            okText="Sim"
            cancelText="Não"
          >
            <Button disabled={extrato.selectedcontamover.length === 0} type="primary">
              Mover
            </Button>
          </Popconfirm>,
        ]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          style={{ width: '100%' }}
          onChange={(value) => dispatch(ChangeUnidadeMover(value))}
          placeholder="Unidade de Negócio"
          value={extrato.unidademover}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {general.unidades.map((unidade) => (
            <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
              {unidade.nome}
            </Select.Option>
          ))}
        </Select>
        <Table
          tableLayout="fixed"
          size="small"
          scroll={{ y: '60vh' }}
          rowKey="id"
          rowSelection={rowSelection}
          columns={columns}
          pagination={false}
          loading={extrato.loadingcontas}
          dataSource={extrato.contasmover}
        />
      </Modal>
    </div>
  );
}

export default JanelaContasMover;
