import { Button, Input, Form, Radio, Select, Spin, Card } from 'antd';
import { cepMask, cnpjMask, cpfMask } from '@utils/masks';
import { PlanoController } from '@controllers/PlanoController';
import { Personalidade } from '@constants/models';

const { Option } = Select;

function NovoPlanoForm() {
  const controller = PlanoController;
  const { data, actions } = controller();

  const [form] = Form.useForm();

  const buscaCNPJ = async (value: string) => {
    const data = await actions.BuscaCNPJ(value);
    if (data !== null) {
      form.setFieldsValue({
        nomeRazao: data.nome,
        bairro: data.bairro,
        cep: data.cep,
        cidade: data.municipio,
        complemento: data.complemento,
        endereco: data.logradouro,
        estado: data.uf,
        numero: data.numero,
      });
    }
  };

  const buscaCEP = async (value: any) => {
    const data = await actions.BuscaCEP(value);
    if (data !== null) {
      form.setFieldsValue({
        bairro: data.bairro,
        cidade: data.localidade,
        complemento: data.complemento,
        endereco: data.lagradouro,
        estado: data.uf,
      });
    }
  };

  if (data.user.confirmemail) {
    return (
      <div>
        <Spin spinning={data.waiting} tip="Estruturando plano de contas. O processo pode durar cerca de dois minutos.">
          <Card title="Preencha os dados da sua nova conta" style={{ margin: 'auto', maxWidth: 430 }} size="small">
            <Form
              form={form}
              style={{ width: 400 }}
              onFinish={(values) => actions.Register(values)}
              layout="horizontal"
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 6 }}
            >
              <Form.Item name="personalidade" label="Personalidade" initialValue="PessoaJuridica">
                <Radio.Group>
                  <Radio value={1}>Pessoa Jurídica</Radio>
                  <Radio value={0}>Pessoa Física</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('personalidade') === Personalidade.PessoaJuridica && (
                    <Form.Item
                      name="cpF_CNPJ"
                      label="CNPJ"
                      normalize={(v) => cnpjMask.format(cnpjMask.normalize(v, ''))}
                      rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                      <Input.Search
                        onBlur={(e) => buscaCNPJ(e.target.value)}
                        loading={data.empresa.searchloading}
                        onSearch={buscaCNPJ}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('personalidade') !== Personalidade.PessoaJuridica && (
                    <Form.Item
                      name="cpF_CNPJ"
                      label="CPF"
                      rules={[{ required: true, message: 'Campo Obrigatório' }]}
                      normalize={(v) => cpfMask.format(cpfMask.normalize(v, ''))}
                    >
                      <Input />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('personalidade') === Personalidade.PessoaJuridica && (
                    <Form.Item
                      name="nomeRazao"
                      label="Razão Social"
                      rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                      <Input />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('personalidade') !== Personalidade.PessoaJuridica && (
                    <Form.Item name="nomeRazao" label="Nome" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                      <Input />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item
                name="cep"
                label="CEP"
                rules={[{ required: true, message: 'Campo Obrigatório' }]}
                normalize={(v) => cepMask.format(cepMask.normalize(v, ''))}
              >
                <Input.Search loading={data.empresa.searchloading} onBlur={(e) => buscaCEP(e.target.value)} />
              </Form.Item>
              <Form.Item name="endereco" label="Rua" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="numero" label="Número" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="bairro" label="Bairro" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="complemento" label="Complemento">
                <Input />
              </Form.Item>
              <Form.Item name="cidade" label="Cidade" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="estado" label="Estado" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                <Select showSearch optionFilterProp="children">
                  <Option value="AC">Acre</Option>
                  <Option value="AL">Alagoas</Option>
                  <Option value="AP">Amapá</Option>
                  <Option value="AM">Amazonas</Option>
                  <Option value="BA">Bahia</Option>
                  <Option value="CE">Ceará</Option>
                  <Option value="DF">Distrito Federal</Option>
                  <Option value="ES">Espírito Santo</Option>
                  <Option value="GO">Goiás</Option>
                  <Option value="MA">Maranhão</Option>
                  <Option value="MT">Mato Grosso</Option>
                  <Option value="MS">Mato Grosso do Sul</Option>
                  <Option value="MG">Minas Gerais</Option>
                  <Option value="PA">Pará</Option>
                  <Option value="PB">Paraíba</Option>
                  <Option value="PR">Paraná</Option>
                  <Option value="PE">Pernambuco</Option>
                  <Option value="PI">Piauí</Option>
                  <Option value="RJ">Rio de Janeiro</Option>
                  <Option value="RN">Rio Grande do Norte</Option>
                  <Option value="RS">Rio Grande do Sul</Option>
                  <Option value="RO">Rondônia</Option>
                  <Option value="RR">Roraima</Option>
                  <Option value="SC">Santa Catarina</Option>
                  <Option value="SP">São Paulo</Option>
                  <Option value="SE">Sergipe</Option>
                  <Option value="TO">Tocantins</Option>
                  <Option value="EX">Estrangeiro</Option>
                </Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button size="small" onClick={() => form.resetFields()}>
                  Limpar
                </Button>
                <Button
                  size="small"
                  style={{ float: 'right' }}
                  type="primary"
                  loading={data.empresa.savingloading}
                  htmlType="submit"
                >
                  Avançar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </div>
    );
  }
  return <div></div>;
}

export default NovoPlanoForm;
