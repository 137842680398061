import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { GetResultado } from '@actions/dashBoardActions';
import moment from 'moment';
import { Spin, Card, Empty } from 'antd';
import { moneyMaskNeg } from '@utils/masks';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
interface CustomApexOptions extends ApexCharts.ApexOptions {
  toolbar: any;
}
function ResultadoPlot() {
  const dispatch = useAppDispatch();
  const dash = useAppSelector((state) => state.dash);
  const [data, setData] = useState<{ series: ApexAxisChartSeries; options: CustomApexOptions }>({
    series: [
      {
        name: 'Receitas',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: 'Despesas',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: 'Resultado',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val: any) {
          return moneyMaskNeg.format(val);
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: 'Valor',
        },
        labels: {
          formatter(val: any) {
            return `${moneyMaskNeg.format(val)}`;
          },
        },
      },
      colors: ['#1876d2', '#d23418', '#3ad218'],
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return moneyMaskNeg.format(val);
          },
        },
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true || '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp: any) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: 'zoom',
      },
    },
  });
  useEffect(() => {
    dispatch(GetResultado([], moment(new Date())));
  }, []);
  useEffect(() => {
    if (dash.resultado) {
      setData({
        ...data,
        options: {
          ...data.options,
          xaxis: { categories: dash.resultado.map((c) => c.mes) },
        },
        series: [
          {
            name: 'Receitas',
            data: dash.resultado.map((c) => c.receita),
          },
          {
            name: 'Despesas',
            data: dash.resultado.map((c) => c.despesa),
          },
          {
            name: 'Resultado',
            data: dash.resultado.map((c) => c.resultado),
          },
        ],
      });
    }
  }, [dash.resultado]);
  return (
    <Card
      title="Resultado Financeiro - DRE"
      size="small"
      style={{ maxWidth: 600, maxHeight: 400, minHeight: 400, minWidth: 600 }}
    >
      <Spin spinning={dash.resultadoloading}>
        {dash.resultado.length > 0 && (
          <ReactApexChart options={data.options} series={data.series} type="bar" height={350} />
        )}
        {dash.resultado.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Não foram encontradas receitas/despesas lançadas para a competência e unidades escolhidas"
          >
            <Link to="/Lancar">Clique aqui para realizar lançamentos</Link>
          </Empty>
        )}
      </Spin>
    </Card>
  );
}

export default ResultadoPlot;
