import axios from 'axios';
import { getCookie } from '@utils/localStorage';

export const cliente = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API : 'https://api.nambi.com.br',
  headers: {
    Authorization: `Bearer ${getCookie('token')}`,
    grupoid: getCookie('grupoid') || -1,
  },
});

// Add a request interceptor
cliente.interceptors.request.use(
  function (config) {
    //const contextoid = Cookies.get('contextoid');
    //if(!contextoid)
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
cliente.interceptors.response.use(
  function (response) {
    //console.log(response);
    return response;
  },
  function (error) {
    if (error.message === 'Request failed with status code 401' && error.config.url !== '/api/v2/Usuarios/Check')
      window.location.href = '/Login?ReturnURL=' + error.config.url;
    return Promise.reject(error);
  }
);

export default cliente;
