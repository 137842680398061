import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { GetAvisos } from '@actions/avisosActions';
import { extendedAction } from '@config/reduxStore';
import { AvisosReducer } from '@reducers/reducers';

const initialState: AvisosReducer = {
  avisos: [],
  loadingAvisos: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(GetAvisos.pending, (state) => {
    return {
      ...state,
      loadingAvisos: true,
    };
  });
  builder.addCase(GetAvisos.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar avisos!' });
    return {
      ...state,
      loadingAvisos: false,
    };
  });
  builder.addCase(GetAvisos.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingAvisos: false,
      avisos: action.payload.data,
    };
  });
});
