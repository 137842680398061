import { useEffect } from 'react';
import { Modal, Input, Radio, Select, Form, Button, Row, Col, Popconfirm } from 'antd';
import jsonp from 'jsonp';
import { SearchCNPJ_CEP, SaveEmpresa, HideEmpresaForm2 } from '@actions/empresaActions';
import { cnpjMask, cpfMask, cepMask } from '@utils/masks';
import { Permission } from '@hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Empresa } from '@models/Empresa';

const { Search } = Input;
const { Option } = Select;

function EmpresaForm() {
  const [form] = Form.useForm<Empresa>();
  const dispatch = useAppDispatch();
  const { savingloading, empresaformvisible, searchloading, empresa, empresas } = useAppSelector(
    (state) => state.empresa
  );
  useEffect(() => {
    if (empresa) {
      const e = empresas.find((c) => c.id === empresa);
      form.setFieldsValue({
        ...e,
        cpF_CNPJ:
          e.personalidade === 0
            ? cnpjMask.format(cnpjMask.normalize(e.cpF_CNPJ, ''))
            : cpfMask.format(cpfMask.normalize(e.cpF_CNPJ, '')),
        cep: cepMask.format(cepMask.normalize(e.cep, '')),
      });
    } else form.resetFields();
  }, [form, empresa, empresas]);
  const buscaCNPJ = (value: string) => {
    if (value !== '') {
      dispatch(SearchCNPJ_CEP());
      var cnpj = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '');
      jsonp(
        'https://www.receitaws.com.br/v1/cnpj/' + cnpj,
        { Authorization: '5921809e3cc408e9ec16cfd40287e228b99db9bea03c66b551b3726eb8d23a62' },
        (_: any, data: any) => {
          form.setFieldsValue({
            nomeRazao: data.nome,
            bairro: data.bairro,
            cep: data.cep,
            cidade: data.municipio,
            complemento: data.complemento,
            endereco: data.logradouro,
            estado: data.uf,
            numero: data.numero,
          });
          dispatch(SearchCNPJ_CEP());
        }
      );
    }
  };
  const buscaCEP = (value: string) => {
    if (value !== '') {
      dispatch(SearchCNPJ_CEP());
      var cep = value.replace(/\./g, '').replace(/\//g, '').replace(/-/g, '');
      jsonp('https://viacep.com.br/ws/' + cep + '/json/', null, (_: any, data: any) => {
        form.setFieldsValue({
          bairro: data.bairro,
          cidade: data.localidade,
          complemento: data.complemento,
          endereco: data.logradouro,
          estado: data.uf,
        });
        dispatch(SearchCNPJ_CEP());
      });
    }
  };

  return (
    <Modal
      title="Cadastro de Empresa"
      open={empresaformvisible}
      width="50em"
      onCancel={() => dispatch(HideEmpresaForm2())}
      footer={[
        <Permission key="3" permission="empresa.delete">
          <Popconfirm title="Está certo disso?">
            <Button type="default" disabled>
              Excluir
            </Button>
          </Popconfirm>
        </Permission>,
        <Permission key="1" permission={form.getFieldValue('id') ? 'empresa.edit' : 'empresa.add'}>
          <Button type="primary" key="1" loading={savingloading} onClick={() => form.submit()}>
            Salvar
          </Button>
        </Permission>,
        <Button key="2" type="default" onClick={() => dispatch(HideEmpresaForm2())}>
          Fechar
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={(values) => dispatch(SaveEmpresa(values))}
        layout="vertical"
        initialValues={{ personalidade: 0 }}
      >
        <Form.Item name="id" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="personalidade" label="Personalidade">
          <Radio.Group>
            <Radio value={0}>Pessoa Jurídica</Radio>
            <Radio value={1}>Pessoa Física</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue('personalidade') === 0)
              return (
                <Form.Item
                  name="cpF_CNPJ"
                  label="CNPJ"
                  normalize={(value) => cnpjMask.format(cnpjMask.normalize(value, ''))}
                >
                  <Search onSearch={buscaCNPJ} loading={searchloading} />
                </Form.Item>
              );
            else
              return (
                <Form.Item name="cpF_CNPJ" label="CPF" normalize={(v) => cpfMask.format(cpfMask.normalize(v, ''))}>
                  <Input />
                </Form.Item>
              );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue('personalidade') === 0)
              return (
                <Form.Item
                  name="nomeRazao"
                  label="Razão Social"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              );
            else
              return (
                <Form.Item name="nomeRazao" label="Nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input />
                </Form.Item>
              );
          }}
        </Form.Item>
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item
              name="cep"
              label="CEP"
              normalize={(value) => cepMask.format(cepMask.normalize(value, ''))}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Search onSearch={buscaCEP} loading={searchloading} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item name="endereco" label="Rua" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="numero" label="Número" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="bairro" label="Bairro" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="complemento" label="Complemento">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="cidade" label="Cidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="estado" label="Estado" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Select showSearch>
                <Option value="AC">Acre</Option>
                <Option value="AL">Alagoas</Option>
                <Option value="AP">Amapá</Option>
                <Option value="AM">Amazonas</Option>
                <Option value="BA">Bahia</Option>
                <Option value="CE">Ceará</Option>
                <Option value="DF">Distrito Federal</Option>
                <Option value="ES">Espírito Santo</Option>
                <Option value="GO">Goiás</Option>
                <Option value="MA">Maranhão</Option>
                <Option value="MT">Mato Grosso</Option>
                <Option value="MS">Mato Grosso do Sul</Option>
                <Option value="MG">Minas Gerais</Option>
                <Option value="PA">Pará</Option>
                <Option value="PB">Paraíba</Option>
                <Option value="PR">Paraná</Option>
                <Option value="PE">Pernambuco</Option>
                <Option value="PI">Piauí</Option>
                <Option value="RJ">Rio de Janeiro</Option>
                <Option value="RN">Rio Grande do Norte</Option>
                <Option value="RS">Rio Grande do Sul</Option>
                <Option value="RO">Rondônia</Option>
                <Option value="RR">Roraima</Option>
                <Option value="SC">Santa Catarina</Option>
                <Option value="SP">São Paulo</Option>
                <Option value="SE">Sergipe</Option>
                <Option value="TO">Tocantins</Option>
                <Option value="EX">Estrangeiro</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EmpresaForm;
