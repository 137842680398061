import { Table } from 'antd';
import moment from 'moment';
import { GetHistorico } from '@actions/historicoActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Historico } from '@models/Historico';
import { getColumnSearchProps } from '@utils/filterandsort';

function TabelaHistorico() {
  const dispatch = useAppDispatch();
  const { pagination, waiting, historico } = useAppSelector((state) => state.historico);

  const columns: ColumnsType<Historico> = [
    {
      title: 'Data',
      dataIndex: 'dataHora',
      sorter: true,
      width: 150,
      render: (text) => moment(text).format('DD/MM/YYYY HH:MM:SS'),
      ...getColumnSearchProps('dataHora', 'daterange', 'Data', null, null),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      sorter: true,
      ...getColumnSearchProps('descricao', 'string', 'Descrição', null),
    },
    {
      title: 'Usuário',
      dataIndex: 'usuario',
      sorter: true,
      width: 220,
      ...getColumnSearchProps('usuario', 'string', 'Usuário', null),
    },
  ];
  return (
    <div>
      <Table
        bordered
        tableLayout="fixed"
        size="small"
        scroll={{ y: '70vh' }}
        rowKey="ID"
        columns={columns}
        pagination={pagination}
        loading={waiting}
        dataSource={historico}
        onChange={(pagination, filters, sorter) => dispatch(GetHistorico(pagination, sorter, filters))}
      />
    </div>
  );
}

export default TabelaHistorico;
