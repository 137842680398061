import { Button, Input, Modal, Table } from 'antd';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { GetUnidades } from '@actions/generalActions';
import { filterobject } from '@utils/filterandsort';
import { CheckOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { UnidadeNegocio } from '@models/UnidadeNegocio';
interface SelectUnidadeProps {
  data: {
    visible: boolean;
    index: null | number;
    tipo: null | number;
  };
  setData: Dispatch<SetStateAction<{ visible: boolean; index: null | number; tipo: null | number }>>;
  setUnidade: (index: number, id: number, nome: string, tipo?: number | null) => void;
}
function SelectUnidade({ data, setData, setUnidade }: SelectUnidadeProps) {
  const dispatch = useAppDispatch();
  const { unidades } = useAppSelector((state) => state.general);
  const [filtro, setFiltro] = useState<string>('');
  const ref = useRef<any>();
  useEffect(() => {
    if (data.visible) {
      ref.current.focus();
      dispatch(GetUnidades(true, true));
    }
  }, [data.visible]);
  return (
    <Modal
      open={data.visible}
      onCancel={() => setData({ visible: false, index: null, tipo: null })}
      title="Selecionar Unidade de Negócio"
      okButtonProps={{ hidden: true }}
    >
      <Input
        value={filtro}
        ref={(el) => (ref.current = el)}
        onChange={(e) => setFiltro(e.target.value)}
        placeholder="Buscar..."
      />
      <Table dataSource={filterobject(unidades, filtro)} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          render={(v, row: UnidadeNegocio) => (
            <Button
              onClick={() => {
                setUnidade(data.index as number, v, row.nome, data.tipo);
                setData({ visible: false, tipo: null, index: null });
              }}
              size="small"
              icon={<CheckOutlined />}
            />
          )}
          width={30}
        />
        <Table.Column title="Descrição" dataIndex="nome" />
      </Table>
    </Modal>
  );
}

export default SelectUnidade;
