import { useAppSelector } from '@config/reduxStore';
import { Button, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { CheckOutlined, StopOutlined, ImportOutlined } from '@ant-design/icons';
import { TipoPessoa } from '@constants/models';

function ImportarTable() {
  const { data } = useAppSelector((state) => state.pessoas);
  const [importData, setImportData] = useState([]);
  const [columns, setColumns] = useState<Record<string, string>>({});

  useEffect(() => {
    if (data?.data) setImportData(data.data);
  }, [data?.data]);

  const renderCell = (dataIndex: string, data: any, row: any, index: number) => {
    if (dataIndex === 'Menu') {
      return (
        <Select
          size="small"
          style={{ width: 100 }}
          onChange={(v) => setImportData(importData.map((c, i) => (i === index ? { ...c, tipoPessoa: v } : c)))}
        >
          <Select.Option value={0}>{TipoPessoa[0]}</Select.Option>
          <Select.Option value={1}>{TipoPessoa[1]}</Select.Option>
          <Select.Option value={2}>{TipoPessoa[2]}</Select.Option>
        </Select>
      );
    } else if (dataIndex === 'Done') {
      return row.done ? <CheckOutlined /> : <StopOutlined />;
    } else if (dataIndex === 'Action') {
      return !row.done && <Button size="small" icon={<ImportOutlined />} />;
    }
    return data;
  };
  const renderTitle = (title: string) => {
    return {
      title: defineTitle(title),
      dataIndex: title,
      width: 100,
      align: (title === 'Done' || title === 'Action') && 'center',
      render: (v: any, row: any, index: number) => renderCell(title, v, row, index),
    };
  };
  const defineTitle = (name: string) => {
    if (name === 'Action') return '';
    else if (name === 'Done') return 'Status';
    else if (name === 'Menu') return 'Tipo Pessoa';
    console.log(columns[name]);
    return (
      <div>
        <div>{name}</div>
        <div>
          <Select
            size="small"
            style={{ width: 100 }}
            onChange={(v) => setColumns({ ...columns, [name]: v })}
            allowClear
          >
            <Select.Option disabled={columns[name]} value="nomeRazao">
              *Nome/Razao
            </Select.Option>
            <Select.Option value="cpF_CNPJ">*CPF/CNPJ</Select.Option>
            <Select.Option value="personalidade">*Personalidade</Select.Option>
            <Select.Option value="cep">CEP</Select.Option>
            <Select.Option value="endereco">Endereço</Select.Option>
            <Select.Option value="numero">Número</Select.Option>
            <Select.Option value="bairro">Bairro</Select.Option>
            <Select.Option value="complemento">Complemento</Select.Option>
            <Select.Option value="cidade">Cidade</Select.Option>
            <Select.Option value="estado">Estado</Select.Option>
            <Select.Option value="banco">Banco</Select.Option>
            <Select.Option value="agencia">Agência</Select.Option>
            <Select.Option value="conta">Conta</Select.Option>
          </Select>
        </div>
      </div>
    );
  };
  //console.log(columns);
  return (
    <div>
      <Table
        columns={data?.columns?.map((c: any) => renderTitle(c))}
        size="small"
        scroll={{ x: 1024, y: 'calc(100vh - 220px)' }}
        dataSource={importData}
      />
    </div>
  );
}

export default ImportarTable;
