import { Descriptions, Drawer, Form, Input, Progress, Radio, Select, Slider, Space, Tag } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { SetCampos, SetColumns, SetDescricao, SetSettings, SetValues } from '@actions/importarActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface ImportarPainelProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

function ImportarPainel({ visible, setVisible }: ImportarPainelProps) {
  const dispatch = useAppDispatch();
  const { columns, settings, campos, values, descricao, importadas, totalLinhas } = useAppSelector(
    (state) => state.importar
  );
  const [form] = Form.useForm();
  useMemo(() => {
    form.setFieldsValue(settings);
  }, [settings]);

  return (
    <Drawer open={visible} title="Configurações" onClose={() => setVisible(false)}>
      <Form
        form={form}
        layout="vertical"
        size="small"
        onValuesChange={(cv) => {
          dispatch(SetColumns([...columns]));
          if (Object.keys(cv).includes('tipo')) {
            let temp = { ...campos };
            const key = Object.entries(campos).find((c) => c[1] === 'oque');
            if (key) delete temp[key[0]];
            const key2 = Object.entries(campos).find((c) => c[1] === 'onde');
            if (key2) delete temp[key2[0]];
            dispatch(SetCampos(temp));
            dispatch(
              SetValues(
                values.map((c) => {
                  if (c.done) return c;
                  return {
                    ...c,
                    onde: null,
                    ondeID: null,
                    oque: null,
                    oqueID: null,
                    unidadeOndeID: null,
                    unidadeOnde: null,
                    unidadeOqueID: null,
                    unidadeOque: null,
                  };
                })
              )
            );
          }
          if (Object.keys(cv).includes('quando')) {
            let temp = { ...campos };
            const key = Object.entries(campos).find((c) => c[1] === 'onde');
            if (key) delete temp[key[0]];
            const key2 = Object.entries(campos).find((c) => c[1] === 'onde');
            if (key2) delete temp[key2[0]];
            dispatch(SetCampos(temp));
            dispatch(
              SetValues(
                values.map((c) => {
                  if (c.done) return c;
                  return {
                    ...c,
                    onde: null,
                    ondeID: null,
                  };
                })
              )
            );
          }
        }}
      >
        <Form.Item label="Descrição">
          <Input value={descricao} onChange={(e) => dispatch(SetDescricao(e.target.value))} placeholder="Descrição" />
        </Form.Item>
        <Form.Item name="tipo" label="Tipo">
          <Radio.Group
            value={settings.tipo}
            onChange={(e) => dispatch(SetSettings({ ...settings, tipo: e.target.value }))}
          >
            <Radio value={0}>O que</Radio>
            <Radio value={1}>Onde</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="quando" label="Quando">
          <Radio.Group
            value={settings.quando}
            onChange={(e) => dispatch(SetSettings({ ...settings, quando: e.target.value }))}
          >
            <Radio value={0}>Agora</Radio>
            <Radio value={1}>Depois</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="dateFormat" label="Formato Data">
          <Select
            onChange={(v) => {
              dispatch(SetSettings({ ...settings, dateFormat: v }));
            }}
            value={settings.dateFormat}
          >
            <Select.Option value="DD/MM/YYYY">DD/MM/YYYY</Select.Option>
            <Select.Option value="MM/DD/YYYY">MM/DD/YYYY</Select.Option>
            <Select.Option value="YYYY-MM-DD">YYYY-MM-DD</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="precision" label="Precisão">
          <Slider
            min={0}
            style={{ width: 300 }}
            max={100}
            value={20}
            onChange={(v: number) => dispatch(SetSettings({ ...settings, precision: v }))}
            marks={{
              0: '0%',
              20: '20%',
              40: '40%',
              60: '60%',
              80: '80%',
              100: '100%',
            }}
          />
        </Form.Item>
        <Form.Item label="Colunas Excluídas">
          <Space>
            {columns
              .filter((c) => c.deleted)
              .map((c) => (
                <Tag
                  key={c.dataIndex}
                  closable
                  color="red"
                  onClose={() =>
                    dispatch(
                      SetColumns(columns.map((d) => (d.dataIndex === c.dataIndex ? { ...d, deleted: false } : d)))
                    )
                  }
                >
                  {c.dataIndex}
                </Tag>
              ))}
          </Space>
        </Form.Item>
        <Form.Item label="Progresso">
          <Progress
            percent={(100 * importadas) / totalLinhas}
            success={{ percent: totalLinhas }}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Descriptions bordered size="small">
          <Descriptions.Item label="Total">{totalLinhas}</Descriptions.Item>
          <Descriptions.Item label="Importado">{importadas}</Descriptions.Item>
        </Descriptions>
      </Form>
    </Drawer>
  );
}

export default ImportarPainel;
