import { Button, Input, Switch, Table } from 'antd';
import { useEffect } from 'react';
import { ActivatePlano, GetAvisos } from '@actions/sistemaActions';
import { CheckCircleFilled, StopFilled, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useState } from 'react';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import AvisoForm from './AvisoForm';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Aviso } from '@models/Aviso';

function Avisos() {
  const dispatch = useAppDispatch();
  const { avisos, loadingAvisos } = useAppSelector((state) => state.sistema);
  const [filtro, setFiltro] = useState('');
  const [novo, setNovo] = useState(false);
  useEffect(() => {
    dispatch(GetAvisos());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Suporte',
        subTitle: 'Avisos',
        extras: [
          <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => setNovo(true)}>
            Novo Aviso
          </Button>,
        ],
      })
    );
  }, [dispatch]);
  return (
    <div>
      <AvisoForm visible={novo} setVisible={setNovo} />
      <Input.Search
        placeholder="Buscar..."
        size="small"
        style={{ width: 200 }}
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
      />
      <Table
        size="small"
        rowKey="id"
        loading={loadingAvisos}
        dataSource={filterobject<Aviso>(avisos, filtro)}
        scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
      >
        <Table.Column
          title="Ativo"
          dataIndex="ativo"
          render={(v, row: Aviso) => <Switch checked={v} onChange={() => dispatch(ActivatePlano(row.id))} />}
        />
        <Table.Column title="Título" dataIndex="title" />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column title="Data Criação" dataIndex="dataCriacao" render={(v) => moment(v).format('DD/MM/YYYY')} />
        <Table.Column
          title="Visto"
          dataIndex="visto"
          align="center"
          sorter={(a: any, b: any) => a.visto - b.visto}
          render={(v) =>
            v ? <CheckCircleFilled style={{ color: 'green' }} /> : <StopFilled style={{ color: 'red' }} />
          }
        />
        <Table.Column
          title="Usuário"
          dataIndex={['user', 'email']}
          sorter={(a: Aviso, b: Aviso) => naturalSorter(a.user.email, b.user.email)}
        />
      </Table>
    </div>
  );
}

export default Avisos;
