import { message } from 'antd';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { DREReducer } from '@reducers/reducers';

var initialState: DREReducer = {
  dados: { nivel3: [], analiticas: [], sinteticas: [], competencia: moment(new Date()) },
  dre: [],
  loadingDRE: false,
};

const dreReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'GET_DRE_DRE_PENDING':
      state = {
        ...state,
        loadingDRE: true,
      };
      break;
    case 'GET_DRE_DRE_REJECTED':
      state = {
        ...state,
        loadingDRE: false,
      };

      message.error('Erro ao gerar DRE');
      break;
    case 'GET_DRE_DRE_FULFILLED':
      let nivel3: unknown[] = [...new Set(action.payload.data.nivel3.map((c: any) => String(c.conta)))];
      let sinteticas = [...new Set(action.payload.data.sinteticas.map((c: any) => c.conta))].map((c) => ({
        conta: c,
        nivel3ID: action.payload.data.sinteticas.find((d: any) => d.conta === c).nivel3ID,
        id: action.payload.data.sinteticas.find((d: any) => d.conta === c).id,
      }));
      let analiticas = [...new Set(action.payload.data.analiticas.map((c: any) => c.conta))].map((c) => ({
        conta: c,
        contaSinteticaID: action.payload.data.analiticas.find((d: any) => d.conta === c).contaSinteticaID,
        id: action.payload.data.analiticas.find((d: any) => d.conta === c).id,
      }));
      let dados: any[] = nivel3.map((c) => {
        return {
          descricao: c,
          class: 'nivel2',
          mes1: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c &&
                moment(action.payload.data.competencia).add(-3, 'M').format('M/YYYY') === `${d.month}/${d.year}`
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          mes2: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c &&
                moment(action.payload.data.competencia).add(-2, 'M').format('M/YYYY') === `${d.month}/${d.year}`
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          mes3: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c &&
                moment(action.payload.data.competencia).add(-1, 'M').format('M/YYYY') === `${d.month}/${d.year}`
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          mes4: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c && moment(action.payload.data.competencia).format('M/YYYY') === `${d.month}/${d.year}`
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          mesPassado: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c &&
                moment(action.payload.data.competencia).add(-1, 'year').format('M/YYYY') === `${d.month}/${d.year}`
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          acumuladoAtual: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c &&
                moment(action.payload.data.competencia).year() === d.year &&
                d.month <= moment(action.payload.data.competencia).month()
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          acumuladoPassado: action.payload.data.nivel3
            .filter(
              (d: any) =>
                d.conta === c &&
                moment(action.payload.data.competencia).add(-1, 'year').year() === d.year &&
                d.month <= moment(action.payload.data.competencia).month()
            )
            .reduce((a: any, b: any) => a + b.valor, 0),
          id: action.payload.data.nivel3.find((d: any) => d.conta === c).id,
          children: sinteticas
            .filter((s) => s.nivel3ID === action.payload.data.nivel3.find((d: any) => d.conta === c).id)
            .map((s) => {
              return {
                descricao: s.conta,
                class: 'nivel3',
                mes1: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).add(-3, 'M').format('M/YYYY') === `${d.month}/${d.year}`
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                mes2: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).add(-2, 'M').format('M/YYYY') === `${d.month}/${d.year}`
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                mes3: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).add(-1, 'M').format('M/YYYY') === `${d.month}/${d.year}`
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                mes4: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).format('M/YYYY') === `${d.month}/${d.year}`
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                mesPassado: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).add(-1, 'year').format('M/YYYY') ===
                        `${d.month}/${d.year}`
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                acumuladoAtual: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).year() === d.year &&
                      d.month <= moment(action.payload.data.competencia).month()
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                acumuladoPassado: action.payload.data.sinteticas
                  .filter(
                    (d: any) =>
                      d.conta === s.conta &&
                      d.nivel3ID === s.nivel3ID &&
                      moment(action.payload.data.competencia).add(-1, 'year').year() === d.year &&
                      d.month <= moment(action.payload.data.competencia).month()
                  )
                  .reduce((a: any, b: any) => a + b.valor, 0),
                id: s.id,
                children: analiticas
                  .filter(
                    (a) =>
                      a.contaSinteticaID === action.payload.data.sinteticas.find((d: any) => d.conta === s.conta).id
                  )
                  .map((a) => {
                    return {
                      descricao: a.conta,
                      class: 'nivel4',
                      mes1: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).add(-3, 'M').format('M/YYYY') ===
                              `${d.month}/${d.year}`
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      mes2: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).add(-2, 'M').format('M/YYYY') ===
                              `${d.month}/${d.year}`
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      mes3: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).add(-1, 'M').format('M/YYYY') ===
                              `${d.month}/${d.year}`
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      mes4: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).format('M/YYYY') === `${d.month}/${d.year}`
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      mesPassado: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).add(-1, 'year').format('M/YYYY') ===
                              `${d.month}/${d.year}`
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      acumuladoAtual: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).year() === d.year &&
                            d.month <= moment(action.payload.data.competencia).month()
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      acumuladoPassado: action.payload.data.analiticas
                        .filter(
                          (d: any) =>
                            d.conta === a.conta &&
                            moment(action.payload.data.competencia).add(-1, 'year').year() === d.year &&
                            d.month <= moment(action.payload.data.competencia).month()
                        )
                        .reduce((a: any, b: any) => a + b.valor, 0),
                      id: a.id,
                    };
                  }),
              };
            }),
        };
      });

      state = {
        ...state,
        loadingDRE: false,
        dados: action.payload.data,
        dre: dados,
      };

      break;
    default:
      return state;
  }

  return state;
};

export default dreReducer;
