import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { RolesReducer } from '@reducers/reducers';

var initialState: RolesReducer = {
  roles: [],
  formview: false,
  role: null,
  loadingroles: false,
  savingrole: false,
  feedback: '',
  permissions: [],
  loadingpermissions: false,
  perfil: null,
  perfis: [],
  savingperfil: false,
  loadingperfis: false,
};

const rolesReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_PERFIL':
      state = {
        ...state,
        formview: action.payload.open,
        perfil: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'ADD_PERFIL_PENDING':
      state = {
        ...state,
        savingperfil: true,
        feedback: '',
      };
      break;
    case 'ADD_PERFIL_REJECTED':
      state = {
        ...state,
        savingperfil: false,
        feedback: action.payload.response.data,
      };
      break;
    case 'ADD_PERFIL_FULFILLED':
      state = {
        ...state,
        savingperfil: false,
        feedback: 'Agência criada com sucesso!',
        perfis: [...state.perfis, action.payload.data],
      };
      break;
    case 'UPDATE_PERFIL_PENDING':
      state = {
        ...state,
        savingperfil: true,
        feedback: '',
      };
      break;
    case 'UPDATE_PERFIL_REJECTED':
      state = {
        ...state,
        savingperfil: false,
        feedback: action.payload.response.data,
      };
      break;
    case 'UPDATE_PERFIL_FULFILLED':
      state = {
        ...state,
        savingperfil: false,
        feedback: 'Agência atualizada com sucesso!',
        perfis: state.perfis.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      break;
    case 'DELETE_PERFIL_PENDING':
      state = {
        ...state,
        loadingperfis: true,
        feedback: '',
      };
      break;
    case 'DELETE_PERFIL_REJECTED':
      state = {
        ...state,
        loadingperfis: false,
        feedback: action.payload.response.data,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_PERFIL_FULFILLED':
      state = {
        ...state,
        loadingperfis: false,
        feedback: 'Agência atualizada com sucesso!',
        perfis: state.perfis.filter((c) => c.id !== action.payload.data),
      };
      notification.success({
        message: state.feedback,
      });
      break;
    case 'GET_ROLES_PENDING':
      state = {
        ...state,
        loadingroles: true,
      };
      break;
    case 'GET_ROLES_REJECTED':
      state = {
        ...state,
        loadingroles: false,
      };
      notification.error({ message: 'Erro ao carregar perfis' });
      break;
    case 'GET_ROLES_FULFILLED':
      state = {
        ...state,
        loadingroles: false,
        roles: action.payload.data,
      };
      break;

    case 'GET_PERMISSIONS_ROLES_PENDING':
      state = {
        ...state,
        loadingpermissions: true,
      };
      break;
    case 'GET_PERMISSIONS_ROLES_REJECTED':
      state = {
        ...state,
        loadingpermissions: false,
      };
      notification.error({ message: 'Erro ao carregar permissões' });
      break;
    case 'GET_PERMISSIONS_ROLES_FULFILLED':
      state = {
        ...state,
        loadingpermissions: false,
        permissions: action.payload.data,
      };
      break;
    case 'ADD_PERMISSION_TO_ROLE_ROLES_PENDING':
      state = {
        ...state,
        loadingpermissions: true,
      };
      break;
    case 'ADD_PERMISSION_TO_ROLE_ROLES_REJECTED':
      state = {
        ...state,
        loadingpermissions: false,
      };
      notification.error({ message: 'Erro ao atualizar permissão' });
      break;
    case 'ADD_PERMISSION_TO_ROLE_ROLES_FULFILLED':
      state = {
        ...state,
        loadingpermissions: false,
        roles: state.roles.map((c) => {
          if (c.id === action.payload.data.role) {
            if (action.payload.data.removido) {
              c.rolePermissions = c.rolePermissions.filter((r) => r.permission !== action.payload.data.permission);
            } else {
              c.rolePermissions = [
                ...c.rolePermissions,
                {
                  permission: action.payload.data.permission as string,
                  id: action.payload.data.permissionid as string,
                },
              ];
            }
            return c;
          } else return c;
        }),
      };
      notification.success({ message: 'Permissão atualizada com sucesso' });
      break;
    default:
      return state;
  }

  return state;
};

export default rolesReducer;
