import { useEffect } from 'react';
import { Modal, Button, Input, Form, Radio, Select, Card, Tabs, Row, Col } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { cnpjMask, cpfMask, cepMask, phoneMobMask, phoneFixoMask } from '@utils/masks';
import { Pessoa } from '@models/Pessoa';
import { Personalidade, TipoPessoa } from '@constants/models';
import { PessoasControllerInterface } from '@controllers/controllers';
const { Option } = Select;
const { Search } = Input;

function FormPessoa({ pessoasController }: { pessoasController: PessoasControllerInterface }) {
  const { data, actions } = pessoasController();
  const [form] = Form.useForm<Pessoa>();

  useEffect(() => {
    if (data.pessoa) {
      const p = data.pessoasCadastro.find((c) => c.id === data.pessoa);
      form.setFieldsValue({
        ...p,
        cpF_CNPJ:
          p?.personalidade === Personalidade.PessoaJuridica
            ? cnpjMask.format(cnpjMask.normalize(p?.cpF_CNPJ || '', ''))
            : cpfMask.format(cpfMask.normalize(p?.cpF_CNPJ || '', '')),
        cep: cpfMask.format(p?.cep || ''),
      });
      if (p?.personalidade === Personalidade.PessoaFisica) form.setFieldsValue({ fantasia: p.fantasia });
    } else form.resetFields();
  }, [data.pessoa, form]);
  const buscaCNPJ = async (value: string) => {
    const data = await actions.BuscaCNPJ(value);
    if (data !== null) {
      form.setFieldsValue({
        nomeRazao: data.nome,
        bairro: data.bairro,
        cep: data.cep,
        cidade: data.municipio,
        complemento: data.complemento,
        endereco: data.logradouro,
        estado: data.uf,
        fantasia: data.fantasia,
        numero: data.numero,
      });
    }
  };
  const buscacep = async (value: string) => {
    const data = await actions.BuscaCEP(value);
    if (data !== null) {
      form.setFieldsValue({
        bairro: data.bairro,
        cidade: data.localidade,
        complemento: data.complemento,
        endereco: data.lagradouro,
        estado: data.uf,
      });
    }
  };

  return (
    <Modal
      title="Cadastro de Pessoa"
      open={data.formvisible}
      width="50em"
      onCancel={() => actions.HideForm()}
      footer={[
        <Button
          type="primary"
          key="1"
          loading={data.savingloading}
          onClick={() => form.submit()}
          style={{ marginLeft: '10px' }}
        >
          Salvar
        </Button>,
        <Button key="2" type="default" onClick={() => actions.HideForm()}>
          Fechar
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={(values) => actions.Salvar(values)}
        layout="vertical"
        initialValues={{
          tipoPessoa: TipoPessoa[0],
          personalidade: Personalidade.PessoaJuridica,
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Tabs
          items={[
            {
              label: 'Dados Empresa',
              key: '1',
              children: (
                <div>
                  <Form.Item name="tipoPessoa" label="Categoria">
                    <Radio.Group>
                      <Radio value={0}>{TipoPessoa[0]}</Radio>
                      <Radio value={1}>{TipoPessoa[1]}</Radio>
                      <Radio value={2}>{TipoPessoa[2]}</Radio>
                      <Radio value={3}>{TipoPessoa[3]}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="personalidade" label="Personalidade">
                    <Radio.Group onChange={() => form.setFieldsValue({ fantasia: '' })}>
                      <Radio value={Personalidade.PessoaJuridica}>Pessoa Jurídica</Radio>
                      <Radio value={Personalidade.PessoaFisica}>Pessoa Física</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => (
                      <Form.Item
                        name="cpF_CNPJ"
                        label={getFieldValue('personalidade') === Personalidade.PessoaJuridica ? 'CNPJ' : 'CPF'}
                        normalize={(value) => {
                          if (getFieldValue('personalidade') === Personalidade.PessoaJuridica)
                            return cnpjMask.format(cnpjMask.normalize(value, ''));
                          else return cpfMask.format(cpfMask.normalize(value, ''));
                        }}
                      >
                        <Search
                          loading={data.searchloading}
                          onBlur={(e) => {
                            if (getFieldValue('personalidade') === Personalidade.PessoaJuridica)
                              buscaCNPJ(e.target.value);
                          }}
                          onSearch={(value) => {
                            if (getFieldValue('personalidade') === Personalidade.PessoaJuridica) buscaCNPJ(value);
                          }}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      if (getFieldValue('personalidade') === Personalidade.PessoaJuridica)
                        return (
                          <div>
                            <Form.Item
                              name="nomeRazao"
                              label="Razão Social"
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item name="fantasia" label="Nome Fantasia">
                              <Input />
                            </Form.Item>
                          </div>
                        );
                      else
                        return (
                          <Form.Item
                            name="nomeRazao"
                            label="Nome"
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                          >
                            <Input />
                          </Form.Item>
                        );
                    }}
                  </Form.Item>
                </div>
              ),
            },
            {
              label: 'Endereço',
              key: '2',
              children: (
                <div>
                  <Form.Item name="cep" label="CEP" normalize={(value) => cepMask.format(cepMask.normalize(value, ''))}>
                    <Search loading={data.searchloading} onSearch={(value) => buscacep(value)} />
                  </Form.Item>
                  <Form.Item name="endereco" label="Rua">
                    <Input />
                  </Form.Item>
                  <Form.Item name="numero" label="Número">
                    <Input />
                  </Form.Item>
                  <Form.Item name="bairro" label="Bairro">
                    <Input />
                  </Form.Item>
                  <Form.Item name="complemento" label="Complemento">
                    <Input />
                  </Form.Item>
                  <Form.Item name="cidade" label="Cidade">
                    <Input />
                  </Form.Item>
                  <Form.Item name="estado" label="Estado">
                    <Select showSearch>
                      <Option value="AC">Acre</Option>
                      <Option value="AL">Alagoas</Option>
                      <Option value="AP">Amapá</Option>
                      <Option value="AM">Amazonas</Option>
                      <Option value="BA">Bahia</Option>
                      <Option value="CE">Ceará</Option>
                      <Option value="DF">Distrito Federal</Option>
                      <Option value="ES">Espírito Santo</Option>
                      <Option value="GO">Goiás</Option>
                      <Option value="MA">Maranhão</Option>
                      <Option value="MT">Mato Grosso</Option>
                      <Option value="MS">Mato Grosso do Sul</Option>
                      <Option value="MG">Minas Gerais</Option>
                      <Option value="PA">Pará</Option>
                      <Option value="PB">Paraíba</Option>
                      <Option value="PR">Paraná</Option>
                      <Option value="PE">Pernambuco</Option>
                      <Option value="PI">Piauí</Option>
                      <Option value="RJ">Rio de Janeiro</Option>
                      <Option value="RN">Rio Grande do Norte</Option>
                      <Option value="RS">Rio Grande do Sul</Option>
                      <Option value="RO">Rondônia</Option>
                      <Option value="RR">Roraima</Option>
                      <Option value="SC">Santa Catarina</Option>
                      <Option value="SP">São Paulo</Option>
                      <Option value="SE">Sergipe</Option>
                      <Option value="TO">Tocantins</Option>
                      <Option value="EX">Estrangeiro</Option>
                    </Select>
                  </Form.Item>
                </div>
              ),
            },
            {
              label: 'Dados Bancários',
              key: '3',
              children: (
                <div>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Form.Item name="banco" label="Banco">
                        <Input />
                      </Form.Item>
                      <Form.Item name="contaBancaria" label="Observação">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="agencia" label="Agência">
                        <Input />
                      </Form.Item>
                      <Form.Item name="conta" label="Conta">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="formaPagamento" label="Forma de Pagamento">
                    <Select>
                      <Select.Option value="Transferência">Transferência</Select.Option>
                      <Select.Option value="Boleto">Boleto</Select.Option>
                      <Select.Option value="Cartão">Cartão</Select.Option>
                      <Select.Option value="Acordo">Acordo</Select.Option>
                      <Select.Option value="Outros">Outros</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              ),
            },
            {
              label: 'Contatos',
              key: '4',
              children: (
                <div>
                  <Form.List name="contatoPessoas">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((_, index) => (
                            <Card
                              key={index}
                              size="small"
                              title={`Contato ${index + 1}`}
                              extra={
                                fields.length > 1 && (
                                  <Button
                                    size="small"
                                    ghost
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => remove(index)}
                                  />
                                )
                              }
                            >
                              <Row gutter={[8, 8]}>
                                <Col span={12}>
                                  <Form.Item name={[index, 'id']} hidden>
                                    <Input />
                                  </Form.Item>
                                  <Form.Item name={[index, 'nome']} label="Nome">
                                    <Input />
                                  </Form.Item>
                                  <Form.Item name={[index, 'setor']} label="Setor">
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={[index, 'contato1']}
                                    normalize={(value) =>
                                      value.length > 14
                                        ? phoneMobMask.format(phoneMobMask.normalize(value, ''))
                                        : phoneFixoMask.format(phoneFixoMask.normalize(value, ''))
                                    }
                                    label="Telefone"
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item name={[index, 'email1']} label="Email">
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                          <Button
                            type="dashed"
                            icon={<PlusOutlined />}
                            block
                            size="small"
                            onClick={() => add({ nome: '', setor: '', contato1: '', email1: '' })}
                          >
                            Contato
                          </Button>
                        </div>
                      );
                    }}
                  </Form.List>
                </div>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
}

export default FormPessoa;
