import { useRef } from 'react';
import { Permission } from '@hooks/usePermissions';
import { Table, Typography, Button, Popconfirm, Descriptions, Collapse, Tooltip } from 'antd';
import { EditOutlined, BarsOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchPropsServer, sortAlpha } from '@utils/filterandsort';
import { cnpjMask, cpfMask } from '@utils/masks';
import { ColumnsType } from 'antd/lib/table';
import { Pessoa } from '@models/Pessoa';
import { Personalidade, TipoPessoa } from '@constants/models';
import { PessoasControllerInterface } from '@controllers/controllers';
const { Text } = Typography;
const { Panel } = Collapse;

function TabelaPessoas({ pessoasController }: { pessoasController: PessoasControllerInterface }) {
  const { data, actions } = pessoasController();
  const ref = useRef();
  const columns: ColumnsType<Pessoa> = [
    {
      render: (_, record) => (
        <div>
          <Permission permission="pessoa.edit">
            <Tooltip title="Editar">
              <Button
                key={`${record.id}editar`}
                size="small"
                icon={<EditOutlined />}
                onClick={() => actions.GetPessoa(record.id)}
              />
            </Tooltip>
          </Permission>
          <Tooltip title="Extrato">
            <Button key={`${record.id}extrato`} size="small" icon={<BarsOutlined />} />
          </Tooltip>
          <Permission permission="pessoa.delete">
            <Tooltip title="Excluir">
              <Popconfirm title="Está certo disso?" onConfirm={() => actions.Delete(record.id)}>
                <Button key={`${record.id}delete`} size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Permission>
        </div>
      ),
      width: 100,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipoPessoa',
      width: 100,
      render: (text) => (text === 0 ? 'Fornecedor' : text === 3 ? 'Cliente' : text === 2 ? 'Sócio' : 'Colaborador'),
      sorter: (a, b) => a.tipoPessoa - b.tipoPessoa,
      ...getColumnSearchPropsServer(
        'tipoPessoa',
        'customselect',
        'Tipo',
        data.pessoaspagination,
        [
          {
            text: TipoPessoa[0],
            value: 0,
          },
          {
            text: TipoPessoa[1],
            value: 1,
          },
          {
            text: TipoPessoa[2],
            value: 2,
          },
          {
            text: TipoPessoa[3],
            value: 3,
          },
        ],
        ref
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'nomeRazao',
      ellipsis: true,
      sorter: (a, b) => sortAlpha(a.nomeRazao, b.nomeRazao),
      width: '30%',
      ...getColumnSearchPropsServer('nomeRazao', 'string', 'Nome', data.pessoaspagination, undefined, ref),
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cpF_CNPJ',
      width: 150,
      sorter: (a, b) => sortAlpha(a.cpF_CNPJ, b.cpF_CNPJ),
      render: (text, row) =>
        row.personalidade === Personalidade.PessoaJuridica
          ? cnpjMask.format(cnpjMask.normalize(text, ''))
          : cpfMask.format(cpfMask.normalize(text, '')),
      ...getColumnSearchPropsServer('cpF_CNPJ', 'string', 'CPF/CNPJ', data.pessoaspagination, undefined, ref),
    },
    {
      title: 'Inform. Bancárias',
      dataIndex: 'banco',
      sorter: (a, b) => sortAlpha(a.banco, b.banco),
      render: (_, record) => (
        <Text type="secondary">
          Banco: {record.banco} Agência: {record.agencia} Conta: {record.conta}
        </Text>
      ),
      ...getColumnSearchPropsServer('banco', 'string', 'Banco', data.pessoaspagination, undefined, ref),
    },
    {
      title: 'Contatos',
      dataIndex: 'contatoPessoas',
      ellipsis: true,
      render: (_, record) => {
        if (record.contatoPessoas.length <= 1) {
          return record.contatoPessoas.map((contato, index) => (
            <div key={index}>
              <Text>Nome: {contato.nome}</Text>
              <br />
              <Text>Contato: {contato.contato1}</Text>
              <br />
              <Text>Setor: {contato.setor}</Text>
              <br />
              <Text>Email: {contato.email1}</Text>
              <br />
            </div>
          ));
        } else
          return (
            <Collapse>
              <Panel header="Contatos" key="id">
                {record.contatoPessoas.map((contato, index) => (
                  <div key={index}>
                    <Text>Nome: {contato.nome}</Text>
                    <br />
                    <Text> Contato: {contato.contato1}</Text>
                    <br />
                    <Text>Setor: {contato.setor}</Text>
                    <br />
                    <Text>Email: {contato.email1}</Text>
                    <br />
                    <hr />
                  </div>
                ))}
              </Panel>
            </Collapse>
          );
      },
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'formaPagamento',
      sorter: (a, b) => sortAlpha(a.formaPagamento, b.formaPagamento),
      ...getColumnSearchPropsServer('formaPagamento', 'string', 'formaPagamento', data.pessoaspagination),
    },
  ];
  return (
    <div>
      <Table
        bordered
        size="small"
        scroll={{ y: 'calc(100vh - 200px)', x: 1024 }}
        rowKey="id"
        onChange={(pagination, filters, sorter) => {
          Object.keys(filters).forEach((c) => {
            if (filters[c] === null) filters[c] = [];
          });
          actions.GetPessoas({ pagination, filters, sorter });
          actions.SetFiltros({ pagination, filters, sorter });
        }}
        columns={columns}
        dataSource={data.pessoasCadastro}
        expandedRowRender={(record, index) => (
          <Descriptions key={index} title="Informações Adicionais">
            <Descriptions.Item label="Personalidade">
              {record.personalidade === Personalidade.PessoaJuridica ? 'Pessoa Jurídica' : 'Pessoa Física'}
            </Descriptions.Item>
            {record.personalidade === Personalidade.PessoaJuridica ? (
              <Descriptions.Item label="Nome Fantasia">{record.fantasia}</Descriptions.Item>
            ) : null}
            <Descriptions.Item label="Rua">{record.endereco}</Descriptions.Item>
            <Descriptions.Item label="Número">{record.numero}</Descriptions.Item>
            <Descriptions.Item label="Complemento">{record.complemento}</Descriptions.Item>
            <Descriptions.Item label="Bairro">{record.bairro}</Descriptions.Item>
            <Descriptions.Item label="Cidade">{record.cidade}</Descriptions.Item>
            <Descriptions.Item label="Estado">{record.estado}</Descriptions.Item>
            <Descriptions.Item label="CEP">{record.cep}</Descriptions.Item>
          </Descriptions>
        )}
        loading={data.pessoasloading}
        pagination={{
          current: data.pessoaspagination.pagination.current,
          total: data.pessoaspagination.pagination.total,
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}

export default TabelaPessoas;
