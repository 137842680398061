import { message, notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { EmpresaReducer } from '@reducers/reducers';

var initialState: EmpresaReducer = {
  waiting: false,
  empresas: [],
  empresa: null,
  unidade: null,
  searchloading: false,
  openloading: false,
  savingloading: false,
  empresaformvisible: false,
  unidadeformvisible: false,
  contasformvisible: false,
  usersformvisible: false,
  users: [],
  userunidade: null,
  planocontas: [],
  loadingplano: false,
  searchPlanoValue: '',
  planoexpandedKeys: [],
  planoselecteddata: {},
  loadingsettings: false,
  contasunidade: [],
  settingsnivel4: {
    newdescricao: '',
    newunidade: null,
    newpessoasbalanco: false,
    newbancaria: null,
    newbaixaestoque: false,
    saving: false,
  },
  sinteticas: [],
  analiticas: [],
  editanaliticaid: null,
  bancarias: [],
  tempanaliticas: [],
  addingcontaloading: false,
  sizeaddcontas: 500,
  editsinteticaid: null,
  tempsinteticas: [],
  novasinteticaview: false,
  savingnomecontexto: false,
};

const empresaReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'CHANGE_NOME_CONTEXTO_EMPRESAS_PENDING':
      state = {
        ...state,
        savingnomecontexto: true,
      };
      break;
    case 'CHANGE_NOME_CONTEXTO_EMPRESAS_FULFILLED':
      state = {
        ...state,
        savingnomecontexto: false,
      };
      break;
    case 'ADD_SINTETICA_EMPRESAS_PENDING':
      state = {
        ...state,
        addingcontaloading: true,
      };
      break;
    case 'ADD_SINTETICA_EMPRESAS_FULFILLED':
      state = {
        ...state,
        sinteticas: [...state.sinteticas, action.payload.data],
        addingcontaloading: false,
        editsinteticaid: null,
      };
      message.success('Conta criada com sucesso!');
      break;
    case 'NOVA_SINTETICA_VIEW_EMPRESAS':
      state = {
        ...state,
        novasinteticaview: !state.novasinteticaview,
      };
      break;
    case 'ADD_ANALITICA_EMPRESAS_PENDING':
      state = {
        ...state,
        addingcontaloading: false,
      };
      break;
    case 'ADD_ANALITICA_EMPRESAS_FULFILLED':
      state = {
        ...state,
        addingcontaloading: false,
        settingsnivel4: {
          ...state.settingsnivel4,
          ...action.payload.data,
        },
      };
      break;
    case 'UPDATE_CONTA_ANALITICA_EMPRESA_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'TOGGLE_CONTAS_EMPRESA':
      state = {
        ...state,
        contasformvisible: !state.contasformvisible,
      };
      break;
    case 'SET_UNIDADE_USERS_FORM_EMPRESA':
      state = {
        ...state,
        userunidade: action.payload,
      };
      break;
    case 'SET_USERS_FORM_EMPRESA_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'SET_USERS_FORM_EMPRESA_REJECTED':
      notification.error({ message: 'Erro ao atualizar usuário!' });
      state = {
        ...state,
        savingloading: false,
      };
      break;
    case 'SET_USERS_FORM_EMPRESA_FULFILLED':
      notification.success({ message: 'Usuário atualizado com sucesso!' });
      state = {
        ...state,
        savingloading: false,
        users: action.payload.data,
      };
      break;
    case 'GET_USERS_FORM_EMPRESA_FULFILLED':
      state = {
        ...state,
        openloading: false,
        users: action.payload.data,
      };
      break;
    case 'GET_USERS_FORM_EMPRESA_PENDING':
      state = {
        ...state,
        openloading: true,
        usersformvisible: true,
      };
      break;
    case 'GET_CONTAS_UNIDADE_EMPRESA_PENDING':
      state = {
        ...state,
        openloading: true,
      };
      break;
    case 'GET_CONTAS_UNIDADE_EMPRESA_FULFILLED':
      state = {
        ...state,
        openloading: false,
        contasunidade: state.contasunidade.find((c) => c.unidade === action.payload.unidade)
          ? state.contasunidade.map((c) =>
              c.unidade === action.payload.unidade ? { ...c, contas: action.payload.data } : c
            )
          : [...state.contasunidade, { unidade: action.payload.unidade, contas: action.payload.data }],
      };
      break;
    case 'SAVE_EMPRESA_EMPRESA_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'SAVE_EMPRESA_EMPRESA_REJECTED':
      notification.error({ message: 'Erro ao salvar empresa!' });
      state = {
        ...state,
        savingloading: false,
      };
      break;
    case 'SAVE_UNIDADE_EMPRESA_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'SAVE_UNIDADE_EMPRESA_REJECTED':
      notification.error({ message: 'Erro ao salvar unidade!' });
      state = {
        ...state,
        savingloading: false,
      };
      message.error('Erro ao criar unidade de negócio');
      break;
    case 'SAVE_UNIDADE_EMPRESA_FULFILLED':
      notification.success({ message: 'Unidade salva com sucesso!' });
      setTimeout(() => (window.location.href = '/Empresa'), 3000);

      break;
    case 'LOAD_UNIDADE_EDIT_EMPRESA':
      state = {
        ...state,
        unidadeformvisible: true,
        unidade: action.payload,
        empresa: null,
      };
      break;
    case 'LOAD_EMPRESA_EDIT_EMPRESA':
      state = {
        ...state,
        empresa: action.payload,
        empresaformvisible: true,
      };
      break;
    case 'LOAD_UNIDADE_EDIT_EMPRESA_FULFILLED':
      state = {
        ...state,
        openloading: false,
        unidade: action.payload[0],
      };
      break;
    case 'LOAD_EMPRESA_EDIT_EMPRESA_FULFILLED':
      state = {
        ...state,
        openloading: false,
        empresa: action.payload,
      };
      break;
    case 'LOAD_EMPRESAS_EMPRESA_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'LOAD_EMPRESAS_EMPRESA_FULFILLED':
      state = {
        ...state,
        waiting: false,
        empresas: action.payload.data,
      };
      break;
    case 'HIDE_USERS_FORM_EMPRESA':
      state = {
        ...state,
        usersformvisible: !state.usersformvisible,
      };
      break;
    case 'HIDE_EMPRESA_FORM_EMPRESA2':
      state = {
        ...state,
        empresaformvisible: !state.empresaformvisible,
      };
      break;
    case 'HIDE_EMPRESA_FORM_EMPRESA':
      state = {
        ...state,
        empresaformvisible: !state.empresaformvisible,
        empresa: initialState.empresa,
      };
      break;
    case 'HIDE_UNIDADE_FORM_EMPRESA':
      state = {
        ...state,
        unidadeformvisible: !state.unidadeformvisible,
        empresa: action.payload,
        unidade: null,
      };
      break;
    case 'SEARCH_EMPRESA':
      state = {
        ...state,
        searchloading: !state.searchloading,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default empresaReducer;
