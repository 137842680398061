import { useEffect } from 'react';
import { useRef } from 'react';

export function copy<Type>(data: Type): Type {
  return JSON.parse(JSON.stringify(data)) as Type;
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
export const serialize = (obj: any) => {
  return '?' + new URLSearchParams(obj).toString();
};
