import { useEffect, useState } from 'react';
import moment from 'moment';
import { Space, Typography } from 'antd';
import { useAppSelector } from '@config/reduxStore';
import { Pessoa } from '@models/Pessoa';

function TituloContasPG() {
  const { tipoconta, unidades, contatitle, periodoinicio, periodofim, selectedpessoas } = useAppSelector(
    (state) => state.contaspg
  );
  const general = useAppSelector((state) => state.general);
  const pessoasR = useAppSelector((state) => state.pessoas);
  const [pessoas, setPessoas] = useState([]);
  useEffect(() => {
    let p: Pessoa[] = [];
    pessoasR.pessoasGrouped.forEach((c) => {
      p = [...p, ...c.pessoas];
    });
    setPessoas(p);
  }, [pessoasR]);
  return (
    <Space direction="horizontal">
      <Typography.Title level={3}>Extrato</Typography.Title>
      {tipoconta === 'normal' && (
        <Typography.Text>
          <strong>Tipo:</strong> Normal
        </Typography.Text>
      )}
      {tipoconta === 'bancaria' && (
        <Typography.Text>
          <strong>Tipo:</strong> Bancária
        </Typography.Text>
      )}
      {contatitle && <Typography.Text>{contatitle}</Typography.Text>}
      {unidades.length > 0 && tipoconta === 'normal' && (
        <Typography.Text>
          <strong>Unidades:</strong> {unidades.map((c) => general.unidades.find((d) => d.id === c)?.nome + ', ')}
        </Typography.Text>
      )}
      {selectedpessoas.length > 0 && (
        <Typography.Text>
          <strong>Pessoas:</strong> {selectedpessoas.map((c) => pessoas.find((d) => d.id === c)?.nomeRazao + ', ')}
        </Typography.Text>
      )}
      {selectedpessoas.length > 0 && (
        <Typography.Text>
          <strong>Período:</strong>{' '}
          {`de ${moment(periodoinicio).isValid() && moment(periodoinicio).format('DD/MM/YYYY')} até ${
            moment(periodofim).isValid() && moment(periodofim).format('DD/MM/YYYY')
          }`}
        </Typography.Text>
      )}
    </Space>
  );
}

export default TituloContasPG;
