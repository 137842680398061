import { combineReducers } from 'redux';
import pessoas from './pessoasReducer';
import user from './userReducer';
import contextos from './contextosReducer';
import general from './generalReducer';
import extrato from './extratoReducer';
import balanco from './balancoReducer';
import produtos from './produtosReducer';
import orcamentos from './orcamentosReducer';
import lancamentos from './lancamentosReducer';
import empresa from './empresaReducer';
import lancar from './lancarReducer';
import dash from './dashBoardReducer';
import contaspg from './contasPGReducer';
import usuarios from './usuariosReducer';
import historico from './historicoReducer';
import bancarias from './bancariasReducer';
import fluxocaixa from './fluxocaixaReducer';
import sistema from './sistemaReducer';
import importar from './importarReducer';
import implantacao from './implantacaoReducer';
import planocontas from './planocontasReducer';
import roles from './rolesReducer';
import dre from './dreReducer';
import avisos from './avisosReducer';
import conciliar from './conciliarReducer';

export default combineReducers({
  user,
  avisos,
  pessoas,
  contextos,
  general,
  extrato,
  balanco,
  produtos,
  orcamentos,
  lancamentos,
  empresa,
  lancar,
  dash,
  contaspg,
  usuarios,
  historico,
  bancarias,
  fluxocaixa,
  sistema,
  importar,
  implantacao,
  planocontas,
  roles,
  dre,
  conciliar,
});
