import { useEffect } from 'react';
import { Button, Collapse, Tabs, Spin } from 'antd';
import { Permission } from '@hooks/usePermissions';

import { LoadEmpresas, HideUnidadeForm, LoadUnidadeEdit, LoadEmpresaEdit, GetUsers } from '@actions/empresaActions';
import ContasForm from './ContasForm';
import UsersForm from './UsersForm';
import { PlusOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { Panel } = Collapse;

function EmpresasTabs() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { unidades } = useAppSelector((state) => state.general);
  const { waiting, empresas } = useAppSelector((state) => state.empresa);
  useEffect(() => {
    dispatch(LoadEmpresas());
  }, []);
  return (
    <Permission permission="empresa.list">
      <Spin spinning={waiting}>
        <Collapse accordion defaultActiveKey={['0']}>
          {empresas.map((empresa, index) => (
            <Panel
              header={empresa.nomeRazao?.toUpperCase()}
              key={index}
              extra={[
                <Permission key={0} permission="unidade.add">
                  <Button
                    key={`${index}editarunidade`}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(HideUnidadeForm(empresa.id));
                    }}
                    disabled={unidades.length >= user.plano.unidades}
                    type="default"
                  >
                    <PlusOutlined /> Unidade de Negócio
                  </Button>
                </Permission>,
                <Permission key={1} permission="empresa.edit">
                  <Button
                    key={`${index}editarempresa`}
                    type="default"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(LoadEmpresaEdit(empresa.id));
                    }}
                  >
                    <EditOutlined /> Editar Empresa
                  </Button>
                </Permission>,
              ]}
            >
              <Tabs
                key={empresa.id}
                tabPosition="left"
                items={empresa.unidadeNegocios.map((unidade, index2) => ({
                  label: unidade.nome,
                  key: `tabpane${index}-${index2}`,
                  children: (
                    <div>
                      <h2 key={`title{index}-${index2}`}>{unidade.nome}</h2>
                      <Permission permission="unidade.edit">
                        <Button
                          key={`btn1${index}-${index2}`}
                          size="small"
                          type="default"
                          icon={<EditOutlined />}
                          onClick={() => dispatch(LoadUnidadeEdit(unidade.id))}
                        >
                          Editar Unidade
                        </Button>
                      </Permission>
                      <Button
                        key={`btn2${index}-${index2}`}
                        size="small"
                        type="default"
                        onClick={() => dispatch(GetUsers(unidade.id))}
                      >
                        <UserOutlined />
                        Usuários
                      </Button>
                      <hr />
                      <ContasForm key={`contasform${index}-${index2}`} unidadeid={unidade.id} />
                      <UsersForm key={`usersform${index}-${index2}`} />
                    </div>
                  ),
                }))}
              />
            </Panel>
          ))}
        </Collapse>
      </Spin>
    </Permission>
  );
}

export default EmpresasTabs;
