import { BancariaFormData } from '@views/EmpresaContext/Bancarias/FormBancaria';
import { cliente } from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetBancarias2 = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_BANCARIAS_BANCARIAS2',
      payload: cliente.get(apiEndpoints.BANCARIAS.GET_BANCARIAS_2),
    });
  };
};
export const GetBancarias = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_BANCARIAS_BANCARIAS2',
      payload: cliente.get(apiEndpoints.BANCARIAS.GET_BANCARIAS),
    });
  };
};
export const NovaConta = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'NOVA_CONTA_BANCARIA_BANCARIAS',
    });
  };
};
export const CloseForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLOSE_FORM_BANCARIAS',
    });
  };
};

export const SaveBancaria2 = function (conta: BancariaFormData) {
  return function (dispatch: AppDispatch) {
    if (!conta.id) {
      dispatch({
        type: 'ADD_CONTA_BANCARIA_BANCARIAS',
        payload: cliente.post(apiEndpoints.BANCARIAS.ADD_BANCARIA, conta),
      });
    } else {
      dispatch({
        type: 'UPDATE_CONTA_BANCARIA_BANCARIAS',
        payload: cliente.post(apiEndpoints.BANCARIAS.EDITAR_BANCARIA, conta),
      });
    }
  };
};

export const LoadBancaria = function (bancariaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_CONTA_BANCARIA_BANCARIAS',
      payload: cliente.get(apiEndpoints.BANCARIAS.GET_BANCARIA, { params: { bancariaid: bancariaid } }),
    });
  };
};

export const DeleteBancaria = function (bancariaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_BANCARIAS_BANCARIAS',
      payload: cliente.get(apiEndpoints.BANCARIAS.EXCLUIR_BANCARIA, { params: { id: bancariaid } }),
    });
  };
};
