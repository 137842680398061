import { useEffect } from 'react';
import { Input, Form, Select, Typography, Modal, Button } from 'antd';
import { UsuariosControllerInterface } from '@controllers/controllers';
const { Text } = Typography;

function UsuarioForm({ controller }: { controller: UsuariosControllerInterface }) {
  const { data, actions } = controller();
  const [form] = Form.useForm();
  useEffect(() => {
    if (data.finduser.existe) form.setFieldsValue({ nome: data.finduser.nome });
    else form.setFieldsValue({ nome: '' });
  }, [data.finduser.existe, data.finduser.nome, form]);

  useEffect(() => {
    form.resetFields();
    if (data.usuario) {
      let u = data.usuarios.find((c) => c.id === data.usuario);
      form.setFieldsValue({ ...u });
    }
  }, [data.usuario, data.usuarios, form]);
  return (
    <Modal
      title={data.editing ? 'Edição de Usuário' : 'Cadastro de Usuário'}
      open={data.formvisible}
      width="50em"
      onCancel={() => actions.HideForm(null, false)}
      footer={[
        <Button type="primary" key="1" loading={data.savingusuario} onClick={() => form.submit()}>
          Confirmar
        </Button>,
        <Button type="default" key="2" onClick={() => actions.HideForm(null, false)}>
          Fechar
        </Button>,
      ]}
    >
      <Form
        layout="horizontal"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={(values) => actions.Add(values.nome, values.email, values.perfil)}
      >
        <Form.Item style={{ display: 'none' }} name="Id">
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Campo obrigatório' },
            { type: 'email', message: 'Formato de email incorreto' },
          ]}
        >
          <Input.Search
            disabled={data.editing}
            onBlur={(e) => actions.Find(e.target.value)}
            onPressEnter={(e) => actions.Find(e.currentTarget.value)}
            onSearch={(v) => actions.Find(v)}
          />
        </Form.Item>
        {data.finduser.existe && (
          <div>
            <Form.Item name="nome" label="Nome">
              <Input disabled />
            </Form.Item>
            <Text>Usuário já possui cadastro no sistema com o nome acima</Text>
          </div>
        )}
        {!data.finduser.existe && (
          <div>
            <Form.Item name="nome" label="Nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input placeholder="Nome completo" />
            </Form.Item>
          </div>
        )}
        <Form.Item label="Perfil" name="perfil" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Select value={form.getFieldValue('perfil')}>
            {data.perfis
              .filter((p) => p.tipoContexto === 1)
              .map((c) => (
                <Select.Option key={c.name} value={c.id}>
                  {c.name.toUpperCase()}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Text type="danger">{data.addusermsg}</Text>
      </Form>
    </Modal>
  );
}

export default UsuarioForm;
