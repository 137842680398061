import { useEffect } from 'react';
import { Table } from 'antd';
import { GetUnidades } from '@actions/generalActions';
import Unidades from './Unidades';
import { formatNumber, moneyMaskNeg } from '@utils/masks';
import moment from 'moment';
import { SetPageHeader } from '@actions/userActions';
import '../../../assets/scss/DRE.scss';
import { Permission } from '@hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function DREView() {
  const dispatch = useAppDispatch();
  const { dados, loadingDRE, dre } = useAppSelector((state) => state.dre);
  useEffect(() => {
    document.title = 'Nambi - DRE';
    dispatch(GetUnidades(true, true));
    dispatch(
      SetPageHeader({
        title: 'DRE',
        subTitle: 'Escolha a(s) unidade(s) de negócio e o ano para gerar o DRE',
        extras: [
          <Permission permission="dre.list" key={0}>
            <Unidades />
          </Permission>,
        ],
      })
    );
  }, [document]);
  return (
    <Permission permission="dre.list">
      <Table
        size="small"
        loading={loadingDRE}
        dataSource={dre}
        rowKey="id"
        className="DREtable"
        pagination={false}
        scroll={{ x: 1200, y: 'calc(100vh - 180px)' }}
        rowClassName={(row) => row.class}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter((c) => `${c.month}/${c.year}` === moment(dados.competencia).add(-3, 'M').format('M/YYYY'))
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter((c) => `${c.month}/${c.year}` === moment(dados.competencia).add(-2, 'M').format('M/YYYY'))
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter((c) => `${c.month}/${c.year}` === moment(dados.competencia).add(-1, 'M').format('M/YYYY'))
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>TOTAL</Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter((c) => `${c.month}/${c.year}` === moment(dados.competencia).format('M/YYYY'))
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter(
                      (c) => `${c.month}/${c.year}` === moment(dados.competencia).add(-1, 'year').format('M/YYYY')
                    )
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter(
                      (c) => c.year === moment(dados.competencia).year() && c.month <= moment(dados.competencia).month()
                    )
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align="right">
                {moneyMaskNeg.format(
                  dados.nivel3
                    .filter(
                      (c) =>
                        c.year === moment(dados.competencia).add(-1, 'year').year() &&
                        c.month <= moment(dados.competencia).month()
                    )
                    .reduce((a, b) => a + b.valor, 0)
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          title={moment(dados.competencia).add(-3, 'M').format('MM/YYYY')}
          align="right"
          dataIndex="mes1"
          render={(v) => formatNumber(v)}
        />
        <Table.Column
          title={moment(dados.competencia).add(-2, 'M').format('MM/YYYY')}
          align="right"
          dataIndex="mes2"
          render={(v) => formatNumber(v)}
        />
        <Table.Column
          title={moment(dados.competencia).add(-1, 'M').format('MM/YYYY')}
          align="right"
          dataIndex="mes3"
          render={(v) => formatNumber(v)}
        />
        <Table.Column title="Descrição" dataIndex="descricao" width={400} />
        <Table.Column
          title={moment(dados.competencia).format('MM/YYYY')}
          align="right"
          dataIndex="mes4"
          render={(v) => formatNumber(v)}
        />
        <Table.Column
          title={moment(dados.competencia).add(-1, 'year').format('MM/YYYY')}
          align="right"
          dataIndex="mesPassado"
          render={(v) => formatNumber(v)}
        />
        <Table.Column
          title={`Acumulado ${moment(dados.competencia).format('YYYY')}`}
          align="right"
          dataIndex="acumuladoAtual"
          render={(v) => formatNumber(v)}
        />
        <Table.Column
          align="right"
          title={`Acumulado ${moment(dados.competencia).add(-1, 'year').format('YYYY')}`}
          dataIndex="acumuladoPassado"
          render={(v) => formatNumber(v)}
        />
      </Table>
    </Permission>
  );
}

export default DREView;
