import { useEffect } from 'react';
import { Modal, Button, Input, Form, Select, Skeleton } from 'antd';
import { Bancos } from '@constants/data';
import { BancariasControllerInterface } from '@controllers/controllers';

const { Option } = Select;
export interface BancariaFormData {
  id?: number;
  banco: string;
  agencia: string;
  conta: string;
}

function FormBancaria({ controller }: { controller: BancariasControllerInterface }) {
  const { data, actions } = controller();
  const [form] = Form.useForm<BancariaFormData>();
  useEffect(() => {
    form.setFieldsValue(data.bancaria);
  }, [form, data.bancaria, data.showform]);
  return (
    <Modal
      title="Cadastro de Conta Bancária"
      open={data.showform}
      onCancel={() => actions.Close()}
      footer={[
        <Button
          type="primary"
          key="1"
          loading={data.savingloading}
          onClick={() => form.submit()}
          style={{ marginLeft: '10px' }}
        >
          Salvar
        </Button>,
        <Button key="2" type="default" onClick={() => actions.Close()}>
          Fechar
        </Button>,
      ]}
    >
      <Skeleton active loading={data.loadingconta}>
        <Form form={form} onFinish={(values) => actions.Save(values)} layout="vertical">
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name="banco" label="Banco">
            <Select showSearch optionFilterProp="children">
              {Object.keys(Bancos).map((c) => (
                <Option key={c} value={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="agencia" label="Agência">
            <Input />
          </Form.Item>
          <Form.Item name="conta" label="Conta">
            <Input />
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
}

export default FormBancaria;
