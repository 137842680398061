import { GetUnidades } from '@actions/generalActions';
import {
  ExcluirLancamento,
  ExportLancamentos,
  MostarLancamentos2,
  ResetLancamentos,
  SelectedLancamentos,
  SetLancamentosFiltros,
  SetLancamentosFiltrosForm,
  ViewForm,
} from '@actions/lancamentosActions';
import { GetPessoasGrouped } from '@actions/pessoasActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Permission } from '@hooks/usePermissions';
import { Button } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { LancamentosControllerInterface } from './controllers';

export const LancamentosController: LancamentosControllerInterface = () => {
  const dispatch = useAppDispatch();
  const { unidades } = useAppSelector((state) => state.general);
  const { lancamentosFiltro, waiting, exportinglancamentos, lancamentos, selectedlancamentos, formview } =
    useAppSelector((state) => state.lancamentos);
  const { pessoasGrouped } = useAppSelector((state) => state.pessoas);
  useEffect(() => {
    document.title = 'Nambi - Lançamentos';
    dispatch(GetUnidades(true, true));
    dispatch(GetPessoasGrouped());
    dispatch(ResetLancamentos());
    dispatch(
      SetPageHeader({
        title: 'Lançamentos',
        subTitle: 'Visualize todos os lançamentos realizados no sistema',
        extras: [
          <Permission permission="lancamento.list" key={0}>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                dispatch(ViewForm());
              }}
            >
              Buscar Lançamentos
            </Button>
          </Permission>,
        ],
      })
    );
  }, [dispatch]);
  const Mostrar = () =>
    dispatch(MostarLancamentos2(lancamentosFiltro.pagination, lancamentosFiltro.form, lancamentosFiltro.sorter));
  const Exportar: (
    unidades: any,
    transacaoinicial: any,
    transacaofim: any,
    selectedpessoas: any,
    blocoid: any,
    descricao: string,
    valor: any,
    documento: string
  ) => void = (unidades, transacaoinicial, transacaofim, selectedpessoas, blocoid, descricao, valor, documento) =>
    dispatch(
      ExportLancamentos(unidades, transacaoinicial, transacaofim, selectedpessoas, blocoid, descricao, valor, documento)
    );
  const SetFiltros: (values: any) => void = (values) => dispatch(SetLancamentosFiltros(values));
  const Excluir: () => void = () => dispatch(ExcluirLancamento(selectedlancamentos));
  const Select: (keys: React.Key[]) => void = (keys) => dispatch(SelectedLancamentos(keys));
  const Form: () => void = () => dispatch(ViewForm());
  const SetFiltrosForm: (values: any) => void = (values) => dispatch(SetLancamentosFiltrosForm(values));
  return {
    data: {
      unidades,
      lancamentosFiltro,
      formview,
      waiting,
      exportinglancamentos,
      pessoasGrouped,
      lancamentos,
      selectedlancamentos,
    },
    actions: {
      Form,
      Mostrar,
      Exportar,
      SetFiltros,
      SetFiltrosForm,
      Excluir,
      Select,
    },
  };
};
