import moment from 'moment';
import axios from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetContasPagar = function (unidades: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_PAGAR_HOME',
      payload: axios.post(apiEndpoints.HOME.GET_TITULOS, {
        unidades: unidades,
        pagar: true,
      }),
    });
  };
};

export const GetContasReceber = function (unidades: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_RECEBER_HOME',
      payload: axios.post(apiEndpoints.HOME.GET_TITULOS, {
        unidades: unidades,
        pagar: false,
      }),
    });
  };
};
export const GetResultado = function (unidades: number[], competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_RESULTADO_HOME',
      payload: axios.post(apiEndpoints.HOME.GET_RESULTADO_PLOT, {
        unidades: unidades,
        competencia: competencia,
      }),
    });
  };
};
export const GetGruposDespesas = function (unidades: number[], competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_GRUPOS_DESPESAS_HOME',
      payload: axios.post('/api/Home/GetGruposDespesas', {
        unidades: unidades,
        competencia: competencia,
      }),
    });
  };
};

export const SelectUnidades = function (unidades: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECT_UNIDADES_HOME',
      payload: unidades,
    });
  };
};

export const SetCompetencia = function (competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_COMPETENCIA_HOME',
      payload: competencia,
    });
  };
};
