import { apiEndpoints } from '@config/apiEndpoints';
import cliente from '@config/axios';
import { IConciliacao, ILancamento, ImportData } from '@models/Conciliar';
import { Lancamento } from '@models/Lancamento';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

export const ImportOFX = createAsyncThunk(
  'conciliar/Import',
  ({ data, id }: { data: FormData; id: string | number }, apiThunk) =>
    cliente.post<ImportData[]>(apiEndpoints.CONCILIAR.IMPORT, data).then((res) => {
      cliente.post(apiEndpoints.CONCILIAR.SAVE, { id: id, values: JSON.stringify(res.data), linhas: res.data.length });
      apiThunk.dispatch(SetOFXData(res.data));
    })
);
export const UpdateOFXValues = createAsyncThunk(
  'conciliar/UpdateOFXValues',
  async ({ data, id }: { data: ImportData[]; id: number | string }) =>
    cliente.post(apiEndpoints.CONCILIAR.SAVE, {
      values: JSON.stringify(data),
      lancadas: data.filter((c) => c.bloco).length,
      id: id,
      linhas: data.length,
    })
);
export const SetOFXData = createAction('conciliar/SetOFXData', (data: ImportData[]) => ({ payload: data }));
export const PickUnidade = createAction('conciliar/PickUnidade', (data: { v: number; id: string }) => ({
  payload: data,
}));
export const GetConciliacoes = createAsyncThunk('conciliar/GetConciliacoes', () =>
  cliente.get<IConciliacao[]>(apiEndpoints.CONCILIAR.GET_ALL)
);
export const GetConciliacao = createAsyncThunk('conciliar/GetConciliacao', (id: string | number) =>
  cliente.get<IConciliacao>(apiEndpoints.CONCILIAR.GET_ONE, { params: { id: id } })
);
export const PickConta = createAction('conciliar/PickConta', (data: { v: number; id: string }) => ({
  payload: data,
}));
export const PickPessoa = createAction('conciliar/PickPessoa', (data: { v: number; id: string }) => ({
  payload: data,
}));
export const SetDocumento = createAction('conciliar/SetDocumento', (data: { v: string; id: string }) => ({
  payload: data,
}));
export const SetDescricao = createAction('conciliar/SetDescricao', (data: { v: string; id: string }) => ({
  payload: data,
}));
export const SetTitulos = createAction('conciliar/SetTitulos', (data: { titulos: Lancamento[]; id: string }) => ({
  payload: data,
}));
export const SetSettings = createAction(
  'conciliar/SetSettings',
  (settings: { contaID: number; bancariaID: number; unidadeID: number }) => ({
    payload: settings,
  })
);
export const UpdateSettings = createAsyncThunk(
  'conciliar/UpdateSettings',
  (settings: { id: string | number; contaID: number; bancariaID: number; unidadeID: number }, apiThunk) =>
    cliente.post(apiEndpoints.CONCILIAR.UPDATE_SETTINGS, settings).then(() => apiThunk.dispatch(SetSettings(settings)))
);
export const SetTransferencia = createAction('conciliar/SetTrasnferencia', (id: string) => ({
  payload: id,
}));
export const Reset = createAction('conciliar/Reset');
export const Lancar = createAsyncThunk<
  AxiosResponse<string>,
  ILancamento,
  { rejectValue: AxiosError<{ Message: string }> }
>('conciliar/Lancar', (data) => cliente.post(apiEndpoints.LANCAR.LANCAR, data));
export const DeleteConciliacao = createAsyncThunk('conciliar/DeleteConciliacao', (id: number) =>
  cliente.delete<number>(apiEndpoints.CONCILIAR.DELETE, { params: { id: id } })
);
