interface PessoaEnum {
  [key: number]: string;
}

export const TipoPessoa: PessoaEnum = {
  0: 'Fornecedor',
  1: 'Colaborador',
  2: 'Sócio',
  3: 'Cliente',
};

export const Personalidade = {
  PessoaFisica: 0,
  PessoaJuridica: 1,
};
export const TipoEmpresa = {
  ME: 0,
  EPP: 1,
  LTDA: 2,
  EIRELI: 3,
  EI: 4,
  SA: 5,
};
export const FormaPagamento = {
  Transferencia: 0,
  CartaodeCredito: 1,
  Boleto: 2,
};
export const FluxoDinheiro = {
  Agora: 0,
  Depois: 1,
  Nenhum: 2,
};
export const Tipo = {
  Pagar: 0,
  Receber: 1,
  Nenhum: 2,
};
export const JurosMultaDesconto = {
  Juros: 0,
  Multa: 1,
  Desconto: 2,
  Nenhum: 3,
};

export const SentidoDinheiro = {
  Entrada: 0,
  Saida: 1,
  Movimentacao: 2,
};

export const LancamentoStatus = {
  Aberto: 0,
  Vencido: 1,
  Baixado: 2,
};
export const TipoContexto = {
  Negocio: 1,
  Fornecedor: 2,
  Sistema: 3,
};
export const TipoLancamento = {
  NULO: -1,
  OQUE: 0,
  ONDE: 1,
};
export const QuandoLancamento = {
  NULO: -1,
  AGORA: 0,
  DEPOIS: 1,
};
export const TipoIDLancamento = {
  NULO: -1,
  PAGAR: 0,
  RECEBER: 1,
  BAIXARPAGAMENTO: 2,
  BAIXARRECEBIMENTO: 3,
  MOVIMENTAR: 4,
};
export const TipoOrcamento = {
  Mensal: 1,
  Global: 0,
};
