import { Permission } from '@hooks/usePermissions';
import TabelaProdutos from './TabelaProdutos';
import FormProduto from './FormProduto';
import NovoGrupoForm from './NovoGrupoForm';
import NovoSubGrupoForm from './NovoSubGrupoForm';
import SaldosPorConta from './SaldosPorConta';
import { ProdutosController } from '@controllers/ProdutosController';

function ProdutosView() {
  const controller = ProdutosController;

  return (
    <Permission permission="produto.list">
      <TabelaProdutos controller={controller} />
      <FormProduto controller={controller} />
      <NovoGrupoForm controller={controller} />
      <NovoSubGrupoForm controller={controller} />
      <SaldosPorConta controller={controller} />
    </Permission>
  );
}
export default ProdutosView;
