export const apiEndpoints = {
  AVISOS: {
    GET_AVISOS: '/api/Avisos/GetAvisos',
    READ_AVISO: '/api/Avisos/ReadAviso',
  },
  BALANCO: {
    GET_NIVEL_1: 'api/Balanco/getNivel12',
    GET_NIVEL_2: 'api/Balanco/getNivel22',
    GET_NIVEL_3: 'api/Balanco/getNivel32',
    GET_CS: 'api/Balanco/getCS2',
    GET_CA: 'api/Balanco/getCA2',
    GET_PESSOAS: 'api/Balanco/getPessoas2',
    UPDATE_LANCAMENTOS: '/api/Balanco/UpdateLancamentos',
  },
  CONCILIAR: {
    IMPORT: 'api/Lancamentos/ImportOFX',
    GET_CONTAS_OQUE: '/api/Planocontas/GetContasConciliarOque',
    ADD: '/api/Conciliacao/AddConciliacao',
    GET_ALL: '/api/Conciliacao/GetConciliacoes',
    GET_ONE: '/api/Conciliacao/GetConciliacao',
    UPDATE_SETTINGS: '/api/Conciliacao/UpdateSettings',
    SAVE: '/api/Conciliacao/SaveConciliacao',
    DELETE: '/api/Conciliacao/DeleteConciliacao',
  },
  LANCAMENTOS: {
    EXCLUIR_LANCAMENTOS: '/api/Lancamentos/ExcluirLancamentos',
    GET_LANCAMENTOS_EXPORT: 'api/Lancamentos/GetLancamentosExport',
    GET_LANCAMENTOS: 'api/Lancamentos/GetLancamentos',
    GET_LANCAMENTOS_BLOCO: 'api/Lancamentos/GetLancamentosBloco',
    SAVE: 'api/Lancamentos/SaveConciliacao',
  },
  BANCARIAS: {
    GET_BANCARIAS_2: '/api/Bancarias/GetBancarias2',
    GET_BANCARIAS: '/api/Bancarias/GetBancarias',
    ADD_BANCARIA: '/api/Bancarias/AddBancaria',
    EDITAR_BANCARIA: '/api/Bancarias/EditarBancaria',
    GET_BANCARIA: '/api/Bancarias/GetBancaria',
    EXCLUIR_BANCARIA: '/api/Bancarias/ExcluirBancaria',
  },
  CONTASPG: {
    GET_CONTAS: '/api/Extrato/GetContas',
    GET_TITULOS_CONTASPG: '/api/ContasPR/GetTitulosContasPG',
  },
  CONTEXTOS: {
    GET_CONTEXTOS: 'api/Home/GetContextos',
  },
  HOME: {
    GET_TITULOS: '/api/Home/GetTitulos',
    GET_RESULTADO_PLOT: '/api/Home/GetResultadoPlot',
    GET_GRUPOS_DESPESAS: '/api/Home/GetGruposDespesas',
    GET_PERFIS: '/api/Home/GetPerfis',
    GET_FLUXO_CAIXA: '/api/Home/GetFluxoCaixa',
  },
  DRE: {
    GET_DRE: '/api/DRE/GetDRE',
  },
  EMPRESAS: {
    CHANGE_CONTEXTO_NAME: '/api/Empresas/ChangeContextoName',
    ADD_SINTETICA: '/api/Empresas/AddSintetica',
    ADD_ANALITICA: '/api/Empresas/AddAnalitica',
    GET_EMPRESAS: '/api/Empresas/GetEmpresas',
    EDITAR_EMPRESA: '/api/Empresas/EditarEmpresa',
    GET_PLANO_CONTAS: '/api/Empresas/GetPlanoContas',
  },
  UNIDADES: {
    EDITAR_UNIDADE: '/api/Unidades/EditarUnidade',
    ADD_UNIDADE: '/api/Unidades/AddUnidade',
    GET_CONTAS: '/api/Unidades/GetContas',
    GET_USERS: '/api/Unidades/GetUsers',
    CHANGE_USER_STATUS: '/api/Unidades/ChangeUserStatus',
    GET_UNIDADES: 'api/Unidades/GetUnidades',
    GET_CONTAS_ANALITICAS: 'api/Unidades/GetContasAnaliticas',
  },
  EXTRATO: {
    MOVER: '/api/Extrato/Mover',
    GET_CONTAS_MOVER: '/api/Extrato/GetContasMover',
    GET_BANCARIAS: '/api/Extrato/GetBancarias',
    GET_CONTAS: '/api/Extrato/getContas',
  },
  FLUXOCAIXA: {
    GET_NUMBERS: '/api/Fluxocaixa/GetNumbers3',
  },
  HISTORICO: {
    GET_HISTORICO: '/api/Historico/GetHistorico',
    GET_USERS_LIST: '/api/Historico/GetUsersList',
  },
  IMPLANTACAO: {
    IMPLANTAR: '/api/Implantacao/Implantar',
  },
  IMPORTAR: {
    LOAD_IMPORTS: '/api/Importar/LoadImports',
    SAVE: '/api/Importar/Save',
    LANCAR: '/api/Importar/Lancar',
    LOAD_TELA: '/api/Importar/LoadTela',
  },
  LANCAR: {
    GET_CONTAS_TREE: '/api/Lancar/GetContasTree',
    GET_PRODUTOS_BAIXA: '/api/Lancar/GetProdutosBaixa',
    GET_PRODUTOS: '/api/Lancar/GetProdutos',
    GET_TITULOS: '/api/Lancar/GetTitulos',
    LOAD_BLOCO: '/api/Lancar/LoadBloco',
    LANCAR: '/api/Lancar/Lancar',
  },
  ORCAMENTOS: {
    GET_ORCAMENTOS: '/api/Orcamento/GetOrcamentos',
    EXCLUIR_ORCAMENTO: '/api/Orcamento/ExcluirOrcamento',
    CRIAR_ORCAMENTO: '/api/Orcamento/CriarOrcamento',
    GET_ORCAMENTO: 'api/Orcamento/GetOrcamento',
    LOAD_ORCAMENTO_VIEW: 'api/Orcamento/LoadOrcamentoView',
  },
  ROLE: {
    GET_ROLES_FULL: '/api/Role/GetRolesFull',
    GET_PERMISSIONS: '/api/Role/GetPermissions',
    SALVAR_PERFIL: '/api/Role/SalvarPerfil',
    ADD_ROLE: '/api/Role/AddRole',
    DELETE_PERFIL: '/api/Role/DeletePerfil',
    ADD_PERMISSION_TO_ROLE: '/api/Role/AddPermissionToRole',
  },
  PESSOAS: {
    GET_PESSOAS: '/api/Pessoas/GetPessoas',
    GET_PESSOAS_LIST: '/api/Pessoas/GetPessoasList',
    GET_PESSOAS_ALL: '/api/Pessoas/GetPessoasAll',
    SALVAR_PESSOA: '/api/Pessoas/SalvarPessoa',
    IMPORT: 'api/Pessoas/Importar',
  },
  PLANOCONTAS: {
    GET_ANALITICAS_BY_BANCARIA: '/api/Planocontas/GetAnaliticasByBancaria',
    GET_ANALITICAS: '/api/Planocontas/GetAnaliticas',
    GET_SINTETICAS: '/api/Planocontas/GetSinteticas',
    EXCLUIR_SINTETICA: '/api/Planocontas/ExcluirSintetica',
    EXCLUIR_ANALITICA: '/api/Planocontas/ExcluirAnalitica',
    EXCLUIR_ANALITICAS: '/api/Planocontas/ExcluirAnaliticas',
    MOVE_SINTETICA: '/api/Planocontas/MoveSintetica',
    MOVE_ANALITICA: '/api/Planocontas/MoveAnalitica',
    UPDATE_SINTETICA: '/api/Planocontas/UpdateSintetica',
    UPDATE_ANALITICA: '/api/Planocontas/UpdateAnalitica',
    ADD_ANALITICA: '/api/Planocontas/AddAnalitica',
    ADD_SINTETICA: '/api/Planocontas/AddSintetica',
  },
  PRODUTOS: {
    EDIT_GRUPO: '/api/Produtos/EditGrupo',
    EDIT_SUBGRUPO: '/api/Produtos/EditSubGrupo',
    EXCLUIR_SUBGRUPO: '/api/Produtos/ExcluirSubGrupo',
    EXCLUIR_GRUPO: '/api/Produtos/ExcluirGrupo',
    ADD_GRUPO: '/api/Produtos/AddGrupo',
    ADD_SUBGRUPO: '/api/Produtos/AddSubgrupo',
    GET_GRUPOS: '/api/Produtos/GetGrupos',
    GET_PRODUTOS: '/api/Produtos/GetProdutos',
    GET_PRODUTOS_ALL: '/api/Produtos/GetProdutosAll',
    EXCLUIR_PRODUTOS: '/api/Produtos/ExcluirProdutos',
    GET_SALDOS_POR_CONTA: '/api/Produtos/GetSaldosPorConta',
    SALVAR_PRODUTO: '/api/Produtos/SalvarProduto',
  },
  ACCOUNT: {
    GET_USUARIOS_CONTEXTO: 'api/Account/GetUsuariosContexto',
    FIND_USER: '/api/Account/FindUser',
    CREATE_USER_BY_ADM: '/api/Account/CreateUserByAdm',
    REMOVE_USER_CONTEXTO_ADM: '/api/Account/RemoveUserContextoAdm',
    CHANGE_PASSWORD: 'api/Account/ChangePassword',
    GET_USER_DATA: 'api/Account/GetUserData',
    GET_USER_PERFIL: 'api/Account/GetUserPerfil',
    GET_PLANOS: 'api/Account/GetPlanos',
    SET_SUBSCRIPTION: '/api/Account/SetSubscription',
    LOGADO: 'api/Account/Logado',
    RESEND_CONFIRM_EMAIL: 'api/Account/ResendConfirmEmail',
    LOGOFF: 'api/Account/LogOff',
    SIGNUP: 'api/Account/SignUp',
    RESET_PASSWORD: '/api/Account/ResetPassword',
    FORGOT_PASSWORD: '/api/Account/ForgotPassword',
    CONFIRM_EMAIL: `/api/Account/ConfirmEmail`,
    REGISTER_CONTEXTO: 'api/Account/RegisterContexto',
    PICK_CONTEXT: '/api/Account/PickContexto',
    RESET_CONTEXTO: '/api/Account/ResetContexto',
    UPDATE_USER_INFO: '/api/Account/UpdateUserInfo',
  },
  USUARIOS: {
    DELETE_USER: '/api/Usuarios/DeleteUser',
    ADD_USER_TO_ROLE: '/api/Role/AddUserToRole',
  },
  SISTEMA: {
    GET_CONTAS: '/api/Sistema/GetContas',
    GET_PLANOS: '/api/Sistema/GetPlanos',
    GET_AVISOS: '/api/Sistema/GetAvisos',
    ACTIVATE_PLANO: '/api/Sistema/ActivatePlano',
    CRIAR_AVISO: '/api/Avisos/CriarAviso',
    SALVAR_PLANO: '/api/Sistema/SalvarPlano',
  },
};
export const externalAPIs = {
  RECEITAWS: 'https://www.receitaws.com.br/v1/cnpj/',
  VIACEP: 'https://viacep.com.br/ws/',
};
