import axios from '@config/axios';
import { message, Modal } from 'antd';
import { AppDispatch } from '@config/reduxStore';
import { LancamentoLoad } from '@views/EmpresaContext/Lancar/LancarViewNovo';
import { apiEndpoints } from '@config/apiEndpoints';
const { confirm } = Modal;

export const GetProdutosBaixa = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PRODUTOS_BAIXA_LANCAR',
      payload: axios.get(apiEndpoints.LANCAR.GET_PRODUTOS_BAIXA),
    });
  };
};
export const GetProdutos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PRODUTOS_LANCAR',
      payload: axios.get(apiEndpoints.LANCAR.GET_PRODUTOS),
    });
  };
};
export const SetSelectedTitulos = function (titulos: any[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_SELECTED_TITULOS_LANCAR',
      payload: titulos,
    });
  };
};
export const RemoveTitulos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'REMOVE_TITULOS_LANCAR',
    });
  };
};
export const RemoveTitulo = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'REMOVE_TITULO_LANCAR',
      payload: value,
    });
  };
};

export const ResetTela = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'RESET_TELA_LANCAR',
    });
  };
};

export const GetContasAnaliticas = function (unidadeid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_ANALITICAS',
      payload: axios.get(apiEndpoints.LANCAR.GET_CONTAS_TREE, { params: { unidadeID: unidadeid } }),
    });
  };
};

export const SetTotal0 = function (valor: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_TOTAL_0',
      payload: valor,
    });
  };
};
export const SetTotal1 = function (valor: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_TOTAL_1',
      payload: valor,
    });
  };
};
export const ShowHideTitulos = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_HIDE_TITULOS_LANCAR',
      payload: value,
    });
    if (value) dispatch(GetTitulos());
  };
};
export const GetTitulos = function (tipo?: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_TITULOS_LANCAR',
      payload: axios.get(apiEndpoints.LANCAR.GET_TITULOS, {
        params: { tipo: tipo },
      }),
    });
  };
};
export const LoadTela = function (data: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_TELA',
      payload: axios.post(apiEndpoints.IMPORTAR.LOAD_TELA, data),
    });
  };
};
export const LoadBloco = function (bloco: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_BLOCO_LANCAR',
      payload: axios.get(apiEndpoints.LANCAR.LOAD_BLOCO, {
        params: { bloco: bloco },
      }),
    });
  };
};
export const Salvar = function (values: LancamentoLoad) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_LANCAMENTO_LANCAR_PENDING',
    });
    axios
      .post(apiEndpoints.LANCAR.LANCAR, {
        ...values,
      })
      .then((res) => {
        if (res.data > 0) {
          message.success('Transação salva com sucesso');
          if (!values.bloco) {
            confirm({
              title: 'Deseja manter lançamento?',
              content: 'Recomeçar lançamento ou aproveitar as informações do lançamento atual para o próximo.',
              okText: 'Recomeçar',
              cancelText: 'Manter Informações',
              onOk() {
                dispatch(ResetTela());
              },
              onCancel() {
                dispatch(RemoveTitulos());
              },
            });
          } else {
            confirm({
              title: 'Lançamento atualizado',
              content: 'Fechar a tela ou realizar outro lançamento?',
              okText: 'Recomeçar',
              cancelText: 'Fechar',
              onOk() {
                window.location.href = '/Lancar';
              },
              onCancel() {
                window.open('', '_parent', '');
                window.close();
              },
            });
          }
        }
        dispatch({
          type: 'SALVAR_LANCAMENTO_LANCAR_FULFILLED',
        });
      });
  };
};
