import React, { ReactNode } from 'react';
import { useAppSelector } from '@config/reduxStore';

export const Permission = ({ children, permission }: { children: ReactNode; permission: string }) => {
  const { user } = useAppSelector((state) => state.user);
  if (user.permissions.find((c) => c === permission)) return <>{children}</>;
  else return <></>;
};

export const HasPermission = (permission: string) => {
  const { user } = useAppSelector((state) => state.user);
  if (user.permissions.find((c) => c === permission)) return true;
  else return false;
};

interface PermissionContextProps {
  HasPermission: (permission: string) => boolean;
  HasRole: (role: string) => boolean;
}

const PermissionContext = React.createContext<PermissionContextProps | null>(null);

const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAppSelector((state) => state.user);
  const HasPermission = (permission = '') => {
    if (user.permissions.some((c) => c === permission)) {
      return true;
    } else return false;
  };
  const HasRole = (role: string = '') => {
    if (user.roles.some((c) => c === role)) {
      return true;
    } else return false;
  };
  return <PermissionContext.Provider value={{ HasPermission, HasRole }}>{children}</PermissionContext.Provider>;
};

const usePermission = () => {
  const context = React.useContext(PermissionContext);
  if (context === null) throw new Error('usePermission must be wrapped by context provider');
  return { HasPermission: context.HasPermission, HasRole: context.HasRole };
};
export { PermissionProvider, usePermission };
