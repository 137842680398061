import { GetTitulos } from '@actions/lancarActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Lancamento } from '@models/Lancamento';
import { naturalSorter, orderDate } from '@utils/filterandsort';
import { formatNumber } from '@utils/masks';
import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { SetTitulos } from '@actions/conciliarActions';

interface ConciliarTitulosProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  /**
   * 0 baixar pagamento, 1 baixar recebimento
   */
  tipo: 0 | 1;
  id: string;
}
function ConciliarTitulos({ visible, setVisible, tipo, id }: ConciliarTitulosProps) {
  const { loadingtitulos, titulos } = useAppSelector((state) => state.lancar);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (visible) dispatch(GetTitulos(tipo));
  }, [visible]);
  return (
    <Modal
      open={visible}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 30 }}>
          <div>Procurar Título</div>
          <div>
            <Button
              loading={loadingtitulos}
              size="small"
              icon={<SyncOutlined />}
              onClick={() => dispatch(GetTitulos(tipo))}
            />
          </div>
        </div>
      }
      okButtonProps={{ disabled: selectedRows.length === 0 }}
      onOk={() => {
        dispatch(SetTitulos({ titulos: selectedRows, id }));
        setVisible(false);
        setSelectedRows([]);
      }}
      onCancel={() => setVisible(false)}
      width="90%"
    >
      <Table
        loading={loadingtitulos}
        dataSource={titulos}
        size="small"
        rowKey="id"
        scroll={{ x: 1000, y: 500 }}
        rowSelection={{
          selectedRowKeys: selectedRows.map((c) => c.id),
          onChange(_, selectedRows) {
            setSelectedRows(selectedRows);
          },
        }}
      >
        <Table.Column
          title="Bloco"
          dataIndex="bloco"
          sorter={(a: Lancamento, b) => naturalSorter(a.bloco, b.bloco)}
          render={(v) => (
            <Link to={`/Lancar?bloco=${v}`} target="_blank">
              {v}
            </Link>
          )}
        />
        <Table.Column
          title="Unidade"
          dataIndex="unidade"
          sorter={(a: Lancamento, b) => naturalSorter(a.unidade, b.unidade)}
        />
        <Table.Column
          title="Vencimento"
          dataIndex="dataVencimento"
          sorter={(a: Lancamento, b) => orderDate(a.dataVencimento, b.dataVencimento)}
          render={(v) => moment(v).format('DD/MM/YYYY')}
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricaoBloco"
          sorter={(a: Lancamento, b) => naturalSorter(a.descricaoBloco, b.descricaoBloco)}
        />
        <Table.Column
          title="Documento"
          dataIndex="documento"
          sorter={(a: Lancamento, b) => naturalSorter(a.documento, b.documento)}
        />
        <Table.Column
          title="Pessoa"
          dataIndex="pessoa"
          sorter={(a: Lancamento, b) => naturalSorter(a.pessoa as string, b.pessoa as string)}
        />
        <Table.Column
          title="Parcela"
          dataIndex="parcela"
          sorter={(a: Lancamento, b) => naturalSorter(a.parcela, b.parcela)}
        />
        <Table.Column
          title="Valor"
          dataIndex="valor"
          align="right"
          sorter={(a: Lancamento, b) => a.valor - b.valor}
          render={(v) => formatNumber(v)}
        />
      </Table>
    </Modal>
  );
}
export default ConciliarTitulos;
