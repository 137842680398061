import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { LancamentosReducer } from '@reducers/reducers';

var initialState: LancamentosReducer = {
  waiting: false,
  unidades: [],
  validate: '',
  competencia: null,
  selectedpessoas: [],
  transacaoinicio: null,
  transacaofim: null,
  blocoid: null,
  descricao: null,
  valor: null,
  lancamentos: [],
  selectedlancamentos: [],
  exportlancamentos: [],
  exportlancamentosview: false,
  exportinglancamentos: false,
  loadingextendrow: false,
  lancamentosexpand: [],
  formview: false,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  sorter: {
    field: null,
    order: null,
  },
  lancamentosFiltro: {
    pagination: {
      current: 1,
      pageSize: 10,
      total: 10,
    },
    filters: null,
    sorter: {
      field: null,
      order: null,
    },
    form: null,
  },
  lancamentosresultado: [],
  confirmdeletemodal: false,
};

const lancamentosReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'HIDE_DOWNLOAD_EXPORT':
      state = {
        ...state,
        exportinglancamentos: false,
        exportlancamentos: [],
        exportlancamentosview: false,
      };
      break;
    case 'SET_LANCAMENTOS_FILTRO':
      state = {
        ...state,
        lancamentosFiltro: {
          ...state.lancamentosFiltro,
          ...action.payload,
        },
      };
      break;
    case 'VIEW_FORM_LANCAMENTOS':
      state = { ...state, formview: !state.formview };
      break;
    case 'SET_LANCAMENTOS_FILTRO_FORM':
      state = {
        ...state,
        lancamentosFiltro: {
          ...state.lancamentosFiltro,
          form: action.payload,
          pagination: {
            ...state.lancamentosFiltro.pagination,
            current: 1,
          },
        },
      };
      break;
    case 'GET_LANCAMENTOS_EXPORT_PENDING':
      state = {
        ...state,
        exportinglancamentos: true,
        exportlancamentos: [],
      };
      break;
    case 'GET_LANCAMENTOS_EXPORT_REJECTED':
      notification.error({ message: action.payload });
      state = {
        ...state,
        exportinglancamentos: false,
        exportlancamentos: [],
      };
      break;
    case 'GET_LANCAMENTOS_EXPORT_FULFILLED':
      state = {
        ...state,
        exportinglancamentos: false,
      };
      break;
    case 'EXCLUIR_LANCAMENTOS':
      state = {
        ...state,
        lancamentos: [...state.lancamentos.filter((c) => !action.payload.includes(c.bloco))],
      };
      break;
    case 'CLOSE_MODAL_CONFIRM_DELETE_LANCAMENTOS':
      state = {
        ...state,
        waiting: false,
        confirmdeletemodal: false,
        selectedlancamentos: [],
      };
      break;
    case 'CONFIRM_DELETE_LANCAMENTOS_LANCAMENTOS':
      state = {
        ...state,
        waiting: false,
        lancamentosresultado: action.payload,
        confirmdeletemodal: true,
      };
      break;
    case 'EXCLUIR_LANCAMENTO_LANCAMENTOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'EXCLUIR_LANCAMENTO_LANCAMENTOS_REJECTED':
      notification.error({ message: 'Erro ao excluir lançamentos' });
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'EXCLUIR_LANCAMENTO_LANCAMENTOS_FULFILLED':
      state = {
        ...state,
        waiting: false,
        lancamentos: state.lancamentos.filter((c) => !action.payload.data.includes(c.id)),
        lancamentosFiltro: {
          ...state.lancamentosFiltro,
          pagination: {
            ...state.lancamentosFiltro.pagination,
            current: 1,
            total: state.lancamentos.filter((c) => !action.payload.data.includes(c.id)).length,
          },
        },
        selectedlancamentos: [],
      };
      break;
    case 'SET_PAGINATION_LANCAMENTOS':
      state = {
        ...state,
        sorter: action.payload.sorter,
      };
      break;
    case 'RESET_LANCAMENTOS':
      state = initialState;
      break;
    case 'SET_UNIDADE_ONLY':
      state.unidades.push(action.payload);
      break;
    case 'SET_UNIDADES_LANCAMENTOS':
      state = {
        ...state,
        unidades: action.payload,
      };
      break;
    case 'SET_COMPETENCIA_LANCAMENTOS':
      state = {
        ...state,
        competencia: action.payload,
      };
      break;
    case 'SET_VALOR_FILTER_LANCAMENTOS':
      state = {
        ...state,
        valor: action.payload,
      };
      break;
    case 'SET_FILTER_PESSOAS_LANCAMENTOS':
      state = {
        ...state,
        selectedpessoas: action.payload,
      };
      break;
    case 'SET_TRANSACAO_LANCAMENTOS':
      if (action.payload.tipo === 0) {
        state = {
          ...state,
          transacaoinicio: action.payload.data,
        };
      } else {
        state = {
          ...state,
          transacaofim: action.payload.data,
        };
      }
      break;
    case 'SET_BLOCO_LANCAMENTOS':
      state = {
        ...state,
        blocoid: action.payload === '' ? null : action.payload,
      };
      break;
    case 'SET_DESCRICAO_LANCAMENTOS':
      state = {
        ...state,
        descricao: action.payload === '' ? null : action.payload,
      };
      break;
    case 'CHECK_FORMAT_BLOCO_LANCAMENTOS':
      var format = /^([A-z]{3})(\d+)?\b/g;
      var result = format.test(action.payload);
      state = {
        ...state,
        validate: action.payload !== '' ? (result ? '' : 'Formato de bloco incorreto. Ex.: ABC1234567890') : '',
      };
      break;
    case 'SELECTED_LANCAMENTOS_LANCAMENTOS':
      state = {
        ...state,
        selectedlancamentos: action.payload,
      };
      break;
    case 'MOSTRAR_LANCAMENTOS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'MOSTRAR_LANCAMENTOS_REJECTED':
      notification.error({ message: 'Erro ao buscar lançamentos!' });
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'MOSTRAR_LANCAMENTOS_FULFILLED':
      state = {
        ...state,
        waiting: false,
        lancamentos: action.payload.data.lancamentos,
        lancamentosFiltro: {
          ...state.lancamentosFiltro,
          pagination: {
            ...state.lancamentosFiltro.pagination,
            total: action.payload.data.total,
          },
        },
      };
      break;
    case 'GET_LANCAMENTOS_EXPAND_LANCAMENTOS_PENDING':
      state = {
        ...state,
        loadingextendrow: true,
      };
      break;
    case 'GET_LANCAMENTOS_EXPAND_LANCAMENTOS_FULFILLED':
      state.lancamentosexpand.push(...action.payload.data);

      state = {
        ...state,

        loadingextendrow: false,
      };
      break;
    case 'POP_LANCAMENTOS_EXPAND_LANCAMENTOS':
      state.lancamentosexpand = state.lancamentosexpand.filter((item) => item.bloco !== action.payload);
      state = {
        ...state,
        loadingextendrow: false,
      };
      break;
    default:
      return state;
  }
  if (action.type.includes('REJECTED')) {
    state = {
      ...state,
      loadingextendrow: false,
      waiting: false,
    };
  }
  return state;
};

export default lancamentosReducer;
