import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { InstagramOutlined } from '@ant-design/icons';

function Footer() {
  return (
    <div className="mainfooter">
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 1 300px' }}>
          <img src={logo} alt="logo" width={240} />
          <span style={{ fontWeight: 'bold' }}>Acesse nossas redes sociais</span>
          <a
            href="https://www.instagram.com/nambigestao/"
            target="_blank"
            style={{ textDecoration: 'none', alignItems: 'center', display: 'flex' }}
          >
            <InstagramOutlined style={{ fontSize: 25 }} />
            <span>@nambigestao</span>
          </a>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 1 300px' }}>
          <span style={{ fontWeight: 'bold' }}>Sobre</span>
          <span>
            <Link to="About">Quem Somos</Link>
          </span>
          <span>
            <Link to="Contato">Contato</Link>
          </span>
          <span>
            <Link to="Termos">Termos de Uso</Link>
          </span>
          <span>
            <Link to="PrivacyPolicy">Política de Privacidade</Link>
          </span>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1 1 300px' }}>
          <span style={{ fontWeight: 'bold' }}>Para a sua empresa</span>
          <span>Financeiro</span>
          <span>Estoque</span>
          <span>Balanço</span>
          <span>DRE</span>
          <span>Extratos</span>
          <span>Fluxo de Caixa</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
