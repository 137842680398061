import { useEffect } from 'react';
import { Table, Card } from 'antd';
import { formatNumber } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ContaBancaria } from '@models/ContaBancaria';
import { GetBancarias2 } from '@actions/bancariasActions';
import { naturalSorter } from '@utils/filterandsort';
const { Column } = Table;

const Lancamentos = ({ data }: { data: ContaBancaria[] }) => (
  <Table
    scroll={{ y: 210 }}
    dataSource={data}
    size="small"
    rowKey="id"
    pagination={{
      position: ['bottomRight'],
      size: 'small',
      showSizeChanger: true,
      pageSizeOptions: [10, 25, 50],
      showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
    }}
  >
    <Column
      title="Banco"
      dataIndex="banco"
      sorter={(a: ContaBancaria, b: ContaBancaria) => naturalSorter(a.banco, b.banco)}
    />
    <Column
      title="Agência"
      dataIndex="agencia"
      sorter={(a: ContaBancaria, b: ContaBancaria) => naturalSorter(a.agencia, b.agencia)}
    />
    <Column
      title="Conta"
      dataIndex="conta"
      sorter={(a: ContaBancaria, b: ContaBancaria) => naturalSorter(a.conta, b.conta)}
    />
    <Column
      title="Saldo"
      align="right"
      dataIndex="saldo"
      render={(text) => formatNumber(text)}
      width={120}
      sorter={(a: ContaBancaria, b: ContaBancaria) => a.saldo - b.saldo}
    />
  </Table>
);

function Disponibilidade() {
  const dispatch = useAppDispatch();
  const { loadingconta, bancarias } = useAppSelector((state) => state.bancarias);
  useEffect(() => {
    dispatch(GetBancarias2());
  }, []);
  return (
    <Card
      size="small"
      loading={loadingconta}
      title="Disponibilidade de Caixa"
      style={{
        maxWidth: 600,
        maxHeight: 400,
        minHeight: 400,
        minWidth: 600,
      }}
    >
      <Lancamentos data={bancarias} />
    </Card>
  );
}

export default Disponibilidade;
