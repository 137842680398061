import { useEffect } from 'react';
import { Modal, Button, Input, Form, Select, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Produto } from '@models/Produto';
import { ProdutosControllerInterface } from '@controllers/controllers';

function FormProduto({ controller }: { controller: ProdutosControllerInterface }) {
  const { data, actions } = controller();
  const [form] = Form.useForm<Produto>();
  useEffect(() => {
    if (!!data.produto) {
      const p = data.produtosAll.find((c) => c.id === data.produto);
      form.setFieldsValue({
        ...p,
        grupoID: p?.grupoID,
        subgrupoProdutoID: p?.subgrupoProdutoID,
      });
    } else form.resetFields();
  }, [form, data.produto]);
  return (
    <Modal
      title="Cadastro de Produto"
      open={data.formvisible}
      width="50em"
      onCancel={() => actions.HideForm()}
      footer={[
        <Button type="primary" key="1" onClick={() => form.submit()} loading={data.produtosloading}>
          Salvar
        </Button>,
        <Button key="2" type="default" onClick={() => actions.ShowForm()}>
          Fechar
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={(values) => actions.Salvar(values)}
        layout="horizontal"
        wrapperCol={{ span: 20 }}
        labelCol={{ span: 4 }}
        initialValues={{ Revenda: false }}
      >
        <Form.Item name="id" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="revenda" label="Revenda" valuePropName="checked">
          <Checkbox>Sim</Checkbox>
        </Form.Item>
        <Form.Item name="unidade" label="Unidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item name="grupoID" label="Grupo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input.Group compact>
                <Select
                  style={{ width: '90%' }}
                  placeholder="Grupo"
                  showSearch
                  value={getFieldValue('grupoID')}
                  optionFilterProp="label"
                  onChange={(value) => setFieldsValue({ subgrupoProdutoID: null, grupoID: value })}
                >
                  {data.grupos.map((c, index) => (
                    <Select.Option value={c.id} label={c.descricao} key={index}>
                      {c.descricao}
                    </Select.Option>
                  ))}
                </Select>
                <Button style={{ width: '10%' }} onClick={() => actions.NovoGrupo()} icon={<PlusOutlined />} />
              </Input.Group>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="subgrupoProdutoID"
              label="Subgrupo"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.Group compact>
                <Select
                  disabled={!getFieldValue('grupoID')}
                  style={{ width: '90%' }}
                  placeholder="Subrupo"
                  showSearch
                  value={getFieldValue('subgrupoProdutoID')}
                  optionFilterProp="label"
                  onChange={(value) => setFieldsValue({ subgrupoProdutoID: value })}
                >
                  {data.grupos.find((c) => c.id === getFieldValue('grupoID')) &&
                    data.grupos
                      .find((c) => c.id === getFieldValue('grupoID'))
                      ?.subgrupoProdutos.map((c, index) => (
                        <Select.Option value={c.id} label={c.descricao} key={index}>
                          {c.descricao}
                        </Select.Option>
                      ))}
                </Select>
                <Button
                  disabled={!getFieldValue('grupoID')}
                  style={{ width: '10%' }}
                  onClick={() => {
                    actions.SetSubGrupoGrupoID(getFieldValue('grupoID'));
                    actions.NovoSubGrupo();
                  }}
                  icon={<PlusOutlined />}
                />
              </Input.Group>
            </Form.Item>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormProduto;
