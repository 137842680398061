import { Select, Button, DatePicker, Input, Space } from 'antd';
import moment from 'moment';
import { SelectUnidades, SetCompetencia } from '@actions/fluxocaixaActions';
import { DoubleRightOutlined, DoubleLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Permission } from '@hooks/usePermissions';
import { GetDRE } from '@actions/dreActions';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { MonthPicker } = DatePicker;

function Unidades() {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.general);
  const fluxo = useAppSelector((state) => state.fluxocaixa);
  useEffect(() => {
    if (general.unidades.length === 1) {
      dispatch(SelectUnidades([general.unidades[0].id]));
    }
  }, [general.unidades]);
  const handleSelectAll = (value: any[]) => {
    if (value && value.length && value.filter((u) => u === 'all').length) {
      if (value.length === general.unidades.map((u) => u.id).length + 1) {
        return [];
      }
      return [...general.unidades.map((u) => u.id)];
    }
    return value;
  };
  var anos = [];
  anos.length = 20;
  return (
    <Space direction="horizontal">
      <Input.Group compact>
        <Select
          size="small"
          mode="multiple"
          maxTagCount={1}
          disabled={general.unidades.length === 1}
          optionFilterProp="label"
          style={{ maxWidth: 300, minWidth: 300 }}
          placeholder="Unidades de Negócio"
          value={fluxo.unidades}
          onChange={(value) => {
            dispatch(SelectUnidades(handleSelectAll(value)));
          }}
        >
          {general.unidades.length > 1 && (
            <Select.Option label="todos" key="all">
              TODOS
            </Select.Option>
          )}
          {general.unidades.map((unidade) => (
            <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
              {unidade.nome}
            </Select.Option>
          ))}
        </Select>
        <Button
          size="small"
          type="default"
          icon={<DoubleLeftOutlined />}
          onClick={() => {
            dispatch(SetCompetencia(moment(fluxo?.competencia).add(-1, 'M')));
          }}
        />
        <MonthPicker
          value={fluxo?.competencia}
          allowClear={false}
          style={{ width: 85 }}
          format="MM/YYYY"
          size="small"
          onChange={(v) => {
            dispatch(SetCompetencia(v));
          }}
        />

        <Button
          size="small"
          type="default"
          icon={<DoubleRightOutlined />}
          onClick={() => {
            dispatch(SetCompetencia(moment(fluxo?.competencia).add(1, 'M')));
          }}
        />
      </Input.Group>
      <Permission permission="fluxocaixa.add">
        <Link
          to={`/PrintDRE?Unidades=${JSON.stringify(fluxo.unidades)}&Competencia=${moment(fluxo.competencia).format(
            'YYYY-MM'
          )}`}
          target="_blank"
        >
          <Button size="small" icon={<PrinterOutlined />} disabled={fluxo.unidades.length === 0}>
            Imprimir
          </Button>
        </Link>
        <Button
          key="2"
          size="small"
          style={{ float: 'right' }}
          loading={fluxo.progress >= 1 && fluxo.progress !== 100}
          type="primary"
          disabled={fluxo.unidades.length === 0}
          onClick={() => dispatch(GetDRE(fluxo.unidades, moment(fluxo.competencia)))}
        >
          Gerar DRE
        </Button>
      </Permission>
    </Space>
  );
}

export default Unidades;
