import { useEffect } from 'react';
import { Button, Table } from 'antd';
import './orcamento.css';
import { formatNumber } from '@utils/masks';
import { Link, useParams } from 'react-router-dom';
import { LoadOrcamentoView } from '@actions/orcamentosActions';
import { SetPageHeader } from '@actions/userActions';
import { PrinterOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { OrcamentoConta, OrcamentoMensal } from '@models/Orcamento';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import moment from 'moment';
import { TipoOrcamento } from '@constants/models';
import { OrcamentoView } from '@reducers/reducers';

const Titulo = ({ orcamento }: { orcamento: OrcamentoView<OrcamentoConta | OrcamentoMensal> }) => (
  <h3>
    {orcamento.descricao} - ({orcamento.unidade}) -{' '}
    {orcamento.tipoOrcamento === TipoOrcamento.Global ? 'Global' : 'Mensal'}
    {orcamento.ano === null ? '' : ' - ' + moment(orcamento.ano).format('YYYY')}
  </h3>
);
const colunasGlobal: ColumnsType<OrcamentoConta> = [
  {
    title: 'Código',
    dataIndex: 'cod',
  },
  { title: 'Descrição', dataIndex: 'contaAnalitica' },
  { title: 'Realizado', dataIndex: 'realizado', render: (text) => formatNumber(text), align: 'right' },
  { title: 'Orçado', dataIndex: 'valor', render: (text) => formatNumber(text), align: 'right' },
  { title: 'Saldo', dataIndex: 'saldo', render: (text) => formatNumber(text), align: 'right' },
];

const colunasMensal: ColumnsType<OrcamentoMensal> = [
  { title: 'Código', dataIndex: 'cod', fixed: 'left', width: 100 },
  { title: 'Descrição', dataIndex: 'contaAnalitica', fixed: 'left', width: 250 },
  {
    title: '',
    dataIndex: '',
    fixed: 'left',
    width: 120,
    align: 'right',
    render: () => (
      <div>
        <div>REALIZADO</div>
        <div>ORÇADO</div>
        <div>SALDO</div>
      </div>
    ),
  },
  {
    title: 'JAN',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado01)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo01)}</div>
      </div>
    ),
  },
  {
    title: 'FEV',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado02)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo02)}</div>
      </div>
    ),
  },
  {
    title: 'MAR',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado03)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo03)}</div>
      </div>
    ),
  },
  {
    title: 'ABR',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado04)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo04)}</div>
      </div>
    ),
  },
  {
    title: 'MAI',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado05)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo05)}</div>
      </div>
    ),
  },
  {
    title: 'JUN',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado06)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo06)}</div>
      </div>
    ),
  },
  {
    title: 'JUL',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado07)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo07)}</div>
      </div>
    ),
  },
  {
    title: 'AGO',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado08)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo08)}</div>
      </div>
    ),
  },
  {
    title: 'SET',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado09)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo09)}</div>
      </div>
    ),
  },
  {
    title: 'OUT',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado10)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo10)}</div>
      </div>
    ),
  },
  {
    title: 'NOV',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado11)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo11)}</div>
      </div>
    ),
  },
  {
    title: 'DEZ',
    align: 'right',
    width: 120,
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado12)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo12)}</div>
      </div>
    ),
  },
  {
    title: 'TOTAL',
    align: 'right',
    width: 120,
    fixed: 'right',
    render: (_, record) => (
      <div>
        <div>{formatNumber(record.realizado13)}</div>
        <div>{formatNumber(record.valor)}</div>
        <div>{formatNumber(record.saldo13)}</div>
      </div>
    ),
  },
];
function Global() {
  const orcamentos = useAppSelector((state) => state.orcamentos);
  return (
    <Table
      dataSource={orcamentos.orcamentoview.orcamentoConta as OrcamentoConta[]}
      pagination={false}
      loading={orcamentos.waiting}
      rowKey="id"
      size="small"
      scroll={{ y: 'calc(100vh - 190px)', x: 1000 }}
      rowClassName={(record) => (record.tipo === 'S' ? 'S' : record.tipo === 'A' ? 'A' : 'TM')}
      tableLayout="fixed"
      columns={colunasGlobal}
    />
  );
}
function Mensal() {
  const orcamentos = useAppSelector((state) => state.orcamentos);
  return (
    <Table
      dataSource={orcamentos.orcamentoview.orcamentoConta as OrcamentoMensal[]}
      pagination={false}
      loading={orcamentos.waiting}
      rowKey="id"
      size="small"
      scroll={{ y: 'calc(100vh - 190px)' }}
      rowClassName={(record) => (record.tipo === 'S' ? 'S' : record.tipo === 'A' ? 'A' : 'TM')}
      tableLayout="fixed"
      columns={colunasMensal}
    />
  );
}

function OrcamentoTabela() {
  const orcamentos = useAppSelector((state) => state.orcamentos);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (Number(id)) dispatch(LoadOrcamentoView(Number(id)));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Orçamento',
        subTitle: <Titulo orcamento={orcamentos.orcamentoview} />,
        extras: [
          <Link key={0} to={`/PrintOrcamento/${id}`} target="_blank">
            <Button size="small" icon={<PrinterOutlined />}>
              Imprimir
            </Button>
          </Link>,
        ],
      })
    );
  }, []);
  return (
    <div style={{ padding: 5 }}>
      {orcamentos.orcamentoview.tipoOrcamento === TipoOrcamento.Global && <Global />}
      {orcamentos.orcamentoview.tipoOrcamento === TipoOrcamento.Mensal && <Mensal />}
      {orcamentos.orcamentoview.tipoOrcamento === null && <h1>Nulo</h1>}
    </div>
  );
}

export default OrcamentoTabela;
