import moment from 'moment';
import { Table, Button, Popconfirm, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SelectedLancamentos, ExcluirLancamentoExtrato, ShowContasMover } from '@actions/extratoActions';
import { formatNumber } from '@utils/masks';
import JanelaContasMover from './JanelaContasMover';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Lancamento } from '@models/Lancamento';
import { TableRowSelection } from 'antd/lib/table/interface';

const TabelaBalanco = () => {
  const dispatch = useAppDispatch();
  const extrato = useAppSelector((state) => state.extrato);

  const columns: ColumnsType<Lancamento> = [
    {
      align: 'center',
      width: '30px',
      render: (_, record, index) =>
        index > 0 ? (
          <Tooltip title="Editar">
            <Link to={`/Lancar?bloco=${record.userCod + record.bid}`}>
              <Button icon={<EditOutlined />} size="small" />
            </Link>
          </Tooltip>
        ) : null,
    },
    {
      title: 'Bloco',
      dataIndex: 'bid',
      width: '6%',
      render: (_, record) => record.userCod + record.bid,
    },
    {
      title: 'Data Transação',
      dataIndex: 'dataTransacao',
      width: '10%',
      render: (v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricaoBloco',
      width: '10%',
    },
    {
      title: 'Unidade',
      dataIndex: 'unidade',
    },
    {
      title: 'Conta',
      dataIndex: 'analitica',
      render: (text, row) => (row.bancaria ? text + ' - ' + row.bancaria : text),
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
    },
    {
      title: 'Parcela',
      dataIndex: 'parcela',
      ellipsis: true,
    },
    {
      title: 'Pessoa',
      dataIndex: 'pessoa',
      width: '8%',
      ellipsis: true,
    },
    {
      title: 'Crédito',
      dataIndex: 'credito',
      className: 'money',
      align: 'right',
      render: (text) => formatNumber(text),
    },
    {
      title: 'Débito',
      dataIndex: 'debito',
      className: 'money',
      align: 'right',
      render: (text) => formatNumber(text),
    },
    {
      title: 'Saldo',
      dataIndex: 'saldo',
      className: 'money',
      align: 'right',
      render: (text) => formatNumber(text),
    },
  ];
  const rowSelection: TableRowSelection<Lancamento> = {
    onChange: (selectedRowKeys) => {
      dispatch(SelectedLancamentos(selectedRowKeys as number[]));
    },
    getCheckboxProps: (record) => ({
      disabled: record.id === '-1',
    }),
    selectedRowKeys: extrato.selectedlancamentos,
  };
  function confirm() {
    dispatch(
      ExcluirLancamentoExtrato(
        extrato.selectedlancamentos,
        false,
        extrato.contaid,
        extrato.nivel,
        moment(extrato.periodoinicio),
        moment(extrato.periodofim),
        extrato.selectedpessoas,
        extrato.unidades,
        extrato.bancaria,
        extrato.tipoconta
      )
    );
  }
  const hasSelected = extrato.selectedlancamentos.length > 0;
  return (
    <div>
      <Popconfirm
        disabled={!hasSelected}
        title="Tem certeza que deseja excluir?"
        onConfirm={() => confirm()}
        okText="Sim"
        cancelText="Não"
      >
        <Button size="small" type="primary" disabled={!hasSelected}>
          Apagar
        </Button>
      </Popconfirm>
      <Button
        size="small"
        type="primary"
        style={{ margin: 4 }}
        disabled={!hasSelected}
        onClick={() => dispatch(ShowContasMover(true, extrato.unidademover))}
      >
        Mover
      </Button>
      <span style={{ marginLeft: 8 }}>{hasSelected ? `${extrato.selectedlancamentos.length} selecionados` : ''}</span>
      <Table
        bordered
        tableLayout="fixed"
        size="small"
        scroll={{ y: 'calc(100vh - 260px)', x: 1000 }}
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        pagination={false}
        loading={extrato.waiting}
        dataSource={extrato.extrato}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={10} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {formatNumber(data.reduce((a, b) => a + b.credito, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {formatNumber(data.reduce((a, b) => a + b.debito, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {formatNumber(extrato.saldototal)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <JanelaContasMover />
    </div>
  );
};

export default TabelaBalanco;
