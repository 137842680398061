import { Typography, Row, Col, Input, Button, Form, Card } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import logo from '../../assets/img/contact.jpg';
import { phoneMobMask } from '@utils/masks';

const { Title, Paragraph } = Typography;
const ContactForm = () => {
  return (
    <Row justify="center">
      <Card style={{ width: '70em' }} cover={<img alt="example" src={logo} />}>
        <Form labelCol={{ span: 6 }} onFinish={() => null}>
          <Row justify="center" style={{ padding: '20px 20px 20px 20px' }}>
            <Typography color="textSecondary">
              Envie sua mensagem com sua dúvida, elogio, crítica ou sugestão e retornaremos em breve.
            </Typography>
            <br />
            <Col span={14} style={{ padding: '0px 20px 20px 20px' }}>
              <Form.Item name="nome" label="Nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { type: 'email', message: 'Email inválido' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="telefone"
                label="Telefone"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                normalize={(v, pv) => phoneMobMask.format(phoneMobMask.normalize(v, pv))}
              >
                <Input />
              </Form.Item>
              <Form.Item name="msg" label="Mensagem" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input.TextArea rows={10} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button type="primary" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Title level={4}>Parnamirim - RN</Title>
              <Paragraph>Av. Olavo Lacerda Montenegro, 5400</Paragraph>
              <Paragraph>
                <PhoneOutlined /> (84) 9 8178-8954
              </Paragraph>
            </Col>
          </Row>
        </Form>
      </Card>
    </Row>
  );
};

export default ContactForm;
