import { Button, DatePicker, Drawer, Form, Select, Space, Typography } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { BalancoControllerInterface } from '@controllers/controllers';

interface FormData {
  unidades: number[];
  competencia: moment.Moment;
  tipo: number;
}
function BalancoForm({ controller }: { controller: BalancoControllerInterface }) {
  const { data, actions } = controller();
  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    if (data.unidades.length === 1) {
      actions.SelectUnidade([data.unidades[0].id]);
      form.setFieldValue('unidades', [data.unidades[0].id]);
    }
  }, [data.unidades]);
  useEffect(() => {
    form.setFieldValue('unidades', data.balanco.unidades);
  }, [data.balanco.unidades, form]);
  return (
    <Drawer open={data.balanco.formview} onClose={() => actions.ShowForm()} title="Gerar Balanço">
      <Form layout="vertical" form={form} onFinish={(v) => actions.Gerar(v.tipo)}>
        <Form.Item
          label="Unidade de Negócio"
          name="unidades"
          rules={[{ required: true, message: 'Cmapo obrigatório' }]}
        >
          <Select
            mode="multiple"
            maxTagCount={5}
            disabled={data.unidades.length === 1}
            optionFilterProp="label"
            placeholder="Unidades de Negócio"
            onChange={(value) => actions.SelectUnidade(value)}
          >
            {data.unidades.length > 1 && (
              <Select.Option label="todos" key="all" value="all">
                TODOS
              </Select.Option>
            )}
            {data.unidades.map((unidade) => (
              <Select.Option label={unidade.nome} key={unidade.id} value={unidade.id}>
                {unidade.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Competência"
          name="competencia"
          initialValue={moment(new Date())}
          rules={[{ required: true, message: 'Cmapo obrigatório' }]}
        >
          <DatePicker.MonthPicker
            format="MM/YYYY"
            placeholder="Competência"
            allowClear={false}
            onChange={(v) => actions.SetCompetencia(v)}
          />
        </Form.Item>
        <Form.Item label="Tipo" initialValue={0} name="tipo" rules={[{ required: true, message: 'Cmapo obrigatório' }]}>
          <Select>
            <Select.Option value={0}>Resumido</Select.Option>
            <Select.Option value={1}>Detalhado</Select.Option>
          </Select>
        </Form.Item>
        <Space direction="vertical">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <div>
                <Button
                  block
                  htmlType="submit"
                  type="primary"
                  loading={data.balanco.waiting}
                  disabled={data.balanco.unidades?.length === 0 || data.balanco.unidades?.length === undefined}
                >
                  Gerar Balanço
                </Button>
                <Link
                  to={`/PrintBalanco?Unidades=${JSON.stringify(data.balanco.unidades)}&Competencia=${moment(
                    getFieldValue('competencia')
                  ).format('YYYY-MM')}&Tipo=${getFieldValue('tipo')}`}
                  target="_blank"
                >
                  <Button
                    key={0}
                    icon={<PrinterOutlined />}
                    block
                    disabled={data.balanco.unidades?.length === 0 || data.balanco.unidades?.length === undefined}
                  >
                    Gerar para Impressão
                  </Button>
                </Link>
              </div>
            )}
          </Form.Item>
          <Typography.Text type="warning">
            {data.balanco.balancoatualizado
              ? 'Lançamentos foram removidos. Gere um novo balanço para obter a versão atualizada.'
              : ''}
          </Typography.Text>
        </Space>
      </Form>
    </Drawer>
  );
}

export default BalancoForm;
