import { useEffect } from 'react';
import { Form } from 'antd';
import TabelaLancamentos from './TabelaLancamentos';
import BuscaLancamentos from './BuscaLancamentos';
import { Permission } from '@hooks/usePermissions';
import { LancamentosController } from '@controllers/LancamentosController';

function LancamentosView() {
  const controller = LancamentosController;
  const { data, actions } = controller();
  const [form] = Form.useForm();
  useEffect(() => {
    if (data.lancamentosFiltro.form) {
      actions.Mostrar();
    }
  }, [
    data.lancamentosFiltro.form,
    data.lancamentosFiltro.pagination.current,
    data.lancamentosFiltro.pagination.pageSize,
    data.lancamentosFiltro.sorter,
    data.lancamentosFiltro.filters,
  ]);
  useEffect(() => {
    if (data.unidades.length === 1) {
      form.setFieldsValue({ unidades: [data.unidades[0].id] });
    }
  }, [data.unidades, form]);
  return (
    <Permission permission="lancamento.list">
      <BuscaLancamentos controller={controller} />
      <TabelaLancamentos controller={controller} />
    </Permission>
  );
}

export default LancamentosView;
