import { useEffect, useState } from 'react';
import { formatNumber } from '@utils/masks';
import { useSearchParams } from 'react-router-dom';
import { GetNumbers } from '@actions/fluxocaixaActions';
import logo from '../../../assets/img/logo.png';
import { Spin } from 'antd';
import { GetUnidades } from '@actions/generalActions';
import '../../../assets/scss/FluxoCaixa.scss';
import ReactDOM from 'react-dom/client';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function PrintFluxo() {
  const dispatch = useAppDispatch();
  const fluxo = useAppSelector((state) => state.fluxocaixa);
  const general = useAppSelector((state) => state.general);
  const [search] = useSearchParams();
  const [data, setData] = useState<{ unidades: number[]; ano: number }>({ unidades: [], ano: 2022 });
  useEffect(() => {
    const unidades = JSON.parse(search.get('unidades'));
    const ano = Number(search.get('ano'));
    setData({ unidades: unidades, ano: ano });
    dispatch(GetNumbers(unidades, ano));
    dispatch(GetUnidades(true, true));
  }, []);
  useEffect(() => {
    if (fluxo.fluxoteste && general.unidades) {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Spin spinning={fluxo.fluxosloading}>
          <div className="fluxoprint">
            <table className="report-container printme" style={{ width: '100%' }}>
              <thead className="report-header">
                <tr>
                  <th>
                    <img src={logo} height={30} className="logo" alt="logo" />
                  </th>
                  <th colSpan={15}>
                    <h4>{`Fluxo de Caixa ${data.ano} - Unidades: ${data.unidades.map(
                      (id) => ' ' + general.unidades.find((c) => c.id === id)?.nome
                    )}`}</h4>
                  </th>
                </tr>
                <tr style={{ fontWeight: 'strong' }}>
                  <th>Código</th>
                  <th>Descrição</th>
                  <th className="valor">Jan</th>
                  <th className="valor">Fev</th>
                  <th className="valor">Mar</th>
                  <th className="valor">Abr</th>
                  <th className="valor">Mai</th>
                  <th className="valor">Jun</th>
                  <th className="valor">Jul</th>
                  <th className="valor">Ago</th>
                  <th className="valor">Set</th>
                  <th className="valor">Out</th>
                  <th className="valor">Nov</th>
                  <th className="valor">Dez</th>
                  <th className="valor">Total</th>
                  <th className="valor">Média</th>
                </tr>
              </thead>
              <tbody className="report-content">
                {fluxo.fluxoteste.map((row, index) => {
                  return (
                    <tr key={index} className={row.tipo}>
                      <td>{row.codigo}</td>
                      <td>{row.descricao}</td>
                      <td className="valor">{formatNumber(row.mes1)}</td>
                      <td className="valor">{formatNumber(row.mes2)}</td>
                      <td className="valor">{formatNumber(row.mes3)}</td>
                      <td className="valor">{formatNumber(row.mes4)}</td>
                      <td className="valor">{formatNumber(row.mes5)}</td>
                      <td className="valor">{formatNumber(row.mes6)}</td>
                      <td className="valor">{formatNumber(row.mes7)}</td>
                      <td className="valor">{formatNumber(row.mes8)}</td>
                      <td className="valor">{formatNumber(row.mes9)}</td>
                      <td className="valor">{formatNumber(row.mes10)}</td>
                      <td className="valor">{formatNumber(row.mes11)}</td>
                      <td className="valor">{formatNumber(row.mes12)}</td>
                      <td className="valor">{row.grupo == 13 || row.ftotal == 13 ? '' : formatNumber(row.total)}</td>
                      <td className="valor">{row.grupo == 13 || row.ftotal == 13 ? '' : formatNumber(row.media)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Spin>
      );
    }
  }, [fluxo.fluxoteste, general.unidades]);
  return <div></div>;
}
export default PrintFluxo;
