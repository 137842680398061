import { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Space, Input } from 'antd';
import { ShowOrcamentoForm, GetOrcamentos, ExcluirOrcamento, LoadOrcamento } from '@actions/orcamentosActions';
import OrcamentoForm from './OrcamentoForm';
import { EditOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Permission } from '@hooks/usePermissions';
import { SetPageHeader } from '@actions/userActions';
import { Link } from 'react-router-dom';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Orcamento } from '@models/Orcamento';

const { Column } = Table;

function OrcamentosView() {
  const general = useAppSelector((state) => state.general);
  const orcamentos = useAppSelector((state) => state.orcamentos);
  const [filter, setFilter] = useState<string>('');
  const dispatch = useAppDispatch();
  useEffect(() => {
    document.title = 'Nambi - Orçamento';
    dispatch(GetOrcamentos());
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Orçamentos',
        subTitle: 'Crie orçamentos mensais ou globais para suas contas',
        extras: [
          <Permission key="1" permission="orcamento.add">
            <Button
              size="small"
              loading={general.waiting}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => dispatch(ShowOrcamentoForm(general.unidades))}
            >
              Novo Orçamento
            </Button>
          </Permission>,
        ],
      })
    );
  }, [general.unidades, general.waiting]);

  const confirm = (id: number) => {
    dispatch(ExcluirOrcamento(id));
  };
  return (
    <div>
      <Permission permission="orcamento.list">
        <Input
          placeholder="Buscar..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ width: 200 }}
        />
        <Table
          loading={orcamentos.waiting}
          rowKey="id"
          size="small"
          dataSource={filterobject(orcamentos.orcamentos, filter)}
          pagination={false}
          scroll={{ y: 'calc(100vh - 180px)', x: 1000 }}
        >
          <Column
            width={100}
            dataIndex="id"
            render={(_, record: Orcamento) => (
              <Space direction="horizontal">
                <Link to={`/Orcamentos/${record.id}`}>
                  <Button size="small" icon={<EyeOutlined />} />
                </Link>
                <Permission permission="orcamentos.edit">
                  <Button size="small" onClick={() => dispatch(LoadOrcamento(record.id))} icon={<EditOutlined />} />
                </Permission>
                <Permission permission="orcamentos.delete">
                  <Popconfirm
                    title="Está certo disso?"
                    onConfirm={() => confirm(record.id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Button size="small" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Permission>
              </Space>
            )}
          />
          <Column
            title="Descrição"
            dataIndex="descricao"
            sorter={(a: Orcamento, b: Orcamento) => naturalSorter(a.descricao, b.descricao)}
          />
          <Column title="Ano" dataIndex="ano" sorter={(a: Orcamento, b: Orcamento) => a.ano - b.ano} />
          <Column
            title="Unidade de Negócio"
            dataIndex="unidade"
            sorter={(a: Orcamento, b: Orcamento) => naturalSorter(a.unidade, b.unidade)}
          />
          <Column
            title="Tipo"
            dataIndex="tipoOrcamento"
            sorter={(a: Orcamento, b: Orcamento) => a.tipoOrcamento - b.tipoOrcamento}
            render={(v) => (v === 0 ? 'Global' : 'Mensal')}
          />
        </Table>
        <OrcamentoForm />
      </Permission>
    </div>
  );
}

export default OrcamentosView;
