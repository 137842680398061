import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import cliente from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetImportacoes = createAsyncThunk('importacao/GetImportacoes', async () =>
  cliente.get(apiEndpoints.IMPORTAR.LOAD_IMPORTS)
);
export const GetContasAnaliticas = createAsyncThunk(
  'importacao/GetContasAnaliticas',
  async (unidadeid: number, thunkAPI) =>
    cliente
      .get(apiEndpoints.LANCAR.GET_CONTAS_TREE, { params: { unidadeID: unidadeid } })
      .then((res) => thunkAPI.fulfillWithValue({ contas: res.data, unidadeID: unidadeid }))
);
export const Save = createAsyncThunk('importacao/Save', async (data: any) =>
  cliente.post(apiEndpoints.IMPORTAR.SAVE, data)
);
export const Load = createAsyncThunk('importacao/Load', async (id: string | number) =>
  cliente.get('/api/Importar/Load', { params: { id: id } })
);
export const Lancar = createAsyncThunk('importacao/Lancar', async (data: any) =>
  cliente.post(apiEndpoints.IMPORTAR.LANCAR, data)
);
export const SetSettings = createAction('importar/SetSettings', (data) => ({ payload: data }));
export const SetCampos = createAction('importar/SetCampos', (data) => ({ payload: data }));
export const SetValues = createAction('importar/SetValues', (data) => ({ payload: data }));
export const SetColumns = createAction('importar/SetColumns', (data) => ({ payload: data }));
export const SetDescricao = createAction('importar/SetDescricao', (data) => ({ payload: data }));
export const SetSelectConta = createAction('importar/SetSelectConta', (data) => ({ payload: data }));
export const SetSelectUnidade = createAction('importar/SetSelectUnidade', (data) => ({ payload: data }));
export const SetSelectPessoa = createAction('importar/SetSelectPessoa', (data) => ({ payload: data }));
export const Reset = createAction('importar/Reset');
