import { ContaAnalitica } from '@models/ContaAnalitica';
import { ContaSintetica } from '@models/ContaSintetica';
import { AppDispatch } from '@config/reduxStore';
import axios from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetAnaliticas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ANALITICAS_PLANOCONTAS',
      payload: axios.get(apiEndpoints.PLANOCONTAS.GET_ANALITICAS),
    });
  };
};
export const GetSinteticas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_SINTETICAS_PLANOCONTAS',
      payload: axios.get(apiEndpoints.PLANOCONTAS.GET_SINTETICAS),
    });
  };
};
export const GetPlanoContas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PLANO_CONTAS_PLANOCONTAS',
      payload: axios.get(apiEndpoints.EMPRESAS.GET_PLANO_CONTAS),
    });
  };
};
export const ExcluirSintetica = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_SINTETICA_PLANOCONTAS_PENDING',
    });
    axios
      .get(apiEndpoints.PLANOCONTAS.EXCLUIR_SINTETICA, { params: { id } })
      .then((res) => {
        dispatch({
          type: 'EXCLUIR_SINTETICA_PLANOCONTAS_FULFILLED',
          payload: res,
        });
        dispatch(GetPlanoContas());
      })
      .catch((res) => {
        dispatch({
          type: 'EXCLUIR_SINTETICA_PLANOCONTAS_REJECTED',
          payload: res,
        });
      });
  };
};
export const ExcluirAnalitica = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_ANALITICA_PLANOCONTAS',
      payload: axios.get(apiEndpoints.PLANOCONTAS.EXCLUIR_ANALITICA, { params: { id } }),
    });
  };
};
export const ExcluirAnaliticas = function (values: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_ANALITICAS_PLANOCONTAS',
      payload: axios.post(apiEndpoints.PLANOCONTAS.EXCLUIR_ANALITICAS, {
        Analiticas: values,
      }),
    });
  };
};
export const MoveSintetica = function (id: number, up: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'MOVE_SINTETICA_PLANOCONTAS',
      payload: axios.get(apiEndpoints.PLANOCONTAS.MOVE_SINTETICA, { params: { id, up } }),
    });
  };
};
export const MoveAnalitica = function (id: number, up: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'MOVE_ANALITICA_PLANOCONTAS',
      payload: axios.get(apiEndpoints.PLANOCONTAS.MOVE_ANALITICA, { params: { id, up } }),
    });
  };
};
export const UpdateSintetica = function (values: ContaSintetica) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPDATE_SINTETICA_PLANOCONTAS',
      payload: axios.post(apiEndpoints.PLANOCONTAS.UPDATE_SINTETICA, values),
    });
  };
};
export const UpdateAnalitica = function (values: ContaAnalitica) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPDATE_ANALITICA_PLANOCONTAS',
      payload: axios.post(apiEndpoints.PLANOCONTAS.UPDATE_ANALITICA, values),
    });
  };
};
export const AddAnalitica = function (values: ContaAnalitica) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_ANALITICA_PLANOCONTAS',
      payload: axios.post(apiEndpoints.PLANOCONTAS.ADD_ANALITICA, values),
    });
  };
};
export const AddSintetica = function (values: ContaSintetica) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_SINTETICA_PLANOCONTAS_PENDING',
    });
    axios
      .post(apiEndpoints.PLANOCONTAS.ADD_SINTETICA, values)
      .then((res) => {
        dispatch({
          type: 'ADD_SINTETICA_PLANOCONTAS_FULFILLED',
          payload: res,
        });
        dispatch(GetPlanoContas());
      })
      .catch((res) => {
        dispatch({
          type: 'ADD_SINTETICA_PLANOCONTAS_REJECTED',
          payload: res,
        });
      });
  };
};
export const SetSelectedAnaliticas = function (values: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_ANALITICAS',
      payload: values,
    });
  };
};
