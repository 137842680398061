import { useEffect } from 'react';
import HomePage from '@views/HomePage/HomePage';
import { isAuth } from '@actions/userActions';
import EmpresaContexto from '@views/EmpresaContext/EmpresaContexto';
import Contextos from '@views/Contextos';
import PlanoForm from '@views/HomePage/Contratar/PlanoForm';
import SistemaContexto from './SistemaContexto/SistemaContexto';
import NavBar from '@views/NavBarAnt';
import { Layout, PageHeader, Spin } from 'antd';
import EmailNaoConfirmado from '@views/HomePage/Contratar/EmailNaoConfirmado';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Planos from '@views/HomePage/Contratar/Plano';
import Footer from '@views/Footer';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { useNavigate } from 'react-router-dom';

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, checking, pageHeader, paypal } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(isAuth(paypal));
  }, []);
  if (checking)
    return (
      <Spin spinning={checking}>
        <div style={{ height: '100vh' }} />
      </Spin>
    );
  return (
    <PayPalScriptProvider
      options={{
        vault: true,
        intent: 'subscription',
        'client-id': paypal.clientid,
      }}
    >
      <Layout>
        <NavBar />
        {user.isAuthenticated && (
          <PageHeader
            style={{ marginTop: 67 }}
            ghost={true}
            title={pageHeader.title}
            subTitle={pageHeader.subTitle}
            extra={pageHeader.extras}
            onBack={() => (pageHeader.backButton ? navigate(-1) : null)}
          />
        )}
        <Layout.Content
          style={{
            padding: 10,
            overflowY: 'scroll',
            display: !user.isAuthenticated && 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: user.isAuthenticated ? 0 : 65,
            height: `calc(100vh - ${user.isAuthenticated ? 120 : 0}px)`,
          }}
        >
          {user.isAuthenticated &&
            user.tipocontexto === 'Empresa' &&
            user.confirmemail &&
            user.subscription.status !== 'ACTIVE' && <Planos />}
          {user.isAuthenticated && user.ngrupos === 0 && user.confirmemail && <PlanoForm />}
          {user.isAuthenticated && user.ngrupos === 0 && !user.confirmemail && <EmailNaoConfirmado />}
          {user.isAuthenticated && user.grupoid === -1 && user.ngrupos > 1 && <Contextos />}

          {user.isAuthenticated && user.tipocontexto === 'Empresa' && user.subscription.status === 'ACTIVE' && (
            <EmpresaContexto />
          )}
          {user.isAuthenticated && user.tipocontexto === 'Sistema' && <SistemaContexto />}
          {!user.isAuthenticated && <HomePage />}
          {!user.isAuthenticated && <Footer />}
        </Layout.Content>
      </Layout>
    </PayPalScriptProvider>
  );
}

export default App;
