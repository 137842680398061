import { createLogger } from 'redux-logger';
import thunk, { ThunkDispatch } from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducers from '@reducers/index';
import { Action, configureStore } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const middleware = process.env.NODE_ENV === 'development' ? [promise, thunk, createLogger()] : [promise, thunk];

//const middleware = applyMiddleware(promise, thunk);
const store = configureStore({ reducer: reducers, middleware: middleware });
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<{}, {}, any>;

export interface extendedAction extends Action {
  type: string;
  payload?: any;
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
