import { notification } from 'antd';
import { setCookie } from '@utils/localStorage';
import { extendedAction } from '@config/reduxStore';
import { UserReducer } from '@reducers/reducers';

const dev = false; // process.env.NODE_ENV === 'development';

const initialState: UserReducer = {
  user: {
    error: '',
    username: '',
    userid: '',
    isAuthenticated: false,
    ngrupos: -1,
    tipocontexto: '',
    grupoid: -1,
    plano: null,
    perfil: '',
    nome: '',
    confirmemail: null,
    roles: [],
    permissions: [],
    subscription: {
      status: null,
    },
  },
  ready: false,
  errorMsg: '',
  isAuthenticated: false,
  loginmessage: '',
  checking: true,
  waiting: false,
  loadingContextos: false,
  signUp: {
    errors: [],
    pending: false,
    success: false,
  },
  confirmEmail: {
    errors: [],
    pending: false,
    success: false,
    resultmsg: '',
  },
  forgotPassword: {
    sending: false,
    msg: '',
    email: '',
  },
  resendConfirmEmail: {
    sending: false,
    msg: '',
  },
  usuario: {
    loadinguser: false,
    loadingcontratos: false,
    name: null,
    phoneNumber: null,
    email: null,
    lancCod: null,
    contratos: [],
    redefinirsenhamodal: false,
    changepasswordloading: false,
    changepasswordresult: '',
  },
  pageHeader: {
    title: '',
    backButton: true,
    extras: [],
    subTitle: '',
  },
  paypal: {
    clientid: dev
      ? 'AVAm8oNha6hcYIAFG9GL2idVfsHbnRQMPfOw54vRnSRWSw24ATZljthZ4movX5muHhRVHYvJs_UN2Xkn'
      : 'ATJF_JVqJ76vzjWwPuboKs8Xpg8q3SkvvS5njg-iMDu4VLsifMnRLGsSyyID6y6PUY7A-mvQzgoKMT21',
    secret: dev
      ? 'EN-h6oSeH-5bZVM8EckNvqCgR9Nn3EW9E5JsBzMn-u17_Oz7VB1o8sSSeZt1rd6UU_FX07fOKib9C4Bs'
      : 'EOOsk5WggAKY-ITkggXkPmBqCgXVP8Jy8icFUMoLmguBDXj68C1kY-KJwwQvrQKJANkA6gd7peiuoZXU',
    api: dev ? 'https://api-m.sandbox.paypal.com' : 'https://api-m.paypal.com',
  },
  loadingPlanos: false,
  planos: [],
};

const userReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'SET_PAGE_HEADER':
      state = {
        ...state,
        pageHeader: action.payload,
      };
      break;
    case 'CHANGE_NOME_CONTEXTO_EMPRESAS_FULFILLED':
      state = {
        ...state,
        user: {
          ...state.user,
          nome: action.payload.data,
        },
      };
      break;
    case 'FORGOT_PASSWORD_PENDING':
      state = {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sending: true,
          msg: '',
        },
      };
      break;
    case 'GET_PLANOS_PENDING':
      state = {
        ...state,
        loadingPlanos: true,
      };
      break;
    case 'GET_PLANOS_REJECTED':
      notification.error({ message: 'Erro ao carregar planos!' });
      state = {
        ...state,
        loadingPlanos: false,
      };
      break;
    case 'GET_PLANOS_FULFILLED':
      state = {
        ...state,
        loadingPlanos: false,
        planos: action.payload.data,
      };
      break;
    case 'FORGOT_PASSWORD_REJECTED':
      state = {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sending: false,
          msg: '',
        },
      };
      break;
    case 'FORGOT_PASSWORD_FULFILLED':
      state = {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sending: false,
          msg: action.payload.data,
        },
      };
      break;
    case 'CHANGE_EMAIL_FORGOT_PASSWORD':
      state = {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          email: action.payload,
        },
      };
      break;
    case 'RESEND_CONFIRM_EMAIL_PENDING':
      state = {
        ...state,
        resendConfirmEmail: {
          ...state.resendConfirmEmail,
          sending: true,
          msg: '',
        },
      };
      break;
    case 'RESEND_CONFIRM_EMAIL_FULFILLED':
      state = {
        ...state,
        resendConfirmEmail: {
          ...state.resendConfirmEmail,
          sending: false,
          msg: 'Email reenviado com sucesso!',
        },
      };
      break;
    case 'GET_USER_DATA_USUARIO_PENDING':
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          loadinguser: true,
        },
      };
      break;
    case 'GET_USER_DATA_USUARIO_FULFILLED':
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          loadinguser: false,
          ...action.payload.data,
        },
      };
      break;
    case 'CHANGE_PASSWORD_USUARIO_PENDING':
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          changepasswordloading: true,
        },
      };
      break;
    case 'CHANGE_PASSWORD_USUARIO_REJECTED':
      notification.error({ message: action.payload.response.data[0].description });
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          changepasswordloading: false,
          changepasswordresult: action.payload.response.data[0].description,
        },
      };
      break;
    case 'CHANGE_PASSWORD_USUARIO_FULFILLED':
      notification.success({ message: 'Senha atualizada com sucesso!' });
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          changepasswordloading: false,
          changepasswordresult: action.payload.data,
        },
      };
      break;
    case 'RESET_SENHA_MODAL_USUARIO':
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          redefinirsenhamodal: action.payload,
        },
      };
      break;
    case 'LOGIN_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'LOGIN_REJECTED':
      state = {
        ...state,
        loginmessage: action.payload.response.data,
        waiting: false,
      };
      break;
    case 'LOGIN_FULFILLED':
      setCookie('token', action.payload.data);
      window.location.href = '/';
      break;
    case 'GET_USER_PERFIL_USER_FULFILLED':
      state = {
        ...state,
        user: {
          ...state.user,
          perfil: action.payload.data,
        },
      };
      break;

    case 'LOGOUT_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'LOGOUT_FULFILLED':
      window.location.href = '/';
      break;
    case 'LOGIN_SUCCESS':
      state = {
        ...state,
        waiting: false,
        ready: true,
        user: {
          ...state.user,
          isAuthenticated: false,
          username: '',
          ngrupos: null,
          perfil: '',
          tipocontexto: '',
          grupoid: null,
          error: '',
          confirmemail: null,
        },
      };
      break;
    case 'LOGIN_ERROR':
      state = {
        ...state,
        waiting: false,
        ready: true,
        isAuthenticated: false,
        errorMsg: action.payload,
      };
      break;
    case 'SIGN_UP_PENDING':
      state = {
        ...state,
        waiting: true,
        signUp: {
          errors: [],
          pending: true,
          success: false,
        },
      };
      break;
    case 'SIGN_UP_FULFILLED':
      state = {
        ...state,
        waiting: false,
        signUp: {
          errors: [],
          pending: false,
          success: true,
        },
      };
      break;
    case 'SIGN_UP_ERROR':
      state = {
        ...state,
        waiting: false,
        signUp: {
          errors: action.payload,
          pending: false,
          success: false,
        },
      };
      break;
    case 'SIGN_UP_REJECTED':
      state = {
        ...state,
        waiting: false,
        signUp: {
          errors: action.payload.response.data,
          pending: false,
          success: false,
        },
      };
      break;
    case 'CONFIRM_EMAIL_PENDING':
      state = {
        ...state,
        confirmEmail: {
          ...state.confirmEmail,
          pending: true,
          errors: null,
          success: false,
        },
      };
      break;
    case 'CONFIRM_EMAIL_FULFILLED':
      state = {
        ...state,
        confirmEmail: {
          ...state.confirmEmail,
          pending: false,
          errors: action.payload.data.errors,
          success: action.payload.data.succeeded,
        },
      };
      break;
    case 'CONFIRM_EMAIL_REJECTED':
      state = {
        ...state,

        confirmEmail: {
          ...state.confirmEmail,
          pending: false,
          errors: action.payload.response.data,
          success: false,
        },
      };
      break;
    case 'REGISTER_CONTEXTO_PENDING':
      state = {
        ...state,
        waiting: true,
        user: {
          ...state.user,
        },
      };
      break;
    case 'REGISTER_CONTEXTO_FULFILLED':
      setCookie('grupoid', action.payload.data.grupoid);
      setCookie('tipocontexto', action.payload.data.tipocontexto);
      window.location.href = '/';
      break;
    case 'REGISTER_CONTEXTO_REJECTED':
      notification.error({ message: 'Erro ao criar plano de contas' });
      state = {
        ...state,
        waiting: false,
        signUp: {
          errors: action.payload.response.data,
          pending: false,
          success: false,
        },
      };
      break;
    case 'IS_AUTH_FULFILLED':
      setCookie('grupoid', action.payload.grupoid);
      setCookie('ngrupos', action.payload.ngrupos);
      state = {
        ...state,
        user: {
          ...action.payload,
        },
        checking: false,
      };
      break;
    case 'IS_AUTH_REJECTED':
      console.log(action.payload);
      state = {
        ...state,
        checking: false,
        user: action.payload,
      };
      break;
    case 'IS_AUTH_PENDING':
      state = {
        ...state,
        checking: true,
      };
      break;
    case 'UPDATE_USER_INFO_PENDING':
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          loadinguser: true,
        },
      };
      break;
    case 'UPDATE_USER_INFO_REJECTED':
      notification.error({ message: 'Erro ao atualizar dados!' });
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          loadinguser: false,
        },
      };
      break;
    case 'UPDATE_USER_INFO_FULFILLED':
      notification.success({ message: 'Dados atualizados com sucesso!' });
      state = {
        ...state,
        usuario: {
          ...state.usuario,
          ...action.payload.data,
          loadinguser: false,
        },
      };
      break;
    case 'PICK_CONTEXTO_PENDING':
      state = {
        ...state,
        loadingContextos: true,
      };
      break;
    case 'PICK_CONTEXTO_REJECTED':
      notification.error({ message: 'Erro ao selecionar empresa!' });
      state = {
        ...state,
        loadingContextos: false,
      };
      break;
    case 'PICK_CONTEXTO_FULFILLED':
      setCookie('grupoid', action.payload.grupoid);
      setCookie('tipocontexto', action.payload.tipocontexto);
      window.location.href = '/';
      break;
    case 'GO_TO_CONTEXTOS':
      setCookie('grupoid', -1);
      setCookie('tipocontexto', '');
      state = {
        ...state,
        user: {
          ...state.user,
          isAuthenticated: true,
          username: state.user.username,
          ngrupos: state.user.ngrupos,
          perfil: '',
          grupoid: -1,
          tipocontexto: '',
          roles: [],
          permissions: [],
        },
        waiting: true,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default userReducer;
