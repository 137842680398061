import { Form, Table, Input, Select, FormInstance } from 'antd';
import { moneyMaskNeg } from '@utils/masks';
import { useState } from 'react';
import { useEffect } from 'react';
import { ImplantarSaldos } from '@actions/implantacaoActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ContaAnaliticaTree } from '@models/ContaAnalitica';
import { FormImplantacaoProps } from './ImplantacaoView';

interface RenderContasProps {
  form: FormInstance<FormImplantacaoProps>;
}
const RenderContas = ({ form }: RenderContasProps) => {
  const implantacao = useAppSelector((state) => state.implantacao);
  const pessoas = useAppSelector((state) => state.pessoas);
  const [contas, setContas] = useState<ContaAnaliticaTree[]>([]);
  useEffect(() => {
    if (implantacao.contas) {
      let count = 0;
      let temp: ContaAnaliticaTree[] = implantacao.contas.map((n1) => ({
        ...n1,
        children: n1.children.map((n2) => ({
          ...n2,
          children: n2.children.map((n3) => ({
            ...n3,
            children: n3.children.map((n4) => ({
              ...n4,
              children: n4.children.map((n5) => {
                const tempn5 = { ...n5, count: count, value: n5.value.slice(2, n5.value.length) };
                count++;
                form.setFieldValue([count, 'id'], n5.value);
                return tempn5;
              }),
            })),
          })),
        })),
      }));
      setContas(temp);
    }
  }, [implantacao.contas]);
  return (
    <Table
      scroll={{ y: 'calc(100vh - 160px)' }}
      size="small"
      pagination={false}
      loading={implantacao.loadingcontas}
      //onExpand={(_, row) => dispatch(ExpandRow(row.key))}
      //expandedRowKeys={implantacao.expandedKeys}
      dataSource={contas}
      rowClassName={(row) => `nivel${row.nivel}`}
      rowKey="key"
    >
      <Table.Column key="key" dataIndex="title" title="Descrição" />
      <Table.Column
        width={140}
        title="Saldo"
        dataIndex="value"
        render={(text, row: ContaAnaliticaTree) => {
          if (row.nivel === 5) {
            return (
              <div>
                <Form.Item
                  name={[text, `valor`]}
                  noStyle
                  initialValue={moneyMaskNeg.format(0)}
                  normalize={(v, pv) => moneyMaskNeg.format(moneyMaskNeg.normalize(v, pv))}
                >
                  <Input size="small" />
                </Form.Item>
              </div>
            );
          } else return null;
        }}
      />
      <Table.Column
        width={140}
        title="Descricão"
        dataIndex="value"
        render={(text, row: ContaAnaliticaTree) => {
          if (row.nivel === 5) {
            return (
              <Form.Item name={[text, `descricao`]} noStyle>
                <Input placeholder="Opcional" />
              </Form.Item>
            );
          } else return null;
        }}
      />
      <Table.Column
        width={200}
        title="Pessoa"
        dataIndex="value"
        render={(text, row: ContaAnaliticaTree) => {
          if (row.nivel === 5) {
            return (
              <Form.Item name={[text, `pessoa`]} noStyle>
                <Select placeholder="Opcional" size="small" showSearch optionFilterProp="label" style={{ width: 200 }}>
                  {pessoas.pessoasGrouped.map((grupo) => (
                    <Select.OptGroup
                      label={
                        grupo.grupo === 0
                          ? 'Fornecedores'
                          : grupo.grupo === 1
                          ? 'Clientes'
                          : grupo.grupo === 2
                          ? 'Colaboradores'
                          : 'Sócios'
                      }
                      key={grupo.grupo}
                    >
                      {grupo.pessoas.map((pessoa) => (
                        <Select.Option key={pessoa.id} label={pessoa.fantasia + pessoa.nomeRazao} value={pessoa.id}>
                          {pessoa.nomeRazao}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>
            );
          } else return null;
        }}
      />
    </Table>
  );
};
function ContasForm({ form }: RenderContasProps) {
  const dispatch = useAppDispatch();
  const { unidade, unidadeImplantacao, data } = useAppSelector((state) => state.implantacao);
  return (
    <Form
      form={form}
      onFinish={(values) => {
        let keys = Object.keys(values.contas).map((c) => {
          const conta = values.contas[c];
          return {
            id: Number(c),
            valor: moneyMaskNeg.normalize(conta?.valor, ''),
            descricao: conta?.descricao,
            pessoa: conta?.pessoa,
          };
        });
        dispatch(ImplantarSaldos(unidade, unidadeImplantacao, data, keys));
      }}
    >
      <Form.List name="contas">{() => <RenderContas form={form} />}</Form.List>
    </Form>
  );
}

export default ContasForm;
