import { Button, Input, Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import { filterobject } from '@utils/filterandsort';
import { CheckOutlined } from '@ant-design/icons';
import { GetPessoasList } from '@actions/pessoasActions';
import { useRef } from 'react';
import { SetSelectPessoa, SetValues } from '@actions/importarActions';
import { copy } from '@utils/queryString';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Pessoa } from '@models/Pessoa';
import { Dictionary } from '@reduxjs/toolkit';

function SelectPessoa() {
  const dispatch = useAppDispatch();
  const { pessoas } = useAppSelector((state) => state.pessoas);
  const { values, selectPessoa } = useAppSelector((state) => state.importar);
  const [filtro, setFiltro] = useState('');
  const ref = useRef<any>();
  useEffect(() => {
    if (selectPessoa.visible) {
      ref.current.focus();
      dispatch(GetPessoasList());
    }
  }, [selectPessoa.visible]);
  console.log(selectPessoa);
  return (
    <Modal
      open={selectPessoa.visible}
      onCancel={() => dispatch(SetSelectPessoa({ visible: false, index: null, tipo: null }))}
      title="Selecionar Pessoa"
      okButtonProps={{ hidden: true }}
    >
      <Input ref={ref} value={filtro} onChange={(e) => setFiltro(e.target.value)} placeholder="Buscar..." />
      <Table dataSource={filterobject(pessoas, filtro)} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          render={(v, row: Pessoa) => (
            <Button
              onClick={() => {
                dispatch(
                  SetValues(
                    copy<Dictionary<any>>(values).map((p: any) => {
                      if (p.key === selectPessoa.index) {
                        p.pessoa = row.nomeRazao;
                        p.pessoaID = v;
                      }
                      return p;
                    })
                  )
                );
                dispatch(SetSelectPessoa({ visible: false, tipo: null, index: null }));
              }}
              size="small"
              icon={<CheckOutlined />}
            />
          )}
          width={30}
        />
        <Table.Column title="Nome" dataIndex="nomeRazao" />
      </Table>
    </Modal>
  );
}

export default SelectPessoa;
