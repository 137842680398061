import { AppDispatch } from '@config/reduxStore';
import axios from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetUsuariosContexto = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_USUARIOS_USUARIOS',
      payload: axios.get(apiEndpoints.ACCOUNT.GET_USUARIOS_CONTEXTO),
    });
  };
};
export const SetSelectedUsuarios = function (values: any[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_USUARIOS_USUARIOS',
      payload: values,
    });
  };
};

export const HideUsuarioForm = function (id: string, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_USUARIO_FORM_USUARIOS',
      payload: { id, open },
    });
  };
};
export const Reset = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'RESET',
    });
  };
};
export const GetPerfils = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PERFIS_USUARIOS',
      payload: axios.get(apiEndpoints.HOME.GET_PERFIS),
    });
  };
};
export const FindUser = function (email: string) {
  return function (dispatch: AppDispatch) {
    if (email) {
      dispatch({
        type: 'FIND_USER_USUARIOS_PENDING',
      });
      axios.get(apiEndpoints.ACCOUNT.FIND_USER, { params: { email: email } }).then((res) => {
        if (res.data.existe) {
          dispatch({
            type: 'FIND_USER_USUARIOS_YES_FULFILLED',
            payload: res.data.nome,
          });
        } else {
          dispatch({
            type: 'FIND_USER_USUARIOS_NO_FULFILLED',
          });
        }
      });
    }
  };
};
export const AddUser = function (nome: string, email: string, perfilid: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CREATE_USER_USUARIOS_PENDING',
    });
    axios
      .post(apiEndpoints.ACCOUNT.CREATE_USER_BY_ADM, {
        nome: nome,
        email: email,
        perfilid: perfilid,
      })
      .then((res) => {
        if (res.data === 0) {
          dispatch({
            type: 'CREATE_USER_JACADASTRADO_USUARIOS',
          });
        } else if (res.data === 1) {
          dispatch({
            type: 'CREATE_USER_REJECTED',
          });
        } else {
          dispatch({
            type: 'CREATE_USER_CRIADO_USUARIOS',
            payload: res.data,
          });
          dispatch(GetUsuariosContexto());
        }
      });
  };
};
export const RemoveUsers = function (users: string[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'REMOVE_USERS_USUARIOS',
      payload: axios.post(apiEndpoints.ACCOUNT.REMOVE_USER_CONTEXTO_ADM, {
        userids: users,
      }),
    });
  };
};
export const DeleteUsuario = function (id: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_USUARIO',
      payload: axios.get(apiEndpoints.USUARIOS.DELETE_USER, { params: { id } }),
    });
  };
};
export const AddUserToRole = function (userid: string, role: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_USER_TO_ROLE_PENDING',
    });

    axios
      .post(apiEndpoints.USUARIOS.ADD_USER_TO_ROLE, { userid, role })
      .then(() => {
        dispatch({
          type: 'ADD_USER_TO_ROLE_FULFILLED',
          payload: {
            userid: userid,
            role: role,
          },
        });
      })
      .catch((res) => {
        //axios.post("/api/Role/AddUserToRole", { role }).then((res) => {
        dispatch({
          type: 'ADD_USER_TO_ROLE_REJECTED',
          payload: res.response,
        });
        //});
      });
  };
};
