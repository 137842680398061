import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Historico } from '@models/Historico';
import axios from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const Reset = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'RESET',
    });
  };
};

export const GetHistorico = function (
  pagination: TablePaginationConfig,
  sorter: SorterResult<Historico> | SorterResult<Historico>[],
  filters: Record<string, FilterValue>
) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_PAGINATION_HISTORICO',
      payload: pagination,
    });
    dispatch({
      type: 'GET_HISTORICO_HISTORICO',
      payload: axios.post(apiEndpoints.HISTORICO.GET_HISTORICO, {
        pagination,
        sorter,
        filters,
      }),
    });
  };
};

export const GetUserList = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_USER_LIST_HISTORICO',
      payload: axios.get(apiEndpoints.HISTORICO.GET_USERS_LIST),
    });
  };
};
