import moment from 'moment';
import { Button, Modal } from 'antd';
import TabelaBalanco from './TabelaBalanco';
import TabelaLancamentos from './TabelaLancamentos';
import { Permission } from '@hooks/usePermissions';
import BalancoForm from './BalancoForm';
import { BalancoController } from '@controllers/BalancoController';

function BalancoView() {
  const controller = BalancoController;
  const { data, actions } = controller();

  return (
    <div>
      <Permission permission="balanco.list">
        <BalancoForm controller={controller} />
        <h4>{`Balanço ${moment(data.balanco.competencia).format('MM/YYYY')}`}</h4>
        <TabelaBalanco controller={controller} />
        <Modal
          title="Lançamentos"
          open={data.balanco.lancamentosvisible}
          width="80%"
          onCancel={() => actions.HideLancamentos()}
          footer={[
            <Button key="submit" type="primary" onClick={() => actions.HideLancamentos()}>
              Fechar
            </Button>,
          ]}
        >
          <TabelaLancamentos controller={controller} />
        </Modal>
      </Permission>
    </div>
  );
}

export default BalancoView;
