import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { formatNumber } from '@utils/masks';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Spin } from 'antd';
import logo from '../../../assets/img/logo.png';
import store, { useAppDispatch, useAppSelector } from '@config/reduxStore';
import '../../../assets/scss/contasPG.scss';
import { GetPessoas } from '@actions/pessoasActions';
import { GetTitulosContasPG } from '@actions/contasPGActions';
import TituloContasPG from './TituloContasPG';
import { ContasPGFormData } from './ContasPGForm';

function PrintContasPG() {
  const dispatch = useAppDispatch();
  const contaspg = useAppSelector((state) => state.contaspg);
  const pessoas = useAppSelector((state) => state.pessoas);
  const [searchParams] = useSearchParams();
  const data: ContasPGFormData = {
    modo: searchParams.get('modo'),
    status: Number(searchParams.get('status')),
    unidades: searchParams
      .get('unidades')
      .split(',')
      .filter((c) => c !== '')
      .map((c) => Number(c)) as number[],
    pessoas: searchParams
      .get('pessoas')
      .split(',')
      .filter((c) => c !== '')
      .map((c) => Number(c)) as number[],
    datainicial: moment(searchParams.get('datainicial')).isValid() ? moment(searchParams.get('datainicial')) : moment(),
    datafinal: moment(searchParams.get('datafinal')).isValid()
      ? moment(searchParams.get('datafinal'))
      : moment().add(30, 'days'),
    contatitle: searchParams.get('ContaTitle'),
  };
  useEffect(() => {
    document.title = 'Nambi - Contas Pagar/Receber';
    dispatch(GetPessoas());
    dispatch(GetTitulosContasPG(data));
  }, []);
  useEffect(() => {
    if (contaspg.extrato && pessoas.pessoas) {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Provider store={store}>
          <Spin spinning={contaspg.waiting}>
            <div style={{ width: '100%' }} className="extratoprint">
              <table className="report-container printme" style={{ width: '100%' }}>
                <thead className="report-header">
                  <tr>
                    <th>
                      <img src={logo} height={30} className="logo" alt="logo" />
                    </th>
                    <th colSpan={10}>
                      <TituloContasPG />
                    </th>
                  </tr>
                  <tr style={{ fontWeight: 'strong' }}>
                    <th>Bloco</th>
                    <th>Status</th>
                    <th>Conta</th>
                    <th>Baixado Por</th>
                    <th>Descricao</th>
                    <th>Vencimento</th>
                    <th>Baixa</th>
                    <th>Pessoa</th>
                    <th>Unidade</th>
                    <th>Documento</th>
                    <th>Parcela</th>
                    <th className="valor">Valor</th>
                  </tr>
                </thead>
                <tbody className="report-content">
                  {contaspg.extrato.map((c, index) => {
                    return (
                      <tr key={index}>
                        <td>{c.bid}</td>
                        <td>{c.status}</td>
                        <td>{c.analitica}</td>
                        <td>{c.baixadoPor}</td>
                        <td>{c.descricaoBloco}</td>
                        <td>
                          {moment(c.dataVencimento).isValid() ? moment(c.dataVencimento).format('DD/MM/YYYY') : ''}
                        </td>
                        <td>{moment(c.dataBaixa).isValid() ? moment(c.dataBaixa).format('DD/MM/YYYY') : ''}</td>
                        <td>{c.pessoa as string}</td>
                        <td>{c.unidade}</td>
                        <td style={{ maxWidth: 200 }}>{c.documento}</td>
                        <td>{c.parcela}</td>
                        <td className="valor">{formatNumber(c.valor)}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="report-footer">
                  <tr style={{ fontWeight: 'bold' }}>
                    <td colSpan={11} align="right">
                      TOTAL
                    </td>
                    <td className="valor">{formatNumber(contaspg.extrato.reduce((a, b) => a + b.valor, 0))}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Spin>
        </Provider>
      );
    }
  }, [contaspg.extrato, pessoas.pessoas]);
  return <div></div>;
}
export default PrintContasPG;
