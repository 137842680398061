import { DatePicker, Drawer, Form, Radio, Select, Spin, Typography, Button, TreeSelect } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  ChangeTipoConta,
  GerarExtrato,
  GetContasExtrato,
  SetBancariaExtrato,
  SetCompetenciaFimExtrato,
  SetCompetenciaInicioExtrato,
  SetContaExtrato,
  SetDefault,
  SetDefault2,
  SetFilterPessoas,
} from '@actions/extratoActions';
import moment from 'moment';
import { PrinterOutlined } from '@ant-design/icons';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { DefaultOptionType } from 'antd/lib/select';

interface ExtratoFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
interface FormData {
  tipoconta: string;
  unidades: number[];
  bancaria: number;
  contaid: number;
  pessoas: number[];
  periodoinicio: moment.Moment;
  periodofim: moment.Moment;
}
function ExtratoForm({ visible, setVisible }: ExtratoFormProps) {
  const dispatch = useAppDispatch();
  const extrato = useAppSelector((state) => state.extrato);
  const general = useAppSelector((state) => state.general);
  const pessoas = useAppSelector((state) => state.pessoas);
  const [form] = Form.useForm<FormData>();
  const [urlParams] = useSearchParams();

  useEffect(() => {
    const ID = urlParams.get('ID');
    const Nivel = urlParams.get('Nivel');
    const Unidades = urlParams.get('Unidades');
    const Bancaria = urlParams.get('Bancaria');

    if (ID && Nivel && Unidades) {
      dispatch(ChangeTipoConta('normal', JSON.parse(Unidades)));
      dispatch(SetContaExtrato(Number(ID.slice(2))));
      //form.setFieldsValue({ tipoconta: 'normal' });
      //form.setFieldValue('unidades', JSON.parse(Unidades));
      dispatch(
        SetDefault(
          Number(Nivel),
          Number(ID.slice(2)),
          extrato.periodoinicio,
          extrato.periodofim,
          [],
          JSON.parse(Unidades),
          null,
          'normal'
        )
      );
    } else if (Bancaria) {
      dispatch(SetBancariaExtrato(Number(Bancaria)));
      dispatch(
        SetDefault2(
          Number(Nivel),
          extrato.contaid,
          extrato.periodoinicio,
          extrato.periodofim,
          [],
          extrato.unidade,
          Number(Bancaria),
          'bancaria'
        )
      );
    }
  }, []);
  const disabledStartDate = (startValue: moment.Moment) => {
    return startValue >= moment(extrato.periodofim);
  };
  const disabledEndDate = (endValue: moment.Moment) => {
    return endValue <= moment(extrato.periodoinicio);
  };
  function handleSelectAll(value: any[]) {
    if (value && value.length && value.filter((u) => u === 'all').length) {
      if (value.length === general.unidades.map((u) => u.id).length + 1) {
        return [];
      }
      return [...general.unidades.map((u) => u.id)];
    }
    return value;
  }
  useEffect(() => {
    if (general.unidades.length === 1) {
      form.setFieldValue('unidades', [general.unidades[0].id]);
      dispatch({ type: 'GET_CONTAS_EXTRATO', payload: [general.unidades[0].id] });
    }
  }, [general.unidades]);
  useEffect(() => {
    form.setFieldValue('unidades', extrato.unidades);
  }, [extrato.unidades]);
  return (
    <Drawer open={visible} onClose={() => setVisible(false)} title="Extrato">
      <Form
        style={{ padding: 10 }}
        layout="vertical"
        form={form}
        initialValues={{
          tipoconta: urlParams.get('Bancaria') !== undefined ? 'bancaria' : 'normal',
          unidades: JSON.parse(urlParams.get('Unidades')),
        }}
      >
        <Spin spinning={general.waiting}>
          <Form.Item label="Tipo de conta" name="tipoconta">
            <Radio.Group
              value={extrato.tipoconta}
              onChange={(e) => dispatch(ChangeTipoConta(e.target.value, general.unidades))}
            >
              <Radio value="bancaria">Bancária</Radio>
              <Radio value="normal">Normal</Radio>
            </Radio.Group>
          </Form.Item>
        </Spin>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('tipoconta') === 'normal' && (
              <div>
                <Form.Item label="Unidade de Negócio" name="unidades">
                  <Select
                    showSearch
                    mode="multiple"
                    maxTagCount={3}
                    disabled={general.unidades.length === 1}
                    optionFilterProp="label"
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      dispatch(GetContasExtrato(handleSelectAll(value)));
                    }}
                    value={extrato.unidades}
                    placeholder="Unidade de Negócio"
                    filterOption={(input, option) =>
                      String(option['children']).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {general.unidades.length > 1 && (
                      <Select.Option label="todos" key="all">
                        TODOS
                      </Select.Option>
                    )}
                    {general.unidades.map((unidade) => (
                      <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
                        {unidade.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Conta" name="contaid">
                  <TreeSelect
                    style={{ width: '100%' }}
                    showSearch
                    treeNodeFilterProp="title"
                    placeholder="Contas"
                    treeData={extrato.contas}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                      minWidth: 400,
                    }}
                    onChange={(value, label) => {
                      dispatch(SetContaExtrato(value.slice(2), Number(value.substring(1, 2)), label[0].toString()));
                    }}
                  />
                  <Typography.Text type="danger">{extrato.validateconta}</Typography.Text>
                </Form.Item>
              </div>
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('tipoconta') === 'bancaria' && (
              <Form.Item label="Conta" name="bancaria">
                <Select
                  showSearch
                  optionFilterProp="label"
                  style={{ width: '100%' }}
                  onChange={(value, option) => {
                    const label: DefaultOptionType = option as DefaultOptionType;
                    dispatch(SetBancariaExtrato(value, label.label));
                  }}
                  placeholder="Contas Bancárias"
                  value={extrato.bancaria}
                >
                  {extrato.bancarias.map((conta) => (
                    <Select.Option
                      key={conta.id}
                      label={`AG: ${conta.agencia} CC: ${conta.conta} Banco: ${conta.banco}`}
                      value={conta.id}
                    >
                      <div
                        style={{ whiteSpace: 'normal' }}
                      >{`AG: ${conta.agencia} CC: ${conta.conta} Banco: ${conta.banco}`}</div>
                    </Select.Option>
                  ))}
                </Select>
                <Typography.Text type="danger">{extrato.validatebancaria}</Typography.Text>
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item label="Periodo Início" name="periodoinicio">
          <DatePicker
            style={{ minWidth: 180 }}
            disabledDate={disabledStartDate}
            onChange={(value) => dispatch(SetCompetenciaInicioExtrato(value))}
            format="DD/MM/YYYY"
            value={extrato.periodoinicio || null}
            placeholder="Início"
          />
        </Form.Item>
        <Form.Item label="Periodo Fim" name="periodofim">
          <DatePicker
            style={{ minWidth: 180 }}
            disabledDate={disabledEndDate}
            onChange={(value) => dispatch(SetCompetenciaFimExtrato(value))}
            format="DD/MM/YYYY"
            value={extrato.periodofim || null}
            placeholder="Fim"
          />
        </Form.Item>
        <Typography.Text type="danger">{extrato.validadeperiodo}</Typography.Text>
        <Form.Item label="Filtrar por Pessoa" name="pessoas">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            onChange={(value) => dispatch(SetFilterPessoas(value))}
            placeholder="Pessoas"
            value={extrato.selectedpessoas}
            filterOption={true}
            optionFilterProp="label"
            maxTagCount={3}
          >
            {pessoas.pessoasGrouped.map((grupo) => (
              <Select.OptGroup
                label={
                  grupo.grupo === 0
                    ? 'Fornecedores'
                    : grupo.grupo === 1
                    ? 'Clientes'
                    : grupo.grupo === 2
                    ? 'Colaboradores'
                    : 'Sócios'
                }
                key={grupo.grupo}
              >
                {grupo.pessoas.map((pessoa) => (
                  <Select.Option key={pessoa.id} value={pessoa.id} label={pessoa.nomeRazao + pessoa.fantasia}>
                    <div style={{ whiteSpace: 'break-spaces' }}>{pessoa.nomeRazao}</div>
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Button
          block
          type="primary"
          onClick={() => {
            dispatch(
              GerarExtrato(
                extrato.nivel,
                extrato.contaid,
                extrato.periodoinicio,
                extrato.periodofim,
                extrato.selectedpessoas,
                extrato.unidades,
                extrato.bancaria,
                extrato.tipoconta
              )
            );
          }}
        >
          Gerar Extrato
        </Button>
        <Link
          to={`/PrintExtrato?Unidades=${JSON.stringify(extrato.unidades)}&Pessoas=${JSON.stringify(
            extrato.selectedpessoas
          )}&ContaID=${extrato.contaid}&Nivel=${extrato.nivel}&PeriodoInicio=${moment(extrato.periodoinicio).format(
            'YYYY-MM-DD'
          )}&PeriodoFim=${moment(extrato.periodofim).format('YYYY-MM-DD')}&Bancaria=${
            extrato.bancaria || null
          }&TipoConta=${extrato.tipoconta}`}
          target="_blank"
        >
          <Button block size="small" icon={<PrinterOutlined />}>
            Imprimir
          </Button>
        </Link>
      </Form>
    </Drawer>
  );
}

export default ExtratoForm;
