import { useEffect } from 'react';
import { Row, Col } from 'antd';
import { GetUnidades } from '@actions/generalActions';
import Unidades from './Unidades';
import ContasPagar from './ContasPagar';
import ContasReceber from './ContasReceber';
import ResultadoPlot from './ResultadoPlot';
import GruposDespesas from './GruposDespesas';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import HomeFluxoCaixa from './Home.FluxoCaixa';
import Disponibilidade from './Disponibilidade';

function Home() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    document.title = 'Nambi - Gestão Financeira Inteligente';
    dispatch(GetUnidades(true, true));
    dispatch(
      SetPageHeader({
        title: 'Dashboard',
        subTitle: 'Resultados por competência e unidades de negócio',
        extras: [<Unidades key={0} />],
      })
    );
  }, []);
  return (
    <Row justify="center" gutter={[12, 12]}>
      <Col>{<ResultadoPlot />}</Col>
      <Col>
        <HomeFluxoCaixa />
      </Col>
      <Col>{<GruposDespesas />}</Col>
      <Col>
        <ContasPagar />
      </Col>
      <Col>
        <ContasReceber />
      </Col>
      <Col>
        <Disponibilidade />
      </Col>
    </Row>
  );
}

export default Home;
