import { apiEndpoints } from '@config/apiEndpoints';
import cliente from '@config/axios';
import { useMemo } from 'react';
import { IFluxoCaixaHome } from '@models/IFluxoCaixaHome';
import { Card, Empty, notification, Spin } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { moneyMaskNeg } from '@utils/masks';
import { useAppSelector } from '@config/reduxStore';
import moment from 'moment';
import br from 'apexcharts/dist/locales/pt-br.json';

interface CustomApexOptions extends ApexCharts.ApexOptions {
  toolbar: any;
}
const GetFluxoCaixa = (mes: moment.Moment, unidades: number[]) => {
  return useQuery<IFluxoCaixaHome[], AxiosError<any>>(
    ['fluxocaixa', mes, JSON.stringify(unidades)],
    () =>
      cliente
        .get<IFluxoCaixaHome[]>(apiEndpoints.HOME.GET_FLUXO_CAIXA, {
          params: { mes: mes.format('YYYY-MM-DD'), unidades: JSON.stringify(unidades) },
        })
        .then((res) => {
          return res.data;
        }),
    {
      enabled: !!mes,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      staleTime: 0,
      initialData: [],
    }
  );
};
function HomeFluxoCaixa() {
  const { unidades, competencia } = useAppSelector((state) => state.dash);
  const { data: dados, isLoading } = GetFluxoCaixa(competencia, unidades);

  const data: { series: ApexAxisChartSeries; options: CustomApexOptions } = useMemo(
    () => ({
      series: [
        {
          name: 'Receitas',
          data: dados.map((c) => c.pagar),
        },
        {
          name: 'Despesas',
          data: dados.map((c) => c.receber),
        },
        {
          name: 'Resultado',
          data: dados.map((c) => c.resultado),
        },
        {
          name: 'Caixa',
          data: dados.map((c) => c.caixa),
        },
      ],
      options: {
        chart: {
          type: 'area',
          id: 'area-datetime',
          height: 350,
          locales: [br],
          defaultLocale: 'pt-br',
        },
        dataLabels: {
          enabled: false,
          formatter: function (val: any) {
            return moneyMaskNeg.format(val);
          },
        },
        markers: {
          size: 0,
        },
        yaxis: {
          labels: {
            formatter(val: any) {
              return `${moneyMaskNeg.format(val)}`;
            },
          },
          title: {
            text: 'Valor',
          },
        },
        annotations: {
          xaxis: [
            {
              x: new Date().getTime(),
              borderColor: '#999',
              label: {
                text: 'Hoje',
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
              },
            },
          ],
        },
        stroke: { show: true, width: 2, colors: ['transparent'] },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          categories: dados.map((c) => new Date(c.year, c.month - 1, c.day).getTime()),
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            },
          },
        },
        colors: ['#1876d2', '#d23418', '#3ad218', 'yellow'],
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyMaskNeg.format(val);
            },
          },
          x: {
            format: 'dd MMM yyyy',
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            pan: true,
            reset: true || '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
        },
      },
    }),
    [dados]
  );

  return (
    <Card
      loading={isLoading}
      title="Fluxo de Caixa"
      size="small"
      style={{ maxWidth: 600, maxHeight: 400, minHeight: 400, minWidth: 600 }}
    >
      <Spin spinning={isLoading}>
        {dados.length > 0 && <ReactApexChart options={data.options} series={data.series} type="area" height={350} />}
        {dados.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Não foram encontrados lançamentos na competência e unidades escolhidas"
          >
            <Link to="/Lancar">Clique aqui para realizar lançamentos</Link>
          </Empty>
        )}
      </Spin>
    </Card>
  );
}
export default HomeFluxoCaixa;
