import moment from 'moment';
import axios from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetDRE = function (unidades: number[], competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_DRE_DRE',
      payload: axios.post(apiEndpoints.DRE.GET_DRE, {
        Unidades: unidades,
        competencia,
      }),
    });
  };
};
