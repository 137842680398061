import { message, notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { PlanoContasReducer } from '@reducers/reducers';

var initialState: PlanoContasReducer = {
  loadingplano: false,
  planocontas: [],
  sinteticas: [],
  loadingsinteticas: false,
  analiticas: [],
  loadinganaliticas: false,
  selectedAnaliticas: [],
};

const planocontasReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'SELECTED_ANALITICAS':
      state = {
        ...state,
        selectedAnaliticas: action.payload,
      };
      break;
    case 'ADD_SINTETICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadingsinteticas: true,
      };
      break;
    case 'ADD_SINTETICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadingsinteticas: false,
      };
      notification.error({ message: 'Erro ao criar conta' });
      break;
    case 'ADD_SINTETICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadingsinteticas: false,
        sinteticas: [...state.sinteticas, action.payload.data],
      };
      notification.success({ message: 'Conta criada com sucesso' });
      break;
    case 'ADD_ANALITICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadinganaliticas: true,
      };
      break;
    case 'ADD_ANALITICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadinganaliticas: false,
      };
      message.error('Erro ao criar conta');
      break;
    case 'ADD_ANALITICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadinganaliticas: false,
        analiticas: action.payload.data,
      };
      notification.success({ message: 'Conta criada com sucesso' });
      break;
    case 'UPDATE_ANALITICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadinganaliticas: true,
      };
      break;
    case 'UPDATE_ANALITICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadinganaliticas: false,
      };
      notification.error({ message: 'Erro ao atualizar conta' });
      break;
    case 'UPDATE_ANALITICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadinganaliticas: false,
        analiticas: state.analiticas.map((c) => (c.id === action.payload.data.id ? action.payload.data : c)),
      };
      notification.success({ message: 'Conta atualizada com sucesso' });
      break;
    case 'UPDATE_SINTETICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadingsinteticas: true,
      };
      break;
    case 'UPDATE_SINTETICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadingsinteticas: false,
      };
      notification.error({ message: 'Erro ao atualizar conta' });
      break;
    case 'UPDATE_SINTETICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadingsinteticas: false,
        sinteticas: state.sinteticas.map((c) => (c.id === action.payload.data.id ? action.payload.data : c)),
      };
      notification.success({ message: 'Conta atualizada com sucesso' });
      break;
    case 'MOVE_SINTETICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadingsinteticas: true,
      };
      break;
    case 'MOVE_SINTETICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadingsinteticas: false,
      };
      notification.error({ message: 'Erro ao mover conta' });
      break;
    case 'MOVE_SINTETICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadingsinteticas: false,
        sinteticas: action.payload.data,
      };
      notification.success({ message: 'Conta movida com sucesso' });
      break;
    case 'MOVE_ANALITICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadinganaliticas: true,
      };
      break;
    case 'MOVE_ANALITICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadinganaliticas: false,
      };
      notification.error({ message: 'Erro ao mover conta' });
      break;
    case 'MOVE_ANALITICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadinganaliticas: false,
        analiticas: action.payload.data,
      };
      notification.success({ message: 'Conta movida com sucesso' });
      break;
    case 'EXCLUIR_ANALITICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadinganaliticas: true,
      };
      break;
    case 'EXCLUIR_ANALITICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadinganaliticas: false,
      };
      notification.error({
        message: action.payload.response.data,
      });
      break;
    case 'EXCLUIR_ANALITICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadinganaliticas: false,
        analiticas: action.payload.data,
      };
      notification.success({ message: 'Conta excluída com sucesso' });
      break;
    case 'EXCLUIR_ANALITICAS_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadinganaliticas: true,
      };
      break;
    case 'EXCLUIR_ANALITICAS_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadinganaliticas: false,
      };
      notification.error({ message: action.payload.response.data });
      break;
    case 'EXCLUIR_ANALITICAS_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadinganaliticas: false,
        analiticas: action.payload.data,
      };
      notification.success({ message: 'Conta excluída com sucesso' });
      break;
    case 'EXCLUIR_SINTETICA_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadingsinteticas: true,
      };
      break;
    case 'EXCLUIR_SINTETICA_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadingsinteticas: false,
      };
      notification.error({ message: action.payload.response.data });
      break;
    case 'EXCLUIR_SINTETICA_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadingsinteticas: false,
        sinteticas: action.payload.data,
      };
      notification.success({ message: 'Conta excluída com sucesso' });
      break;
    case 'GET_ANALITICAS_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadinganaliticas: true,
      };
      break;
    case 'GET_ANALITICAS_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadinganaliticas: false,
      };
      notification.error({ message: 'Erro ao carregar contas analíticas' });
      break;
    case 'GET_ANALITICAS_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadinganaliticas: false,
        analiticas: action.payload.data,
      };
      break;
    case 'GET_SINTETICAS_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadingsinteticas: true,
      };
      break;
    case 'GET_SINTETICAS_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadingsinteticas: false,
      };
      notification.error({ message: 'Erro ao carregar contas sintéticas' });
      break;
    case 'GET_SINTETICAS_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadingsinteticas: false,
        sinteticas: action.payload.data,
      };
      break;
    case 'GET_PLANO_CONTAS_PLANOCONTAS_PENDING':
      state = {
        ...state,
        loadingplano: true,
      };
      break;
    case 'GET_PLANO_CONTAS_PLANOCONTAS_REJECTED':
      state = {
        ...state,
        loadingplano: false,
      };
      notification.error({ message: 'Erro ao carregar plano de contas' });
      break;
    case 'GET_PLANO_CONTAS_PLANOCONTAS_FULFILLED':
      state = {
        ...state,
        loadingplano: false,
        planocontas: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default planocontasReducer;
