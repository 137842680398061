import { Button, DatePicker, Drawer, Form, Radio, Segmented, Select } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { ChangeTituloContasPG, GetContasExtrato, GetTitulosContasPG, SetFiltros } from '@actions/contasPGActions';
import { Link } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { serialize } from '@utils/queryString';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface ContasPGFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
export interface ContasPGFormData {
  modo: string;
  tipo?: number;
  status: number;
  unidades: number[];
  pessoas: number[];
  datainicial?: moment.Moment;
  datafinal?: moment.Moment;
  contatitle?: string;
}
function ContasPGForm({ visible, setVisible }: ContasPGFormProps) {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.general);
  const contaspg = useAppSelector((state) => state.contaspg);
  const pessoas = useAppSelector((state) => state.pessoas);
  const [form] = Form.useForm<ContasPGFormData>();

  useEffect(() => {
    if (general.unidades.length === 1) {
      form.setFieldValue('unidades', [general.unidades[0].id]);
      dispatch(GetContasExtrato([general.unidades[0].id]));
    }
  }, [general.unidades]);
  useEffect(() => {
    form.setFieldValue('unidades', contaspg.unidades);
  }, [contaspg.unidades]);
  return (
    <Drawer open={visible} onClose={() => setVisible(false)} title="Buscar Contas Pagar/Receber">
      <Form
        layout="vertical"
        form={form}
        onValuesChange={(_, values) => dispatch(SetFiltros(values))}
        initialValues={contaspg.filtros}
        onFinish={(values) => dispatch(GetTitulosContasPG(values))}
      >
        <Form.Item label="Agrupamento" name="modo">
          <Segmented options={['Geral', 'Pessoa', 'Unidade']} />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Radio.Group onChange={(e) => dispatch(ChangeTituloContasPG(e.target.value))}>
            <Radio value={0}>Pagar</Radio>
            <Radio value={1}>Receber</Radio>
            <Radio value={2}>Todos</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Titulos" name="status">
          <Radio.Group onChange={(e) => dispatch(ChangeTituloContasPG(e.target.value))}>
            <Radio value={0}>Abertos</Radio>
            <Radio value={1}>Vencidos</Radio>
            <Radio value={2}>Baixados</Radio>
            <Radio value={3}>Em dia</Radio>
            <Radio value={4}>Todos</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Unidade de Negócio" name="unidades">
          <Select
            showSearch
            mode="multiple"
            maxTagCount={3}
            optionFilterProp="label"
            style={{ width: '100%' }}
            disabled={general.unidades.length === 1}
            placeholder="Unidade de Negócio"
            value={contaspg.unidades}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {general.unidades.length > 1 && (
              <Select.Option label="todos" key="all">
                TODOS
              </Select.Option>
            )}
            {general.unidades.map((unidade) => (
              <Select.Option key={unidade.id} label={unidade.nome} value={unidade.id}>
                {unidade.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Vencimento">
          <Form.Item label="Data Inicial" name="datainicial">
            <DatePicker style={{ minWidth: 180 }} format="DD/MM/YYYY" placeholder="Início" allowClear={false} />
          </Form.Item>
          <Form.Item label="Data Final" name="datafinal">
            <DatePicker style={{ minWidth: 180 }} format="DD/MM/YYYY" placeholder="Fim" allowClear={false} />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Filtrar por Pessoa" name="pessoas">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Pessoas"
            defaultValue={contaspg.selectedpessoas}
            filterOption={true}
            optionFilterProp="label"
          >
            {pessoas.pessoasGrouped.map((grupo) => (
              <Select.OptGroup
                label={
                  grupo.grupo === 0
                    ? 'Fornecedores'
                    : grupo.grupo === 1
                    ? 'Clientes'
                    : grupo.grupo === 2
                    ? 'Colaboradores'
                    : 'Sócios'
                }
                key={grupo.grupo}
              >
                {grupo.pessoas.map((pessoa) => (
                  <Select.Option key={pessoa.id} value={pessoa.id} label={pessoa.nomeRazao + pessoa.fantasia}>
                    {pessoa.nomeRazao}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Button block type="primary" htmlType="submit">
          Mostrar Extrato
        </Button>
        <Link to={`/PrintContasPG${serialize(contaspg.filtros)}`} target="_blank">
          <Button block size="small" icon={<PrinterOutlined />}>
            Imprimir
          </Button>
        </Link>
      </Form>
    </Drawer>
  );
}

export default ContasPGForm;
