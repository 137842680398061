import { useEffect } from 'react';
import { Form, Spin, Select, DatePicker, Button, Input, message, Drawer } from 'antd';
import moment from 'moment';
import { moneyMask } from '@utils/masks';
import { LancamentosControllerInterface } from '@controllers/controllers';

const { Option, OptGroup } = Select;
interface FormFields {
  unidades: number[];
  competencia: moment.Moment;
  transacaoinicial: moment.Moment;
  transacaofim: moment.Moment;
  valor: string;
  selectedpessoas: number[];
  blocoid: string;
  descricao: string;
  documento: string;
}
function BuscaLancamentos({ controller }: { controller: LancamentosControllerInterface }) {
  const { data, actions } = controller();
  const [form] = Form.useForm<FormFields>();
  useEffect(() => {
    if (data.unidades.length === 1) {
      form.setFieldsValue({ unidades: [data.unidades[0].id] });
    }
  }, [data.unidades, form]);

  const handleSelectAll = (value: any[]) => {
    if (value && value.length && value.filter((u) => u === 'all').length) {
      if (value.length === data.unidades.map((u) => u.id).length + 1) {
        return [];
      }
      return [...data.unidades.map((u) => u.id)];
    }
    return value;
  };
  const exportview = () => {
    if (!form.getFieldValue('transacaoinicial') || !form.getFieldValue('transacaofim')) {
      message.error('Escolha um período de no máximo no 90 dias');
    } else if (form.getFieldValue('transacaofim').diff(form.getFieldValue('transacaoinicial'), 'days') > 90) {
      message.error('Período não pode ultrapassar 90 dias');
    } else {
      actions.Exportar(
        form.getFieldValue('unidades'),
        form.getFieldValue('transacaoinicial'),
        form.getFieldValue('transacaofim'),
        form.getFieldValue('selectedpessoas'),
        form.getFieldValue('blocoid'),
        form.getFieldValue('descricao'),
        form.getFieldValue('valor'),
        form.getFieldValue('documento')
      );
    }
  };

  return (
    <Drawer open={data.formview} onClose={() => actions.Form()} title="Buscar Lançamentos">
      <Spin spinning={data.waiting}>
        <Form
          form={form}
          initialValues={{ unidades: [], selectedpessoas: [] }}
          layout="vertical"
          style={{ padding: 10 }}
          onFinish={(values: FormFields) => {
            actions.SetFiltrosForm({
              ...values,
              unidades: values.unidades.filter((c) => c !== undefined),
              competencia: values.competencia ? moment(values.competencia).format('YYYY-MM') : null,
              transacaoinicial: values.transacaoinicial ? moment(values.transacaoinicial).format('YYYY-MM-DD') : null,
              transacaofinal: values.transacaofim ? moment(values.transacaofim).format('YYYY-MM-DD') : null,
              valor: values.valor ? moneyMask.normalize(values.valor, '') : null,
              pessoas: values.selectedpessoas,
              bloco: values.blocoid,
              descricao: values.descricao,
              documento: values.documento,
            });
          }}
        >
          <Form.Item
            label="Unidade de Negócio"
            name="unidades"
            style={{ display: data.unidades.length > 1 ? 'block' : 'none' }}
          >
            <Select
              value={data.unidades}
              onChange={(value) => form.setFieldsValue({ unidades: handleSelectAll(value) })}
              mode="multiple"
              maxTagCount={3}
              optionFilterProp="label"
              placeholder="Unidades de Negócio"
            >
              {false && (
                <Option label="todos" value="all" key="all">
                  TODOS
                </Option>
              )}
              {data.unidades.map((unidade) => (
                <Option label={unidade.nome} key={unidade.id}>
                  {unidade.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="transacaoinicial" label="Data da Transação">
            <DatePicker
              placeholder="Início"
              format="DD/MM/YYYY"
              disabledDate={(startDate) => startDate >= moment(form.getFieldValue('transacaofim'))}
            />
          </Form.Item>
          <Form.Item name="transacaofim">
            <DatePicker
              placeholder="Fim"
              format="DD/MM/YYYY"
              disabledDate={(endDate) => endDate <= moment(form.getFieldValue('transacaoinicial'))}
            />
          </Form.Item>
          <Form.Item name="selectedpessoas" label="Pessoas">
            <Select filterOption={true} optionFilterProp="label" placeholder="Pessoas" mode="multiple">
              {data.pessoasGrouped.map((grupo) => (
                <OptGroup
                  label={
                    grupo.grupo === 0
                      ? 'Fornecedores'
                      : grupo.grupo === 1
                      ? 'Clientes'
                      : grupo.grupo === 2
                      ? 'Colaboradores'
                      : 'Sócios'
                  }
                  key={grupo.grupo}
                >
                  {grupo.pessoas.map((pessoa) => (
                    <Option key={pessoa.id} label={pessoa.nomeRazao + pessoa.fantasia}>
                      {pessoa.nomeRazao}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="blocoid"
            label="Bloco"
            rules={[{ pattern: /^(?:[A-z]{3})\d*$/g, message: 'Formato incorreto' }]}
          >
            <Input placeholder="ID do Bloco (ex.: DAd123)" />
          </Form.Item>
          <Form.Item name="valor" label="Valor" normalize={(value) => moneyMask.format(moneyMask.normalize(value, ''))}>
            <Input />
          </Form.Item>
          <Form.Item name="descricao" label="Descrição">
            <Input />
          </Form.Item>
          <Form.Item name="documento" label="Documento">
            <Input />
          </Form.Item>
          <hr />
          <Button
            block
            style={{ marginBottom: 8 }}
            size="small"
            onClick={() => {
              form.resetFields();
            }}
            type="default"
          >
            Limpar
          </Button>
          <span />
          <Button
            block
            loading={data.exportinglancamentos}
            style={{ marginBottom: 8, marginLeft: 0 }}
            size="small"
            onClick={exportview}
            type="default"
          >
            Exportar
          </Button>
          <span />
          <Button block style={{ marginLeft: 0 }} htmlType="submit" type="primary">
            Buscar Lançamentos
          </Button>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default BuscaLancamentos;
