import { Permission } from '@hooks/usePermissions';
import { Button, Input, Typography, Popconfirm, Drawer, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getColumnSearchProps } from '@utils/filterandsort';
import { Produto } from '@models/Produto';
import { ProdutosControllerInterface } from '@controllers/controllers';

const { Text } = Typography;
function NovoGrupoForm({ controller }: { controller: ProdutosControllerInterface }) {
  const { actions, data } = controller();
  return (
    <Drawer
      title="Grupos de Produtos"
      width={400}
      closable={true}
      onClose={() => actions.NovoGrupo()}
      open={data.novogrupovisible}
    >
      <div>
        <Permission permission="produto.add">
          <Input.Group compact>
            <Input
              style={{ width: '90%' }}
              placeholder="Criar novo grupo"
              value={data.novogrupo}
              onChange={(e) => actions.NovoGrupoChange(e.target.value)}
            />
            <Button
              style={{ width: '10%' }}
              loading={data.addgrupoloading}
              onClick={() => actions.CriarGrupo()}
              icon={<PlusOutlined />}
            />
          </Input.Group>
        </Permission>
        <Permission permission="produto.list">
          <Table
            loading={data.excluirgrupoloading}
            rowKey="id"
            scroll={{ y: '70vh' }}
            size="small"
            pagination={false}
            dataSource={data.grupos}
          >
            <Table.Column
              title="Editar"
              width={60}
              render={(_, row: Produto) => (
                <Permission permission="produto.delete">
                  <Popconfirm
                    key="1"
                    title="Tem certeza que deseja remover o grupo?"
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={() => actions.Excluir(row.id)}
                  >
                    <Button size="small" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Permission>
              )}
            />
            <Table.Column
              title="Descrição"
              sorter={true}
              dataIndex="descricao"
              {...getColumnSearchProps('descricao', 'string', 'Descrição', undefined, undefined)}
              render={(text, row: Produto) => (
                <>
                  <Permission permission="produto.edit">
                    {row.codigo + ' - '}
                    <Text
                      editable={{
                        onChange: (value) => actions.Editar(row.id, value),
                      }}
                    >
                      {text}
                    </Text>
                  </Permission>
                </>
              )}
            />
          </Table>
        </Permission>
      </div>
    </Drawer>
  );
}

export default NovoGrupoForm;
