import {
  GerarBalanco2,
  HideLancamentosBalanco,
  ResetBalanco,
  SetCompetenciaBalanco,
  SetUnidadesBalanco,
  ShowBalancoForm,
  ShowLancamentosBalanco,
} from '@actions/balancoActions';
import { GetUnidades } from '@actions/generalActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Permission } from '@hooks/usePermissions';
import { handleSelectAll } from '@utils/selectUnidades';
import { Button } from 'antd';
import { useEffect } from 'react';
import { BalancoControllerInterface } from './controllers';

export const BalancoController: BalancoControllerInterface = () => {
  const balanco = useAppSelector((state) => state.balanco);
  const { unidades } = useAppSelector((state) => state.general);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Balanço',
        subTitle:
          'Gerar um balanço financeiro completo para uma ou mais unidades de negócio. Clique na conta para ver um extrato ou nos valores para ver os lançamentos',
        extras: [
          <Permission permission="balanco.list" key={1}>
            <Button size="small" type="primary" onClick={() => dispatch(ShowBalancoForm())}>
              Gerar Balanço
            </Button>
          </Permission>,
        ],
      })
    );
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Nambi - Balanço';
    dispatch(GetUnidades(true, true));
    dispatch(ResetBalanco());
  }, [dispatch]);
  const ShowLancamentos: (nivel: number, id: number, tempo: number) => void = (nivel, id, tempo) =>
    dispatch(ShowLancamentosBalanco(nivel, id, balanco.unidades, tempo, balanco.competencia.format('YYYY-MM')));
  const HideLancamentos: () => void = () => dispatch(HideLancamentosBalanco());
  const ShowForm: () => void = () => dispatch(ShowBalancoForm());
  const ExcluirLancamento: (ids: number[]) => void = (ids) => dispatch(ExcluirLancamento(ids));
  const SelectUnidade: (keys: number[]) => void = (keys) =>
    dispatch(SetUnidadesBalanco(handleSelectAll(keys, unidades)));
  const SetCompetencia: (date: moment.Moment) => void = (date) => dispatch(SetCompetenciaBalanco(date));
  const Gerar: (tipo: number) => void = (tipo) =>
    dispatch(GerarBalanco2({ unidades: balanco.unidades, competencia: balanco.competencia.format('YYYY-MM'), tipo }));
  return {
    data: { balanco, unidades },
    actions: {
      ShowLancamentos,
      HideLancamentos,
      ShowForm,
      ExcluirLancamento,
      SelectUnidade,
      SetCompetencia,
      Gerar,
    },
  };
};
