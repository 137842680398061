import { useState } from 'react';
import { Permission } from '@hooks/usePermissions';
import { Table, Button, Popconfirm, Input, Tooltip } from 'antd';
import { moneyMaskNeg, doubleMask } from '@utils/masks';
import { SearchOutlined, EditOutlined, BarsOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { ColumnsType } from 'antd/lib/table';
import { Produto } from '@models/Produto';
import { ProdutosControllerInterface } from '@controllers/controllers';

function TabelaProdutos({ controller }: { controller: ProdutosControllerInterface }) {
  const { data, actions } = controller();
  const [filter, setFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState<any>(null);
  const columns: ColumnsType<Produto> = [
    {
      render: (_, record) => (
        <div>
          <Permission permission="produto.edit">
            <Tooltip title="Editar">
              <Button size="small" icon={<EditOutlined />} onClick={() => actions.Load(record.id)} />
            </Tooltip>
          </Permission>
          <Tooltip title="Saldos por Conta">
            <Button
              key={`${record.id}extrato`}
              size="small"
              icon={<BarsOutlined />}
              onClick={() => actions.SaldoPorConta(record.id, true)}
            />
          </Tooltip>
          <Permission permission="produto.delete">
            <Tooltip title="Excluir">
              <Popconfirm title="Está certo disso?" onConfirm={() => actions.Delete(record.id)}>
                <Button key={`${record.id}delete`} size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Permission>
        </div>
      ),
      width: 100,
    },
    {
      title: 'Grupo',
      dataIndex: 'grupo',
      ellipsis: true,
      sorter: (a, b) => sortAlpha(a.grupo, b.grupo),
      ...getColumnSearchProps('grupo', 'string', 'Grupo', filters),
    },
    {
      title: 'Subgrupo',
      dataIndex: 'subgrupo',
      ellipsis: true,
      sorter: (a, b) => sortAlpha(a.subgrupo, b.subgrupo),
      ...getColumnSearchProps('subgrupo', 'string', 'Subgrupo', filters),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      width: '30%',
      ellipsis: true,
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchProps('descricao', 'string', 'Descrição', filters),
      render: (text, row) => row.codigo + ' - ' + text,
    },
    {
      title: 'Unidade',
      dataIndex: 'unidade',
      align: 'center',
      sorter: (a, b) => sortAlpha(a.unidade, b.unidade),
    },
    {
      title: 'Custo Médio Atual',
      dataIndex: 'custoMedioAtual',
      align: 'right',
      sorter: (a, b) => a.custoMedioAtual - b.custoMedioAtual,
      render: (text) => moneyMaskNeg.format(text),
    },
    {
      title: 'Custo Médio Vendido',
      dataIndex: 'custoMedioVendido',
      align: 'right',
      sorter: (a, b) => a.custoMedioVendido - b.custoMedioVendido,
      render: (text) => moneyMaskNeg.format(text),
    },
    {
      title: 'Saldo (Valor)',
      dataIndex: 'saldoValor',
      align: 'right',
      sorter: (a, b) => a.saldoValor - b.saldoValor,
      render: (text) => moneyMaskNeg.format(text),
    },
    {
      title: 'Saldo (Quantidade)',
      dataIndex: 'saldoQuantidade',
      align: 'right',
      sorter: (a, b) => a.saldoQuantidade - b.saldoQuantidade,
      render: (text) => doubleMask.format(text),
    },
  ];

  return (
    <div>
      <Permission permission="produto.list">
        <div style={{ textAlign: 'right' }}>
          <Input
            style={{ width: 200 }}
            suffix={<SearchOutlined />}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Buscar"
            allowClear
          />
        </div>
        <Table
          size="small"
          id="produtosTable"
          scroll={{ y: 'calc(100vh - 260px)', x: 1024 }}
          rowKey="id"
          columns={columns}
          onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
          dataSource={filterobject(data.produtosAll, filter)}
          loading={data.produtosloading}
          pagination={{
            position: ['bottomRight'],
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: [10, 25, 50],
            showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          }}
        />
      </Permission>
    </div>
  );
}
export default TabelaProdutos;
