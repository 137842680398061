import home1 from '../../assets/img/Home/home1.jpg';
import home2 from '../../assets/img/Home/home2.jpg';
import home3 from '../../assets/img/Home/home3.jpg';
import home4 from '../../assets/img/Home/home4.jpg';
import logo from '../../assets/img/logo.png';
import dre from '../../assets/img/nambi_dre.png';
import balanco from '../../assets/img/nambi_balanco.png';
import contaspg from '../../assets/img/nambi_contaspg.png';
import extrato from '../../assets/img/nambi_extrato.png';
import fluxo from '../../assets/img/nambi_fluxo.png';
import permissoes from '../../assets/img/nambi_permissoes.png';

import { Animate } from '@hooks/useAnimation';
import '../../assets/scss/Home.scss';
import { CSSProperties, ReactNode } from 'react';
import { Button, Carousel } from 'antd';
import { Link } from 'react-router-dom';

const Session = ({
  image,
  children,
  title,
  invert = false,
}: {
  image?: any;
  children: ReactNode;
  title: string;
  invert?: boolean;
  style?: CSSProperties;
}) => {
  return (
    <div className={`session ${invert ? 'invert' : ''}`}>
      {image && (
        <div className="image">
          <img src={image} alt={image} />
        </div>
      )}
      <div className="body">
        <div className="title">{title}</div>
        <div className="text">{children}</div>
      </div>
    </div>
  );
};

const Home = function () {
  return (
    <div className="homeLayout">
      <div className="box">
        <div className="content">
          <div className="title"></div>
          <div className="description">
            Apresentamos um sistema web revolucionário para gestão financeira de empresas. Com ele, sua empresa terá uma
            gestão financeira eficiente, simplificada e inteligente, possibilitando gerar relatórios complexos em tempo
            real com lançamentos muito simples.
          </div>
          <Link to="/Empresa" style={{ width: 'fit-content' }}>
            <Button type="default" ghost>
              Experimente Grátis
            </Button>
          </Link>
        </div>
        <div className="image">
          <img src={logo} alt={logo} />
        </div>
      </div>
      <Animate effect="fadeLeft">
        <Session image={home1} title="">
          O Nambi é uma solução completa para as empresas que desejam ter uma visão completa da sua saúde financeira e
          tomada de decisão assertiva. Com funcionalidades intuitivas, é possível realizar lançamentos financeiros em
          poucos cliques, eliminando a necessidade de planilhas e processos manuais que demandam muito tempo e recursos.
        </Session>
      </Animate>
      <Animate effect="fadeRight" style={{ margin: 'auto' }}>
        <Session image={home2} title="" invert>
          O sistema é capaz de gerar relatórios complexos e detalhados em tempo real, permitindo uma visão completa da
          situação financeira da empresa. Com isso, é possível identificar rapidamente possíveis problemas e tomar
          decisões assertivas para corrigi-los, evitando prejuízos e maximizando os lucros.
        </Session>
      </Animate>
      <Carousel autoplay>
        <div className="carousel-img">
          <div className="title">Fluxo de Caixa</div>
          <img src={fluxo} alt={fluxo} />
        </div>
        <div className="carousel-img">
          <div className="title">Demonstrativo de Resultado</div>
          <img src={dre} alt={dre} />
        </div>
        <div className="carousel-img">
          <div className="title">Controle de permissões dos usuários</div>
          <img src={permissoes} alt={permissoes} />
        </div>
        <div className="carousel-img">
          <div className="title">Contas a Pagar/Receber</div>
          <img src={contaspg} alt={contaspg} />
        </div>
        <div className="carousel-img">
          <div className="title">Extrato</div>
          <img src={extrato} alt={extrato} />
        </div>
        <div className="carousel-img">
          <div className="title">Balanço Financeiro</div>
          <img src={balanco} alt={balanco} />
        </div>
      </Carousel>
      <Animate effect="fadeRight" style={{ margin: 'auto' }}>
        <Session image={null} title="" invert>
          Além disso, o Nambi é uma solução versátil e adaptável, atendendo empresas de todos os tamanhos e segmentos.
          Sua interface moderna e amigável permite uma utilização intuitiva e de fácil aprendizado.
        </Session>
      </Animate>
      <Animate effect="fadeRight" style={{ margin: 'auto' }}>
        <Session image={home4} title="" invert>
          Com o Nambi, sua empresa terá uma gestão financeira inteligente e eficiente, proporcionando mais segurança e
          agilidade na tomada de decisões.
        </Session>
      </Animate>
      <Animate effect="fadeRight" style={{ margin: 'auto' }}>
        <Session image={home3} title="" invert>
          <div style={{ marginBottom: 10 }}>
            Não perca mais tempo com processos manuais e planilhas complicadas. Experimente agora mesmo o Nambi - Gestão
            Financeira Inteligente e veja como é fácil e eficiente gerenciar as finanças da sua empresa.
          </div>
          <Link to="/Empresa" style={{ width: 'fit-content' }}>
            <Button type="default" ghost>
              Experimente Grátis
            </Button>
          </Link>
        </Session>
      </Animate>
    </div>
  );
};

export default Home;
