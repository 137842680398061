import { ReactNode } from 'react';
import { Layout, Menu } from 'antd';
import {
  ShoppingOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  AreaChartOutlined,
  ToolOutlined,
  BankOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  PicLeftOutlined,
  ProfileOutlined,
  HistoryOutlined,
  UsergroupAddOutlined,
  ControlOutlined,
  ImportOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';
import logo from '../assets/img/logo.png';

import { Logout, goToContextos } from '@actions/userActions';
import { usePermission } from '@hooks/usePermissions';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
const { Header } = Layout;
interface MenuItem {
  label: string | ReactNode;
  icon?: null | ReactNode;
  key: string;
  authorize: boolean;
  children?: MenuItem[];
}
function NavBar() {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { HasPermission } = usePermission();
  const empresaMenu: MenuItem[] = [
    {
      label: 'Lançamentos',
      icon: <UnorderedListOutlined />,
      key: 'Lancamentos',
      authorize: true,
      children: [
        {
          label: <Link to="/Lancar">Lançar</Link>,
          icon: <PlusCircleOutlined />,
          key: '/Lancar',
          authorize: HasPermission('lancamento.add'),
        },
        {
          label: <Link to="/Lancamentos">Lançamentos</Link>,
          icon: <OrderedListOutlined />,
          key: '/Lancamentos',
          authorize: HasPermission('lancamento.list'),
        },
        {
          label: <Link to="/Importar">Importações</Link>,
          key: '/Importar',
          icon: <ImportOutlined />,
          authorize: HasPermission('importar.add'),
        },
        {
          label: <Link to="/ConciliarAll">Conciliação Bancária</Link>,
          key: '/Conciliar',
          icon: <FileSyncOutlined />,
          authorize: HasPermission('importar.add'),
        },
      ],
    },
    {
      label: 'Cadastros',
      icon: <PicLeftOutlined />,
      key: 'Cadastros',
      authorize: true,
      children: [
        {
          label: <Link to="/Bancarias">Contas Bancárias</Link>,
          icon: <BankOutlined />,
          key: '/Bancarias',
          authorize: HasPermission('bancaria.list'),
        },
        {
          label: <Link to="/Pessoas">Pessoas</Link>,
          icon: <TeamOutlined />,
          key: '/Pessoas',
          authorize: HasPermission('pessoa.list'),
        },
        {
          label: <Link to="/Produtos">Produtos</Link>,
          icon: <ShoppingOutlined />,
          key: '/Produtos',
          authorize: HasPermission('produto.list'),
        },
        {
          label: <Link to="/Servicos">Servicos</Link>,
          icon: <ToolOutlined />,
          key: '/Servicos',
          authorize: HasPermission('servico.list'),
        },
      ],
    },
    {
      label: 'Relatorios',
      icon: <AreaChartOutlined />,
      key: 'Relatorios',
      authorize: true,
      children: [
        { label: <Link to="/Balanco">Balanço</Link>, key: '/Balanco', authorize: HasPermission('balanco.list') },
        { label: <Link to="/DRE">DRE</Link>, key: '/DRE', authorize: HasPermission('dre.list') },
        { label: <Link to="/Extrato">Extrato</Link>, key: '/Extrato', authorize: HasPermission('extrato.list') },
        {
          label: <Link to="/ContasPG">Contas Pagar/Receber</Link>,
          key: '/ContasPG',
          authorize: HasPermission('contaspg.list'),
        },
        {
          label: <Link to="/Orcamentos">Orçamentos</Link>,
          key: '/Orcamentos',
          authorize: HasPermission('orcamentos.list'),
        },
        {
          label: <Link to="/Fluxocaixa">Fluxo de Caixa</Link>,
          key: '/Fluxocaixa',
          authorize: HasPermission('fluxocaixa.list'),
        },
      ],
    },
    {
      label: 'Configurações',
      icon: <SettingOutlined />,
      key: 'Configurações',
      authorize: true,
      children: [
        {
          label: <Link to="/Implantar">Implantação de Saldos</Link>,
          key: '/Implantar',
          authorize: HasPermission('implantacaosaldo.add'),
        },

        {
          label: <Link to="/Empresa">Empresa</Link>,
          key: '/Empresa',
          authorize: HasPermission('empresa.add'),
        },
        {
          label: <Link to="/Planocontas">Plano de Contas</Link>,
          key: '/Planocontas',
          authorize: HasPermission('planocontas.add'),
        },
        {
          label: <Link to="/Usuarios">Usuários</Link>,
          key: '/Usuarios',
          icon: <UsergroupAddOutlined />,
          authorize: HasPermission('usuario.add'),
        },
        {
          label: <Link to="/Perfis">Perfis</Link>,
          key: '/Perfis',
          icon: <ControlOutlined />,
          authorize: HasPermission('perfis.add'),
        },
      ],
    },
    {
      label: <Link to="/Historico">Histórico</Link>,
      icon: <HistoryOutlined />,
      key: '/Historico',
      authorize: HasPermission('historico.list'),
    },
    {
      label: user.username,
      icon: <UserOutlined />,
      key: 'User',
      authorize: true,
      children: [
        { label: <Link to="/Usuario">Usuário</Link>, key: '/Usuario', icon: <SettingOutlined />, authorize: true },
        {
          label: (
            <Link to={''} onClick={() => dispatch(goToContextos())} key="contextos">
              Contas
            </Link>
          ),
          key: 'Usuario',
          icon: <ProfileOutlined />,
          authorize: !!(user.ngrupos && user.ngrupos > 1),
        },
        {
          label: (
            <Link to={''} onClick={() => dispatch(Logout())} key="logout">
              Sair
            </Link>
          ),
          key: 'logout',
          icon: <LogoutOutlined />,
          authorize: true,
        },
      ],
    },
  ];
  const inactiveUserMenu: MenuItem[] = [
    {
      label: user.username,
      key: 'User2',
      icon: <UserOutlined />,
      authorize: true,
      children: [
        {
          label: (
            <Link to={''} onClick={() => dispatch(goToContextos())} key="contextos">
              Contas
            </Link>
          ),
          key: 'Usuario',
          icon: <ProfileOutlined />,
          authorize: user.ngrupos && user.ngrupos > 1,
        },
        {
          label: (
            <Link to={''} onClick={() => dispatch(Logout())} key="logout">
              Sair
            </Link>
          ),
          key: 'Logout',
          authorize: true,
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];
  const sistemaMenu: MenuItem[] = [
    {
      label: <Link to="/Planos">Planos</Link>,
      key: '/Planos',
      authorize: true,
      icon: null,
      children: [],
    },
    {
      label: <Link to="/Contas">Contas</Link>,
      key: '/Contas',
      authorize: true,
      icon: null,
      children: [],
    },
    {
      label: <Link to="/Avisos">Avisos</Link>,
      key: '/Avisos',
      authorize: true,
      icon: null,
      children: [],
    },
    ...inactiveUserMenu,
  ];

  return (
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <div
        style={{
          width: '120px',
          float: 'left',
          margin: '11px 24px 11px 0',
        }}
      >
        <Link to="/">
          <img className="ant-menu-item" height="36px" src={logo} alt="logo" />
        </Link>
      </div>
      {user.isAuthenticated && user.tipocontexto === 'Empresa' && (
        <div
          style={{
            fontWeight: 'bold',
            textTransform: 'capitalize',
            maxWidth: '220px',
            float: 'left',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {user.nome}
        </div>
      )}
      {!user.isAuthenticated && (
        <Menu
          theme="light"
          mode="horizontal"
          activeKey={location.pathname === '/' ? undefined : location.pathname}
          style={{ justifyContent: 'right' }}
          items={[
            { label: <Link to="/About">Quem Somos</Link>, key: '/About' },
            { label: <Link to="/Contratar">Contratar</Link>, key: '/Contratar' },
            { label: <Link to="/Planos">Planos</Link>, key: '/Planos' },
            { label: <Link to="/Contato">Contato</Link>, key: '/Contato' },
            { label: <Link to="/Entrar">Entrar</Link>, key: '/Entrar', icon: <UserOutlined /> },
          ]}
        />
      )}
      {user.isAuthenticated &&
        (user.grupoid === -1 || user.subscription.status !== 'ACTIVE') &&
        user.tipocontexto !== 'Sistema' && (
          <Menu
            theme="light"
            mode="horizontal"
            activeKey={location.pathname}
            style={{ justifyContent: 'right' }}
            items={inactiveUserMenu
              .filter((c) => !!c.authorize)
              .map((c) => ({ ...c, children: c.children && c.children.filter((d) => !!d.authorize) }))}
          />
        )}
      {user.isAuthenticated && user.tipocontexto === 'Empresa' && user.subscription.status === 'ACTIVE' && (
        <Menu
          theme="light"
          mode="horizontal"
          activeKey={location.pathname}
          style={{ lineHeight: '64px', justifyContent: 'right' }}
          items={empresaMenu
            .filter((c) => !!c.authorize)
            .map((c) => ({ ...c, children: c.children && c.children.filter((d: any) => !!d.authorize) }))}
        />
      )}
      {user.isAuthenticated && user.tipocontexto === 'Sistema' && (
        <Menu
          theme="light"
          mode="horizontal"
          activeKey={location.pathname}
          style={{ lineHeight: '64px', justifyContent: 'right' }}
          items={sistemaMenu
            .filter((c) => !!c.authorize)
            .map((c) => ({ ...c, children: c.children && c.children.filter((d) => !!d.authorize) }))}
        />
      )}
    </Header>
  );
}

export default NavBar;
