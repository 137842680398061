import { apiEndpoints } from '@config/apiEndpoints';
import cliente from '@config/axios';
import { Pessoa } from '@models/Pessoa';
import { notification, Select, SelectProps } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

const GetPessoas = () => {
  return useQuery<Pessoa[], AxiosError<any>>(
    ['pessoas'],
    () =>
      cliente.get<Pessoa[]>(apiEndpoints.PESSOAS.GET_PESSOAS_LIST).then((res) => {
        return res.data;
      }),
    {
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      staleTime: 0,
      initialData: [],
    }
  );
};

function PessoaSelect(props: SelectProps) {
  const { data, isLoading } = GetPessoas();
  return (
    <Select
      {...props}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth || false}
      loading={props.loading || isLoading}
      allowClear={props.allowClear || true}
      showSearch={props.showSearch || true}
      optionFilterProp={props.optionFilterProp || 'children'}
      style={{ width: props?.style?.width || '100%' }}
    >
      <Select.OptGroup label="Fornecedores">
        {data
          .filter((c) => c.tipoPessoa === 0)
          .map((c) => (
            <Select.Option value={c.id} key={c.id}>
              {c.nomeRazao}
            </Select.Option>
          ))}
      </Select.OptGroup>
      <Select.OptGroup label="Colaborades">
        {data
          .filter((c) => c.tipoPessoa === 1)
          .map((c) => (
            <Select.Option value={c.id} key={c.id}>
              {c.nomeRazao}
            </Select.Option>
          ))}
      </Select.OptGroup>
      <Select.OptGroup label="Sócios">
        {data
          .filter((c) => c.tipoPessoa === 2)
          .map((c) => (
            <Select.Option value={c.id} key={c.id}>
              {c.nomeRazao}
            </Select.Option>
          ))}
      </Select.OptGroup>
      <Select.OptGroup label="Clientes">
        {data
          .filter((c) => c.tipoPessoa === 3)
          .map((c) => (
            <Select.Option value={c.id} key={c.id}>
              {c.nomeRazao}
            </Select.Option>
          ))}
      </Select.OptGroup>
    </Select>
  );
}
export default PessoaSelect;
