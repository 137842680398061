import { useAppSelector } from '@config/reduxStore';
import { Progress } from 'antd';

function ConciliarProgress() {
  const { data } = useAppSelector((state) => state.conciliar);
  if (data.length === 0) return <div />;
  return <Progress percent={Math.floor(100 * (data.filter((c) => c.bloco).length / data.length) * 1) / 1} />;
}

export default ConciliarProgress;
