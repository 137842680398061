import { DeleteConciliacao, GetConciliacoes } from '@actions/conciliarActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Button, Popconfirm, Progress, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IConciliacao } from '@models/Conciliar';
import { SetPageHeader } from '@actions/userActions';
import ConciliarNovo from './Conciliar.Novo';
import { orderDate } from '@utils/filterandsort';

function ConciliarAll() {
  const dispatch = useAppDispatch();
  const { loadingConciliacoes, conciliacoes } = useAppSelector((state) => state.conciliar);
  const [novoView, setNovoView] = useState(false);
  useEffect(() => {
    dispatch(GetConciliacoes());
    dispatch(
      SetPageHeader({
        title: 'Conciliações',
        subTitle: 'Conciliações bancárias',
        extras: [
          <Button key="0" size="small" icon={<PlusOutlined />} type="primary" onClick={() => setNovoView(true)}>
            Nova Conciliação
          </Button>,
        ],
      })
    );
  }, []);
  return (
    <div>
      <ConciliarNovo visible={novoView} setVisible={setNovoView} />
      <Table
        size="small"
        dataSource={conciliacoes}
        loading={loadingConciliacoes}
        rowKey="id"
        scroll={{ x: 1024, y: 'calc(100vh - 200px)' }}
      >
        <Table.Column
          dataIndex="id"
          width={60}
          align="center"
          render={(v) => (
            <Space>
              <Link to={`/Conciliar/${v}`}>
                <Button size="small" icon={<EditOutlined />} />
              </Link>
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteConciliacao(v))}>
                <Button size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
        <Table.Column
          title="Data"
          width={150}
          dataIndex="data"
          render={(v) => moment(v).format('DD/MM/YYYY HH:mm')}
          sorter={(a: IConciliacao, b) => orderDate(a.data, b.data)}
        />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column title="Linhas" dataIndex="linhas" width={80} align="center" />
        <Table.Column title="Lançadas" dataIndex="lancadas" width={80} align="center" />
        <Table.Column
          title="Progresso"
          align="center"
          width={100}
          render={(_, row: IConciliacao) => (
            <Progress percent={Math.floor(100 * (row.lancadas / row.linhas) * 1) / 1} />
          )}
        />
      </Table>
    </div>
  );
}

export default ConciliarAll;
