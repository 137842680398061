import cliente from '@config/axios';

import { notification } from 'antd';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const SetSubgruposGrupoid = function (grupoid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_SUBGRUPO_GRUPOID_PRODUTOS',
      payload: grupoid,
    });
  };
};
export const EditarGrupo = function (grupoid: number, descricao: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EDITAR_GRUPO_PRODUTOS',
      payload: cliente.post(apiEndpoints.PRODUTOS.EDIT_GRUPO, {
        grupoid: grupoid,
        descricao: descricao,
      }),
    });
  };
};
export const EditarSubGrupo = function (subgrupoid: number, grupoid: number, descricao: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EDITAR_SUBGRUPO_PRODUTOS',
      payload: cliente.post(apiEndpoints.PRODUTOS.EDIT_SUBGRUPO, {
        grupoid: grupoid,
        descricao: descricao,
        subgrupoid: subgrupoid,
      }),
    });
  };
};
export const ExcluirSubGrupo = function (grupoid: number, subgrupoid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_SUBGRUPO_PRODUTOS',
      payload: cliente.post(apiEndpoints.PRODUTOS.EXCLUIR_SUBGRUPO, {
        grupoid: grupoid,
        subgrupoid: subgrupoid,
      }),
    });
  };
};
export const ExcluirGrupo = function (grupoid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_GRUPO_PRODUTOS',
      payload: cliente.post(apiEndpoints.PRODUTOS.EXCLUIR_GRUPO, {
        grupoid: grupoid,
      }),
    });
  };
};

export const NovoGrupoVisible = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'NOVO_GRUPO_VISIBLE_PRODUTOS',
    });
  };
};
export const NovoSubGrupoVisible = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'NOVO_SUBGRUPO_VISIBLE_PRODUTOS',
    });
  };
};
export const ChangeNovoGrupo = function (value: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_NOVO_GRUPO_PRODUTOS',
      payload: value,
    });
  };
};
export const AddGrupo = function (descricao: string) {
  return function (dispatch: AppDispatch) {
    if (!descricao) notification.error({ message: 'Preencha o campo descrição' });
    else {
      dispatch({
        type: 'ADD_GRUPO_PRODUTOS',
        payload: cliente.post(apiEndpoints.PRODUTOS.ADD_GRUPO, {
          descricao: descricao,
        }),
      });
    }
  };
};
export const AddSubgrupo = function (descricao: string, grupoid: number) {
  return function (dispatch: AppDispatch) {
    if (!descricao) notification.error({ message: 'Preencha o campo descrição' });
    else if (!grupoid) notification.error({ message: 'Escolha um grupo' });
    else {
      dispatch({
        type: 'ADD_SUBGRUPO_PRODUTOS',
        payload: cliente.post(apiEndpoints.PRODUTOS.ADD_SUBGRUPO, {
          descricao: descricao,
          grupoid: grupoid,
        }),
      });
    }
  };
};
export const GetGrupos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_GRUPOS_PRODUTOS',
      payload: cliente.get(apiEndpoints.PRODUTOS.GET_GRUPOS),
    });
  };
};
export const GetProdutos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PRODUTOS',
      payload: cliente.get(apiEndpoints.PRODUTOS.GET_PRODUTOS),
    });
  };
};
export const SelectedProdutos = function (produtos: any[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_SELECTED_PRODUTOS',
      payload: produtos,
    });
  };
};
export const ChangeTabelaProdutos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FETCH_PRODUTOS',
      payload: cliente.get(apiEndpoints.PRODUTOS.GET_PRODUTOS_ALL),
    });
  };
};

export const ShowProdutos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_PRODUTOS',
      payload: cliente.get(apiEndpoints.PRODUTOS.GET_PRODUTOS_ALL),
    });
  };
};

export const DeleteProdutos = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_PRODUTO',
      payload: cliente.get(apiEndpoints.PRODUTOS.EXCLUIR_PRODUTOS, { params: { id } }),
    });
  };
};
export const HideProdutoForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_PRODUTO_FORM',
    });
  };
};

export const ShowProdutoForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_PRODUTO_FORM',
    });
  };
};
export const LoadProduto = function (data: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_PRODUTO',
      payload: data,
    });
  };
};
export const ShowSaldoPorConta = function (data: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_SALDOS_POR_CONTA',
      payload: data,
    });
  };
};
export const GetSaldosPorConta = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_SALDOS_POR_CONTA',
      payload: cliente.get(apiEndpoints.PRODUTOS.GET_SALDOS_POR_CONTA, { params: { id } }),
    });
  };
};

export const SalvarProduto = function (values: any) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'SALVAR_PRODUTO',
        payload: cliente.post(apiEndpoints.PRODUTOS.SALVAR_PRODUTO, values),
      });
    } else {
      dispatch({
        type: 'ADD_PRODUTO',
        payload: cliente.post(apiEndpoints.PRODUTOS.SALVAR_PRODUTO, values),
      });
    }
  };
};
