import { createAsyncThunk } from '@reduxjs/toolkit';
import { Plano } from '@models/Plano';
import { Aviso } from '@models/Aviso';
import { cliente } from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetContasSistema = createAsyncThunk('sistema/getcontassistema', async () =>
  cliente.get(apiEndpoints.SISTEMA.GET_CONTAS)
);
export const GetPlanos = createAsyncThunk('sistema/getplanos', async (ativo: boolean) =>
  cliente.get(apiEndpoints.SISTEMA.GET_PLANOS, { params: { ativo: ativo } })
);
export const GetAvisos = createAsyncThunk('sistema/getavisos', async () =>
  cliente.get(apiEndpoints.SISTEMA.GET_AVISOS)
);
export const ActivatePlano = createAsyncThunk('sistema/ActivatePlano', async (id: number) =>
  cliente.get(apiEndpoints.SISTEMA.ACTIVATE_PLANO, { params: { id: id } })
);

export const CriarAviso = createAsyncThunk('sistema/CriarAviso', async (data: Aviso) =>
  cliente.post(apiEndpoints.SISTEMA.CRIAR_AVISO, data)
);
export const SalvarPlano = createAsyncThunk('sistema/SalvarPlano', async (data: Plano) =>
  cliente.post(apiEndpoints.SISTEMA.SALVAR_PLANO, data)
);
