import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Segmented,
  Select,
  Spin,
  Table,
  Tooltip,
  TreeSelect,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import confirm from 'antd/lib/modal/confirm';
import {
  ReloadOutlined,
  PlusOutlined,
  DeleteOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  DownSquareOutlined,
  SwapOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  SelectOutlined,
  FileOutlined,
  CloseOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import SelectConta from './SelectConta';
import { doubleMask, moneyMask, moneyMaskNeg } from '@utils/masks';
import Titulos from './Titulos';
import { GetUnidades } from '@actions/generalActions';
import SelectUnidade from './SelectUnidade';
import { GetPessoasList } from '@actions/pessoasActions';
import SelectPessoa from './SelectPessoa';
import moment from 'moment';
import { GetProdutos, GetProdutosBaixa, RemoveTitulo, RemoveTitulos, ShowHideTitulos } from '@actions/lancarActions';
import { useSearchParams } from 'react-router-dom';
import { cliente } from '@config/axios';
import { PrepareLancarTela } from '../Importacao/ImportacaoUtils';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Lancamento } from '@models/Lancamento';
import { ContaAnaliticaTree } from '@models/ContaAnalitica';
export interface Parcela {
  valor: string;
  vencimento: moment.Moment;
  titulo: boolean;
  documento: string;
}
export interface LancamentoTipo0 {
  [key: string]: any;
  tipo: number;
  conta: null | string;
  contaID: null | string;
  valor: string;
  multa: string;
  juros: string;
  desconto: string;
  quantidade: string;
  competencia: string;
  titulo: boolean;
  unidade: string | null;
  unidadeID: null | number;
  baixou: string;
  baixaEstoque: boolean;
  estoque: boolean;
}
export interface LancamentoTipo1 {
  quando: number;
  unidade: null | string;
  unidadeID: null | number;
  conta: null;
  contaID: null | number;
  valor: string;
  quantidade: string;
  parcelas: number;
  periodoparcela: number;
  vparcela: string;
  parcela: Parcela[];
  onde: string | null;
  ondeID: string | null;
}
export interface LancamentoLoad {
  padrao: number;
  bloco: string;
  dataTransacao: moment.Moment;
  total0: number | string;
  total1: string;
  saldo: string;
  inverter: boolean;
  lancamentotipo0: LancamentoTipo0[];
  lancamentotipo1: LancamentoTipo1[];
}
function LancarViewNovo() {
  const dispatch = useAppDispatch();
  const {
    produtos,
    produtosbaixa,
    titulos,
    selectedtitulos,
    loadingbloco,
    loadingProdutosLancar,
    loadingProdutosBaixa,
  } = useAppSelector((state) => state.lancar);
  const { unidades, loadingUnidades } = useAppSelector((state) => state.general);
  const { loadingPessoas } = useAppSelector((state) => state.pessoas);
  const [parcelasView, setParcelasView] = useState<{ id: number | null }>({
    id: null,
  });
  const [selectConta, setSelectConta] = useState<{
    visible: boolean;
    index: null | number;
    tipo: null | number;
    lancamento: null | number;
    fluxo: null | number;
    movimentar: boolean;
    unidadeID: null | number;
    conta: ContaAnaliticaTree | null;
  }>({
    visible: false,
    index: null,
    lancamento: null,
    tipo: null,
    fluxo: null,
    unidadeID: null,
    movimentar: false,
    conta: null,
  });
  const [selectUnidade, setSelectUnidade] = useState<{ visible: boolean; index: null | number; tipo: null | number }>({
    visible: false,
    index: null,
    tipo: null,
  });
  const [selectPessoa, setSelectPessoa] = useState<{ visible: boolean; index: null | number; tipo: null | number }>({
    visible: false,
    index: null,
    tipo: null,
  });
  const [urlParams] = useSearchParams();
  const [form] = Form.useForm();
  const initial: (padraoatual: number) => LancamentoLoad = (padraoatual: number) => {
    return {
      padrao: 0,
      bloco: null,
      total0: moneyMask.format(0),
      total1: moneyMask.format(0),
      saldo: moneyMask.format(0),
      inverter: false,
      dataTransacao: moment(),
      lancamentotipo0: [
        {
          tipo: padraoatual === 1 ? 4 : 0,
          conta: null,
          valor: moneyMask.format(0),
          multa: moneyMask.format(0),
          juros: moneyMask.format(0),
          desconto: moneyMask.format(0),
          quantidade: doubleMask.format(0),
          competencia: moment().format('YYYY-MM-DD'),
          titulo: false,
          unidadeID: unidades.length === 1 ? unidades[0].id : null,
          unidade: unidades.length === 1 ? unidades[0].nome : null,
          pessoaID: null,
          contaID: null,
          baixou: null,
          baixaEstoque: null,
          estoque: null,
        },
      ],
      lancamentotipo1: [
        {
          quando: padraoatual === 1 ? 2 : 0,
          unidade: unidades.length === 1 ? unidades[0].nome : null,
          unidadeID: unidades.length === 1 ? unidades[0].id : null,
          ondeID: null,
          onde: null,
          valor: moneyMask.format(0),
          quantidade: doubleMask.format(0),
          parcelas: 1,
          periodoparcela: 0,
          vparcela: moneyMask.format(0),
          conta: null,
          contaID: null,
          parcela: [
            {
              valor: moneyMask.format(0),
              vencimento: moment(),
              titulo: false,
              documento: '',
            },
          ],
        },
      ],
    };
  };
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Lançar',
        subTitle: 'Novos lançamento e edição ' + (urlParams.get('bloco') || ''),
        extras: [
          <Button
            key={0}
            icon={<ReloadOutlined />}
            size="small"
            loading={loadingUnidades || loadingPessoas || loadingProdutosLancar || loadingProdutosBaixa}
            onClick={() => {
              dispatch(GetUnidades(true, true));
              dispatch(GetPessoasList());
              dispatch(GetProdutos());
              dispatch(GetProdutosBaixa());
            }}
          >
            Atualizar Cadastros
          </Button>,
          <Button size="small" key={2} onClick={() => form.resetFields()}>
            Limpar
          </Button>,
          <Button size="small" key={3} type="primary" onClick={() => form.submit()} loading={loadingbloco}>
            Salvar
          </Button>,
        ],
      })
    );
  }, [loadingbloco, loadingUnidades, loadingPessoas, loadingProdutosLancar, loadingProdutosBaixa]);
  useEffect(() => {
    dispatch(GetUnidades(true, true));
    dispatch(GetPessoasList());
    dispatch(GetProdutos());
    dispatch(GetProdutosBaixa());
    const Titulos = urlParams.get('Titulos');
    const Bloco = urlParams.get('bloco');
    const importid = urlParams.get('importid');
    if (importid) {
      const keys = JSON.parse(urlParams.get('keys') || '');
      cliente.post('/api/Importar/LoadTela', { importid, keys }).then((res) => {
        const dadosTela = PrepareLancarTela(res.data.values, res.data.campos, res.data.settings);
        form.setFieldsValue({ ...initial(0), ...dadosTela });
        calculateTotal(null, form.getFieldsValue());
      });
    }
    if (Titulos) {
      cliente.post('/api/Lancar/GetTitulo', { Titulos: JSON.parse(Titulos) }).then((res) => {
        form.setFieldsValue({
          lancamentotipo0: res.data.map((c: Lancamento) => ({
            quantidade: doubleMask.format(c.quantidade),
            titulo: true,
            tipo: c.valor > 0 ? 3 : 2,
            id: c.id,
            unidadeID: c.unidadeNegocioID,
            unidade: c.unidade,
            valor: c.valor >= 0 ? moneyMask.format(c.valor) : moneyMask.format(c.valor * -1),
            contaID: c.analiticaID,
            pessoaID: c.pessoaID,
            pessoa: c.pessoa,
            conta: c.analitica,
            juros: c.juros >= 0 ? moneyMask.format(c.juros) : moneyMask.format(c.juros * -1),
            multa: c.multa >= 0 ? moneyMask.format(c.multa) : moneyMask.format(c.multa * -1),
            desconto: c.desconto >= 0 ? moneyMask.format(c.desconto) : moneyMask.format(c.desconto * -1),
            documento: c.documento,
            competencia: moment(c.competencia).format('YYYY-MM-DD'),
            descricaotitulo: c.descricaoBloco,
          })),
        });
        calculateTotal(null, form.getFieldsValue());
      });
    }
    if (Bloco) {
      cliente
        .get('/api/Lancar/LoadBloco', {
          params: { bloco: Bloco },
        })
        .then((res) => {
          form.setFieldsValue({
            ...res.data,
            dataTransacao: moment(res.data.datatransacao),
            total0: moneyMask.format(res.data.total0),
            lancamentotipo0: res.data.lancamentotipo0.map((c: LancamentoTipo0) => ({
              ...c,
              valor: moneyMask.format(c.valor),
              juros: moneyMask.format(c.juros),
              multa: moneyMask.format(c.multa),
              contaID: 'n5' + c.contaID,
              desconto: moneyMask.format(c.desconto),
              quantidade: doubleMask.format(c.quantidade),
            })),
            lancamentotipo1: res.data.lancamentotipo1.map((c: LancamentoTipo1) => ({
              ...c,
              valor: moneyMask.format(c.valor),
              quantidade: doubleMask.format(c.quantidade),
              periodoparcela: 0,
              vparcela: moneyMask.format(0),
              ondeID: 'n5' + c.ondeID,
              parcela:
                c.parcela.length > 0
                  ? c.parcela.map((p: Parcela) => ({
                      ...p,
                      vencimento: moment(p.vencimento),
                      valor: moneyMask.format(p.valor),
                    }))
                  : initial(0)['lancamentotipo1'][0].parcela,
            })),
          });
          calculateTotal(null, form.getFieldsValue());
          //SetInitialFields(form.getFieldsValue());
        })
        .catch(() => notification.error({ message: 'Erro ao carregar bloco' }));
    }
  }, []);
  useEffect(() => {
    const ftitulos: Lancamento[] = titulos.filter((c) => selectedtitulos.includes(c.id as number));
    if (ftitulos.length > 0) {
      form.setFieldsValue({
        lancamentotipo0: [
          ...form.getFieldValue('lancamentotipo0')?.filter((d: LancamentoTipo0) => !d.titulo || d.baixou),
          ...ftitulos.map((c) => ({
            ...initial(0)['lancamentotipo0'][0],
            quantidade: doubleMask.format(c.quantidade),
            titulo: true,
            tipo: c.valor > 0 ? 3 : 2,
            id: c.id,
            unidadeID: c.unidadeNegocioID,
            unidade: c.unidade,
            valor: c.valor >= 0 ? moneyMask.format(c.valor) : moneyMask.format(c.valor * -1),
            contaID: c.analiticaID,
            pessoaID: c.pessoaID,
            pessoa: c.pessoa,
            conta: c.analitica,
            juros: c.juros >= 0 ? moneyMask.format(c.juros) : moneyMask.format(c.juros * -1),
            multa: c.multa >= 0 ? moneyMask.format(c.multa) : moneyMask.format(c.multa * -1),
            desconto: c.desconto >= 0 ? moneyMask.format(c.desconto) : moneyMask.format(c.desconto * -1),
            documento: c.documento,
            competencia: moment(c.competencia).format('YYYY-MM-DD'),
            descricaotitulo: c.descricaoBloco,
            baixaEstoque: c.baixaEstoque,
            estoque: c.estoque,
            baixou: c.baixou,
            baixadoPor: c.baixadoPor,
          })),
        ],
      });
      if (form.getFieldValue('lancamentotipo0').length === 0)
        form.setFieldsValue({
          lancamentotipo0: [
            ...form.getFieldValue('lancamentotipo0').filter((c: LancamentoTipo0) => c.baixou),
            initial(0)['lancamentotipo0'][0],
          ],
        });
      calculateTotal(null, form.getFieldsValue());
    }
  }, [selectedtitulos, titulos]);
  useEffect(() => {
    if (unidades.length === 1) {
      form.setFieldsValue({
        lancamentotipo0: form
          .getFieldValue('lancamentotipo0')
          .map((c: LancamentoTipo0) => ({ ...c, unidadeID: unidades[0].id })),
        lancamentotipo1: form
          .getFieldValue('lancamentotipo1')
          .map((c: LancamentoTipo1) => ({ ...c, unidadeID: unidades[0].id })),
      });
    }
  }, [unidades, form]);
  const setUnidade = (index: number, id: number, nome: string, tipo?: number | null) => {
    form.setFieldValue([`lancamentotipo${tipo}`, index, 'unidadeID'], id);
    form.setFieldValue([`lancamentotipo${tipo}`, index, 'unidade'], nome);
    form.setFieldValue([`lancamentotipo${tipo}`, index, tipo === 0 ? 'contaID' : 'ondeID'], null);
    form.setFieldValue([`lancamentotipo${tipo}`, index, tipo === 0 ? 'conta' : 'onde'], null);
    form.validateFields([
      [`lancamentotipo${tipo}`, index, tipo === 0 ? 'conta' : 'onde'],
      [`lancamentotipo${tipo}`, index, 'unidade'],
    ]);
  };
  const setPessoa = (index: number, id: number, nome: string, tipo: number) => {
    form.setFieldValue([`lancamentotipo${tipo}`, index, 'pessoaID'], id);
    form.setFieldValue([`lancamentotipo${tipo}`, index, 'pessoa'], nome);
  };
  const setConta = (index: number, id: number, nome: string, tipo: number, conta: ContaAnaliticaTree) => {
    form.setFieldValue([`lancamentotipo${tipo}`, index, tipo === 0 ? 'contaID' : 'ondeID'], id);
    form.setFieldValue([`lancamentotipo${tipo}`, index, tipo === 0 ? 'conta' : 'onde'], nome);
    form.setFieldValue([`lancamentotipo${tipo}`, index, 'estoque'], conta.estoque);
    form.setFieldValue([`lancamentotipo${tipo}`, index, 'baixaEstoque'], conta.baixaEstoque);
    form.validateFields([[`lancamentotipo${tipo}`, index, tipo === 0 ? 'conta' : 'onde']]);
  };
  const calculateTotal = (_: any, values: LancamentoLoad) => {
    const total0 = values.lancamentotipo0.reduce((a, b) => {
      if (b.tipo === 0 || b.tipo === 2 || b.tipo === 4) {
        return (
          a -
          Number(moneyMask.normalize(b.valor, '')) -
          Number(moneyMask.normalize(b.multa, '')) -
          Number(moneyMask.normalize(b.juros, '')) +
          Number(moneyMask.normalize(b.desconto, ''))
        );
      } else {
        return (
          a +
          Number(moneyMask.normalize(b.valor, '')) +
          Number(moneyMask.normalize(b.multa, '')) +
          Number(moneyMask.normalize(b.juros, '')) -
          Number(moneyMask.normalize(b.desconto, ''))
        );
      }
    }, 0);
    let total1 = values.lancamentotipo1.reduce((a, b) => {
      if (total0 < 0) {
        if (b.parcela && b.quando === 1)
          return (
            a +
            b.parcela.reduce((c, d) => {
              return c + Number(moneyMask.normalize(d.valor, ''));
            }, 0)
          );
        return a + Number(moneyMask.normalize(b.valor, ''));
      } else {
        if (b.parcela && b.quando === 1)
          return (
            a -
            b.parcela.reduce((c, d) => {
              return c + Number(moneyMask.normalize(d.valor, ''));
            }, 0)
          );
        return a - Number(moneyMask.normalize(b.valor, ''));
      }
    }, 0);
    if (values.lancamentotipo1.length === 1 && values.lancamentotipo1[0].quando === 0) {
      total1 = total0 * -1;
      form.setFieldsValue({
        lancamentotipo1: [
          {
            ...form.getFieldValue(['lancamentotipo1', 0]),
            valor: moneyMask.format(total0 * -1),
          },
        ],
        total0: moneyMaskNeg.format(total0),
        total1: moneyMaskNeg.format(total1),
        saldo: moneyMask.format(total0 + total1),
      });
      return;
    }
    form.setFieldsValue({
      total0: moneyMaskNeg.format(total0),
      total1: moneyMaskNeg.format(total1),
      saldo: moneyMask.format(total0 + total1),
    });
  };
  return (
    <div style={{ width: '100%' }}>
      <Spin spinning={loadingbloco}>
        <div style={{ position: 'relative' }}>
          <SelectConta data={selectConta} setData={setSelectConta} setConta={setConta} />
          <SelectPessoa data={selectPessoa} setData={setSelectPessoa} setPessoa={setPessoa} />
          <SelectUnidade data={selectUnidade} setUnidade={setUnidade} setData={setSelectUnidade} />
          <Titulos />
          {unidades.length > 0 && (
            <Form
              form={form}
              onFinish={(values: LancamentoLoad) => {
                if (
                  Number(moneyMaskNeg.normalize(values.total0 as string, '')) +
                    Number(moneyMaskNeg.normalize(values.total1, '')) !==
                  0
                ) {
                  notification.error({ message: 'Saldo diferente de zero!' });
                  return;
                }
                dispatch({
                  type: 'SALVAR_LANCAMENTO_LANCAR_PENDING',
                });
                console.log(values);
                cliente
                  .post('/api/Lancar/Lancar', {
                    ...values,
                    importid: urlParams.get('importid'),
                    keys: urlParams.get('keys') && JSON.parse(urlParams.get('keys') || ''),
                    bloco: urlParams.get('bloco'),
                    total0: moneyMaskNeg.normalize(values.total0 as string, ''),
                    dataTransacao: moment(values.dataTransacao).format('YYYY-MM-DD'),
                    lancamentotipo0: values.lancamentotipo0.map((c) => ({
                      ...c,
                      contaID: c.contaID && Number(c.contaID.slice(2, c.contaID.length)),
                      valor: moneyMask.normalize(c.valor, ''),
                      desconto: moneyMask.normalize(c.desconto, ''),
                      juros: moneyMask.normalize(c.juros, ''),
                      multa: moneyMask.normalize(c.multa, ''),
                      quantidade: doubleMask.normalize(c.quantidade, ''),
                    })),
                    lancamentotipo1: values.lancamentotipo1.map((c) => ({
                      ...c,
                      ondeID: c.ondeID && Number(c.ondeID.slice(2, c.ondeID.length)),
                      valor: moneyMask.normalize(c.valor, ''),
                      quantidade: doubleMask.normalize(c.quantidade, ''),
                      parcela: c.parcela?.map((p) => ({
                        ...p,
                        valor: moneyMask.normalize(p.valor, ''),
                        vencimento: moment(p.vencimento).format('YYYY-MM-DD'),
                      })),
                    })),
                  })
                  .then((res) => {
                    notification.success({ message: 'Transação salva com sucesso' });
                    if (!new URLSearchParams(window.location.search).get('bloco')) {
                      confirm({
                        title: 'Deseja manter lançamento?',
                        content: (
                          <div>
                            <div>{`Lançamento criado: ${res.data}`}</div>
                            <div>
                              Recomeçar lançamento ou aproveitar as informações do lançamento atual para o próximo.
                            </div>
                          </div>
                        ),
                        okText: 'Novo Lançamento',
                        cancelText: 'Manter Informações',
                        onOk() {
                          form.resetFields();
                          dispatch(RemoveTitulos());
                        },
                        onCancel() {
                          dispatch(RemoveTitulos());
                          //window.location.href = '/Lancar?bloco=' + res.data;
                        },
                      });
                    } else {
                      confirm({
                        title: 'Lançamento Atualizado',
                        content: 'Lançamento atualizado com sucesso!',
                        okText: 'Continuar Atualizando',
                        cancelText: 'Novo Lançamento',
                        onOk() {
                          window.location.href = '/Lancar?bloco=' + res.data;
                        },
                        onCancel() {
                          window.location.href = '/Lancar';
                        },
                      });
                    }
                    dispatch({
                      type: 'SALVAR_LANCAMENTO_LANCAR_FULFILLED',
                    });
                  })
                  .catch(() => {
                    notification.error({ message: 'Erro ao salvar lançamento' });
                    dispatch({
                      type: 'SALVAR_LANCAMENTO_LANCAR_FULFILLED',
                    });
                  });
              }}
              layout="vertical"
              onValuesChange={calculateTotal}
              initialValues={{
                ...initial(0),
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  padding: 10,
                  height: 58,
                  paddingRight: 10,
                  right: 0,
                  width: '100%',
                  position: 'fixed',
                  zIndex: 1000,
                  boxShadow: '1px 2px 2px lightgrey',
                }}
              >
                <Form.Item name="dataTransacao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <DatePicker format="DD/MM/YYYY" placeholder="Data Transação" />
                </Form.Item>
                <Form.Item name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input placeholder="Descrição" style={{ width: 200 }} />
                </Form.Item>
                <Form.Item name="total0" label="A Quitar" valuePropName="children">
                  <Typography.Text strong />
                </Form.Item>
                <Form.Item name="total1" label="Quitado" valuePropName="children">
                  <Typography.Text strong />
                </Form.Item>
                <Form.Item name="saldo" label="Saldo" valuePropName="children">
                  <Typography.Text strong />
                </Form.Item>
                <Form.Item name="padrao" label="Padrão">
                  <Radio.Group
                    onChange={(e) => {
                      const v = form.getFieldValue('lancamentotipo0')[0];
                      const v1 = form.getFieldValue('lancamentotipo1')[0];
                      if (e.target.value === 1) {
                        form.setFieldsValue({
                          lancamentotipo0: [
                            {
                              ...v,
                              tipo: 4,
                            },
                          ],
                          lancamentotipo1: [
                            {
                              ...v1,
                              quando: 0,
                            },
                          ],
                        });
                      } else if (e.target.value === 0) {
                        form.setFieldsValue({
                          lancamentotipo0: [
                            {
                              ...v,
                              tipo: 0,
                            },
                          ],
                          lancamentotipo1: [
                            {
                              ...v1,
                              quando: 0,
                            },
                          ],
                        });
                      }
                    }}
                  >
                    <Radio value={0}>Pagar/Receber</Radio>
                    <Radio value={1}>Movimentar</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(n, o) => n.inverter !== o.inverter}>
                  {({ getFieldValue }) =>
                    getFieldValue('padrao') === 1 && (
                      <Form.Item label="Sentido" name="inverter" valuePropName="checked">
                        <Checkbox>Inverter</Checkbox>
                      </Form.Item>
                    )
                  }
                </Form.Item>

                <Button type="primary" size="small" onClick={() => dispatch(ShowHideTitulos(true))}>
                  Baixar Títulos
                </Button>
              </div>
              <div style={{ paddingTop: 60 }}>
                <Form.List name="lancamentotipo0">
                  {(fields, { add, remove }) => (
                    <Collapse accordion defaultActiveKey={0}>
                      <Collapse.Panel
                        header={
                          form.getFieldValue('padrao') !== 1 ? (
                            'Pagar/Receber o que'
                          ) : form.getFieldValue('inverter') ? (
                            <span>
                              <PlusSquareOutlined style={{ color: 'green', marginRight: 8 }} />
                              Para
                            </span>
                          ) : (
                            <span>
                              <MinusSquareOutlined style={{ color: 'red', marginRight: 8 }} />
                              De
                            </span>
                          )
                        }
                        key={0}
                        extra={
                          <Button
                            size="small"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              add({
                                ...initial(form.getFieldValue('padrao'))['lancamentotipo0'][0],
                                unidadeID: unidades.length === 1 ? unidades[0].id : null,
                                unidade: unidades.length === 1 ? unidades[0].nome : null,
                              });
                            }}
                          >
                            Adicionar
                          </Button>
                        }
                      >
                        <Row gutter={[8, 8]} style={{ width: '100%' }}>
                          {fields.map((field) => (
                            <Col key={field.key}>
                              <Card
                                title={
                                  <div>
                                    <span style={{ marginRight: 8 }}>{field.name + 1}:</span>
                                    <Form.Item noStyle shouldUpdate>
                                      {({ getFieldValue, setFieldValue }) => (
                                        <Form.Item noStyle name={[field.name, 'tipo']}>
                                          <Segmented
                                            onChange={() => {
                                              setFieldValue(['lancamentotipo0', field.name, 'contaID'], null);
                                              setFieldValue(['lancamentotipo0', field.name, 'conta'], null);
                                              setFieldValue(['lancamentotipo0', field.name, 'estoque'], null);
                                              setFieldValue(['lancamentotipo0', field.name, 'produto'], null);
                                              setFieldValue(
                                                ['lancamentotipo0', field.name, 'quantidade'],
                                                doubleMask.format(0)
                                              );
                                            }}
                                            options={[
                                              {
                                                label: (
                                                  <Tooltip title="Pagar">
                                                    <MinusSquareOutlined
                                                      style={{
                                                        color:
                                                          getFieldValue('padrao') === 0 &&
                                                          !getFieldValue(['lancamentotipo0', field.name, 'titulo'])
                                                            ? 'red'
                                                            : undefined,
                                                      }}
                                                    />
                                                  </Tooltip>
                                                ),
                                                value: 0,
                                                disabled:
                                                  getFieldValue('padrao') !== 0 ||
                                                  getFieldValue(['lancamentotipo0', field.name, 'titulo']),
                                              },
                                              {
                                                label: (
                                                  <Tooltip title="Receber">
                                                    <PlusSquareOutlined
                                                      style={{
                                                        color:
                                                          getFieldValue('padrao') === 0 &&
                                                          !getFieldValue(['lancamentotipo0', field.name, 'titulo'])
                                                            ? 'green'
                                                            : undefined,
                                                      }}
                                                    />
                                                  </Tooltip>
                                                ),
                                                value: 1,
                                                disabled:
                                                  getFieldValue('padrao') !== 0 ||
                                                  getFieldValue(['lancamentotipo0', field.name, 'titulo']),
                                              },
                                              {
                                                label: (
                                                  <Tooltip title="Baixar Pagamento">
                                                    <DownSquareOutlined
                                                      style={{
                                                        color:
                                                          getFieldValue('lancamentotipo0')[field.name].titulo && 'red',
                                                      }}
                                                    />
                                                  </Tooltip>
                                                ),
                                                value: 2,
                                                disabled: true,
                                              },
                                              {
                                                label: (
                                                  <Tooltip title="Baixar Recebimento">
                                                    <DownSquareOutlined
                                                      style={{
                                                        color:
                                                          getFieldValue('lancamentotipo0')[field.name].titulo &&
                                                          'green',
                                                      }}
                                                    />
                                                  </Tooltip>
                                                ),
                                                value: 3,
                                                disabled: true,
                                              },
                                              {
                                                label: (
                                                  <Tooltip title="Movimentar">
                                                    <SwapOutlined />
                                                  </Tooltip>
                                                ),
                                                value: 4,
                                                disabled: getFieldValue('padrao') !== 1,
                                              },
                                            ]}
                                          />
                                        </Form.Item>
                                      )}
                                    </Form.Item>
                                  </div>
                                }
                                size="small"
                                style={{ width: 450 }}
                                extra={
                                  fields.length > 1 && (
                                    <Button
                                      size="small"
                                      icon={<DeleteOutlined />}
                                      onClick={() => {
                                        if (form.getFieldValue(['lancamentotipo0', field.name, 'titulo']))
                                          dispatch(
                                            RemoveTitulo(form.getFieldValue(['lancamentotipo0', field.name, 'id']))
                                          );
                                        remove(field.name);
                                      }}
                                    >
                                      Remover
                                    </Button>
                                  )
                                }
                              >
                                <div>
                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue, setFieldValue, getFieldError, validateFields }) => (
                                      <Descriptions bordered column={1} size="small">
                                        <Descriptions.Item
                                          label={
                                            <Button
                                              key={0}
                                              size="small"
                                              icon={<SelectOutlined />}
                                              disabled={
                                                form.getFieldValue(['lancamentotipo0', field.name, 'titulo']) ||
                                                unidades.length === 1
                                              }
                                              onClick={() =>
                                                setSelectUnidade({
                                                  visible: true,
                                                  index: field.name,
                                                  tipo: 0,
                                                })
                                              }
                                            >
                                              Unidade
                                            </Button>
                                          }
                                          labelStyle={{ textAlign: 'right' }}
                                        >
                                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>
                                              <Typography.Text type="danger">
                                                {getFieldError(['lancamentotipo0', field.name, 'unidade'])[0]}
                                              </Typography.Text>
                                              <Form.Item
                                                noStyle
                                                name={[field.name, 'unidade']}
                                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                valuePropName="children"
                                              >
                                                <Typography.Text strong style={{ textJustify: 'auto' }} />
                                              </Form.Item>
                                            </span>
                                            {unidades.length > 1 && (
                                              <span
                                                onClick={() => {
                                                  setFieldValue(['lancamentotipo0', field.name, 'unidade'], null);
                                                  setFieldValue(['lancamentotipo0', field.name, 'unidadeID'], null);
                                                  setFieldValue(['lancamentotipo0', field.name, 'conta'], null);
                                                  setFieldValue(['lancamentotipo0', field.name, 'contaID'], null);
                                                  setFieldValue(['lancamentotipo0', field.name, 'estoque'], null);
                                                  setFieldValue(['lancamentotipo0', field.name, 'produto'], null);
                                                  setFieldValue(
                                                    ['lancamentotipo0', field.name, 'quantidade'],
                                                    doubleMask.format(0)
                                                  );
                                                  validateFields([
                                                    ['lancamentotipo0', field.name, 'unidade'],
                                                    ['lancamentotipo0', field.name, 'conta'],
                                                  ]);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                hidden={
                                                  getFieldValue(['lancamentotipo0', field.name, 'unidadeID']) ===
                                                    null || getFieldValue(['lancamentotipo0', field.name, 'titulo'])
                                                }
                                              >
                                                <CloseOutlined />
                                              </span>
                                            )}
                                          </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={
                                            <Button
                                              key={1}
                                              size="small"
                                              icon={<SelectOutlined />}
                                              onClick={() =>
                                                setSelectConta({
                                                  visible: true,
                                                  index: field.name,
                                                  tipo: form.getFieldValue(['lancamentotipo0', field.name, 'tipo']),
                                                  lancamento: 0,
                                                  fluxo: null,
                                                  movimentar: form.getFieldValue('padrao') === 1,
                                                  unidadeID: form.getFieldValue([
                                                    'lancamentotipo0',
                                                    field.name,
                                                    'unidadeID',
                                                  ]),
                                                  conta: null,
                                                })
                                              }
                                              disabled={
                                                !form.getFieldValue(['lancamentotipo0', field.name, 'unidadeID']) ||
                                                form.getFieldValue(['lancamentotipo0', field.name, 'titulo'])
                                              }
                                            >
                                              {form.getFieldValue('padrao') === 1
                                                ? form.getFieldValue('inverter')
                                                  ? 'Para onde'
                                                  : 'De onde'
                                                : 'O que'}
                                            </Button>
                                          }
                                          labelStyle={{ textAlign: 'right' }}
                                        >
                                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>
                                              <Typography.Text type="danger">
                                                {getFieldError(['lancamentotipo0', field.name, 'conta'])[0]}
                                              </Typography.Text>
                                              <Form.Item
                                                noStyle
                                                name={[field.name, 'conta']}
                                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                valuePropName="children"
                                              >
                                                <Typography.Text strong style={{ textAlign: 'left' }} />
                                              </Form.Item>
                                            </span>
                                            <span
                                              onClick={() => {
                                                setFieldValue(['lancamentotipo0', field.name, 'conta'], null);
                                                setFieldValue(['lancamentotipo0', field.name, 'contaID'], null);
                                                setFieldValue(['lancamentotipo0', field.name, 'estoque'], null);
                                                setFieldValue(['lancamentotipo0', field.name, 'baixaEstoque'], null);
                                              }}
                                              style={{ cursor: 'pointer' }}
                                              hidden={
                                                getFieldValue(['lancamentotipo0', field.name, 'contaID']) === null ||
                                                getFieldValue(['lancamentotipo0', field.name, 'titulo'])
                                              }
                                            >
                                              <CloseOutlined />
                                            </span>
                                          </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={
                                            <Button
                                              key={2}
                                              size="small"
                                              icon={<SelectOutlined />}
                                              disabled={form.getFieldValue(['lancamentotipo0', field.name, 'titulo'])}
                                              onClick={() =>
                                                setSelectPessoa({
                                                  visible: true,
                                                  index: field.name,
                                                  tipo: 0,
                                                })
                                              }
                                            >
                                              Quem
                                            </Button>
                                          }
                                          labelStyle={{ textAlign: 'right' }}
                                          contentStyle={{
                                            width: 340,
                                          }}
                                        >
                                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ fontWeight: 'bold' }}>
                                              {getFieldValue(['lancamentotipo0', field.name, 'pessoa'])}
                                            </span>
                                            <span
                                              onClick={() => {
                                                setFieldValue(['lancamentotipo0', field.name, 'pessoa'], null);
                                                setFieldValue(['lancamentotipo0', field.name, 'pessoaID'], null);
                                              }}
                                              style={{ cursor: 'pointer' }}
                                              hidden={
                                                getFieldValue(['lancamentotipo0', field.name, 'pessoaID']) === null ||
                                                getFieldValue(['lancamentotipo0', field.name, 'titulo'])
                                              }
                                            >
                                              <CloseOutlined />
                                            </span>
                                          </div>
                                        </Descriptions.Item>
                                        {getFieldValue(['lancamentotipo0', field.name, 'titulo']) && (
                                          <Descriptions.Item label="Descrição" labelStyle={{ textAlign: 'right' }}>
                                            {getFieldValue(['lancamentotipo0', field.name, 'descricaotitulo'])}
                                          </Descriptions.Item>
                                        )}
                                        <Descriptions.Item label="Documento" labelStyle={{ textAlign: 'right' }}>
                                          <Form.Item name={[field.name, 'documento']} noStyle>
                                            <Input size="small" placeholder="Documento..." />
                                          </Form.Item>
                                        </Descriptions.Item>
                                      </Descriptions>
                                    )}
                                  </Form.Item>
                                  <Form.Item label="Unidade" name={[field.name, 'estoque']} hidden>
                                    <Input />
                                  </Form.Item>
                                  <Form.Item label="Unidade" name={[field.name, 'unidadeID']} hidden>
                                    <Input />
                                  </Form.Item>
                                  <Form.Item label="O que" name={[field.name, 'contaID']} hidden>
                                    <Input />
                                  </Form.Item>
                                  <Form.Item label="Quem" name={[field.name, 'pessoaID']} hidden>
                                    <Input />
                                  </Form.Item>
                                  <Form.Item name={[field.name, 'baixou']} hidden>
                                    <Input />
                                  </Form.Item>
                                  <Row gutter={[8, 8]}>
                                    <Col>
                                      <Form.Item
                                        label="Valor"
                                        name={[field.name, 'valor']}
                                        normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                        rules={[
                                          { required: true, message: 'Campo obrigatório' },
                                          () => ({
                                            validator(_, value) {
                                              if ((doubleMask.normalize(value, '') as number) <= 0) {
                                                return Promise.reject('Valor deve ser maior que zero');
                                              }
                                              return Promise.resolve();
                                            },
                                          }),
                                        ]}
                                      >
                                        <Input
                                          style={{ width: 100 }}
                                          disabled={form.getFieldValue(['lancamentotipo0', field.name, 'titulo'])}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Form.Item
                                        label="Juros"
                                        name={[field.name, 'juros']}
                                        normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                      >
                                        <Input style={{ width: 100 }} />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Form.Item
                                        label="Multa"
                                        name={[field.name, 'multa']}
                                        normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                      >
                                        <Input style={{ width: 100 }} />
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Form.Item
                                        label="Desconto"
                                        name={[field.name, 'desconto']}
                                        normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                      >
                                        <Input style={{ width: 100 }} />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(n, o) =>
                                      n.lancamentotipo0[field.name]?.baixaEstoque !==
                                        o.lancamentotipo0[field.name]?.baixaEstoque ||
                                      n.lancamentotipo0[field.name]?.unidadeID !==
                                        o.lancamentotipo0[field.name]?.unidadeID ||
                                      n.lancamentotipo0[field.name]?.contaID !== o.lancamentotipo0[field.name]?.contaID
                                    }
                                  >
                                    {({ getFieldValue }) =>
                                      getFieldValue(['lancamentotipo0', field.name, 'baixaEstoque']) && (
                                        <Row gutter={[8, 8]}>
                                          <Col span={18}>
                                            <Form.Item
                                              name={[field.name, 'produtoidcontaestoqueid']}
                                              label="Produto Baixa Estoque"
                                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                                            >
                                              <TreeSelect
                                                size="small"
                                                showSearch
                                                treeNodeFilterProp="title"
                                                placeholder="Escolha o produto"
                                                treeData={produtosbaixa.filter(
                                                  (c) =>
                                                    c.unidadeID ===
                                                    getFieldValue(['lancamentotipo0', field.name, 'unidadeID'])
                                                )}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={6}>
                                            <Form.Item
                                              name={[field.name, 'quantidade']}
                                              label="Quantidade"
                                              normalize={(value) => doubleMask.format(doubleMask.normalize(value, ''))}
                                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                                            >
                                              <Input
                                                size="small"
                                                onChange={(e) => console.log(e.target.value)}
                                                suffix={
                                                  getFieldValue([
                                                    'lancamentotipo0',
                                                    field.name,
                                                    'produtoidcontaestoqueid',
                                                  ])
                                                    ? produtos.find(
                                                        (c) =>
                                                          c.id ==
                                                          getFieldValue([
                                                            'lancamentotipo0',
                                                            field.name,
                                                            'produtoidcontaestoqueid',
                                                          ]).split(',')[0]
                                                      )
                                                      ? produtos.find(
                                                          (c) =>
                                                            c.id ==
                                                            getFieldValue([
                                                              'lancamentotipo0',
                                                              field.name,
                                                              'produtoidcontaestoqueid',
                                                            ]).split(',')[0]
                                                        )?.unidade
                                                      : ''
                                                    : ''
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      )
                                    }
                                  </Form.Item>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(n, o) =>
                                      n.lancamentotipo0[field.name]?.estoque !==
                                        o.lancamentotipo0[field.name]?.estoque ||
                                      n.lancamentotipo0[field.name]?.quando !== o.lancamentotipo0[field.name]?.quando ||
                                      n.lancamentotipo0[field.name]?.produto !==
                                        o.lancamentotipo0[field.name]?.produto ||
                                      n.lancamentotipo0[field.name]?.unidadeID !==
                                        o.lancamentotipo0[field.name]?.unidadeID ||
                                      n.lancamentotipo0[field.name]?.contaID !== o.lancamentotipo0[field.name]?.contaID
                                    }
                                  >
                                    {({ getFieldValue }) =>
                                      getFieldValue(['lancamentotipo0', field.name, 'estoque']) && (
                                        <Row gutter={[8, 8]} justify="space-between">
                                          <Col span={18}>
                                            <Form.Item
                                              name={[field.name, 'produto']}
                                              label="Produto"
                                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                                            >
                                              <Select
                                                size="small"
                                                optionFilterProp="label"
                                                showSearch
                                                placeholder="Escolha o produto"
                                              >
                                                {produtos.map((c) => (
                                                  <Select.Option value={c.id} key={c.id} label={c.codigo + c.descricao}>
                                                    {c.codigo + ' - ' + c.descricao}
                                                  </Select.Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col span={6}>
                                            <Form.Item
                                              name={[field.name, 'quantidade']}
                                              label="Quantidade"
                                              normalize={(v) => doubleMask.format(doubleMask.normalize(v, ''))}
                                              rules={[
                                                { required: true, message: 'Campo obrigatório' },
                                                () => ({
                                                  validator(_, value) {
                                                    if ((doubleMask.normalize(value, '') as number) <= 0) {
                                                      return Promise.reject('Quantidade deve ser maior que zero');
                                                    }
                                                    return Promise.resolve();
                                                  },
                                                }),
                                              ]}
                                            >
                                              <Input
                                                size="small"
                                                suffix={
                                                  produtos.find(
                                                    (c) =>
                                                      c.id === getFieldValue(['lancamentotipo0', field.name, 'produto'])
                                                  )?.unidade
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      )
                                    }
                                  </Form.Item>
                                </div>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </Form.List>
                <Form.List name="lancamentotipo1">
                  {(fields, { add, remove }) => (
                    <Collapse accordion defaultActiveKey={0}>
                      <Collapse.Panel
                        header={
                          form.getFieldValue('padrao') !== 1 ? (
                            'Como Pagar/Receber'
                          ) : form.getFieldValue('inverter') ? (
                            <span>
                              <MinusSquareOutlined style={{ color: 'red', marginRight: 8 }} />
                              De
                            </span>
                          ) : (
                            <span>
                              <PlusSquareOutlined style={{ color: 'green', marginRight: 8 }} />
                              Para
                            </span>
                          )
                        }
                        key={0}
                        extra={
                          <Button
                            size="small"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              add({
                                ...initial(form.getFieldValue('padrao'))['lancamentotipo1'][0],
                                unidadeID: unidades.length === 1 ? unidades[0].id : null,
                                unidade: unidades.length === 1 ? unidades[0].nome : null,
                              });
                            }}
                          >
                            Adicionar
                          </Button>
                        }
                      >
                        <Row gutter={[8, 8]}>
                          {fields.map((field) => (
                            <Col key={field.key}>
                              <Card
                                title={
                                  <div>
                                    <span style={{ marginRight: 8 }}>{field.name + 1}:</span>
                                    <Form.Item noStyle shouldUpdate>
                                      {({ getFieldValue, setFieldValue }) => (
                                        <Form.Item noStyle name={[field.name, 'quando']}>
                                          <Segmented
                                            onChange={() => {
                                              setFieldValue(['lancamentotipo1', field.name, 'ondeID'], null);
                                              setFieldValue(['lancamentotipo1', field.name, 'onde'], null);
                                              setFieldValue(['lancamentotipo1', field.name, 'parcela'], null);
                                              setFieldValue(
                                                ['lancamentotipo1', field.name, 'produtoidcontaestoqueid'],
                                                null
                                              );
                                              setFieldValue(
                                                ['lancamentotipo1', field.name, 'quantidade'],
                                                doubleMask.format(0)
                                              );
                                              setFieldValue(['lancamentotipo1', field.name, 'baixaEstoque'], null);

                                              if (parcelasView.id === field.name) setParcelasView({ id: null });
                                            }}
                                            options={[
                                              {
                                                label: (
                                                  <Tooltip
                                                    title={getFieldValue('padrao') === 0 ? 'Agora' : 'Todas as Contas'}
                                                  >
                                                    {getFieldValue('padrao') === 0 ? (
                                                      <CheckCircleOutlined />
                                                    ) : (
                                                      <DownSquareOutlined />
                                                    )}
                                                  </Tooltip>
                                                ),
                                                value: 0,
                                              },
                                              {
                                                label: (
                                                  <Tooltip title={getFieldValue('padrao') === 0 ? 'Depois' : 'Títulos'}>
                                                    {getFieldValue('padrao') === 0 ? (
                                                      <ClockCircleOutlined />
                                                    ) : (
                                                      <FileOutlined />
                                                    )}
                                                  </Tooltip>
                                                ),
                                                value: 1,
                                              },
                                            ]}
                                          />
                                        </Form.Item>
                                      )}
                                    </Form.Item>
                                  </div>
                                }
                                size="small"
                                style={{ width: parcelasView.id === field.name ? 1000 : 450 }}
                                extra={
                                  <div>
                                    {fields.length > 1 && (
                                      <Button size="small" icon={<DeleteOutlined />} onClick={() => remove(field.name)}>
                                        Remover
                                      </Button>
                                    )}
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(n, o) =>
                                        n.lancamentotipo1[field.name].quando !== o.lancamentotipo1[field.name].quando
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        (getFieldValue(['lancamentotipo1', field.name, 'quando']) === 1 ||
                                          getFieldValue(['lancamentotipo1', field.name, 'quando']) === 3) && (
                                          <Button
                                            size="small"
                                            icon={
                                              parcelasView.id === field.name ? (
                                                <ArrowLeftOutlined />
                                              ) : (
                                                <ArrowRightOutlined />
                                              )
                                            }
                                            onClick={() =>
                                              setParcelasView({
                                                id: parcelasView.id === field.name ? null : field.name,
                                              })
                                            }
                                          >
                                            Valores
                                          </Button>
                                        )
                                      }
                                    </Form.Item>
                                  </div>
                                }
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    gap: '5px 5px',
                                  }}
                                >
                                  <div style={{ flex: '0 0 425px' }}>
                                    <Form.Item noStyle shouldUpdate>
                                      {({ getFieldValue, setFieldValue, validateFields, getFieldError }) => (
                                        <Descriptions bordered column={1} size="small">
                                          <Descriptions.Item
                                            label={
                                              <div>
                                                <Button
                                                  key={0}
                                                  size="small"
                                                  icon={<SelectOutlined />}
                                                  disabled={
                                                    form.getFieldValue(['lancamentotipo1', field.name, 'titulo']) ||
                                                    unidades.length === 1
                                                  }
                                                  onClick={() =>
                                                    setSelectUnidade({
                                                      visible: true,
                                                      index: field.name,
                                                      tipo: 1,
                                                    })
                                                  }
                                                >
                                                  Unidade
                                                </Button>
                                              </div>
                                            }
                                            labelStyle={{ textAlign: 'right' }}
                                          >
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                              <span>
                                                <Typography.Text type="danger">
                                                  {getFieldError(['lancamentotipo1', field.name, 'unidade'])[0]}
                                                </Typography.Text>
                                                <Form.Item
                                                  noStyle
                                                  name={[field.name, 'unidade']}
                                                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                  valuePropName="children"
                                                >
                                                  <Typography.Text strong style={{ textAlign: 'left' }} />
                                                </Form.Item>
                                              </span>
                                              {unidades.length > 1 && (
                                                <span
                                                  onClick={() => {
                                                    setFieldValue(['lancamentotipo1', field.name, 'unidade'], null);
                                                    setFieldValue(['lancamentotipo1', field.name, 'unidadeID'], null);
                                                    setFieldValue(['lancamentotipo1', field.name, 'onde'], null);
                                                    setFieldValue(['lancamentotipo1', field.name, 'ondeID'], null);
                                                    validateFields([
                                                      ['lancamentotipo1', field.name, 'unidade'],
                                                      ['lancamentotipo1', field.name, 'onde'],
                                                    ]);
                                                  }}
                                                  style={{ cursor: 'pointer' }}
                                                  hidden={
                                                    getFieldValue(['lancamentotipo1', field.name, 'unidadeID']) === null
                                                  }
                                                >
                                                  <CloseOutlined />
                                                </span>
                                              )}
                                            </div>
                                          </Descriptions.Item>
                                          <Descriptions.Item
                                            label={
                                              <Button
                                                key={1}
                                                size="small"
                                                icon={<SelectOutlined />}
                                                onClick={() =>
                                                  setSelectConta({
                                                    visible: true,
                                                    index: field.name,
                                                    tipo: null,
                                                    lancamento: 1,
                                                    movimentar: form.getFieldValue('padrao') === 1,
                                                    fluxo: form.getFieldValue([
                                                      'lancamentotipo1',
                                                      field.name,
                                                      'quando',
                                                    ]),
                                                    unidadeID: form.getFieldValue([
                                                      'lancamentotipo1',
                                                      field.name,
                                                      'unidadeID',
                                                    ]),
                                                    conta: null,
                                                  })
                                                }
                                                disabled={
                                                  !form.getFieldValue(['lancamentotipo1', field.name, 'unidadeID']) ||
                                                  form.getFieldValue(['lancamentotipo1', field.name, 'titulo'])
                                                }
                                              >
                                                {form.getFieldValue('padrao') === 1
                                                  ? form.getFieldValue('inverter')
                                                    ? 'De onde'
                                                    : 'Para onde'
                                                  : 'Por onde'}
                                              </Button>
                                            }
                                            labelStyle={{ textAlign: 'right' }}
                                            contentStyle={{
                                              width: 340,
                                            }}
                                          >
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                              <span>
                                                <Typography.Text type="danger">
                                                  {getFieldError(['lancamentotipo1', field.name, 'onde'])[0]}
                                                </Typography.Text>
                                                <Form.Item
                                                  noStyle
                                                  name={[field.name, 'onde']}
                                                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                  valuePropName="children"
                                                >
                                                  <Typography.Text strong style={{ textAlign: 'left' }} />
                                                </Form.Item>
                                              </span>
                                              <span
                                                onClick={() => {
                                                  setFieldValue(['lancamentotipo1', field.name, 'onde'], null);
                                                  setFieldValue(['lancamentotipo1', field.name, 'ondeID'], null);
                                                  setFieldValue(['lancamentotipo1', field.name, 'estoque'], null);
                                                  setFieldValue(['lancamentotipo1', field.name, 'baixaEstoque'], null);
                                                  validateFields([['lancamentotipo1', field.name, 'onde']]);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                hidden={
                                                  getFieldValue(['lancamentotipo1', field.name, 'ondeID']) === null
                                                }
                                              >
                                                <CloseOutlined />
                                              </span>
                                            </div>
                                          </Descriptions.Item>
                                          {getFieldValue(['lancamentotipo1', field.name, 'quando']) === 0 && (
                                            <Descriptions.Item label="Documento" labelStyle={{ textAlign: 'right' }}>
                                              <Form.Item label="Documento" name={[field.name, 'documento']} noStyle>
                                                <Input size="small" placeholder="Documento..." />
                                              </Form.Item>
                                            </Descriptions.Item>
                                          )}
                                        </Descriptions>
                                      )}
                                    </Form.Item>
                                    <Form.Item label="Unidade" name={[field.name, 'unidadeID']} hidden>
                                      <Input />
                                    </Form.Item>
                                    <Form.Item label="Por Onde" name={[field.name, 'ondeID']} hidden>
                                      <Input />
                                    </Form.Item>
                                    <Form.Item label="Quem" name={[field.name, 'baixaEstoque']} hidden>
                                      <Input size="small" />
                                    </Form.Item>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(n, o) =>
                                        n.lancamentotipo1.length !== o.lancamentotipo1.length ||
                                        n.padrao != o.padrao ||
                                        n.lancamentotipo1[field.name].quando !== o.lancamentotipo1[field.name].quando
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue(['lancamentotipo1', field.name, 'quando']) === 0 && (
                                          <Form.Item
                                            label="Valor"
                                            hidden={getFieldValue('lancamentotipo1').length === 1}
                                            name={[field.name, 'valor']}
                                            normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                            rules={[
                                              () => ({
                                                validator(_, value) {
                                                  if ((doubleMask.normalize(value, '') as number) <= 0) {
                                                    return Promise.reject('Valor deve ser maior que zero');
                                                  }
                                                  return Promise.resolve();
                                                },
                                              }),
                                            ]}
                                          >
                                            <Input
                                              style={{ width: 100 }}
                                              disabled={getFieldValue(['lancamentotipo1', field.name, 'titulo'])}
                                            />
                                          </Form.Item>
                                        )
                                      }
                                    </Form.Item>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(n, o) =>
                                        n.lancamentotipo1[field.name].estoque !==
                                          o.lancamentotipo1[field.name].estoque ||
                                        n.lancamentotipo1[field.name].quando !== o.lancamentotipo1[field.name].quando ||
                                        n.lancamentotipo1[field.name].produto !==
                                          o.lancamentotipo1[field.name].produto ||
                                        n.lancamentotipo1[field.name].unidadeID !==
                                          o.lancamentotipo1[field.name].unidadeID
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue(['lancamentotipo1', field.name, 'estoque']) && (
                                          <Row gutter={[8, 8]}>
                                            <Col span={18}>
                                              <Form.Item
                                                name={[field.name, 'produto']}
                                                label="Produto"
                                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                                              >
                                                <Select optionFilterProp="children" size="small">
                                                  {produtos.map((c) => (
                                                    <Select.Option
                                                      value={c.id}
                                                      key={c.id}
                                                      label={c.codigo + c.descricao}
                                                    >
                                                      {c.codigo + ' - ' + c.descricao}
                                                    </Select.Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                              <Form.Item
                                                name={[field.name, 'quantidade']}
                                                normalize={(value) => doubleMask.format(moneyMask.normalize(value, ''))}
                                                label="Quantidade"
                                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                                              >
                                                <Input
                                                  size="small"
                                                  suffix={
                                                    produtos.find(
                                                      (c) =>
                                                        c.id ===
                                                        getFieldValue(['lancamentotipo1', field.name, 'produto'])
                                                    )?.unidade
                                                  }
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    </Form.Item>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(n, o) =>
                                        n.lancamentotipo1[field.name].baixaEstoque !==
                                          o.lancamentotipo1[field.name].baixaEstoque ||
                                        n.lancamentotipo1[field.name].quando !== o.lancamentotipo1[field.name].quando ||
                                        n.lancamentotipo1[field.name].unidadeID !==
                                          o.lancamentotipo1[field.name].unidadeID
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue(['lancamentotipo1', field.name, 'baixaEstoque']) && (
                                          <Row gutter={[8, 8]}>
                                            <Col span={18}>
                                              <Form.Item
                                                name={[field.name, 'produtoidcontaestoqueid']}
                                                label="Produto Baixa Estoque"
                                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                                              >
                                                <TreeSelect
                                                  size="small"
                                                  showSearch
                                                  treeNodeFilterProp="title"
                                                  placeholder="Escolha o produto"
                                                  treeData={produtosbaixa.filter(
                                                    (c) =>
                                                      c.unidadeID ===
                                                      getFieldValue(['lancamentotipo1', field.name, 'unidadeID'])
                                                  )}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                              <Form.Item
                                                name={[field.name, 'quantidade']}
                                                label="Quantidade"
                                                normalize={(value) =>
                                                  doubleMask.format(doubleMask.normalize(value, ''))
                                                }
                                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                                              >
                                                <Input
                                                  size="small"
                                                  suffix={
                                                    getFieldValue([
                                                      'lancamentotipo1',
                                                      field.name,
                                                      'produtoidcontaestoqueid',
                                                    ])
                                                      ? produtos.find(
                                                          (c) =>
                                                            c.id ==
                                                            getFieldValue([
                                                              'lancamentotipo1',
                                                              field.name,
                                                              'produtoidcontaestoqueid',
                                                            ]).split(',')[0]
                                                        )
                                                        ? produtos.find(
                                                            (c) =>
                                                              c.id ==
                                                              getFieldValue([
                                                                'lancamentotipo1',
                                                                field.name,
                                                                'produtoidcontaestoqueid',
                                                              ]).split(',')[0]
                                                          )?.unidade
                                                        : ''
                                                      : ''
                                                  }
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    </Form.Item>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(n, o) =>
                                        n.lancamentotipo1[field.name].quando !== o.lancamentotipo1[field.name].quando ||
                                        n.lancamentotipo1[field.name].parcela !== o.lancamentotipo1[field.name].parcela
                                      }
                                    >
                                      {({ getFieldValue }) => {
                                        if (getFieldValue(['lancamentotipo1', field.name, 'quando']) === 1) {
                                          const parcela: Parcela[] = getFieldValue([
                                            'lancamentotipo1',
                                            field.name,
                                            'parcela',
                                          ]);
                                          return (
                                            <Descriptions
                                              bordered
                                              layout="vertical"
                                              size="small"
                                              style={{ marginTop: 8 }}
                                            >
                                              <Descriptions.Item label="Total">
                                                {moneyMask.format(
                                                  parcela?.reduce(
                                                    (a, b) => a + Number(moneyMask.normalize(b.valor, '')),
                                                    0
                                                  )
                                                )}
                                              </Descriptions.Item>
                                              <Descriptions.Item label="Total Baixado">
                                                {moneyMask.format(
                                                  parcela
                                                    ?.filter((c) => c.titulo)
                                                    .reduce((a, b) => a + Number(moneyMask.normalize(b.valor, '')), 0)
                                                )}
                                              </Descriptions.Item>
                                              <Descriptions.Item label="Total Aberto">
                                                {moneyMask.format(
                                                  parcela
                                                    ?.filter((c) => !c.titulo)
                                                    .reduce((a, b) => a + Number(moneyMask.normalize(b.valor, '')), 0)
                                                )}
                                              </Descriptions.Item>
                                            </Descriptions>
                                          );
                                        }
                                        return null;
                                      }}
                                    </Form.Item>

                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(n, o) =>
                                        n.lancamentotipo1[field.name].quando !== o.lancamentotipo1[field.name].quando
                                      }
                                    >
                                      {({ getFieldValue, setFieldValue }) =>
                                        getFieldValue(['lancamentotipo1', field.name, 'quando']) === 1 &&
                                        parcelasView.id === field.name && (
                                          <div>
                                            <Divider>Parcelas</Divider>
                                            <Row gutter={[12, 8]}>
                                              <Col>
                                                <Row gutter={[8, 8]}>
                                                  <Col>
                                                    <Form.Item noStyle name={[field.name, 'periodo']}>
                                                      <Select size="small">
                                                        <Select.Option value={0}>Mensal</Select.Option>
                                                        <Select.Option value={1}>Quinzenal</Select.Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col>
                                                    <Button
                                                      size="small"
                                                      icon={<PlusOutlined />}
                                                      onClick={() => {
                                                        const parcelas: Parcela[] = getFieldValue([
                                                          'lancamentotipo1',
                                                          field.name,
                                                          'parcela',
                                                        ]);
                                                        if (parcelas) {
                                                          const lastparcela: moment.Moment = moment.max(
                                                            ...parcelas.map((c) => moment(c.vencimento))
                                                          );
                                                          if (
                                                            getFieldValue([
                                                              'lancamentotipo1',
                                                              field.name,
                                                              'periodo',
                                                            ]) === 0
                                                          ) {
                                                            setFieldValue(
                                                              ['lancamentotipo1', field.name, 'parcela'],
                                                              [
                                                                ...getFieldValue([
                                                                  'lancamentotipo1',
                                                                  field.name,
                                                                  'parcela',
                                                                ]),
                                                                {
                                                                  valor: moneyMask.format(0),
                                                                  vencimento: lastparcela.add(1, 'M'),
                                                                  documento: '',
                                                                },
                                                              ]
                                                            );
                                                          } else {
                                                            setFieldValue(
                                                              ['lancamentotipo1', field.name, 'parcela'],
                                                              [
                                                                ...getFieldValue([
                                                                  'lancamentotipo1',
                                                                  field.name,
                                                                  'parcela',
                                                                ]),
                                                                {
                                                                  valor: moneyMask.format(0),
                                                                  vencimento: lastparcela.add(15, 'days'),
                                                                  documento: '',
                                                                },
                                                              ]
                                                            );
                                                          }
                                                        } else {
                                                          setFieldValue(
                                                            ['lancamentotipo1', field.name, 'parcela'],
                                                            [
                                                              {
                                                                valor: moneyMask.format(0),
                                                                vencimento: moment(),
                                                                documento: '',
                                                              },
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      Adicionar
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col>
                                                <Row gutter={[8, 8]}>
                                                  <Col>
                                                    <Form.Item
                                                      name={[field.name, 'vparcela']}
                                                      noStyle
                                                      normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                                    >
                                                      <Input size="small" />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col>
                                                    <Button
                                                      size="small"
                                                      onClick={() => {
                                                        const nparcelas: number = getFieldValue([
                                                          'lancamentotipo1',
                                                          field.name,
                                                          'parcela',
                                                        ]).filter((c: Parcela) => !c.titulo).length;
                                                        const valor =
                                                          Number(
                                                            moneyMask.normalize(
                                                              getFieldValue([
                                                                'lancamentotipo1',
                                                                field.name,
                                                                'vparcela',
                                                              ]),
                                                              ''
                                                            )
                                                          ) / nparcelas;
                                                        const parcelas: Parcela[] = getFieldValue([
                                                          'lancamentotipo1',
                                                          field.name,
                                                          'parcela',
                                                        ]).map((c: Parcela) => ({
                                                          ...c,
                                                          valor: c.titulo ? c.valor : moneyMask.format(valor),
                                                        }));
                                                        setFieldValue(
                                                          ['lancamentotipo1', field.name, 'parcela'],
                                                          parcelas
                                                        );
                                                      }}
                                                    >
                                                      Distribuir
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </div>
                                        )
                                      }
                                    </Form.Item>
                                  </div>
                                  {parcelasView.id === parcelasView.id && (
                                    <div
                                      style={{
                                        flex: '1 0 415px',
                                      }}
                                    >
                                      <Form.Item noStyle shouldUpdate>
                                        {({ getFieldValue }) =>
                                          getFieldValue(['lancamentotipo1', field.name, 'quando']) === 1 &&
                                          field.name === parcelasView.id && (
                                            <Form.List name={[field.name, 'parcela']}>
                                              {(parcelas, { add, remove }) => (
                                                <Table
                                                  size="small"
                                                  rowKey="name"
                                                  dataSource={parcelas}
                                                  pagination={false}
                                                  scroll={{ y: 225, x: 400 }}
                                                >
                                                  <Table.Column
                                                    dataIndex="name"
                                                    width={30}
                                                    title={
                                                      <Button
                                                        size="small"
                                                        icon={<PlusOutlined />}
                                                        onClick={() =>
                                                          add({
                                                            valor: moneyMask.format(0),
                                                            vencimento:
                                                              getFieldValue(['lancamentotipo1', field.name, 'parcela'])
                                                                ?.length === 1
                                                                ? getFieldValue([
                                                                    'lancamentotipo1',
                                                                    field.name,
                                                                    'parcela',
                                                                    0,
                                                                    'vencimento',
                                                                  ])
                                                                : moment(),
                                                          })
                                                        }
                                                      />
                                                    }
                                                    render={(_, __, i) => i + 1}
                                                  />
                                                  <Table.Column
                                                    dataIndex="name"
                                                    title="Valor"
                                                    width={120}
                                                    render={(v) => (
                                                      <Form.Item
                                                        name={[v, 'valor']}
                                                        rules={[
                                                          () => ({
                                                            validator(_, value) {
                                                              if ((doubleMask.normalize(value, '') as number) <= 0) {
                                                                return Promise.reject('Valor deve ser maior que zero');
                                                              }
                                                              return Promise.resolve();
                                                            },
                                                          }),
                                                        ]}
                                                        normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
                                                      >
                                                        <Input
                                                          size="small"
                                                          style={{ width: 110 }}
                                                          disabled={getFieldValue([
                                                            'lancamentotipo1',
                                                            field.name,
                                                            'parcela',
                                                            v,
                                                            'titulo',
                                                          ])}
                                                        />
                                                      </Form.Item>
                                                    )}
                                                  />
                                                  <Table.Column
                                                    dataIndex="name"
                                                    title="Vencimento"
                                                    width={120}
                                                    render={(v) => (
                                                      <Form.Item
                                                        name={[v, 'vencimento']}
                                                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                                                      >
                                                        <DatePicker
                                                          size="small"
                                                          format="DD/MM/YYYY"
                                                          placeholder="Vencimento"
                                                          disabled={getFieldValue([
                                                            'lancamentotipo1',
                                                            field.name,
                                                            'parcela',
                                                            v,
                                                            'titulo',
                                                          ])}
                                                        />
                                                      </Form.Item>
                                                    )}
                                                  />
                                                  <Table.Column
                                                    dataIndex="name"
                                                    title="Documento"
                                                    render={(v) => (
                                                      <Form.Item name={[v, 'documento']}>
                                                        <Input
                                                          size="small"
                                                          placeholder="Documento..."
                                                          disabled={getFieldValue([
                                                            'lancamentotipo1',
                                                            field.name,
                                                            'parcela',
                                                            v,
                                                            'titulo',
                                                          ])}
                                                        />
                                                      </Form.Item>
                                                    )}
                                                  />
                                                  <Table.Column
                                                    dataIndex="name"
                                                    width={30}
                                                    render={(v) => (
                                                      <div>
                                                        <Form.Item name={[v, 'titulo']} hidden>
                                                          <Input />
                                                        </Form.Item>
                                                        <Form.Item name={[v, 'baixadopor']} hidden>
                                                          <Input />
                                                        </Form.Item>
                                                        <Button
                                                          size="small"
                                                          disabled={getFieldValue([
                                                            'lancamentotipo1',
                                                            field.name,
                                                            'parcela',
                                                            v,
                                                            'titulo',
                                                          ])}
                                                          icon={<DeleteOutlined />}
                                                          onClick={() => remove(v)}
                                                        />
                                                      </div>
                                                    )}
                                                  />
                                                </Table>
                                              )}
                                            </Form.List>
                                          )
                                        }
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </Form.List>
              </div>
            </Form>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default LancarViewNovo;
