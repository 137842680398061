import { useEffect } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';

function Home() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    document.title = 'Nambi - Gestão Financeira Inteligente';
    dispatch(SetPageHeader({ title: 'Suporte', subTitle: 'Configurações do sistema' }));
  }, [dispatch]);
  return <div>Dashboard</div>;
}

export default Home;
