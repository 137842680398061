import { Pessoa } from '@models/Pessoa';
import { cliente } from '@config/axios';
import { apiEndpoints } from '@config/apiEndpoints';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

export const SetPessoasFiltros = createAction('pessoas/SetPessoasFiltros', (data: any) => ({ payload: data }));
export const GetPessoas = createAsyncThunk('pessoas/GetPessoas', async () =>
  cliente.get(apiEndpoints.PESSOAS.GET_PESSOAS)
);
export const GetPessoasGrouped = createAsyncThunk('pessoas/GetPessoasGrouped', async () =>
  cliente.get(apiEndpoints.PESSOAS.GET_PESSOAS)
);
export const GetPessoasList = createAsyncThunk('pessoas/GetPessoasList', async () =>
  cliente.get(apiEndpoints.PESSOAS.GET_PESSOAS_LIST)
);
export const SelectedPessoas = createAction('pessoas/SetSelectedPessoas', (pessoas: any[]) => ({ payload: pessoas }));
export const ChangeTabelaPessoas = createAsyncThunk('pessoas/ChangeTabelaPessoas', async () =>
  cliente.post(apiEndpoints.PESSOAS.GET_PESSOAS_ALL)
);
export const ShowPessoas = createAsyncThunk('pessoas/ShowPessoas', (data: any) =>
  cliente.post(apiEndpoints.PESSOAS.GET_PESSOAS_ALL, data)
);
export const DeletePessoa = createAsyncThunk('pessoas/ExcluirPessoa', async (id: number) =>
  cliente.get('/api/Pessoas/ExcluirPessoas', { params: { id: id } })
);
export const HidePessoaForm = createAction('pessoas/HidePessoaForm');
export const ShowPessoaForm = createAction('pessoas/ShowPessoaForm');
export const LoadPessoa = createAction('pessoas/LoadPessoa', (id: number) => ({ payload: id }));

export const AddPessoaContato = createAction('pessoas/AddPessoaContato');
export const SearchCNPJ = createAction('pessoas/SearchCNPJ', (value: boolean) => ({ payload: value }));

export const NewPessoa = createAsyncThunk('pessoas/AddPessoa', async (values: Pessoa) =>
  cliente.post(apiEndpoints.PESSOAS.SALVAR_PESSOA, values)
);
export const EditPessoa = createAsyncThunk('pessoas/EditPessoa', async (values: Pessoa) =>
  cliente.post(apiEndpoints.PESSOAS.SALVAR_PESSOA, values)
);

export const SalvarPessoa = createAsyncThunk('pessoas/SalvarPessoa', async (values: Pessoa, apiThunk) => {
  if (values.id) {
    apiThunk.dispatch(EditPessoa(values));
  } else {
    apiThunk.dispatch(NewPessoa(values));
  }
});
export const ImportPessoas = createAsyncThunk<AxiosResponse<any[]>, FormData, { rejectValue: AxiosError<string> }>(
  'pessoas/Import',
  (data: FormData, apiThunk) =>
    cliente.post(apiEndpoints.PESSOAS.IMPORT, data).catch((res) => apiThunk.rejectWithValue(res))
);

export const ClearImportData = createAction('pessoas/ClearImportData');
