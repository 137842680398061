import { Dispatch } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { cliente } from '@config/axios';
import { setCookie } from '@utils/localStorage';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const ChangePassword = function (values: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'CHANGE_PASSWORD_USUARIO',
            payload: cliente({
                method: 'post',
                url: apiEndpoints.ACCOUNT.CHANGE_PASSWORD,
                data: {
                    ...values,
                },
            }),
        });
    };
};
export const ResetSenhaModal = function (value: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'RESET_SENHA_MODAL_USUARIO',
            payload: value,
        });
    };
};
export const SetPageHeader = function ({
    backButton,
    title,
    subTitle,
    extras = [],
}: {
    backButton?: boolean;
    title: string | ReactNode;
    subTitle?: string | ReactNode;
    extras?: ReactNode[];
}) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'SET_PAGE_HEADER',
            payload: { backButton, title, subTitle, extras },
        });
    };
};
export const GetUserData = function () {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'GET_USER_DATA_USUARIO',
            payload: cliente.get(apiEndpoints.ACCOUNT.GET_USER_DATA),
        });
    };
};
export const Login = function (username: string, password: string, remember: boolean) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'LOGIN',
            payload: cliente.post('api/Account/Login', {
                userName: username,
                password: password,
                remember: remember,
            }),
        });
    };
};
export const GetUserPerfil = function () {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'GET_USER_PERFIL_USER',
            payload: cliente.get(apiEndpoints.ACCOUNT.GET_USER_PERFIL),
        });
    };
};
export const GetPlanos = function (ativo: boolean) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'GET_PLANOS',
            payload: cliente.get(apiEndpoints.ACCOUNT.GET_PLANOS, { params: { ativo: ativo } }),
        });
    };
};
export const SetSubscription = function (values: any, paypal: any) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'SET_SUBSCRIPTION_PENDING',
        });
        /*
    APPROVAL_PENDING. The subscription is created but not yet approved by the buyer.
    APPROVED. The buyer has approved the subscription.
    ACTIVE. The subscription is active.
    SUSPENDED. The subscription is suspended.
    CANCELLED. The subscription is cancelled.
    EXPIRED. The subscription is expired.
    [Log] {orderID: "5N2482971L527321W", subscriptionID: "I-G1T11E4VSB59", facilitatorAccessToken: "A21AANLbF7ChghndYsBFxbFnOmTehlcIX-TDu0nfgZ_l0PWtJbeGfgQd8Dad7V6NIYEuCQec5r2rntACOkgmMIFewGoXbhV5A", planoID: 2}
     */
        cliente.get(apiEndpoints.ACCOUNT.SET_SUBSCRIPTION, { params: values }).then(() => {
            dispatch(isAuth(paypal));
        });
    };
};
export const isAuth = function (paypal: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'IS_AUTH_PENDING',
        });
        cliente
            .get(apiEndpoints.ACCOUNT.LOGADO)
            .then((check) => {
                if (
                    check.data.isAuthenticated === true &&
                    check.data.subscriptionID !== null &&
                    check.data.grupoid > 0
                ) {
                    const params = new URLSearchParams();
                    params.append('grant_type', 'client_credentials');
                    cliente
                        .post(`${paypal.api}/v1/oauth2/token`, params, {
                            headers: { Accept: 'application/json', 'Accept-Language': 'en_US' },
                            auth: {
                                username: paypal.clientid,
                                password: paypal.secret,
                            },
                        })
                        .then((res) => {
                            cliente
                                .get(`${paypal.api}/v1/billing/subscriptions/${check.data.subscriptionID}`, {
                                    headers: {
                                        Authorization: `Bearer ${res.data.access_token}`,
                                        'Content-Type': 'application/json',
                                    },
                                })
                                .then((c) => {
                                    dispatch({
                                        type: 'IS_AUTH_FULFILLED',
                                        payload: { ...check.data, subscription: c.data },
                                    });
                                })
                                .catch((c) => {
                                    console.log(check);
                                    dispatch({
                                        type: 'IS_AUTH_FULFILLED',
                                        payload: {
                                            ...check.data,
                                            subscription: { ...c.response.data, status: 'ACTIVE' },
                                        },
                                        //type: 'IS_AUTH_REJECTED',
                                        //payload: { ...check.data, subscription: { ...c.response.data, status: c.response.data.name } },
                                    });
                                });
                        });
                } else {
                    dispatch({
                        type: 'IS_AUTH_FULFILLED',
                        payload: { ...check.data, subscription: { status: 'NULL' } },
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: 'IS_AUTH_REJECTED',
                    payload: { isAuthenticated: false, permissions: [] },
                });
            });
    };
};

export const ResendConfirmEmail = function () {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'RESEND_CONFIRM_EMAIL',
            payload: cliente.post(apiEndpoints.ACCOUNT.RESEND_CONFIRM_EMAIL),
        });
    };
};
export const Logout = function () {
    return function (dispatch: Dispatch) {
        setCookie('token', null);
        dispatch({
            type: 'LOGOUT',
            payload: cliente.post(apiEndpoints.ACCOUNT.LOGOFF),
        });
    };
};

export const SignUp = function (nome: string, email: string, telefone: string, senha: string) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'SIGN_UP',
            payload: cliente.post(apiEndpoints.ACCOUNT.SIGNUP, {
                nome,
                email,
                telefone,
                senha,
            }),
        });
    };
};

export const ResetPassword = function (values: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: cliente.post(apiEndpoints.ACCOUNT.RESET_PASSWORD, { ...values }),
        });
    };
};
export const ChangeEmailForgotPassword = function (value: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'CHANGE_EMAIL_FORGOT_PASSWORD',
            payload: value,
        });
    };
};
export const ForgotPassword = function (email: string) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: cliente.post(apiEndpoints.ACCOUNT.FORGOT_PASSWORD, { Email: email }),
        });
    };
};
export const ConfirmEmail = function (userid: string, code: string) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'CONFIRM_EMAIL',
            payload: cliente.post(apiEndpoints.ACCOUNT.CONFIRM_EMAIL, { userid, code }),
        });
    };
};

export const RegisterContexto = function (data: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'REGISTER_CONTEXTO',
            payload: cliente.post(apiEndpoints.ACCOUNT.REGISTER_CONTEXTO, data),
        });
    };
};

export const pickContexto = function (grupoid: number, perfil: string, tipocontexto: number, nome: string) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'PICK_CONTEXTO_PENDING',
        });
        cliente
            .get(apiEndpoints.ACCOUNT.PICK_CONTEXT, { params: { grupoid: grupoid } })
            .then((res) => {
                setCookie('token', res.data);
                dispatch({
                    type: 'PICK_CONTEXTO_FULFILLED',
                    payload: {
                        grupoid: grupoid,
                        perfil: perfil,
                        nome: nome,
                        tipocontexto: tipocontexto,
                    },
                });
            })
            .catch(() =>
                dispatch({
                    type: 'PICK_CONTEXTO_REJECTED',
                })
            );
    };
};
export const goToContextos = function () {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'GO_TO_CONTEXTOS',
        });
        cliente.get(apiEndpoints.ACCOUNT.RESET_CONTEXTO);
    };
};
export const UpdateUserInfo = function (data: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: 'UPDATE_USER_INFO',
            payload: cliente.post(apiEndpoints.ACCOUNT.UPDATE_USER_INFO, data),
        });
    };
};
