import { cliente } from '@config/axios';
import moment from 'moment';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const GetNumbers = function (unidades: number[], competencia: string | number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_NUMBERS_FLUXO_PENDING',
    });
    cliente
      .post(apiEndpoints.FLUXOCAIXA.GET_NUMBERS, {
        unidades: unidades,
        ano: Number(competencia),
      })
      .then((res) => {
        dispatch({
          type: 'GET_NUMBERS_FLUXO_FULFILLED1',
          payload: res,
        });
        cliente
          .post('/api/Fluxocaixa/GetLancamentosFluxo', {
            unidades: unidades,
            ano: Number(competencia),
          })
          .then((res) => {
            dispatch({
              type: 'GET_NUMBERS_FLUXO_FULFILLED2',
              payload: res,
            });
          })
          .catch((res) => dispatch({ type: 'GET_NUMBERS_FLUXO_REJECTED', payload: res }));
      })
      .catch((res) => dispatch({ type: 'GET_NUMBERS_FLUXO_REJECTED', payload: res }));
  };
};

export const SelectUnidades = function (unidades: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECT_UNIDADES_FLUXO',
      payload: unidades,
    });
  };
};

export const SetCompetencia = function (competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_COMPETENCIA_FLUXO',
      payload: competencia,
    });
  };
};
