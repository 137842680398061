import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { PessoasReducer } from '@reducers/reducers';
import { createReducer } from '@reduxjs/toolkit';
import {
  ChangeTabelaPessoas,
  ClearImportData,
  DeletePessoa,
  EditPessoa,
  GetPessoas,
  GetPessoasGrouped,
  GetPessoasList,
  HidePessoaForm,
  ImportPessoas,
  LoadPessoa,
  NewPessoa,
  SearchCNPJ,
  SelectedPessoas,
  SetPessoasFiltros,
  ShowPessoaForm,
  ShowPessoas,
} from '@actions/pessoasActions';

var initialState: PessoasReducer = {
  waiting: false,
  pessoas: [],
  pessoasAll: [],
  selectedpessoas: [],
  pessoaspagination: {
    filters: {},
    pagination: {
      current: 1,
      pageSize: 10,
      total: 10,
    },
    sorter: {},
  },
  filters: {},
  sorter: {},
  pessoasGrouped: [],
  pessoasCadastro: [],
  pessoasloading: false,
  formvisible: false,
  pessoa: null,
  searchloading: false,
  savingloading: false,
  loadingPessoas: false,
  importando: false,
  data: null,
};
export default createReducer(initialState, (builder) => {
  builder.addCase(ClearImportData, (state) => {
    return {
      ...state,
      data: null,
    };
  });
  builder.addCase(ImportPessoas.pending, (state) => {
    return { ...state, importando: true };
  });
  builder.addCase(ImportPessoas.rejected, (state, action) => {
    notification.error({ message: action.payload.response.data });
    console.log(action);
    return { ...state, importando: false };
  });
  builder.addCase(ImportPessoas.fulfilled, (state, action) => {
    notification.success({ message: 'Arquivo importado com sucesso!' });
    return { ...state, importando: false, data: action.payload.data };
  });
  builder.addCase(SetPessoasFiltros, (state, action) => {
    return {
      ...state,
      pessoaspagination: action.payload,
    };
  });
  builder.addCase(GetPessoas.pending, (state) => {
    return {
      ...state,
      waiting: true,
      loadingPessoas: true,
    };
  });
  builder.addCase(GetPessoas.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar pessoas' });
    return {
      ...state,
      waiting: false,
      loadingPessoas: false,
    };
  });
  builder.addCase(GetPessoas.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      waiting: false,
      loadingPessoas: false,
      pessoas: action.payload.data,
      pessoasAll: action.payload.data,
    };
  });
  builder.addCase(GetPessoasList.pending, (state) => {
    return {
      ...state,
      waiting: true,
      loadingPessoas: true,
    };
  });
  builder.addCase(GetPessoasList.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar pessoas' });
    return {
      ...state,
      waiting: false,
      loadingPessoas: false,
    };
  });
  builder.addCase(GetPessoasList.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      waiting: false,
      loadingPessoas: false,
      pessoas: action.payload.data,
      pessoasAll: action.payload.data,
    };
  });
  builder.addCase(GetPessoasGrouped.pending, (state) => {
    return {
      ...state,
      waiting: true,
    };
  });
  builder.addCase(GetPessoasGrouped.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar pessoas!' });
    return {
      ...state,
      waiting: false,
    };
  });
  builder.addCase(GetPessoasGrouped.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      waiting: false,
      pessoasGrouped: action.payload.data,
    };
  });
  builder.addCase(SelectedPessoas, (state, action) => {
    return { ...state, selectedpessoas: action.payload };
  });
  builder.addCase(ChangeTabelaPessoas.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      pessoasAll: action.payload.data.result,
      pessoasloading: false,
      waiting: false,
    };
  });
  builder.addCase(ShowPessoas.pending, (state) => {
    return {
      ...state,
      pessoasloading: true,
    };
  });
  builder.addCase(ShowPessoas.rejected, (state) => {
    return {
      ...state,
      pessoasloading: false,
    };
  });
  builder.addCase(ShowPessoas.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      waiting: false,
      pessoaspagination: {
        ...state.pessoaspagination,
        pagination: {
          ...state.pessoaspagination.pagination,
          total: action.payload.data.total,
        },
      },
      pessoasCadastro: action.payload.data.pessoas,
      pessoasloading: false,
    };
  });
  builder.addCase(DeletePessoa.pending, (state) => {
    return {
      ...state,
      pessoasloading: true,
    };
  });
  builder.addCase(DeletePessoa.rejected, (state, action: extendedAction) => {
    notification.error({ message: action.payload.response.data });
    return {
      ...state,
      pessoasloading: false,
    };
  });
  builder.addCase(DeletePessoa.fulfilled, (state, action: extendedAction) => {
    notification.success({ message: 'Item excluído com sucesso!' });
    return {
      ...state,
      pessoasloading: false,
      pessoasCadastro: state.pessoasCadastro.filter((c: any) => c.id !== action.payload.data),
    };
  });
  builder.addCase(HidePessoaForm, (state) => {
    return {
      ...state,
      formvisible: false,
    };
  });
  builder.addCase(ShowPessoaForm, (state) => {
    return {
      ...state,
      formvisible: true,
      pessoa: null,
    };
  });
  builder.addCase(LoadPessoa, (state, action) => {
    return {
      ...state,
      pessoa: action.payload,
      waiting: false,
      formvisible: true,
    };
  });
  builder.addCase(SearchCNPJ, (state, action) => {
    return {
      ...state,
      searchloading: action.payload,
    };
  });
  builder.addCase(NewPessoa.pending, (state) => {
    return {
      ...state,
      savingloading: true,
    };
  });
  builder.addCase(NewPessoa.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar cadastro!' });
    return {
      ...state,
      savingloading: false,
    };
  });
  builder.addCase(NewPessoa.fulfilled, (state, action: extendedAction) => {
    notification.success({ message: 'Cadastro salvo com sucesso!' });
    return {
      ...state,
      savingloading: false,
      formvisible: false,
      pessoasCadastro: action.payload.data.novo
        ? [...state.pessoasCadastro, action.payload.data.pessoa]
        : state.pessoasCadastro.map((c: any) =>
            c.id === action.payload.data.pessoa.id ? action.payload.data.pessoa : c
          ),
    };
  });
  builder.addCase(EditPessoa.pending, (state) => {
    return {
      ...state,
      pessoasloading: true,
    };
  });
  builder.addCase(EditPessoa.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar cadastro!' });
    return {
      ...state,
      pessoasloading: false,
    };
  });
  builder.addCase(EditPessoa.fulfilled, (state, action: extendedAction) => {
    notification.success({ message: 'Cadastro salvo com sucesso!' });
    return {
      ...state,
      pessoasloading: false,
      savingloading: false,
      formvisible: false,
      pessoasCadastro: state.pessoasCadastro.map((c) =>
        c.id === action.payload.data.pessoa.id ? action.payload.data.pessoa : c
      ),
    };
  });
});
