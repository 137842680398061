import { cliente } from '@config/axios';
import { message } from 'antd';
import { AppDispatch } from '@config/reduxStore';
import { ContaSintetica } from '@models/ContaSintetica';
import { ContaAnalitica } from '@models/ContaAnalitica';
import { Empresa } from '@models/Empresa';
import { UnidadeNegocio } from '@models/UnidadeNegocio';
import { GetPlanoContas } from '@actions/planocontasActions';
import { apiEndpoints } from '@config/apiEndpoints';

export const ChangeContextoName = function (nome: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_NOME_CONTEXTO_EMPRESAS',
      payload: cliente.post(apiEndpoints.EMPRESAS.CHANGE_CONTEXTO_NAME, { nome }),
    });
  };
};
export const NovaSinteticaView = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'NOVA_SINTETICA_VIEW_EMPRESAS',
    });
  };
};
export const AddSintetica = function (values: ContaSintetica) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_SINTETICA_EMPRESAS_PENDING',
    });
    cliente
      .post(apiEndpoints.EMPRESAS.ADD_SINTETICA, {
        values: values,
      })
      .then((res) => {
        dispatch({
          type: 'ADD_SINTETICA_EMPRESAS_FULFILLED',
          payload: { data: res.data },
        });
        dispatch(GetPlanoContas());
      });
  };
};

export const AddAnalitica = function (data: ContaAnalitica) {
  return function (dispatch: AppDispatch) {
    if (!data.descricao) message.error('Descriçao da conta não pode ser em branco');
    else {
      if (!data.unidade) message.error('Escolha uma unidade de negócio.');
      else {
        dispatch({
          type: 'ADD_ANALITICA_EMPRESAS_PENDING',
        });
        cliente.post(apiEndpoints.EMPRESAS.ADD_ANALITICA, data).then((res) => {
          message.success('Conta criada com sucesso!');
          dispatch({
            type: 'ADD_ANALITICA_EMPRESAS_FULFILLED',
            payload: res,
          });
        });
      }
    }
  };
};
export const Reset = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'RESET',
    });
  };
};

export const LoadEmpresas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_EMPRESAS_EMPRESA',
      payload: cliente.get(apiEndpoints.EMPRESAS.GET_EMPRESAS),
    });
  };
};

export const HideEmpresaForm2 = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_EMPRESA_FORM_EMPRESA2',
    });
  };
};
export const HideEmpresaForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_EMPRESA_FORM_EMPRESA',
    });
  };
};
export const HideUnidadeForm = function (empresaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_UNIDADE_FORM_EMPRESA',
      payload: empresaid,
    });
  };
};
export const SearchCNPJ_CEP = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SEARCH_EMPRESA',
    });
  };
};

export const LoadUnidadeEdit = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_UNIDADE_EDIT_EMPRESA',
      payload: id,
    });
  };
};
export const LoadEmpresaEdit = function (empresaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_EMPRESA_EDIT_EMPRESA',
      payload: empresaid,
    });
  };
};
export const SaveEmpresa = function (empresa: Empresa) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SAVE_EMPRESA_EMPRESA',
      payload: empresa.id
        ? cliente.post(apiEndpoints.EMPRESAS.EDITAR_EMPRESA, empresa)
        : cliente.post('/api/Empresas/AddEmpresa', empresa),
    });
  };
};
export const SaveUnidade = function (unidade: UnidadeNegocio) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SAVE_UNIDADE_EMPRESA',
      payload: unidade.id
        ? cliente.post(apiEndpoints.UNIDADES.EDITAR_UNIDADE, unidade)
        : cliente.post(apiEndpoints.UNIDADES.ADD_UNIDADE, unidade),
    });
  };
};

export const GetContasUnidade = function (unidadeid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS_UNIDADE_EMPRESA_PENDING',
    });
    cliente
      .get(apiEndpoints.UNIDADES.GET_CONTAS, {
        params: { unidadeid: unidadeid },
      })
      .then((res) => {
        dispatch({
          type: 'GET_CONTAS_UNIDADE_EMPRESA_FULFILLED',
          payload: {
            data: res.data,
            unidade: unidadeid,
          },
        });
      });
  };
};
export const HideUsersForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_USERS_FORM_EMPRESA',
    });
  };
};

export const GetUsers = function (unidadeid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_UNIDADE_USERS_FORM_EMPRESA',
      payload: unidadeid,
    });
    dispatch({
      type: 'GET_USERS_FORM_EMPRESA',
      payload: cliente.get(apiEndpoints.UNIDADES.GET_USERS, {
        params: { unidadeid: unidadeid },
      }),
    });
  };
};

export const SetUserStatus = function (userid: string, unidadeid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_USERS_FORM_EMPRESA',
      payload: cliente.post(apiEndpoints.UNIDADES.CHANGE_USER_STATUS, {
        userid: userid,
        unidadeid: unidadeid,
      }),
    });
  };
};

export const ToggleAddContaForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'TOGGLE_CONTAS_EMPRESA',
    });
  };
};
