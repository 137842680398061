import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import logo from '../../../assets/img/logo.png';
import { Skeleton, Spin } from 'antd';
import { formatNumber } from '@utils/masks';
import { BalancoController } from '@controllers/BalancoController';

function PrintBalanco() {
  const { data, actions } = BalancoController();
  const [searchParams] = useSearchParams();
  const [query] = useState({
    unidades: JSON.parse(searchParams.get('Unidades')) as number[],
    competencia: moment(searchParams.get('Competencia')),
    tipo: Number(searchParams.get('Tipo')),
  });
  useEffect(() => {
    if (data.unidades) {
      actions.SelectUnidade(query.unidades);
      actions.SetCompetencia(query.competencia);
      actions.Gerar(query.tipo);
    }
  }, [data.unidades]);
  useEffect(() => {
    if (data.unidades && data.balanco) {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Spin spinning={data.balanco.waiting}>
          <div className="balancoprint">
            <table className="" style={{ width: '100%' }}>
              <thead className="">
                <tr>
                  <th>
                    <img src={logo} height={30} className="logo" alt="logo" />
                  </th>
                  <th colSpan={5}>
                    {data.unidades.length > 0 && (
                      <h4>{`Balanço ${data.balanco.competencia.format('MM/YYYY')} - Unidades: ${data.unidades.map(
                        (u) => ' ' + data.unidades.find((c) => c.id === u.id)?.nome
                      )}`}</h4>
                    )}
                  </th>
                </tr>
                <tr style={{ fontWeight: 'strong' }}>
                  <th>Código</th>
                  <th>Descrição</th>
                  <th className="valor">{'Saldo ' + data.balanco.competencia.subtract(1, 'year').format('YYYY')}</th>
                  <th className="valor">{'Créditos até ' + data.balanco.competencia.format('MMM/YYYY')}</th>
                  <th className="valor">{'Débitos até ' + data.balanco.competencia.format('MMM/YYYY')}</th>
                  <th className="valor">Saldo Total</th>
                </tr>
              </thead>
              <tbody className="report-content">
                {data.balanco.balanco.map((c: any, index: number) => {
                  return (
                    <tr key={index} className={`nivel${c.nivel}`}>
                      <td>{c.nivel === 6 ? '' : c.codigo}</td>
                      <td>{c.descricao}</td>
                      <td className={c.saldoAnoAnterior < 0 ? 'valor negativo' : 'valor positivo'}>
                        {formatNumber(c.saldoAnoAnterior)}
                      </td>
                      <td className={c.creditosCompetencia < 0 ? 'valor negativo' : 'valor positivo'}>
                        {formatNumber(c.creditosCompetencia)}
                      </td>
                      <td className={c.debitosCompetencia < 0 ? 'valor negativo' : 'valor positivo'}>
                        {formatNumber(c.debitosCompetencia)}
                      </td>
                      <td className={c.saldoTotal < 0 ? 'valor negativo' : 'valor positivo'}>
                        {formatNumber(c.saldoTotal)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Spin>
      );
    }
  }, [data.balanco.balanco, data.unidades, data.balanco.waiting]);
  return <Skeleton active loading={true} />;
}
export default PrintBalanco;
