import moment from 'moment';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';

export const moneyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
  locale: 'pt-BR',
});
export const moneyMaskNeg = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
  locale: 'pt-BR',
  allowNegative: true,
});

export const phoneMobMask = createTextMask({
  pattern: '(99) 99999-9999',
  guide: false,
  stripMask: false,
});
export const doubleMask = createNumberMask({
  decimalPlaces: 2,
  allowNegative: false,
  locale: 'pt-BR',
});

export const phoneFixoMask = createTextMask({
  pattern: '(99) 9999-9999',
  guide: false,
  stripMask: false,
});
export const cpfMask = createTextMask({
  pattern: '999.999.999-99',
  guide: false,
});
export const cnpjMask = createTextMask({
  pattern: '99.999.999/9999-99',
  guide: false,
  stripMask: false,
});
export const cepMask = createTextMask({
  pattern: '99.999-999',
  guide: false,
  allowEmpty: true,
});
export const removeMoment = (value: any) => {
  return moment(value).format('YYYY-MM-DD');
};

export const putMoment = (value: any) => {
  return moment(value);
};

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  minimumFractionDigits: 2,
  currency: 'BRL',
});

export const formatvalor = function (n: number) {
  if (n < 0) n *= -1;
  return formatter.format(n);
};
export const formatvalorneg = function (n: number) {
  return formatter.format(n);
};
export const rformatvalor = function (n: string) {
  if (!n) return '';
  var valor = parseInt(n.replace('/D/g', ''));
  return valor;
};
export const inteiro = function (n: string) {
  if (!n) return n;
  n = n.replace(/[A-z]/g, '');
  if (n === '') return '';

  var result = parseInt(n.replace(/[\D]+/g, ''));
  return result;
};

export const formatNumber = (number: number | string) => {
  return <span style={{ color: number < 0 ? 'red' : 'blue' }}>{moneyMaskNeg.format(number)}</span>;
};

export const sortAlpha = (a: any, b: any) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};
export const orderDate = (a: Date, b: Date) =>
  moment(moment(a).isValid() ? moment(a) : moment(0)).unix() -
  moment(moment(b).isValid() ? moment(b) : moment(0)).unix();
