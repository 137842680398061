import { UnidadeNegocio } from '@models/UnidadeNegocio';

export const handleSelectAll = (value: any[], unidades: UnidadeNegocio[]) => {
  if (value && value.length && value.filter((u) => u === 'all').length) {
    if (value.length === unidades.map((u) => u.id).length + 1) {
      return [];
    }
    return [...unidades.map((u) => u.id)];
  }
  return value;
};
