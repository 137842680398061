import moment from 'moment';
import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { ImplantacaoReducer } from '@reducers/reducers';

var initialState: ImplantacaoReducer = {
  unidade: null,
  unidadeImplantacao: null,
  contas: [],
  loadingcontas: false,
  expandedKeys: [],
  data: moment(new Date()),
};

const implantacaoReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'IMPLANTAR_SALDOS_IMPLANTACAO_PENDING':
      state = {
        ...state,
        loadingcontas: true,
      };
      break;
    case 'IMPLANTAR_SALDOS_IMPLANTACAO_REJECTED':
      notification.error({ message: 'Erro ao implantar saldos!' });
      state = {
        ...state,
        loadingcontas: false,
      };
      break;
    case 'IMPLANTAR_SALDOS_IMPLANTACAO_FULFILLED':
      state = {
        ...state,
        loadingcontas: false,
        unidade: null,
        data: moment(),
        unidadeImplantacao: null,
        contas: [],
      };
      notification.success({ message: 'Saldos implantados com sucesso!' });
      break;
    case 'EXPAND_ROW_IMPLANTACAO':
      let exists = state.expandedKeys.find((c) => c === action.payload);
      if (exists) {
        state = {
          ...state,
          expandedKeys: state.expandedKeys.filter((c) => c !== action.payload),
        };
      } else {
        state = {
          ...state,
          expandedKeys: [...state.expandedKeys, action.payload],
        };
      }
      break;
    case 'SET_UNIDADE_IMPLANTACAO':
      state = {
        ...state,
        unidade: action.payload,
        unidadeImplantacao: action.payload,
      };
      break;
    case 'SET_UNIDADE_IMPLANTACAO_IMPLANTACAO':
      state = {
        ...state,
        unidadeImplantacao: action.payload,
      };
      break;
    case 'SET_DATA_IMPLANTACAO':
      state = {
        ...state,
        data: action.payload,
      };
      break;
    case 'SET_UNIDADE_ONLY':
      state = {
        ...state,
        unidade: action.payload,
        unidadeImplantacao: action.payload,
      };
      break;
    case 'LOAD_CONTAS_IMPLANTACAO_FULFILLED':
      var tempExpandedKeys: any[] = [];
      let count = 0;
      action.payload.data.forEach((n1: any) => {
        tempExpandedKeys.push(n1.key);
        n1.children.forEach((n2: any) => {
          tempExpandedKeys.push(n2.key);
          n2.children.forEach((n3: any) => {
            tempExpandedKeys.push(n3.key);
            n3.children.forEach((n4: any) => {
              tempExpandedKeys.push(n4.key);
              n4.children.forEach((n5: any) => {
                n5.count = count;
                count++;
              });
            });
          });
        });
      });

      state = {
        ...state,
        contas: action.payload.data,
        loadingcontas: false,
        expandedKeys: tempExpandedKeys,
      };
      break;
    case 'LOAD_CONTAS_IMPLANTACAO_PENDING':
      state = {
        ...state,
        loadingcontas: true,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default implantacaoReducer;
