import { useEffect } from 'react';
import { Spin } from 'antd';
import './orcamento.css';
import { formatNumber } from '@utils/masks';
import { useParams } from 'react-router-dom';
import { LoadOrcamentoView } from '@actions/orcamentosActions';
import logo from '../../../assets/img/logo.png';
import '../../../assets/scss/Orcamentos.scss';
import ReactDOM from 'react-dom/client';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrcamentoConta, OrcamentoMensal } from '@models/Orcamento';
import { TipoOrcamento } from '@constants/models';
import { OrcamentosReducer, OrcamentoView } from '@reducers/reducers';

const Titulo = ({ orcamento }: { orcamento: OrcamentoView<OrcamentoConta | OrcamentoMensal> }) => (
  <h3>
    {orcamento.descricao} - ({orcamento.unidade}) -{' '}
    {orcamento.tipoOrcamento === TipoOrcamento.Global ? 'Global' : 'Mensal'}
    {orcamento.ano === null ? '' : ' - ' + orcamento.ano}
  </h3>
);

const TableMensal = ({ orcamentos }: { orcamentos: OrcamentosReducer<OrcamentoMensal> }) => (
  <div className="printmensaltable">
    <table>
      <thead>
        <tr>
          <th>
            <img src={logo} alt="logo" height={30} />
          </th>
          <th colSpan={4}>
            <Titulo orcamento={orcamentos.orcamentoview} />
          </th>
        </tr>
        <tr>
          <th>Código</th>
          <th>Descrição</th>
          <th></th>
          <th>JAN</th>
          <th>FEV</th>
          <th>MAR</th>
          <th>ABR</th>
          <th>MAI</th>
          <th>JUN</th>
          <th>JUL</th>
          <th>AGO</th>
          <th>SET</th>
          <th>OUT</th>
          <th>NOV</th>
          <th>DEV</th>
          <th>TOTAL</th>
        </tr>
      </thead>
      <tbody>
        {orcamentos.orcamentoview.orcamentoConta.map((c, index) => (
          <tr key={index} className={c.tipo === 'S' ? 'S' : c.tipo === 'A' ? 'A' : 'TM'}>
            <td>{c.cod}</td>
            <td>{c.contaAnalitica}</td>
            <td className="valor">
              <div>
                <div>REALIZADO</div>
                <div>ORÇADO</div>
                <div>SALDO</div>
              </div>
            </td>
            {new Array(13).fill(0).map((_, index) => (
              <td className="valor" key={index}>
                <div>
                  <div>{formatNumber(c[`realizado${index + 1 < 10 ? '0' : ''}${index + 1}`])}</div>
                  <div>{formatNumber(c.saldo)}</div>
                  <div>{formatNumber(c[`saldo${index + 1 < 10 ? '0' : ''}${index + 1}`])}</div>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const TableGlobal = ({ orcamentos }: { orcamentos: OrcamentosReducer<OrcamentoConta> }) => (
  <div className="printmensaltable">
    <table>
      <thead>
        <tr>
          <th>
            <img src={logo} alt="logo" height={30} />
          </th>
          <th colSpan={15}>
            <Titulo orcamento={orcamentos.orcamentoview} />
          </th>
        </tr>
        <tr>
          <th>Código</th>
          <th>Descrição</th>
          <th>Realizado</th>
          <th>Orçado</th>
          <th>Saldo</th>
        </tr>
      </thead>
      <tbody>
        {orcamentos.orcamentoview.orcamentoConta.map((c, index) => (
          <tr key={index} className={c.tipo === 'S' ? 'S' : c.tipo === 'A' ? 'A' : 'TM'}>
            <td>{c.cod}</td>
            <td>{c.contaAnalitica}</td>
            <td className="valor">{formatNumber(c.realizado)}</td>
            <td className="valor">{formatNumber(c.orcado)}</td>
            <td className="valor">{formatNumber(c.saldo)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

function OrcamentoPrint() {
  const orcamentos = useAppSelector((state) => state.orcamentos);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (Number(id)) dispatch(LoadOrcamentoView(Number(id)));
  }, [id, dispatch]);
  useEffect(() => {
    if (orcamentos.orcamentoview.orcamentoConta.length > 0) {
      const element = document.getElementById('root');
      if (element) {
        const root = ReactDOM.createRoot(element);
        root.render(
          <Spin spinning={orcamentos.waiting}>
            {orcamentos.orcamentoview.tipoOrcamento === TipoOrcamento.Global && (
              <TableGlobal orcamentos={orcamentos as OrcamentosReducer<OrcamentoConta>} />
            )}
            {orcamentos.orcamentoview.tipoOrcamento === TipoOrcamento.Mensal && (
              <TableMensal orcamentos={orcamentos as OrcamentosReducer<OrcamentoMensal>} />
            )}
          </Spin>
        );
      }
    }
  }, [orcamentos.orcamentoview.orcamentoConta, orcamentos]);
  return (
    <Spin spinning={orcamentos.waiting}>
      <div style={{ minHeight: '100vh' }}></div>
    </Spin>
  );
}

export default OrcamentoPrint;
