import { Button, Input, Switch, Table } from 'antd';
import { useEffect } from 'react';
import { ActivatePlano, GetPlanos } from '@actions/sistemaActions';
import { moneyMask } from '@utils/masks';
import moment from 'moment';
import { useState } from 'react';
import { filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import PlanoForm from './PlanoForm';
import { EditOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Plano } from '@models/Plano';

function Planos() {
  const dispatch = useAppDispatch();
  const { planos, loadingPlanos } = useAppSelector((state) => state.sistema);
  const [filtro, setFiltro] = useState('');
  const [novo, setNovo] = useState(false);
  const [edit, setEdit] = useState(null);
  useEffect(() => {
    dispatch(GetPlanos(false));
  }, [dispatch]);
  useEffect(() => {
    document.title = 'Nambi - Gestão Financeira Inteligente';
    dispatch(
      SetPageHeader({
        title: 'Suporte',
        subTitle: 'Planos',
        extras: [
          <Button key={0} size="small" type="primary" onClick={() => setNovo(true)}>
            Novo Plano
          </Button>,
        ],
      })
    );
  }, [dispatch]);
  return (
    <div>
      <PlanoForm visible={novo} setVisible={setNovo} item={edit} setItem={setEdit} />
      <Input.Search
        placeholder="Buscar..."
        size="small"
        style={{ width: 200 }}
        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
      />
      <Table
        size="small"
        rowKey="id"
        loading={loadingPlanos}
        dataSource={filterobject(planos, filtro)}
        scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
      >
        <Table.Column
          title="Ativo"
          width={50}
          dataIndex="ativo"
          render={(v, row: Plano) => <Switch checked={v} onChange={() => dispatch(ActivatePlano(row.id))} />}
        />
        <Table.Column
          width={50}
          dataIndex="id"
          align="center"
          render={(_, row: Plano) => (
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                setNovo(true);
                setEdit(row);
              }}
            />
          )}
        />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column title="Unidades" dataIndex="unidades" align="center" />
        <Table.Column title="Empresas" dataIndex="empresas" align="center" />
        <Table.Column
          title="Data Lançamento"
          dataIndex="dataLancamento"
          render={(v) => moment(v).format('DD/MM/YYYY')}
        />
        <Table.Column
          title="Data Encerramento"
          dataIndex="dataEncerramento"
          render={(v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : '')}
        />
        <Table.Column title="PlanoID" dataIndex="planoID" />
        <Table.Column title="Valor" dataIndex="valor" align="right" render={(v) => moneyMask.format(v)} />
      </Table>
    </div>
  );
}

export default Planos;
