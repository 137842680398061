import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, notification } from 'antd';
import TabelaExtrato from './TabelaExtrato';
import { ResetExtrato, GetBancariasExtrato } from '@actions/extratoActions';
import { GetPessoasGrouped } from '@actions/pessoasActions';
import { GetUnidades } from '@actions/generalActions';
import TituloExtrato from './TituloExtrato';
import { SetPageHeader } from '@actions/userActions';
import ExtratoForm from './ExtratoForm';
import { Permission } from '@hooks/usePermissions';
import { FileExcelOutlined } from '@ant-design/icons';
import cliente from '@config/axios';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function ExtratoView() {
  const dispatch = useAppDispatch();
  const { extrato } = useAppSelector((state) => state.extrato);
  const [extratoForm, setExtratoForm] = useState(true);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    dispatch(GetBancariasExtrato());
    dispatch(GetPessoasGrouped());
    dispatch(GetUnidades(true, true));
    dispatch(ResetExtrato());
  }, [dispatch]);
  useEffect(() => {
    document.title = 'Nambi - Extrato';
    dispatch(
      SetPageHeader({
        title: 'Extrato',
        subTitle: 'Visualize o extrato de uma conta escolhendo o período desejado no menu lateral',
        extras: [
          <Button
            key={0}
            size="small"
            icon={<FileExcelOutlined />}
            disabled={extrato.length === 0}
            loading={downloading}
            onClick={() => {
              setDownloading(true);
              cliente
                .post(
                  '/api/Extrato/Exportar',
                  { extrato: extrato.map((c) => ({ ...c, bloco: c.userCod + c.bid })) },
                  {
                    responseType: 'blob',
                  }
                )
                .then((res) => {
                  setDownloading(false);
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `Extrato_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
                })
                .catch(() => {
                  notification.error({ message: 'Erro ao gerar arquivo!' });
                  setDownloading(false);
                });
            }}
          >
            Exportar
          </Button>,
          <Permission permission="extrato.list" key={1}>
            <Button size="small" type="primary" onClick={() => setExtratoForm(true)}>
              Gerar Extrato
            </Button>
          </Permission>,
        ],
      })
    );
  }, [downloading, extrato, dispatch]);

  return (
    <div>
      <Permission permission="extrato.list">
        <TituloExtrato />
        <TabelaExtrato />
        <ExtratoForm visible={extratoForm} setVisible={setExtratoForm} />
      </Permission>
    </div>
  );
}

export default connect()(ExtratoView);
