import { notification } from 'antd';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { ContasPGreducer } from '@reducers/reducers';

var initialState: ContasPGreducer = {
  unidades: [],
  unidade: null,
  id: null,
  extrato: [],
  totaldebito: 0,
  totalcredito: 0,
  saldototal: 0,
  contas: [],
  contaid: null,
  periodo: [],
  tipoconta: null,
  periodoinicio: moment(new Date()).subtract(90, 'days'),
  periodofim: moment(new Date()),
  waiting: false,
  validateunidade: '',
  validadeperiodo: '',
  validateconta: '',
  selectedpessoas: [],
  selectedlancamentos: [],
  choseunidademodal: false,
  unidadeschoose: [],
  lancamentosresultado: [],
  deleteids: [],
  confirmdeletemodal: false,
  titulo: 'todos',
  contatitle: '',
  nivel: null,
  modo: 'Pessoa',
  filtros: {
    modo: 'Geral',
    status: 0,
    datainicial: moment(),
    datafinal: moment().add(30, 'days'),
    tipo: 2,
    pessoas: [],
    unidades: [],
  },
  loadingTitulos: false,
};

const contasPGReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SET_UNIDADE_ONLY':
      state = {
        ...state,
        unidade: [Number(action.payload)],
      };
      break;
    case 'SET_FILTROS':
      state = {
        ...state,
        filtros: action.payload,
      };
      break;
    case 'GET_CONTAS_EXTRATO_FULFILLED':
      state = {
        ...state,
        contas: action.payload.data,
        //contaid: null,
        waiting: false,
      };
      break;
    case 'GET_TITULOS_CONTASPG_PENDING':
      state = {
        ...state,
        extrato: [],
        loadingTitulos: true,
      };
      break;
    case 'GET_TITULOS_CONTASPG_REJECTED':
      state = {
        ...state,
        loadingTitulos: false,
      };
      notification.error({ message: action.payload.response.data });
      break;
    case 'GET_TITULOS_CONTASPG_FULFILLED':
      state = {
        ...state,
        loadingTitulos: false,
        extrato: action.payload.data,
      };
      break;
    case 'GET_CONTAS_EXTRATO':
      state = {
        ...state,
        unidades: action.payload,
        contaid: action.payload.length > 0 ? '' : null,
      };
      break;
    case 'GET_CONTAS_EXTRATO_PENDING':
      state = {
        ...state,
        validateunidade: '',
        waiting: true,
      };
      break;
    case 'SET_CONTA_EXTRATO':
      state = {
        ...state,
        contaid: action.payload.value,
      };
      break;
    case 'SET_CONTA_TITLE':
      state = {
        ...state,
        contatitle: action.payload.title,
      };
      break;

    case 'CLEAR_CONTA_TITLE':
      state = {
        ...state,
        contatitle: '',
      };
      break;
    case 'CHANGE_TITULOS_CONTASPG':
      state = {
        ...state,
        titulo: action.payload,
      };
      break;
    case 'GET_CONTAS_CONTASPG_FULFILLED':
      state = {
        ...state,
        contas: action.payload.data,

        waiting: false,
      };
      break;
    case 'GET_CONTAS_CONTASPG':
      state = {
        ...state,
        unidade: action.payload,
      };
      break;
    case 'GET_CONTAS_CONTASPG_PENDING':
      state = {
        ...state,
        validateunidade: '',
        waiting: true,
      };
      break;
    case 'SET_FILTER_PESSOAS_CONTASPG':
      state = {
        ...state,
        selectedpessoas: action.payload,
      };
      break;
    case 'SET_FILTER_CONTASPG_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'SET_FILTER_CONTASPG_FULFILLED':
      state = {
        ...state,
        extrato: action.payload.data.result,
        totalcredito: action.payload.data.totalcredito,
        totaldebito: action.payload.data.totaldebito,
        saldototal: action.payload.data.saldo,
        waiting: false,
      };
      break;
    case 'RESET_CONTASPG':
      state = {
        ...initialState,
      };
      break;
    case 'GET_UNIDADES_CHOOSE_FULFILLED':
      state = {
        ...state,
        unidadeschoose: action.payload.data,
      };
      break;
    default:
      return state;
  }

  return state;
};

export default contasPGReducer;
