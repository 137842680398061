import { notification, Spin, Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ImportPessoas } from '@actions/pessoasActions';

function ImportarImportButton() {
  const dispatch = useAppDispatch();
  const { importando } = useAppSelector((state) => state.conciliar);
  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    customRequest(options) {
      const { file } = options;
      const fmData = new FormData();
      fmData.append('file', file);
      dispatch(ImportPessoas(fmData));
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        notification.success({ message: `${info.file.name} file uploaded successfully.` });
      } else if (status === 'error') {
        notification.error({ message: `${info.file.name} file upload failed.` });
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  return (
    <Spin spinning={importando}>
      <Upload.Dragger {...props} style={{ padding: 10 }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Clique ou arraste seu arquivo para esta área</p>
        <p className="ant-upload-hint">Suporte para um único arquivo no formato xlsx</p>
      </Upload.Dragger>
    </Spin>
  );
}
export default ImportarImportButton;
