import { message } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { GeneralReducer } from '@reducers/reducers';

var initialState: GeneralReducer = {
  unidades: [],
  contasanaliticas: [],
  waiting: false,
  loadingUnidades: false,
};
message.config({
  top: 65,
});

const generalReducer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'GET_UNIDADES_PENDING':
      state = {
        ...state,
        waiting: true,
        loadingUnidades: true,
      };
      break;
    case 'GET_UNIDADES_FULFILLED':
      state = {
        ...state,
        unidades: action.payload.data,
        waiting: false,
        loadingUnidades: false,
      };
      //state.unidades.length === 1 ? state.balanco.unidadesbalanco.push(state.unidades[0].ID) : [];
      break;

    default:
      return state;
  }

  return state;
};

export default generalReducer;
