import { useState } from 'react';
import { Permission } from '@hooks/usePermissions';
import { Button, Table, Radio, Row, Col, Input, Tooltip, Popconfirm, Spin, Space } from 'antd';
import { sortAlpha, filterobject } from '@utils/filterandsort';
import UsuarioForm from './UsuarioForm';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { User } from '@models/User';
import { UsuariosController } from '@controllers/UsuariosController';
import { TipoContexto } from '@constants/models';

function UsuariosView() {
  const controller = UsuariosController;
  const { data, actions } = controller();
  const [filter, setFilter] = useState('');
  const hasSelected = data.selectedUsuarios.length > 0;
  return (
    <div>
      <Permission permission="usuario.list">
        <Space>
          <Input
            style={{ width: 200 }}
            suffix={<SearchOutlined />}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Buscar"
            allowClear
          />
        </Space>
        <Popconfirm
          title="Tem certeza que deseja excluir usuário desta conta?"
          onConfirm={() => actions.Remove()}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="primary" disabled={!hasSelected}>
            Excluir
          </Button>
        </Popconfirm>
        <span style={{ marginLeft: 8 }}>{hasSelected ? `${data.selectedUsuarios.length} selecionados` : ''}</span>
        <Spin spinning={data.savingusuario}>
          <Table
            bordered
            tableLayout="fixed"
            size="small"
            scroll={{ y: 'calc(100vh - 230px)', x: 1000 }}
            rowKey="id"
            pagination={false}
            loading={data.loadingusuarios}
            dataSource={filterobject(data.usuarios, filter)}
            rowSelection={{
              onChange: (selectedRowKeys) => actions.SelectUsers(selectedRowKeys),
              selectedRowKeys: data.selectedUsuarios,
            }}
          >
            {false && (
              <Table.Column
                width={50}
                fixed="left"
                render={(_, row: User) => (
                  <div>
                    <Permission permission="usuario.edit">
                      <Tooltip title="Editar">
                        <Button icon={<EditOutlined />} onClick={() => actions.HideForm(row.id, true)} size="small" />
                      </Tooltip>
                    </Permission>
                  </div>
                )}
              />
            )}
            <Table.Column title="Nome" dataIndex="nome" sorter={(a: User, b: User) => sortAlpha(a.nome, b.nome)} />
            <Table.Column title="Email" dataIndex="email" sorter={(a: User, b: User) => sortAlpha(a.email, b.email)} />
            <Table.Column
              title="Perfis"
              dataIndex="Perfil"
              width={400}
              filters={data.perfis.map((c) => ({ value: c.name, text: c.name.toUpperCase() }))}
              onFilter={(value, row: User) => row.perfil.includes(value.toString())}
              render={(_, record: User) => (
                <Radio.Group size="small" value={record.perfilID}>
                  <Row>
                    {data.perfis
                      .filter((p) => p.tipoContexto === TipoContexto.Negocio)
                      .map((item, index: number) => (
                        <Col key={index}>
                          <Radio
                            style={{ display: 'block' }}
                            key={index}
                            value={item.id}
                            onChange={(e) => actions.AddUserRole(record.id, e.target.value)}
                          >
                            {item.name.toUpperCase()}
                          </Radio>
                        </Col>
                      ))}
                  </Row>
                </Radio.Group>
              )}
            />
          </Table>
        </Spin>
      </Permission>
      <UsuarioForm controller={controller} />
    </div>
  );
}

export default UsuariosView;
