import { Permission } from '@hooks/usePermissions';
import TabelaHistorico from './TabelaHistorico';
import { GetHistorico, GetUserList } from '@actions/historicoActions';
import { useEffect } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function HistoricoView() {
  const dispatch = useAppDispatch();
  const { pagination, sorter, filters } = useAppSelector((state) => state.historico);
  useEffect(() => {
    document.title = 'Nambi - Histórico';
    dispatch(GetHistorico(pagination, sorter, filters));
    dispatch(GetUserList());
    dispatch(
      SetPageHeader({
        title: 'Histórico',
        subTitle: 'Histórico de operações realizadas pelos usuários do sistema',
      })
    );
  }, []);

  return (
    <Permission permission="historico.list">
      <TabelaHistorico />
    </Permission>
  );
}

export default HistoricoView;
