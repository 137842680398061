import { useEffect, useState } from 'react';
import { Permission } from '@hooks/usePermissions';
import { Table, Col, Typography, Button, Popconfirm, Switch, Radio, Form, Input, Row } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps } from '@utils/filterandsort';
import { ExcluirSintetica, MoveSintetica, UpdateSintetica, AddSintetica } from '@actions/planocontasActions';
import Modal from 'antd/lib/modal/Modal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ContaSintetica } from '@models/ContaSintetica';
import { FluxoDinheiro, Tipo } from '@constants/models';

interface TabelaSinteticasProps {
  nivel3id: number;
}

function TabelaSinteticas(props: TabelaSinteticasProps) {
  const dispatch = useAppDispatch();
  const { loadingsinteticas, sinteticas } = useAppSelector((state) => state.planocontas);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    setData(sinteticas.filter((c) => c.nivel3ID == props.nivel3id));
    form.setFieldsValue({ nivel3ID: props.nivel3id });
  }, [sinteticas, props]);
  return (
    <div>
      <Permission permission="sintetica.add">
        <Button size="small" type="primary" onClick={() => setAddModal(true)}>
          Nova Conta
        </Button>
      </Permission>
      <Permission permission="sintetica.list">
        <Table
          rowKey="id"
          size="small"
          loading={loadingsinteticas}
          dataSource={data}
          onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
          pagination={{
            position: ['bottomRight'],
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: [10, 25, 50],
            showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          }}
        >
          <Table.Column
            title="Ação"
            dataIndex="id"
            render={(value, row: ContaSintetica) => (
              <div>
                {row.editavel && (
                  <Permission permission="sintetica.delete">
                    <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(ExcluirSintetica(value))}>
                      <Button size="small" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Permission>
                )}
                {row.cod > Math.min(...data.map((c) => c.cod)) && (
                  <Button
                    size="small"
                    icon={<ArrowUpOutlined />}
                    onClick={() => dispatch(MoveSintetica(value, true))}
                  />
                )}
                {row.cod < Math.max(...data.map((c) => c.cod)) && (
                  <Button
                    size="small"
                    icon={<ArrowDownOutlined />}
                    onClick={() => dispatch(MoveSintetica(value, false))}
                  />
                )}
              </div>
            )}
          />
          <Table.Column
            title="Código"
            dataIndex="codigo"
            {...getColumnSearchProps('codigo', 'string', 'Código', filters)}
          />
          <Table.Column
            title="Descrição"
            dataIndex="descricao"
            {...getColumnSearchProps('descricao', 'string', 'Descrição', filters)}
            render={(value, row: ContaSintetica) =>
              row.editavel ? (
                <Permission permission="sintetica.edit">
                  <Typography.Text
                    editable={{
                      onChange: (v) => dispatch(UpdateSintetica({ ...row, descricao: v })),
                    }}
                  >
                    {value}
                  </Typography.Text>
                </Permission>
              ) : (
                value
              )
            }
          />
          <Table.Column
            title="Estoque"
            dataIndex="estoque"
            align="center"
            render={(value, row: ContaSintetica) => (
              <Permission permission="sintetica.edit">
                <Switch
                  disabled={!row.editavel}
                  checked={value}
                  onChange={(v) =>
                    dispatch(
                      UpdateSintetica({
                        ...row,
                        estoque: v,
                      })
                    )
                  }
                />
              </Permission>
            )}
          />
          <Table.Column
            title="Título"
            dataIndex="titulo"
            align="center"
            render={(value, row: ContaSintetica) => (
              <Permission permission="sintetica.edit">
                <Switch
                  checked={value}
                  disabled={!row.editavel}
                  onChange={(v) =>
                    dispatch(
                      UpdateSintetica({
                        ...row,
                        titulo: v,
                      })
                    )
                  }
                />
              </Permission>
            )}
          />
          <Table.Column
            title="Tipo"
            dataIndex="tipo"
            render={(value, row: ContaSintetica) => (
              <Permission permission="sintetica.edit">
                <Radio.Group
                  disabled={!row.editavel}
                  value={value}
                  onChange={(e) =>
                    dispatch(
                      UpdateSintetica({
                        ...row,
                        tipo: e.target.value,
                      })
                    )
                  }
                >
                  <Radio style={{ display: 'block' }} value={Tipo.Pagar}>
                    Pagar
                  </Radio>
                  <Radio style={{ display: 'block' }} value={Tipo.Receber}>
                    Receber
                  </Radio>
                  <Radio style={{ display: 'block' }} value={Tipo.Nenhum}>
                    Nenhum
                  </Radio>
                </Radio.Group>
              </Permission>
            )}
          />
          <Table.Column
            title="Entra Dinheiro"
            dataIndex="entraDinheiro"
            render={(value, row: ContaSintetica) => (
              <Permission permission="sintetica.edit">
                <Radio.Group
                  disabled={!row.editavel}
                  value={value}
                  onChange={(e) =>
                    dispatch(
                      UpdateSintetica({
                        ...row,
                        entraDinheiro: e.target.value,
                      })
                    )
                  }
                >
                  <Radio style={{ display: 'block' }} value={FluxoDinheiro.Agora}>
                    Agora
                  </Radio>
                  <Radio style={{ display: 'block' }} value={FluxoDinheiro.Depois}>
                    Depois
                  </Radio>
                  <Radio style={{ display: 'block' }} value={FluxoDinheiro.Nenhum}>
                    Nenhum
                  </Radio>
                </Radio.Group>
              </Permission>
            )}
          />
          <Table.Column
            title="Sai Dinheiro"
            dataIndex="saiDinheiro"
            render={(value, row: ContaSintetica) => (
              <Permission permission="sintetica.edit">
                <Radio.Group
                  disabled={!row.editavel}
                  value={value}
                  onChange={(e) =>
                    dispatch(
                      UpdateSintetica({
                        ...row,
                        saiDinheiro: e.target.value,
                      })
                    )
                  }
                >
                  <Radio style={{ display: 'block' }} value={FluxoDinheiro.Agora}>
                    Agora
                  </Radio>
                  <Radio style={{ display: 'block' }} value={FluxoDinheiro.Depois}>
                    Depois
                  </Radio>
                  <Radio style={{ display: 'block' }} value={FluxoDinheiro.Nenhum}>
                    Nenhum
                  </Radio>
                </Radio.Group>
              </Permission>
            )}
          />
        </Table>
        <Modal
          open={addModal}
          title="Nova conta sintética"
          onCancel={() => setAddModal(false)}
          footer={[
            <Button key="1" onClick={() => setAddModal(false)} loading={loadingsinteticas}>
              Fechar
            </Button>,
            <Button key="2" type="primary" onClick={() => form.submit()}>
              Salvar
            </Button>,
          ]}
        >
          <Form
            form={form}
            onFinish={(values) => dispatch(AddSintetica(values))}
            layout="vertical"
            initialValues={{
              estoque: false,
              titulo: false,
              tipo: 2,
              entraDinheiro: 2,
              saiDinheiro: 2,
              nivel3ID: props.nivel3id,
            }}
          >
            <Form.Item name="nivel3ID" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input />
            </Form.Item>
            <Row gutter={8} justify="space-around">
              <Col>
                <Form.Item name="estoque" label="Estoque" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="titulo" label="Título" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="tipo" label="Tipo">
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={Tipo.Pagar}>
                      Pagar
                    </Radio>
                    <Radio style={{ display: 'block' }} value={Tipo.Receber}>
                      Receber
                    </Radio>
                    <Radio style={{ display: 'block' }} value={Tipo.Nenhum}>
                      Nenhum
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="entraDinheiro" label="Entra Dinheiro">
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={FluxoDinheiro.Agora}>
                      Agora
                    </Radio>
                    <Radio style={{ display: 'block' }} value={FluxoDinheiro.Depois}>
                      Depois
                    </Radio>
                    <Radio style={{ display: 'block' }} value={FluxoDinheiro.Nenhum}>
                      Nenhum
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="saiDinheiro" label="Sai Dinheiro">
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={FluxoDinheiro.Agora}>
                      Agora
                    </Radio>
                    <Radio style={{ display: 'block' }} value={FluxoDinheiro.Depois}>
                      Depois
                    </Radio>
                    <Radio style={{ display: 'block' }} value={FluxoDinheiro.Nenhum}>
                      Nenhum
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Permission>
    </div>
  );
}
export default TabelaSinteticas;
