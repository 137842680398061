import { apiEndpoints } from '@config/apiEndpoints';
import axios from '@config/axios';
import { AppDispatch } from '@config/reduxStore';

export const getContextos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTEXTOS',
      payload: axios.get(apiEndpoints.CONTEXTOS.GET_CONTEXTOS),
    });
  };
};
