import { Button, Input, Modal, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { GetUnidades } from '@actions/generalActions';
import { filterobject } from '@utils/filterandsort';
import { CheckOutlined } from '@ant-design/icons';
import { GetContasAnaliticas, SetColumns, SetSelectUnidade, SetValues } from '@actions/importarActions';
import { copy } from '@utils/queryString';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { UnidadeNegocio } from '@models/UnidadeNegocio';

function SelectUnidade() {
  const dispatch = useAppDispatch();
  const { unidades } = useAppSelector((state) => state.general);
  const { selectUnidade, values, contas, columns } = useAppSelector((state) => state.importar);
  const [filtro, setFiltro] = useState('');
  const ref = useRef<any>();
  useEffect(() => {
    if (selectUnidade.visible) {
      ref.current.focus();
      dispatch(GetUnidades(true, true));
    }
  }, [selectUnidade.visible, dispatch]);
  return (
    <Modal
      open={selectUnidade.visible}
      onCancel={() => dispatch(SetSelectUnidade({ visible: false, tipo: null, index: null }))}
      title="Selecionar Unidade de Negócio"
      okButtonProps={{ hidden: true }}
    >
      <Input
        value={filtro}
        ref={(el) => (ref.current = el)}
        onChange={(e) => setFiltro(e.target.value)}
        placeholder="Buscar..."
      />
      <Table dataSource={filterobject(unidades, filtro)} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          render={(v, row: UnidadeNegocio) => (
            <Button
              onClick={() => {
                dispatch(
                  SetValues(
                    copy(values).map((c) => {
                      if (c.key === selectUnidade.index) {
                        if (selectUnidade.tipo === 0) {
                          c.unidadeOqueID = v;
                          c.unidadeOque = row.nome;
                          c.oque = null;
                          c.oqueID = null;
                        } else {
                          c.unidadeOnde = row.nome;
                          c.unidadeOndeID = v;
                          c.onde = null;
                          c.ondeID = null;
                        }
                        dispatch(
                          SetColumns(
                            copy(columns).map((c) => {
                              if (c.dataIndex === selectUnidade.dataIndex) {
                                c.width = Math.max(c.width, row.nome.length * 8);
                              }
                              return c;
                            })
                          )
                        );
                      }
                      return c;
                    })
                  )
                );
                dispatch(SetSelectUnidade({ visible: false, tipo: null, index: null }));
                if (!contas.find((ca) => ca.unidadeID === v)) dispatch(GetContasAnaliticas(v));
              }}
              size="small"
              icon={<CheckOutlined />}
            />
          )}
          width={30}
        />
        <Table.Column title="Descrição" dataIndex="nome" />
      </Table>
    </Modal>
  );
}

export default SelectUnidade;
