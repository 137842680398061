import { useEffect, useState } from 'react';
import { Permission } from '@hooks/usePermissions';
import { Table, Col, Typography, Button, Popconfirm, Switch, Form, Input, Row, Select } from 'antd';
import { connect } from 'react-redux';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { ExcluirAnalitica, MoveAnalitica, UpdateAnalitica, AddAnalitica } from '@actions/planocontasActions';
import Modal from 'antd/lib/modal/Modal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ContaAnalitica } from '@models/ContaAnalitica';

interface TabelaAnaliticas {
  sinteticaid: number;
}

function TabelaAnaliticas(props: TabelaAnaliticas) {
  const dispatch = useAppDispatch();
  const { loadinganaliticas, analiticas } = useAppSelector((state) => state.planocontas);
  const { bancarias } = useAppSelector((state) => state.bancarias);
  const { unidades } = useAppSelector((state) => state.general);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setData(analiticas.filter((c) => c.contaSinteticaID == props.sinteticaid));
    form.setFieldsValue({ contaSinteticaID: props.sinteticaid });
  }, [analiticas, props]);
  return (
    <div>
      <Permission permission="analitica.add">
        <Button size="small" type="primary" onClick={() => setAddModal(true)}>
          Nova Conta
        </Button>
      </Permission>
      <Permission permission="analitica.list">
        <Table
          rowKey="id"
          size="small"
          loading={loadinganaliticas}
          dataSource={data}
          onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
          pagination={{
            position: ['bottomRight'],
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: [10, 25, 50],
            showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          }}
        >
          <Table.Column
            title="Ação"
            dataIndex="id"
            render={(value, row: ContaAnalitica) => (
              <div>
                {row.editavel && (
                  <Permission permission="analitica.delete">
                    <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(ExcluirAnalitica(value))}>
                      <Button size="small" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Permission>
                )}
                {row.cod > Math.min(...data.map((c) => c.cod)) && (
                  <Button
                    size="small"
                    icon={<ArrowUpOutlined />}
                    onClick={() => dispatch(MoveAnalitica(value, true))}
                  />
                )}
                {row.cod < Math.max(...data.map((c) => c.cod)) && (
                  <Button
                    size="small"
                    icon={<ArrowDownOutlined />}
                    onClick={() => dispatch(MoveAnalitica(value, false))}
                  />
                )}
              </div>
            )}
          />
          <Table.Column
            title="Código"
            dataIndex="codigo"
            {...getColumnSearchProps('codigo', 'string', 'Código', filters)}
          />
          <Table.Column
            title="Descrição"
            dataIndex="descricao"
            {...getColumnSearchProps('descricao', 'string', 'Descrição', filters)}
            render={(value, row) =>
              row.editavel ? (
                <Permission permission="analitica.edit">
                  <Typography.Text
                    editable={{
                      onChange: (v) => dispatch(UpdateAnalitica({ ...row, descricao: v })),
                    }}
                  >
                    {value}
                  </Typography.Text>
                </Permission>
              ) : (
                value
              )
            }
          />
          <Table.Column
            title="Pessoas Balanço"
            dataIndex="pessoasBalanco"
            align="center"
            render={(value, row: ContaAnalitica) => (
              <Permission permission="analitica.edit">
                <Switch
                  disabled={!row.editavel}
                  checked={value}
                  onChange={(v) =>
                    dispatch(
                      UpdateAnalitica({
                        ...row,
                        pessoasBalanco: v,
                      })
                    )
                  }
                />
              </Permission>
            )}
          />
          <Table.Column
            title="Baixa Estoque"
            dataIndex="baixaEstoque"
            align="center"
            render={(value, row: ContaAnalitica) => (
              <Permission permission="analitica.edit">
                <Switch
                  checked={value}
                  disabled={!row.editavel}
                  onChange={(v) =>
                    dispatch(
                      UpdateAnalitica({
                        ...row,
                        baixaEstoque: v,
                      })
                    )
                  }
                />
              </Permission>
            )}
          />
          <Table.Column
            title="Unidade de Negócio"
            dataIndex="unidadeNegocioID"
            sorter={(a, b) => sortAlpha(a.unidadeNegocioID, b.unidadeNegocioID)}
            {...getColumnSearchProps('unidadeNegocioID', 'planocontas', 'Unidades', filters, unidades)}
            render={(value, row) => (
              <Permission permission="analitica.edit">
                <Select
                  value={value}
                  showSearch
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  onChange={(v) =>
                    dispatch(
                      UpdateAnalitica({
                        ...row,
                        unidadeNegocioID: v,
                      })
                    )
                  }
                >
                  {unidades.map((c, index) => (
                    <Select.Option value={c.id} key={index}>
                      {c.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Permission>
            )}
          />
          <Table.Column
            title="Conta Bancária"
            dataIndex="contaBancariaID"
            render={(value, row: ContaAnalitica) => (
              <Permission permission="analitica.edit">
                <Select
                  value={value}
                  showSearch
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  onChange={(v) =>
                    dispatch(
                      UpdateAnalitica({
                        ...row,
                        contaBancariaID: v,
                      })
                    )
                  }
                >
                  <Select.Option value={null}>Não é conta bancária</Select.Option>
                  {bancarias.map((c, index) => (
                    <Select.Option
                      value={c.id}
                      key={index}
                    >{`${c.banco} - AG: ${c.agencia} - Conta: ${c.conta}`}</Select.Option>
                  ))}
                </Select>
              </Permission>
            )}
          />
        </Table>
        <Modal
          open={addModal}
          title="Nova conta analítica"
          onCancel={() => setAddModal(false)}
          footer={[
            <Button key="1" onClick={() => setAddModal(false)}>
              Fechar
            </Button>,
            <Button key="2" type="primary" onClick={() => form.submit()} loading={loadinganaliticas}>
              Salvar
            </Button>,
          ]}
        >
          <Form
            form={form}
            onFinish={(values) => dispatch(AddAnalitica(values))}
            layout="vertical"
            initialValues={{
              contaBancariaID: null,
              pessoasBalanco: false,
              baixaEstoque: false,
              contaSinteticaID: props.sinteticaid,
            }}
          >
            <Form.Item name="contaSinteticaID" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="contaBancariaID" label="Conta Bancária">
              <Select showSearch optionFilterProp="children">
                <Select.Option value={null}>Não é conta bancária</Select.Option>
                {bancarias.map((c, index) => (
                  <Select.Option
                    value={c.id}
                    key={index}
                  >{`${c.banco} - AG: ${c.agencia} - Conta: ${c.conta}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="unidadeNegocios"
              label="Unidade de Negócio"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select showSearch optionFilterProp="children" mode="multiple" allowClear>
                {unidades.map((c, index) => (
                  <Select.Option value={c.id} key={index}>
                    {c.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={8} justify="space-around">
              <Col>
                <Form.Item name="pessoasBalanco" label="Pessoas Balanço" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="baixaEstoque" label="Baixa Estoque" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Permission>
    </div>
  );
}
export default connect()(TabelaAnaliticas);
