import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  ActivatePlano,
  CriarAviso,
  GetAvisos,
  GetContasSistema,
  GetPlanos,
  SalvarPlano,
} from '@actions/sistemaActions';
import { extendedAction } from '@config/reduxStore';
import { SistemaReducer } from '@reducers/reducers';

const initialState: SistemaReducer = {
  contas: [],
  loadingContas: false,
  planos: [],
  loadingPlanos: false,
  avisos: [],
  loadingAvisos: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(GetContasSistema.pending, (state) => {
    return { ...state, loadingContas: true };
  });
  builder.addCase(GetContasSistema.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar contas!' });
    return { ...state, loadingContas: false };
  });
  builder.addCase(GetContasSistema.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingContas: false,
      contas: action.payload.data,
    };
  });
  builder.addCase(GetPlanos.pending, (state) => {
    return { ...state, loadingPlanos: true };
  });
  builder.addCase(GetPlanos.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar planos!' });
    return { ...state, loadingPlanos: false };
  });
  builder.addCase(GetPlanos.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingPlanos: false,
      planos: action.payload.data,
    };
  });
  builder.addCase(ActivatePlano.pending, (state) => {
    return { ...state, loadingPlanos: true };
  });
  builder.addCase(ActivatePlano.rejected, (state) => {
    notification.error({ message: 'Erro ao alterar plano!' });
    return { ...state, loadingPlanos: false };
  });
  builder.addCase(ActivatePlano.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingPlanos: false,
      planos: state.planos.map((c) => (c.id === action.payload.data.id ? { ...c, ...action.payload.data } : c)),
    };
  });
  builder.addCase(GetAvisos.pending, (state) => {
    return { ...state, loadingAvisos: true };
  });
  builder.addCase(GetAvisos.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar avisos!' });
    return { ...state, loadingAvisos: false };
  });
  builder.addCase(GetAvisos.fulfilled, (state, action: extendedAction) => {
    return {
      ...state,
      loadingAvisos: false,
      avisos: action.payload.data,
    };
  });
  builder.addCase(CriarAviso.pending, (state) => {
    return { ...state, loadingAvisos: true };
  });
  builder.addCase(CriarAviso.rejected, (state) => {
    notification.error({ message: 'Erro ao criar aviso!' });
    return { ...state, loadingAvisos: false };
  });
  builder.addCase(CriarAviso.fulfilled, (state, action: extendedAction) => {
    notification.success({ message: 'Aviso criado com sucesso!' });
    return {
      ...state,
      loadingAvisos: false,
      avisos: [...state.avisos, ...action.payload.data],
    };
  });
  builder.addCase(SalvarPlano.pending, (state) => {
    return { ...state, loadingPlanos: true };
  });
  builder.addCase(SalvarPlano.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar plano!' });
    return { ...state, loadingPlanos: false };
  });
  builder.addCase(SalvarPlano.fulfilled, (state, action: extendedAction) => {
    notification.success({ message: 'Plano salvo com sucesso!' });
    return {
      ...state,
      loadingPlanos: false,
      planos: action.payload.data.novo
        ? [...state.avisos, action.payload.data.plano]
        : state.planos.map((c) => (c.id === action.payload.data.plano.id ? action.payload.data.plano : c)),
    };
  });
});
