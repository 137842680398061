import moment from 'moment';
import { Table, Button, Popconfirm, Tooltip, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { moneyMaskNeg, orderDate, sortAlpha } from '@utils/masks';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Lancamento } from '@models/Lancamento';
import { getColumnSearchProps } from '@utils/filterandsort';
import { BalancoControllerInterface } from '@controllers/controllers';

function TabelaLancamentos({ controller }: { controller: BalancoControllerInterface }) {
  const { data, actions } = controller();
  const confirm = (id: number) => {
    actions.ExcluirLancamento([id]);
  };
  const columns2: ColumnsType<Lancamento> = [
    {
      title: '',
      align: 'center',
      width: 70,
      render: (_, record: Lancamento) => (
        <Space>
          <Tooltip title="Editar">
            <Link to={`/Lancar?bloco=${record.userCod + record.bid}`}>
              <Button size="small" icon={<EditOutlined />} />
            </Link>
          </Tooltip>
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => confirm(record.id as number)}
            okText="Sim"
            cancelText="Não"
          >
            <Tooltip title="Excluir">
              <Button size="small" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Bloco',
      dataIndex: 'bid',
      sorter: (a, b) => sortAlpha(a.userCod + a.bid, b.userCod + b.bid),
      render: (_, record) => record.userCod + record.bid,
    },
    {
      title: 'Transação',
      dataIndex: 'dataTransacao',
      //width: '7%',
      render: (text) => moment(text).format('DD/MM/YYYY'),
      sorter: (a, b) => orderDate(a.dataTransacao, b.dataTransacao),
      ...getColumnSearchProps('dataTransacao', 'daterange', 'Data Transação', null),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricaoBloco',
      width: '30%',
      sorter: (a, b) => sortAlpha(a.descricaoBloco, b.descricaoBloco),
      ...getColumnSearchProps('descricaoBloco', 'string', 'Descrição', null),
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      //width: '7%',
      sorter: (a, b) => sortAlpha(a.documento, b.documento),
      ...getColumnSearchProps('documento', 'string', 'Documento', null),
    },
    {
      title: 'Pessoa',
      dataIndex: 'pessoa',
      //width: '10%',
      sorter: (a, b) => sortAlpha(a.pessoa, b.pessoa),
      ...getColumnSearchProps('pessoa', 'string', 'Pessoa', null),
    },
    {
      title: 'Produto',
      dataIndex: 'produto',
      //width: '10%',
      sorter: (a, b) => sortAlpha(a.produto, b.produto),
      ...getColumnSearchProps('produto', 'string', 'Produto', null),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      align: 'right',
      render: (text) => <span style={{ color: text < 0 ? 'red' : 'blue' }}>{moneyMaskNeg.format(text)}</span>,
      sorter: (a, b) => a.valor - b.valor,
    },
  ];
  return (
    <div>
      <Table
        style={{ minHeight: 300 }}
        bordered
        loading={data.balanco.waiting}
        size="small"
        scroll={{ y: '60vh' }}
        rowKey="id"
        columns={columns2}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50],
          showTotal: (total) => `Total ${total}`,
        }}
        dataSource={data.balanco.lancamentos}
      />
    </div>
  );
}

export default TabelaLancamentos;
