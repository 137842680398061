import { useEffect, useState } from 'react';
import { GetUnidades } from '@actions/implantacaoActions';
import ContasForm from './ContasForm';
import { GetPessoasGrouped } from '@actions/pessoasActions';
import { SetPageHeader } from '@actions/userActions';
import MenuImplantacao from './MenuImplantacao';
import { Button, Form } from 'antd';
import { Permission } from '@hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import moment from 'moment';

export interface FormImplantacaoProps {
  contas: FormImplantacaoContas;
  unidade: number;
  unidadeimplantacao: number;
  data: moment.Moment;
}
interface FormImplantacaoContas {
  descricao: string;
  pessoa: number;
  valor: string;
  id: number;
  [key: string]: any;
}

function ImplantacaoView() {
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const implantacao = useAppSelector((state) => state.implantacao);
  const [form] = Form.useForm<FormImplantacaoProps>();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Implantação de Saldos',
        subTitle:
          'Implante os saldos diretamente nas contas. A contra partida será sempre a conta de implantação de saldos.',
        extras: [
          <Button key={0} size="small" onClick={() => setCollapsed(true)} type="primary">
            Selecionar Contas
          </Button>,
          <Permission key={1} permission="implantacaosaldo.create">
            <Button onClick={() => form.submit()} loading={implantacao.loadingcontas} size="small" type="primary" block>
              Implantar
            </Button>
          </Permission>,
        ],
      })
    );
  }, []);
  useEffect(() => {
    document.title = 'Nambi - Implantação de Saldos';
    dispatch(GetUnidades());
    dispatch(GetPessoasGrouped());
  }, []);

  return (
    <div>
      <MenuImplantacao visible={collapsed} setVisible={setCollapsed} />
      <ContasForm form={form} />
    </div>
  );
}

export default ImplantacaoView;
