import { Permission } from '@hooks/usePermissions';
import ImportarImportButton from './Importar.ImportButton';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Button, Spin } from 'antd';
import ImportarTable from './Importar.Table';
import { useEffect } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { ClearImportData } from '@actions/pessoasActions';

function ImportarPessoasView() {
  const { data, importando } = useAppSelector((state) => state.pessoas);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Pessoas',
        subTitle: 'Importar pessoas',
        backButton: true,
        extras: [
          <Button onClick={() => dispatch(ClearImportData())} type="primary" key="0" ghost size="small">
            Limpar
          </Button>,
        ],
      })
    );
  }, []);
  return (
    <div>
      <Permission permission="pessoa.add">
        {!data?.data && (
          <Spin spinning={importando}>
            <ImportarImportButton />
          </Spin>
        )}
        {data?.data && <ImportarTable />}
      </Permission>
    </div>
  );
}
export default ImportarPessoasView;
