import { useEffect } from 'react';
import { Permission } from '@hooks/usePermissions';
import { Table } from 'antd';
import '../../../assets/scss/FluxoCaixa.scss';
import { GetUnidades } from '@actions/generalActions';
import Unidades from './Unidades';
import { SetPageHeader } from '@actions/userActions';
import { formatNumber } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { LancamentoFluxo } from 'reducers/reducers';

function FluxocaixaView() {
  const dispatch = useAppDispatch();
  const fluxo = useAppSelector((state) => state.fluxocaixa);
  useEffect(() => {
    document.title = 'Nambi - Fluxo de Caixa';
    dispatch(GetUnidades(true, true));
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Fluxo de Caixa',
        subTitle: 'Escolha a(s) unidade(s) de negócio e o ano para gerar o fluxo de caixa',
        extras: [<Unidades key="3" />],
      })
    );
  }, [fluxo.fluxosloading]);
  return (
    <Permission permission="fluxocaixa.list">
      <Table
        tableLayout="fixed"
        loading={fluxo.fluxosloading}
        scroll={{ y: 'calc(100vh - 160px)', x: 1000 }}
        size="small"
        rowKey="id"
        className="fluxoprint"
        dataSource={fluxo.fluxoteste}
        rowClassName={(record) => record.tipo}
        pagination={false}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Table.Column title="Código" width={100} dataIndex="codigo" />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          width={450}
          render={(text, row: LancamentoFluxo) =>
            row.tipo === 'subtotal'
              ? '' + text
              : row.tipo === 'conta'
              ? '' + text
              : row.tipo === 'analitica'
              ? '' + text
              : text
          }
        />
        <Table.Column align="right" title="Jan" dataIndex="mes1" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Fev" dataIndex="mes2" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Mar" dataIndex="mes3" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Abr" dataIndex="mes4" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Mai" dataIndex="mes5" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Jun" dataIndex="mes6" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Jul" dataIndex="mes7" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Ago" dataIndex="mes8" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Set" dataIndex="mes9" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Out" dataIndex="mes10" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Nov" dataIndex="mes11" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Dez" dataIndex="mes12" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Total" dataIndex="total" render={(v) => formatNumber(v)} width={100} />
        <Table.Column align="right" title="Média" dataIndex="media" render={(v) => formatNumber(v)} width={100} />
      </Table>
    </Permission>
  );
}

export default FluxocaixaView;
