import { Button, Space, Typography, Tooltip, Popconfirm, notification } from 'antd';
import { DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { doubleMask, formatNumber, moneyMask } from '@utils/masks';
import { CheckCircleFilled, SelectOutlined } from '@ant-design/icons';
import { SetValues, Lancar, SetSelectConta, SetSelectUnidade, SetSelectPessoa } from '@actions/importarActions';
import { AppDispatch } from '@config/reduxStore';
import { SelectConta } from '@reducers/reducers';
interface Dictionary {
  [key: string]: any;
}
export const disableCampo = (value: null | string = null, campos: Dictionary = {}) => {
  const fields = Object.values(campos);
  if (fields.includes(value)) return true;
  else return false;
};

export const formatCell = (v: string, tipo: string, dateFormat: string) => {
  if (tipo) {
    if (tipo === 'dataTransacao' || tipo === 'dataVencimento') {
      if (moment(v, dateFormat).isValid()) {
        return moment(v, dateFormat).format('DD/MM/YYYY');
      } else return 'Data inválida';
    }
    if (tipo === 'valor') {
      return formatNumber(v.replace(/,/g, '.'));
    }
  } else return v;
  return v;
};

export const renderCell = (
  v: string,
  row: Dictionary,
  index: number,
  dataIndex: string,
  dateFormat: string,
  values: any[],
  campos: Dictionary,
  settings: any,
  dispatch: AppDispatch,
  tipo: number,
  selectConta: SelectConta
) => {
  const fullfilled = isRowFulfilled(row, campos, settings);
  if (dataIndex === 'Done') {
    return (
      <div>
        {(fullfilled || row.done) && <CheckCircleFilled style={{ color: 'green', fontSize: 16 }} />}
        {!fullfilled && !row.done && <CheckCircleFilled style={{ color: 'red', fontSize: 16 }} />}
      </div>
    );
  }
  if (dataIndex === 'Action') {
    if (row.bloco) {
      return `${index + 1}: ${row.bloco}`;
    }
    return (
      <Space>
        <span>{index + 1}:</span>
        <Popconfirm
          title="Está certo disso?"
          onConfirm={() => dispatch(SetValues(values.filter((c) => c.key !== row.key)))}
        >
          <Button icon={<DeleteOutlined />} size="small" disabled={row.done} />
        </Popconfirm>
        <Tooltip title="Lançar">
          <Button
            icon={<CheckCircleOutlined />}
            size="small"
            disabled={!fullfilled}
            onClick={() => {
              const data = PrepareLancar(row, campos, settings);
              if (data) dispatch(Lancar(data));
            }}
          />
        </Tooltip>
      </Space>
    );
  }
  if (dataIndex === 'Menu') {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button
          size="small"
          icon={<SelectOutlined />}
          disabled={row.done}
          onClick={() => {
            dispatch(
              SetSelectUnidade({
                tipo: tipo === 0 ? 1 : 0,
                visible: true,
                index: row.key,
                dataIndex: dataIndex,
              })
            );
          }}
        >
          {tipo === 0
            ? row.unidadeOndeID
              ? row.unidadeOnde
              : 'Unidade'
            : row.unidadeOqueID
            ? row.unidadeOque
            : 'Unidade'}
        </Button>
        <Button
          size="small"
          type={tipo === 1 ? (row.oque ? 'ghost' : 'default') : row.onde ? 'ghost' : 'default'}
          disabled={
            (tipo === 0 &&
              row[Object.values(campos).find((c) => c === 'valor')] &&
              !row[Object.entries(campos).find((c) => c[1] === 'valor')![0]]) ||
            (!row[Object.values(campos).find((c) => c === 'valor')] && tipo === 1) ||
            row.done ||
            (tipo === 1 && !row.unidadeOqueID) ||
            (tipo === 0 && !row.unidadeOndeID)
          }
          icon={<SelectOutlined />}
          onClick={() => {
            const valorField =
              Object.values(campos).find((c) => c === 'valor') &&
              Object.entries(campos).find((c) => c[1] === 'valor')![0];
            dispatch(
              SetSelectConta({
                ...selectConta,
                visible: true,
                unidadeID: tipo === 0 ? row.unidadeOndeID : row.unidadeOqueID,
                key: row.key,
                tipo: tipo === 1 ? (row[valorField] < 0 ? 0 : 1) : null,
                fluxo: settings.quando,
                movimentar: false,
                position: tipo === 0 ? 1 : 0,
                dataIndex: dataIndex,
              })
            );
          }}
        >
          {tipo === 1 ? (row.oque ? row.oque : 'Selecionar Conta') : row.onde ? row.onde : 'Selecionar Conta'}
        </Button>
      </Space>
    );
  }
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {Object.entries(campos).find((c) => c[1] === 'unidade') &&
        Object.entries(campos).find((c) => c[1] === 'unidade')![0] === dataIndex && (
          <Button
            size="small"
            icon={<SelectOutlined />}
            disabled={row.done}
            type={tipo === 0 ? (row.unidadeOque ? 'ghost' : 'default') : row.unidadeOnde ? 'ghost' : 'default'}
            onClick={() => {
              dispatch(
                SetSelectUnidade({
                  tipo: tipo === 0 ? 0 : 1,
                  visible: true,
                  index: row.key,
                  dataIndex: dataIndex,
                })
              );
            }}
          >
            {tipo === 0
              ? row.unidadeOque
                ? row.unidadeOque
                : 'Selecionar Unidade'
              : row.unidadeOnde
              ? row.unidadeOnde
              : 'Selecionar Unidade'}
          </Button>
        )}
      {Object.entries(campos).find((c) => c[1] === 'oque') &&
        Object.entries(campos).find((c) => c[1] === 'oque')![0] === dataIndex && (
          <Button
            size="small"
            type={tipo === 0 ? (row.oque ? 'ghost' : 'default') : row.onde ? 'ghost' : 'default'}
            disabled={
              (Object.values(campos).find((c) => c === 'valor') &&
                !Number(row[Object.entries(campos).find((c) => c[1] === 'valor')![0]])) ||
              (tipo === 0 && !Object.values(campos).find((c) => c === 'valor')) ||
              row.done ||
              (tipo === 0 && !row.unidadeOqueID) ||
              (tipo === 1 && !row.unidadeOndeID)
            }
            icon={<SelectOutlined />}
            onClick={() => {
              let valor = row[Object.entries(campos).find((c) => c[1] === 'valor')![0]];
              dispatch(
                SetSelectConta({
                  ...selectConta,
                  visible: true,
                  unidadeID: row.unidadeOqueID,
                  key: row.key,
                  tipo: valor < 0 ? 0 : 1,
                  fluxo: null,
                  movimentar: false,
                  position: 0,
                  dataIndex: dataIndex,
                })
              );
            }}
          >
            {tipo === 0 ? (row.oque ? row.oque : 'Selecionar Conta') : row.onde ? row.onde : 'Selecionar Conta'}
          </Button>
        )}
      {Object.entries(campos).find((c) => c[1] === 'onde') &&
        Object.entries(campos).find((c) => c[1] === 'onde')![0] === dataIndex && (
          <Button
            size="small"
            type={tipo === 0 ? (row.oque ? 'ghost' : 'default') : row.onde ? 'ghost' : 'default'}
            disabled={
              !row[Object.entries(campos).find((c) => c[1] === 'valor')![0]] ||
              row.done ||
              (tipo === 0 && !row.unidadeOqueID) ||
              (tipo === 1 && !row.unidadeOndeID)
            }
            icon={<SelectOutlined />}
            onClick={() => {
              dispatch(
                SetSelectConta({
                  ...selectConta,
                  visible: true,
                  unidadeID: row.unidadeOndeID,
                  key: row.key,
                  tipo: tipo === 1 ? null : 0,
                  fluxo: settings.quando,
                  movimentar: false,
                  position: 1,
                  dataIndex: dataIndex,
                })
              );
            }}
          >
            {tipo === 0 ? (row.oque ? row.oque : 'Selecionar Conta') : row.onde ? row.onde : 'Selecionar Conta'}
          </Button>
        )}
      {Object.values(campos).find((c) => c === 'pessoa') &&
        Object.entries(campos).find((c) => c[1] === 'pessoa')![0] === dataIndex && (
          <Button
            size="small"
            icon={<SelectOutlined />}
            onClick={() => {
              dispatch(
                SetSelectPessoa({
                  visible: true,
                  index: row.key,
                  dataIndex: dataIndex,
                })
              );
            }}
          >
            {row.pessoa ? row.pessoa : 'Selecionar Pessoa'}
          </Button>
        )}
      <Typography.Text
        editable={
          row.done
            ? false
            : {
                text: v,
                onChange: (value) => {
                  dispatch(
                    SetValues(
                      values.map((e) =>
                        e.key === row.key ? { ...row, [dataIndex]: value, oque: dataIndex === '' } : e
                      )
                    )
                  );
                },
              }
        }
      >
        {formatCell(v, campos[dataIndex], dateFormat)}
      </Typography.Text>
    </Space>
  );
};

export const isRowFulfilled = (row: any, campos: any, settings: any) => {
  if (settings.quando === 1 && !Object.values(campos).find((c) => c === 'dataVencimento')) {
    return false;
  }
  if (
    Object.values(campos).find((c) => c === 'dataVencimento') &&
    !moment(row[Object.entries(campos).find((c) => c[1] === 'dataVencimento')![0]], settings.dateFormat).isValid()
  ) {
    return false;
  }
  if (
    row.unidadeOndeID &&
    row.unidadeOqueID &&
    row.ondeID &&
    row.oqueID &&
    Object.values(campos).find((c) => c === 'valor') &&
    Object.values(campos).find((c) => c === 'dataTransacao') &&
    Object.values(campos).find((c) => c === 'descricao')
  )
    return true;
  else return false;
};

export const PrepareLancar = (values: any, campos: any, settings: any) => {
  try {
    const valorField: any =
      Object.values(campos).find((c) => c === 'valor') && Object.entries(campos).find((c) => c[1] === 'valor')![0];
    const jurosField: any =
      Object.values(campos).find((c) => c === 'juros') && Object.entries(campos).find((c) => c[1] === 'juros')![0];
    const multaField: any =
      Object.values(campos).find((c) => c === 'multa') && Object.entries(campos).find((c) => c[1] === 'multa')![0];
    const descontoField: any =
      Object.values(campos).find((c) => c === 'desconto') &&
      Object.entries(campos).find((c) => c[1] === 'desconto')![0];
    const descricaoField: any =
      Object.values(campos).find((c) => c === 'descricao') &&
      Object.entries(campos).find((c) => c[1] === 'descricao')![0];
    const dataTransacaoField: any =
      Object.values(campos).find((c) => c === 'dataTransacao') &&
      Object.entries(campos).find((c) => c[1] === 'dataTransacao')![0];
    const documentoField: any =
      Object.values(campos).find((c) => c === 'documento') &&
      Object.entries(campos).find((c) => c[1] === 'documento')![0];
    if (!values[valorField]) {
      notification.error({ message: 'Valor inválido' });
      return null;
    }
    if (!values.unidadeOqueID) {
      notification.error({ message: 'Unidade "O que" inválida' });
      return null;
    }
    if (!values.unidadeOndeID) {
      notification.error({ message: 'Unidade "Onde" inválida' });
      return null;
    }
    if (!values.oqueID) {
      notification.error({ message: 'Conta "O Que" inválida' });
      return null;
    }
    if (!values.ondeID) {
      notification.error({ message: 'Conta "Onde" inválida' });
      return null;
    }
    if (!moment(values[dataTransacaoField], settings.dateFormat).isValid()) {
      notification.error({ message: 'Data de transação inválida' });
      return null;
    }
    const lancamento = {
      total0: values[valorField],
      dataTransacao: moment(values[dataTransacaoField], settings.dateFormat).format('YYYY-MM-DD'),
      descricao: values[descricaoField],
      padrao: 0,
      inverter: false,
      key: values.key,
      lancamentotipo0: [
        {
          competencia: moment(values[dataTransacaoField], settings.dateFormat).format('YYYY-MM-DD'),
          documento: values[documentoField],
          desconto: values[descontoField] || 0,
          juros: values[jurosField] || 0,
          multa: values[multaField] || 0,
          valor: values[valorField] < 0 ? -1 * values[valorField] : values[valorField],
          tipo: values[valorField] < 0 ? 0 : 1,
          unidadeID: values.unidadeOqueID,
          contaID: values.oqueID.slice(2, values.oqueID.length),
          pessoaID: values.pessoaID,
        },
      ],
      lancamentotipo1: [
        {
          documento: values[documentoField],
          valor: values[valorField] < 0 ? -1 * values[valorField] : values[valorField],
          unidadeID: values.unidadeOndeID,
          ondeID: values.ondeID.slice(2, values.ondeID.length),
          quando: settings.quando,
          parcela:
            settings.quando === 0
              ? []
              : [
                  {
                    valor: values[valorField] < 0 ? -1 * values[valorField] : values[valorField],
                    vencimento: moment(
                      values[Object.entries(campos).find((c) => c[1] === 'dataVencimento')![0]],
                      settings.dateFormat
                    ).format('YYYY-MM-DD'),
                    documento: values[documentoField],
                  },
                ],
        },
      ],
    };
    return lancamento;
  } catch (ex) {
    console.log(ex);
  }
  return null;
};

export const PrepareLancarTela = (rows: Dictionary[] = [], campos: Dictionary = {}, settings: Dictionary = {}) => {
  try {
    let lancamento: Dictionary = {};
    const valorField: any =
      Object.values(campos).find((c) => c === 'valor') && Object.entries(campos).find((c) => c[1] === 'valor')![0];
    const jurosField: any =
      Object.values(campos).find((c) => c === 'juros') && Object.entries(campos).find((c) => c[1] === 'juros')![0];
    const multaField: any =
      Object.values(campos).find((c) => c === 'multa') && Object.entries(campos).find((c) => c[1] === 'multa')![0];
    const descontoField: any =
      Object.values(campos).find((c) => c === 'desconto') &&
      Object.entries(campos).find((c) => c[1] === 'desconto')![0];
    const descricaoField: any =
      Object.values(campos).find((c) => c === 'descricao') &&
      Object.entries(campos).find((c) => c[1] === 'descricao')![0];
    const dataTransacaoField: any =
      Object.values(campos).find((c) => c === 'dataTransacao') &&
      Object.entries(campos).find((c) => c[1] === 'dataTransacao')![0];
    const documentoField: any =
      Object.values(campos).find((c) => c === 'documento') &&
      Object.entries(campos).find((c) => c[1] === 'documento')![0];
    /* 
    const unidadeOqueField: any =
      Object.values(campos).find((c) => c === 'unidade') && Object.entries(campos).find((c) => c[1] === 'unidade')![0];
     */
    const oqueField: any =
      Object.values(campos).find((c) => c === 'oque') && Object.entries(campos).find((c) => c[1] === 'oque')![0];
    /* 
    const ondeField: any =
      Object.values(campos).find((c) => c === 'onde') && Object.entries(campos).find((c) => c[1] === 'onde')![0];
     */
    const pessoaField: any =
      Object.values(campos).find((c) => c === 'pessoa') && Object.entries(campos).find((c) => c[1] === 'pessoa')![0];
    if (dataTransacaoField)
      lancamento['dataTransacao'] = moment(
        rows.find((c) => moment(c[dataTransacaoField], settings.dateFormat).isValid)![dataTransacaoField] || moment(),
        settings.dateFormat
      );
    if (descricaoField) lancamento['descricao'] = rows.find((c) => !!c[descricaoField])![descricaoField] || '';
    lancamento['padrao'] = 0;
    lancamento['lancamentotipo0'] = rows.map((c: any) => {
      let temptipo0: Dictionary = {};
      temptipo0['documento'] = c[documentoField];
      temptipo0['quantidade'] = doubleMask.format(0);
      if (valorField) {
        temptipo0['valor'] = moneyMask.format(c[valorField] < 0 ? c[valorField] * -1 : c[valorField]);
        temptipo0['tipo'] = c[valorField] < 0 ? 0 : 1;
      } else {
        temptipo0['tipo'] = 0;
      }
      if (jurosField) {
        temptipo0['juros'] = moneyMask.format(c[jurosField] < 0 ? c[jurosField] * -1 : c[jurosField]);
      } else {
        temptipo0['juros'] = moneyMask.format(0);
      }
      if (multaField) {
        temptipo0['multa'] = moneyMask.format(c[multaField] < 0 ? c[multaField] * -1 : c[multaField]);
      } else {
        temptipo0['multa'] = moneyMask.format(0);
      }
      if (descontoField) {
        temptipo0['desconto'] = moneyMask.format(c[descontoField] < 0 ? c[descontoField] * -1 : c[descontoField]);
      } else {
        temptipo0['desconto'] = moneyMask.format(0);
      }
      if (c.unidadeOqueID) {
        temptipo0['unidade'] = c.unidadeOque;
        temptipo0['unidadeID'] = c.unidadeOqueID;
      }
      if (oqueField) {
        if (c.oqueID) {
          temptipo0['conta'] = c.oque;
          temptipo0['contaID'] = c.oqueID;
        }
      }
      if (pessoaField) {
        if (c.pessoa) {
          temptipo0['pessoa'] = c.pessoa || null;
          temptipo0['pessoaID'] = c.pessoaID || null;
        }
      }
      return temptipo0;
    });
    return lancamento;
  } catch (ex) {
    console.log(ex);
  }
  return null;
};
