import {
  DeleteConciliacao,
  GetConciliacao,
  GetConciliacoes,
  ImportOFX,
  Lancar,
  PickConta,
  PickPessoa,
  PickUnidade,
  Reset,
  SetDescricao,
  SetDocumento,
  SetOFXData,
  SetSettings,
  SetTitulos,
  SetTransferencia,
} from '@actions/conciliarActions';
import { IConciliacao, ImportData } from '@models/Conciliar';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

interface ConciliarReducerProps {
  data: ImportData[];
  importando: boolean;
  lancando: boolean;
  loadingConciliacoes: boolean;
  conciliacoes: IConciliacao[];
  loadingConciliacao: boolean;
  settings: { bancariaID: number; contaID: number; unidadeID: number };
}
const initialState: ConciliarReducerProps = {
  data: [],
  importando: false,
  lancando: false,
  loadingConciliacoes: false,
  conciliacoes: [],
  loadingConciliacao: false,
  settings: {
    bancariaID: null,
    contaID: null,
    unidadeID: null,
  },
};
const conciliarReducer = createReducer(initialState, (builder) => {
  builder.addCase(Lancar.pending, (state) => {
    return { ...state, lancando: true };
  });
  builder.addCase(Lancar.rejected, (state, action) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, lancando: false };
  });
  builder.addCase(Lancar.fulfilled, (state, action) => {
    notification.success({ message: 'Lançamento realizado com sucesso!' });
    return {
      ...state,
      lancando: false,
      data: state.data.map((c) => (c.id === action.meta.arg.rowID ? { ...c, bloco: action.payload.data } : c)),
    };
  });
  builder.addCase(ImportOFX.pending, (state) => {
    return { ...state, importando: true };
  });
  builder.addCase(ImportOFX.rejected, (state) => {
    notification.error({ message: 'Erro na formatação do arquivo' });
    return { ...state, importando: false };
  });
  builder.addCase(ImportOFX.fulfilled, (state) => {
    notification.success({ message: 'Arquivo importado com sucesso!' });
    return { ...state, importando: false };
  });
  builder.addCase(SetOFXData, (state, action) => {
    return {
      ...state,
      data: action.payload,
    };
  });
  builder.addCase(PickUnidade, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload.id) return { ...c, unidadeID: action.payload.v, contaID: null };
        else return c;
      }),
    };
  });
  builder.addCase(PickConta, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload.id) return { ...c, contaID: action.payload.v };
        else return c;
      }),
    };
  });
  builder.addCase(PickPessoa, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload.id) return { ...c, pessoaID: action.payload.v };
        else return c;
      }),
    };
  });
  builder.addCase(SetDocumento, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload.id) return { ...c, documento: action.payload.v };
        else return c;
      }),
    };
  });
  builder.addCase(SetDescricao, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload.id) return { ...c, descricao: action.payload.v };
        else return c;
      }),
    };
  });
  builder.addCase(SetTitulos, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload.id) return { ...c, titulos: action.payload.titulos };
        else return c;
      }),
    };
  });
  builder.addCase(SetTransferencia, (state, action) => {
    return {
      ...state,
      data: state.data.map((c) => {
        if (c.id === action.payload) return { ...c, transferencia: !c.transferencia, titulos: [] };
        else return c;
      }),
    };
  });
  builder.addCase(Reset, (state) => {
    return {
      ...state,
      data: [],
    };
  });
  builder.addCase(GetConciliacoes.pending, (state) => {
    return {
      ...state,
      loadingConciliacoes: true,
    };
  });
  builder.addCase(GetConciliacoes.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar conciliações!' });
    return {
      ...state,
      loadingConciliacoes: false,
    };
  });
  builder.addCase(GetConciliacoes.fulfilled, (state, action) => {
    return {
      ...state,
      loadingConciliacoes: false,
      conciliacoes: action.payload.data,
    };
  });
  builder.addCase(GetConciliacao.pending, (state) => {
    return {
      ...state,
      loadingConciliacao: true,
    };
  });
  builder.addCase(GetConciliacao.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar conciliação' });
    return {
      ...state,
      loadingConciliacao: false,
    };
  });
  builder.addCase(GetConciliacao.fulfilled, (state, action) => {
    return {
      ...state,
      loadingConciliacao: false,
      data: JSON.parse(action.payload.data.values),
      settings: {
        contaID: action.payload.data.contaID,
        unidadeID: action.payload.data.unidadeID,
        bancariaID: action.payload.data.bancariaID,
      },
    };
  });
  builder.addCase(SetSettings, (state, action) => {
    return {
      ...state,
      settings: action.payload,
    };
  });
  builder.addCase(DeleteConciliacao.pending, (state) => {
    return {
      ...state,
      loadingConciliacao: true,
    };
  });
  builder.addCase(DeleteConciliacao.rejected, (state) => {
    notification.error({ message: 'Erro ao excluir notificação' });
    return {
      ...state,
      loadingConciliacao: false,
    };
  });
  builder.addCase(DeleteConciliacao.fulfilled, (state, action) => {
    return {
      ...state,
      loadingConciliacao: false,
      conciliacoes: state.conciliacoes.filter((c) => c.id !== action.payload.data),
    };
  });
});
export default conciliarReducer;
