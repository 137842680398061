import { Modal, TreeSelect } from 'antd';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { GetContasAnaliticas } from '@actions/lancarActions';
import { ContaAnaliticaTree } from '@models/ContaAnalitica';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface SelectContaProps {
  data: {
    visible: boolean;
    unidadeID: null | number;
    movimentar: boolean;
    tipo: null | number;
    fluxo: null | number;
    index: null | number;
    lancamento: null | number;
  };
  setData: Dispatch<
    SetStateAction<{
      visible: boolean;
      index: null | number;
      tipo: null | number;
      lancamento: null | number;
      fluxo: null | number;
      movimentar: boolean;
      unidadeID: null | number;
      conta: ContaAnaliticaTree | null;
    }>
  >;
  setConta: (index: number, id: number, nome: string, tipo: number, conta: ContaAnaliticaTree) => void;
}
function SelectConta({ data, setData, setConta }: SelectContaProps) {
  const dispatch = useAppDispatch();
  const { contasanaliticas, loadingcontas } = useAppSelector((state) => state.lancar);
  const [selectedConta, setSelectedConta] = useState<{
    id: null | number;
    name: null | string | undefined;
    conta: null | ContaAnaliticaTree;
  }>({ id: null, name: '', conta: null });
  const [contas, setContas] = useState<ContaAnaliticaTree[]>([]);
  const ref = useRef<any>();
  useEffect(() => {
    setSelectedConta({ id: null, name: '', conta: null });
  }, [data.visible]);
  useEffect(() => {
    if (data.visible) {
      ref.current.focus();
      dispatch(GetContasAnaliticas(data.unidadeID));
    }
  }, [data.visible]);
  useEffect(() => {
    const tempData = contasanaliticas.map((n1) => {
      n1.children.map((n2) => {
        n2.children.map((n3) => {
          if (!data.movimentar) {
            if (data.tipo === 0 || data.tipo === 1) {
              let tempCs = n3.children.filter((c) => c.tipo === data.tipo);
              n3.children = tempCs;
            } else if (data.tipo === 2 || data.tipo === 3) {
            } else {
              let tempCs = n3.children.filter((c) => c.saiDinheiro === data.fluxo || c.entraDinheiro === data.fluxo);
              n3.children = tempCs;
            }
          } else {
            if (data.fluxo === 0) {
              let tempCs = n3.children.filter((c) => c.saiDinheiro !== 1 && c.entraDinheiro !== 1);
              n3.children = tempCs;
            } else {
            }
          }
          return n3;
        });
        return n2;
      });
      return n1;
    });
    setContas(tempData);
  }, [contasanaliticas, data]);

  return (
    <Modal
      open={data.visible}
      onCancel={() =>
        setData({
          visible: false,
          index: null,
          tipo: null,
          lancamento: null,
          fluxo: null,
          unidadeID: data.unidadeID,
          movimentar: false,
          conta: null,
        })
      }
      title="Selecionar Conta"
      onOk={() => {
        setConta(
          data.index as number,
          selectedConta.id as number,
          selectedConta.name as string,
          data.lancamento as number,
          selectedConta.conta as ContaAnaliticaTree
        );
        setSelectedConta({ id: null, name: '', conta: null });
        setData({
          visible: false,
          index: null,
          tipo: null,
          lancamento: null,
          fluxo: null,
          unidadeID: data.unidadeID,
          conta: selectedConta.conta,
          movimentar: false,
        });
      }}
    >
      <TreeSelect
        treeData={contas}
        showSearch
        ref={ref}
        loading={loadingcontas}
        placeholder="Buscar..."
        treeNodeFilterProp="title"
        style={{ width: '100%' }}
        value={selectedConta.id}
        onChange={(v, labels, extra) => {
          setSelectedConta({ name: labels[0]?.toString(), id: v, conta: extra.triggerNode.props });
        }}
      />
    </Modal>
  );
}

export default SelectConta;
