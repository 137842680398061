import { useEffect } from 'react';
import { Select, Button, DatePicker, Space } from 'antd';
import moment from 'moment';
import {
  GetContasPagar,
  GetContasReceber,
  GetResultado,
  SelectUnidades,
  SetCompetencia,
  GetGruposDespesas,
} from '@actions/dashBoardActions';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
const { MonthPicker } = DatePicker;

function Unidades() {
  const dispatch = useAppDispatch();
  const general = useAppSelector((state) => state.general);
  const dash = useAppSelector((state) => state.dash);

  useEffect(() => {}, []);
  const handleSelectAll = (value: any[]) => {
    if (value && value.length && value.filter((u) => u === 'all').length) {
      if (value.length === general.unidades.map((u) => u.id).length + 1) {
        return [];
      }
      return [...general.unidades.map((u) => u.id)];
    }
    return value;
  };
  return (
    <Space direction="horizontal">
      <Select
        size="small"
        mode="multiple"
        maxTagCount={1}
        optionFilterProp="label"
        style={{ maxWidth: 300, minWidth: 300 }}
        placeholder="Unidades de Negócio"
        value={dash.unidades}
        disabled={general.unidades.length === 1}
        onChange={(value) => {
          dispatch(SelectUnidades(handleSelectAll(value)));
          dispatch(GetContasPagar(handleSelectAll(value)));
          dispatch(GetContasReceber(handleSelectAll(value)));
          dispatch(GetResultado(handleSelectAll(value), moment(dash.competencia)));
          dispatch(GetGruposDespesas(handleSelectAll(value), moment(dash.competencia)));
        }}
      >
        {general.unidades.length > 1 && (
          <Select.Option label="todos" key="all">
            TODOS
          </Select.Option>
        )}
        {general.unidades.map((unidade) => (
          <Select.Option label={unidade.nome} key={unidade.id} value={unidade.id}>
            {unidade.nome}
          </Select.Option>
        ))}
      </Select>
      <Button
        size="small"
        type="default"
        icon={<DoubleLeftOutlined />}
        onClick={() => {
          dispatch(SetCompetencia(moment(dash.competencia).add(-1, 'M')));
          dispatch(GetResultado(dash.unidades, moment(dash.competencia).add(-1, 'M')));
          dispatch(GetGruposDespesas(dash.unidades, moment(dash.competencia).add(-1, 'M')));
        }}
      />
      <MonthPicker
        value={dash.competencia}
        allowClear={false}
        style={{ width: 85 }}
        format="MM/YYYY"
        size="small"
        onChange={(v) => {
          dispatch(SetCompetencia(v));
          dispatch(GetResultado(dash.unidades, moment(dash.competencia)));
          dispatch(GetGruposDespesas(dash.unidades, moment(dash.competencia)));
        }}
      />
      <Button
        size="small"
        type="default"
        icon={<DoubleRightOutlined />}
        onClick={() => {
          dispatch(SetCompetencia(moment(dash.competencia).add(1, 'M')));
          dispatch(GetResultado(dash.unidades, moment(dash.competencia).add(1, 'M')));
          dispatch(GetGruposDespesas(dash.unidades, moment(dash.competencia).add(1, 'M')));
        }}
      />
    </Space>
  );
}

export default Unidades;
