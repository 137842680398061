import { apiEndpoints } from '@config/apiEndpoints';
import cliente from '@config/axios';
import { ContaAnalitica } from '@models/ContaAnalitica';
import { notification, Select, SelectProps } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

const GetAnaliticasByBancaria = (bancariaID: number) => {
  return useQuery<ContaAnalitica[], AxiosError<any>>(
    ['analiticasbybancaria', bancariaID],
    () =>
      cliente
        .get<ContaAnalitica[]>(apiEndpoints.PLANOCONTAS.GET_ANALITICAS_BY_BANCARIA, {
          params: { bancariaID: bancariaID },
        })
        .then((res) => {
          return res.data;
        }),
    {
      enabled: !!bancariaID,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      staleTime: 0,
      initialData: [],
    }
  );
};
interface AnaliticaBancariaSelectProps extends SelectProps {
  bancariaID: number;
  onChangeGetUnidadeID?: (contaID: number, unidadeID: number) => void;
}
function AnaliticaBancariaSelect(props: AnaliticaBancariaSelectProps) {
  const { data, isLoading } = GetAnaliticasByBancaria(props.bancariaID);
  return (
    <Select
      {...props}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth || false}
      loading={props.loading || isLoading}
      optionFilterProp={props.optionFilterProp || 'children'}
      style={{ width: props?.style?.width || '100%' }}
      onChange={(value, option) => {
        props.onChange && props.onChange(value, option);
        props.onChangeGetUnidadeID(value, data.find((c) => c.id === value).unidadeID);
      }}
    >
      {data?.map((c) => (
        <Select.Option value={c.id} key={c.id}>
          {c.descricao}
        </Select.Option>
      ))}
    </Select>
  );
}
export default AnaliticaBancariaSelect;
