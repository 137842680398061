import { CSSProperties, ReactNode } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import '../assets/scss/Animate.scss';

const Animate = ({ children, effect, style }: { children: ReactNode; effect: string; style?: CSSProperties }) => {
  const hiddenRef = useRef<any>();
  const [isVisible, setIsVisible] = useState(false);
  const scrollHandler = () => {
    if (hiddenRef !== undefined) {
      if (hiddenRef.current?.parentNode?.parentNode?.scrollTop + window.innerHeight >= hiddenRef.current?.offsetTop) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };
  useEffect(() => {
    if (hiddenRef.current?.parentNode?.parentNode?.scrollTop + window.innerHeight >= hiddenRef.current.offsetTop) {
      setIsVisible(true);
    }
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);
  return (
    <div ref={hiddenRef} style={style} className={`${effect} ${isVisible ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

export { Animate };
