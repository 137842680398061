import { Form, Input, Modal, Select } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { useEffect } from 'react';
import { GetRoles } from '@actions/rolesActions';
import { CriarAviso } from '@actions/sistemaActions';
import { Aviso } from '@models/Aviso';
import { User } from '@models/User';
import cliente from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface AvisoFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
function AvisoForm({ visible, setVisible }: AvisoFormProps) {
  const dispatch = useAppDispatch();
  const { roles, loadingroles } = useAppSelector((state) => state.roles);
  const { loadingAvisos } = useAppSelector((state) => state.sistema);
  const [users, setUsers] = useState<User[]>([]);
  const [form] = Form.useForm<Aviso>();
  useEffect(() => {
    dispatch(GetRoles());
  }, [dispatch]);

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title="Aviso"
      okText="Salvar"
      onOk={() => form.submit()}
      okButtonProps={{ loading: loadingAvisos }}
    >
      <Form form={form} onFinish={(values) => dispatch(CriarAviso(values))} size="small" labelCol={{ span: 6 }}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="title" label="Título" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="roles" label="Perfis">
          <Select mode="multiple" showSearch optionFilterProp="children">
            {roles.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="userIds" label="Usuários">
          <Select
            mode="multiple"
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            loading={loadingroles}
            onSearch={(v) =>
              cliente.get('/api/Sistema/GetUsers', { params: { name: v } }).then((res) => setUsers(res.data))
            }
          >
            {users.map((c) => (
              <Select.Option value={c.id} key={c.id} label={c.nome}>
                <div>
                  <div>{c.nome}</div>
                  <div>{c.email}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="actionText" label="Texto Ação">
          <Input />
        </Form.Item>
        <Form.Item name="actionLink" label="Link Ação">
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Tipo" rules={[{ required: true, message: 'Tipo inválido' }]}>
          <Select>
            <Select.Option value="success">Sucesso</Select.Option>
            <Select.Option value="info">Informação</Select.Option>
            <Select.Option value="warning">Alerta</Select.Option>
            <Select.Option value="error">Erro</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AvisoForm;
