import { Button, Typography } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ResendConfirmEmail } from '@actions/userActions';
import ConfirmarEmail from './ConfirmarEmail';

function EmailNaoConfirmado() {
  const dispatch = useAppDispatch();
  const { resendConfirmEmail } = useAppSelector((state) => state.user);
  return (
    <Routes>
      <Route path="/ConfirmEmail" element={<ConfirmarEmail />} />
      <Route
        path="*"
        element={
          <div>
            <div>
              <Typography.Title level={4}>Email ainda não confirmado.</Typography.Title>
              <Button loading={resendConfirmEmail.sending} onClick={() => dispatch(ResendConfirmEmail())}>
                Enviar email novamente
              </Button>
            </div>
            <div>
              <Typography.Text type="danger">{resendConfirmEmail.msg}</Typography.Text>
            </div>
          </div>
        }
      />
    </Routes>
  );
}

export default EmailNaoConfirmado;
