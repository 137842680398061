import moment from 'moment';
import { cliente } from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@config/apiEndpoints';

export const SetUnidadesBalanco = function (unidadesid: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_UNIDADES_BALANCO',
      payload: unidadesid,
    });
  };
};
export const SetCompetenciaBalanco = function (competencia: moment.Moment) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_COMPETENCIA_BALANCO',
      payload: competencia,
    });
  };
};
export const GerarBalanco2 = function ({
  unidades,
  competencia,
  tipo,
}: {
  unidades: number[];
  competencia: string;
  tipo: number;
}) {
  return function (dispatch: AppDispatch) {
    if (unidades.length === 0) {
      dispatch({
        type: 'SET_ERROR_BALANCO',
      });
    } else {
      dispatch({
        type: 'GERAR_BALANCO_PENDING',
      });
      var result: any = [];
      cliente
        .post(apiEndpoints.BALANCO.GET_NIVEL_1, {
          Unidades: unidades,
          Competencia: competencia,
          Tipo: tipo,
        })
        .then((_n1) => {
          _n1.data.forEach((n1: any) => {
            n1.saldoTotal =
              Math.ceil((n1.saldoAnoAnterior + n1.creditosCompetencia + n1.debitosCompetencia) * 1000) / 1000;
            //n1.Codigo = n1.Codigo + ' - ' + n1.Descricao;
            result.push(n1);
          });
          dispatch({
            type: 'GERAR_BALANCO',
            payload: {
              result,
              loading: true,
            },
          });
          cliente
            .post(apiEndpoints.BALANCO.GET_NIVEL_2, {
              Unidades: unidades,
              Competencia: competencia,
              Tipo: tipo,
            })
            .then((_n2) => {
              _n2.data.forEach((n2: any) => {
                n2.saldoTotal =
                  Math.ceil((n2.saldoAnoAnterior + n2.creditosCompetencia + n2.debitosCompetencia) * 1000) / 1000;
                //n2.Codigo = n2.Codigo + ' - ' + n2.Descricao;
                result.splice(
                  result.findIndex((value: any) => {
                    return value.id === n2.pai;
                  }) + 1,
                  0,
                  n2
                );
              });
              dispatch({
                type: 'GERAR_BALANCO',
                payload: {
                  result,
                  loading: true,
                },
              });
              cliente
                .post(apiEndpoints.BALANCO.GET_NIVEL_3, {
                  Unidades: unidades,
                  Competencia: competencia,
                  Tipo: tipo,
                })
                .then((_n3) => {
                  _n3.data.forEach((n3: any) => {
                    n3.saldoTotal =
                      Math.ceil((n3.saldoAnoAnterior + n3.creditosCompetencia + n3.debitosCompetencia) * 1000) / 1000;
                    //n3.Codigo = n3.Codigo + ' - ' + n3.Descricao;
                    result.splice(
                      result.findIndex((value: any) => {
                        return value.id === n3.pai;
                      }) + 1,
                      0,
                      n3
                    );
                  });
                  dispatch({
                    type: 'GERAR_BALANCO',
                    payload: {
                      result,
                      loading: true,
                    },
                  });
                  cliente
                    .post(apiEndpoints.BALANCO.GET_CS, {
                      Unidades: unidades,
                      Competencia: competencia,
                      Tipo: tipo,
                    })
                    .then((_n4) => {
                      _n4.data.forEach((n4: any) => {
                        n4.saldoTotal =
                          Math.ceil((n4.saldoAnoAnterior + n4.creditosCompetencia + n4.debitosCompetencia) * 1000) /
                          1000;
                        //n4.Codigo = n4.Codigo + ' - ' + n4.Descricao;
                        result.splice(
                          result.findIndex((value: any) => {
                            return value.id === n4.pai;
                          }) + 1,
                          0,
                          n4
                        );
                      });
                      dispatch({
                        type: 'GERAR_BALANCO',
                        payload: {
                          result,
                          loading: tipo === 0 ? false : true,
                        },
                      });
                      if (tipo === 1) {
                        cliente
                          .post(apiEndpoints.BALANCO.GET_CA, {
                            Unidades: unidades,
                            Competencia: competencia,
                            Tipo: tipo,
                          })
                          .then((_n5) => {
                            _n5.data.forEach((n5: any) => {
                              n5.saldoTotal =
                                Math.ceil(
                                  (n5.saldoAnoAnterior + n5.creditosCompetencia + n5.debitosCompetencia) * 1000
                                ) / 1000;
                              //n5.Codigo = n5.Codigo + ' - ' + n5.Descricao;
                              result.splice(
                                result.findIndex((value: any) => {
                                  return value.id === n5.pai;
                                }) + 1,
                                0,
                                n5
                              );
                            });
                            dispatch({
                              type: 'GERAR_BALANCO',
                              payload: {
                                result,
                                loading: true,
                              },
                            });
                            cliente
                              .post(apiEndpoints.BALANCO.GET_PESSOAS, {
                                Unidades: unidades,
                                Competencia: competencia,
                                Tipo: tipo,
                              })
                              .then((_n6) => {
                                _n6.data.forEach((n6: any) => {
                                  n6.saldoTotal =
                                    Math.ceil(
                                      (n6.saldoAnoAnterior + n6.creditosCompetencia + n6.debitosCompetencia) * 1000
                                    ) / 1000;
                                  //n6.Codigo = n6.Codigo + ' - ' + n6.Descricao;
                                  result.splice(
                                    result.findIndex((value: any) => {
                                      return value.id === n6.pai;
                                    }) + 1,
                                    0,
                                    n6
                                  );
                                });
                                dispatch({
                                  type: 'GERAR_BALANCO',
                                  payload: {
                                    result,
                                    loading: false,
                                  },
                                });
                              })
                              .catch(() => dispatch({ type: 'GERAR_BALANCO_REJECTED' }));
                          })
                          .catch(() => dispatch({ type: 'GERAR_BALANCO_REJECTED' }));
                      }
                    })
                    .catch(() => dispatch({ type: 'GERAR_BALANCO_REJECTED' }));
                })
                .catch(() => dispatch({ type: 'GERAR_BALANCO_REJECTED' }));
            })
            .catch(() => dispatch({ type: 'GERAR_BALANCO_REJECTED' }));
        })
        .catch(() => dispatch({ type: 'GERAR_BALANCO_REJECTED' }));
    }
  };
};

export const ShowLancamentosBalanco = function (
  nivel: number,
  id: number,
  unidades: number[],
  tempo: number,
  competencia: string
) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_LANCAMENTOS_BALANCO_DATA',
      payload: {
        nivel: nivel,
        id: id,
        tempo: tempo,
      },
    });
    dispatch({
      type: 'SHOW_LANCAMENTOS_BALANCO',
      payload: cliente.post(apiEndpoints.BALANCO.UPDATE_LANCAMENTOS, {
        results: 10,
        page: 1,
        nivel: nivel,
        id: id,
        unidades: unidades,
        tempo: tempo,
        competencia: competencia,
      }),
    });
  };
};

export const HideLancamentosBalanco = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'HIDE_LANCAMENTOS_BALANCO',
    });
  };
};

export const ResetBalanco = function () {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'RESET_BALANCO' });
  };
};
export const ShowBalancoForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'SHOW_BALANCO_FORM' });
  };
};
export const ExcluirLancamento = function (ids: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_LANCAMENTO_BALANCO_PENDING',
      payload: cliente.post(apiEndpoints.LANCAMENTOS.EXCLUIR_LANCAMENTOS, {
        ids: ids,
      }),
    });
  };
};
