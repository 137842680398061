import { notification } from 'antd';
import { extendedAction } from '@config/reduxStore';
import { BancariasReducer } from '@reducers/reducers';

var initialState: BancariasReducer = {
  bancarias: [],
  waiting: false,
  savingloading: false,
  loadingconta: false,
  showform: false,
  selectedbancarias: null,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  bancaria: null,
};

const bancariasRedecer = function (state = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'EXCLUIR_BANCARIAS_BANCARIAS_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'EXCLUIR_BANCARIAS_BANCARIAS_REJECTED':
      state = {
        ...state,
        savingloading: false,
      };
      notification.error({ message: action.payload.response.data });
      break;
    case 'EXCLUIR_BANCARIAS_BANCARIAS_FULFILLED':
      state = {
        ...state,
        savingloading: false,
        bancarias: state.bancarias.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: 'Item excluído com sucesso!' });
      break;
    case 'SELECTED_BANCARIAS_BANCARIAS':
      state = {
        ...state,
        selectedbancarias: action.payload,
      };
      break;
    case 'LOAD_CONTA_BANCARIA_BANCARIAS_PENDING':
      state = {
        ...state,
        loadingconta: true,
        showform: true,
      };
      break;
    case 'LOAD_CONTA_BANCARIA_BANCARIAS_FULFILLED':
      state = {
        ...state,
        loadingconta: false,
        bancaria: action.payload.data,
      };
      break;
    case 'ADD_CONTA_BANCARIA_BANCARIAS_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'ADD_CONTA_BANCARIA_BANCARIAS_REJECTED':
      notification.error({ message: 'Erro ao criar conta!' });
      state = {
        ...state,
        savingloading: false,
      };
      break;
    case 'ADD_CONTA_BANCARIA_BANCARIAS_FULFILLED':
      notification.success({ message: 'Conta cadastrada com sucesso!' });
      state = {
        ...state,
        savingloading: false,
        showform: false,
        bancarias: [...state.bancarias, action.payload.data],
      };
      break;
    case 'UPDATE_CONTA_BANCARIA_BANCARIAS_PENDING':
      state = {
        ...state,
        savingloading: true,
      };
      break;
    case 'UPDATE_CONTA_BANCARIA_BANCARIAS_REJECTED':
      notification.error({ message: 'Erro ao atualizar conta!' });
      state = {
        ...state,
        savingloading: false,
      };
      break;
    case 'UPDATE_CONTA_BANCARIA_BANCARIAS_FULFILLED':
      notification.success({ message: 'Conta atualizada com sucesso!' });
      state = {
        ...state,
        savingloading: false,
        showform: false,
        bancarias: state.bancarias.map((c) => (c.id === action.payload.data.id ? { ...c, ...action.payload.data } : c)),
      };
      break;
    case 'GET_BANCARIAS_BANCARIAS_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_BANCARIAS_BANCARIAS_FULFILLED':
      var pager = state.pagination;
      pager.total = action.payload.data.totalCount;
      state = {
        ...state,
        waiting: false,
        bancarias: action.payload.data,
        pagination: pager,
      };
      break;
    case 'GET_BANCARIAS_BANCARIAS2_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'GET_BANCARIAS_BANCARIAS2_FULFILLED':
      state = {
        ...state,
        waiting: false,
        bancarias: action.payload.data,
      };
      break;
    case 'NOVA_CONTA_BANCARIA_BANCARIAS':
      state = {
        ...state,
        showform: true,
        bancaria: initialState.bancaria,
      };
      break;
    case 'CLOSE_FORM_BANCARIAS':
      state = {
        ...state,
        showform: false,
        bancaria: initialState.bancaria,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default bancariasRedecer;
