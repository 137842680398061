import PessoasView from './Pessoas/PessoasView';
import ProdutosView from './Produtos/ProdutosView';
import BalancoView from './Balanco/BalancoView';
import ExtratoView from './Extrato/ExtratoView';
import { Route, Routes } from 'react-router-dom';
import OrcamentosView from './Orcamentos/OrcamentosView';
import EmpresaView from './Empresa/EmpresaView';
import Home from './Home/Home';
import UsuariosView from './Usuarios/UsuariosView';
import HistoricoView from './Historico/HistoricoView';
import BancariasView from './Bancarias/BancariasView';
import UsuarioView from './Usuario/UsuarioView';
import FluxocaixaView from './Fluxocaixa/FluxocaixaView';
import ImplantacaoView from './Implantacao/ImplantacaoView';
import PlanoContasView from './Empresa/PlanoContasView';
import PerfisView from './Perfis/PerfisView';
import DREView from './DRE/DREView';
import LancamentosView from './Lancamentos/LancamentosView';
import NovoPlanoForm from './Usuario/NovoPlanoForm';
import PrintBalanco from './Balanco/Print';
import PrintExtrato from './Extrato/PrintExtrato';
import PrintContasPG from './ContasPG/PrintContasPG';
import PrintDRE from './DRE/PrintDRE';
import PrintFluxo from './Fluxocaixa/PrintFluxo';
import OrcamentoTabela from './Orcamentos/OrcamentoTabela';
import OrcamentoPrint from './Orcamentos/OrcamentoPrint';
import LancarViewNovo from './Lancar/LancarViewNovo';
import { useEffect } from 'react';
import { GetAvisos, ReadAviso } from '@actions/avisosActions';
import { Button, notification, Space } from 'antd';
import cliente from '@config/axios';
import Planos from '../HomePage/Contratar/Plano';
import ImportarView from './Importacao/ImportarView';
import NovaImportacao from './Importacao/NovaImportacao';
import ContasPGNovoView from './ContasPG/ContasPGNovoView';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import ConciliarView from './Conciliar/ConciliarView';
import ImportarPessoasView from './Pessoas/Importar/ImportarPessoasView';
import ConciliarAll from './Conciliar/Conciliar.All';

function EmpresaContexto() {
  const dispatch = useAppDispatch();
  const { avisos } = useAppSelector((state) => state.avisos);
  useEffect(() => {
    dispatch(GetAvisos());
  }, []);
  useEffect(() => {
    if (avisos) {
      avisos.forEach((c) =>
        notification.open({
          message: c.title,
          key: String(c.id),
          type: c.type,
          description: c.descricao,
          duration: 0,
          closeIcon: null,
          style: { width: 600 },
          onClose: () => dispatch(ReadAviso(c.id)),
          btn: (
            <Space direction="horizontal">
              {c.actionLink && (
                <Button
                  type="default"
                  size="small"
                  onClick={() => {
                    cliente
                      .get('/api/Avisos/ReadAviso', { params: { id: c.id } })
                      .then(() => (window.location.href = c.actionLink));
                  }}
                >
                  {c.actionText}
                </Button>
              )}
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  dispatch(ReadAviso(c.id));
                  notification.close(String(c.id));
                }}
              >
                Ok
              </Button>
            </Space>
          ),
        })
      );
    }
  }, [avisos]);
  return (
    <Routes>
      <Route path="/Conciliar" element={<ConciliarView />} />
      <Route path="/Conciliar/:id" element={<ConciliarView />} />
      <Route path="/ConciliarAll" element={<ConciliarAll />} />
      <Route path="/Produtos" element={<ProdutosView />} />
      <Route path="/Pessoas" element={<PessoasView />} />
      <Route path="/ImportarPessoas" element={<ImportarPessoasView />} />
      <Route path="/Extrato/:ContaID" element={<ExtratoView />} />
      <Route path="/Extrato" element={<ExtratoView />} />
      <Route path="/PrintExtrato" element={<PrintExtrato />} />
      <Route path="/Balanco" element={<BalancoView />} />
      <Route path="/PrintBalanco" element={<PrintBalanco />} />
      <Route path="/Orcamentos" element={<OrcamentosView />} />
      <Route path="/Orcamentos/:id" element={<OrcamentoTabela />} />
      <Route path="/PrintOrcamento/:id" element={<OrcamentoPrint />} />
      <Route path="/ContasPG" element={<ContasPGNovoView />} />
      <Route path="/PrintContasPG" element={<PrintContasPG />} />
      <Route path="/Empresa" element={<EmpresaView />} />
      <Route path="/Lancar" element={<LancarViewNovo />} />
      <Route path="/Usuarios" element={<UsuariosView />} />
      <Route path="/Lancamentos" element={<LancamentosView />} />
      <Route path="/Perfis" element={<PerfisView />} />
      <Route path="/Historico" element={<HistoricoView />} />
      <Route path="/Bancarias" element={<BancariasView />} />
      <Route path="/Usuario" element={<UsuarioView />} />
      <Route path="/Usuario/Contratar" element={<NovoPlanoForm />} />
      <Route path="/Planocontas" element={<PlanoContasView />} />
      <Route path="/Fluxocaixa" element={<FluxocaixaView />} />
      <Route path="/PrintFLuxo" element={<PrintFluxo />} />
      <Route path="/DRE" element={<DREView />} />
      <Route path="/PrintDRE" element={<PrintDRE />} />
      <Route path="/Implantar" element={<ImplantacaoView />} />
      <Route path="/Planos" element={<Planos />} />
      <Route path="/Importar" element={<ImportarView />} />
      <Route path="/NovaImportacao" element={<NovaImportacao />} />
      <Route path="/NovaImportacao/:id" element={<NovaImportacao />} />
      <Route path="/" element={<Home />} />
      <Route path="*">Page not Found</Route>
    </Routes>
  );
}

export default EmpresaContexto;
