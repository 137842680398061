import { useState, useEffect } from 'react';
import { Select, Checkbox, Spin, notification, Table } from 'antd';
import { GetRoles, GetPermissions, AddPermissionToRole } from '../../../actions/rolesActions';
import { SetPageHeader } from '../../../actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { naturalSorter } from '@utils/filterandsort';

function PerfisView() {
  const dispatch = useAppDispatch();
  const { roles, loadingroles, loadingpermissions } = useAppSelector((state) => state.roles);
  const [role, setRole] = useState();
  useEffect(() => {
    dispatch(GetRoles());
    dispatch(GetPermissions());
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Perfis',
        subTitle: 'Gerencie os perfis dos usuários que tem acesso a esta conta',
        extras: [
          <Select
            size="small"
            value={role}
            placeholder="Escolher perfil"
            key={0}
            loading={loadingroles}
            style={{ width: 400 }}
            onChange={(v) => setRole(v)}
          >
            {roles.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name.toUpperCase()}
              </Select.Option>
            ))}
          </Select>,
        ],
      })
    );
  }, [role, loadingroles, roles]);
  const permissionsList = [
    {
      name: 'Conta Analítica',
      list: 'analitica.list',
      edit: 'analitica.edit',
      add: 'analitica.add',
      delete: 'analitica.delete',
    },
    {
      name: 'Plano de Contas',
      list: 'plnaocontas.list',
      edit: 'plnaocontas.edit',
      add: 'plnaocontas.add',
      delete: 'plnaocontas.delete',
    },
    {
      name: 'Conta Sintética',
      list: 'sintetica.list',
      edit: 'sintetica.edit',
      add: 'sintetica.add',
      delete: 'sintetica.delete',
    },
    {
      name: 'Unidade de Negócio',
      list: 'unidade.list',
      edit: 'unidade.edit',
      add: 'unidade.add',
      delete: 'unidade.delete',
    },
    {
      name: 'Conta Bancária',
      list: 'bancaria.list',
      edit: 'bancaria.edit',
      add: 'bancaria.add',
      delete: 'bancaria.delete',
    },
    {
      name: 'Empresa',
      list: 'empresa.list',
      edit: 'empresa.edit',
      add: 'empresa.add',
      delete: 'empresa.delete',
    },
    {
      name: 'Fluxo de Caixa',
      list: 'fluxocaixa.list',
      edit: 'fluxocaixa.edit',
      add: 'fluxocaixa.add',
      delete: 'fluxocaixa.delete',
    },
    {
      name: 'Produtos',
      list: 'produto.list',
      edit: 'produto.edit',
      add: 'produto.add',
      delete: 'produto.delete',
    },
    {
      name: 'Lançamentos',
      list: 'lancamento.list',
      edit: 'lancamento.edit',
      add: 'lancamento.add',
      delete: 'lancamento.delete',
    },
    {
      name: 'Pessoas',
      list: 'pessoa.list',
      edit: 'pessoa.edit',
      add: 'pessoa.add',
      delete: 'pessoa.delete',
    },
    {
      name: 'Usuários',
      list: 'usuario.list',
      edit: 'usuario.edit',
      add: 'usuario.add',
      delete: 'usuario.delete',
    },
    {
      name: 'Contas Pagar/Receber',
      list: 'contaspg.list',
      edit: 'contaspg.edit',
      add: 'contaspg.add',
      delete: 'contaspg.delete',
    },
    {
      name: 'Orçamentos',
      list: 'orcamento.list',
      edit: 'orcamento.edit',
      add: 'orcamento.add',
      delete: 'orcamento.delete',
    },
    {
      name: 'Histórico',
      list: 'historico.list',
    },
    {
      name: 'Balanço',
      list: 'balanco.list',
    },
    {
      name: 'Implantação de Saldos',
      add: 'implantacaosaldo.add',
    },
    {
      name: 'Importar',
      add: 'importar.add',
    },
    {
      name: 'Perfis',
      list: 'perfis.list',
      edit: 'perfis.edit',
      add: 'perfis.add',
      delete: 'perfis.delete',
    },
    {
      name: 'DRE',
      list: 'dre.list',
      edit: 'dre.edit',
      add: 'dre.add',
      delete: 'dre.delete',
    },
    {
      name: 'Extrato',
      list: 'extrato.list',
      edit: 'extrato.edit',
      add: 'extrato.add',
      delete: 'extrato.delete',
    },
  ];
  return (
    <Spin spinning={loadingpermissions}>
      <Table
        size="small"
        dataSource={[...permissionsList].sort((a, b) => naturalSorter(a.name, b.name))}
        pagination={false}
        scroll={{ x: 1000, y: 'calc(100vh - 160px)' }}
      >
        <Table.Column title="Grupo" dataIndex="name" />
        <Table.Column
          title="Listar"
          dataIndex="list"
          align="center"
          render={(v) =>
            v ? (
              <Checkbox
                checked={!!roles.find((c) => c.id === role)?.rolePermissions.find((c) => c.permission === v)}
                onChange={() => {
                  if (role)
                    dispatch(
                      AddPermissionToRole({
                        Permission: v,
                        Role: role,
                      })
                    );
                  else notification.error({ message: 'Escolha um perfil' });
                }}
              />
            ) : null
          }
        />
        <Table.Column
          title="Atualizar"
          dataIndex="edit"
          align="center"
          render={(v) =>
            v ? (
              <Checkbox
                checked={!!roles.find((c) => c.id === role)?.rolePermissions.find((c) => c.permission === v)}
                onChange={() => {
                  if (role)
                    dispatch(
                      AddPermissionToRole({
                        Permission: v,
                        Role: role,
                      })
                    );
                  else notification.error({ message: 'Escolha um perfil' });
                }}
              />
            ) : null
          }
        />
        <Table.Column
          title="Adicionar"
          dataIndex="add"
          align="center"
          render={(v) =>
            v ? (
              <Checkbox
                checked={!!roles.find((c) => c.id === role)?.rolePermissions.find((c) => c.permission === v)}
                onChange={() => {
                  if (role)
                    dispatch(
                      AddPermissionToRole({
                        Permission: v,
                        Role: role,
                      })
                    );
                  else notification.error({ message: 'Escolha um perfil' });
                }}
              />
            ) : null
          }
        />
        <Table.Column
          title="Excluir"
          dataIndex="delete"
          align="center"
          render={(v) =>
            v ? (
              <Checkbox
                checked={!!roles.find((c) => c.id === role)?.rolePermissions.find((c) => c.permission === v)}
                onChange={() => {
                  if (role)
                    dispatch(
                      AddPermissionToRole({
                        Permission: v,
                        Role: role,
                      })
                    );
                  else notification.error({ message: 'Escolha um perfil' });
                }}
              />
            ) : null
          }
        />
      </Table>
    </Spin>
  );
}

export default PerfisView;
