import { Table, Modal, Switch, Skeleton } from 'antd';
import { HideUsersForm, SetUserStatus } from '@actions/empresaActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { User } from '@models/User';

const { Column } = Table;

function UsersForm() {
  const empresa = useAppSelector((state) => state.empresa);
  const dispatch = useAppDispatch();
  return (
    <Modal
      title="Habilitar usuários"
      open={empresa.usersformvisible}
      width="50em"
      okButtonProps={{ hidden: true }}
      cancelText="Fechar"
      onCancel={() => dispatch(HideUsersForm())}
    >
      <Skeleton loading={empresa.openloading} active>
        <Table size="small" dataSource={empresa.users} rowKey="id">
          <Column
            title="Estado"
            render={(_, record: User) => (
              <Switch
                onChange={() => dispatch(SetUserStatus(record.id, empresa.userunidade))}
                loading={empresa.savingloading}
                checked={record.habilitado}
              />
            )}
          />
          <Column title="Usuário" dataIndex="nome" />
        </Table>
      </Skeleton>
    </Modal>
  );
}

export default UsersForm;
