import {
  CloseForm,
  DeleteBancaria,
  GetBancarias2,
  LoadBancaria,
  NovaConta,
  SaveBancaria2,
} from '@actions/bancariasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { BancariasControllerInterface } from './controllers';

export const BancariasController: BancariasControllerInterface = () => {
  const dispatch = useAppDispatch();
  const { bancarias, showform, savingloading, loadingconta, bancaria, waiting } = useAppSelector(
    (state) => state.bancarias
  );
  const Close: () => void = () => dispatch(CloseForm());
  const LoadContas: () => void = () => dispatch(GetBancarias2());
  const Nova: () => void = () => dispatch(NovaConta());
  return {
    data: { bancarias, showform, savingloading, loadingconta, bancaria, waiting },
    actions: {
      Close,
      LoadContas,
      Nova,
      Save: (values) => dispatch(SaveBancaria2(values)),
      Delete: (id) => dispatch(DeleteBancaria(id)),
      LoadConta: (id) => dispatch(LoadBancaria(id)),
    },
  };
};
