import { useEffect } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { ResetPassword } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

const { Text } = Typography;

function ChangePasswordForm(props: { query: string }) {
  const { sending, msg } = useAppSelector((state) => state.user.forgotPassword);
  const dispatch = useAppDispatch();
  const [changePasswordForm] = Form.useForm();
  const search = new URLSearchParams(props.query);
  useEffect(() => {
    document.title = 'Nambi - Redefinição de Senha';
  }, [props]);
  return (
    <Form
      form={changePasswordForm}
      onFinish={(values) =>
        dispatch(
          ResetPassword({
            code: search.get('code'),
            userid: search.get('userId'),
            ...values,
          })
        )
      }
      layout="vertical"
    >
      <Form.Item
        name="Password"
        label="Nova Senha"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório!',
          },
          {
            pattern: /^(?=.{6,})/,
            message: 'A senha deve conter no mínimo 6 dígitos',
          },
          {
            pattern: /^(?=.*[a-z])/,
            message: 'A senha deve conter ao menos uma letra minúscula',
          },
          {
            pattern: /^(?=.*[A-Z])/,
            message: 'A senha deve conter ao menos uma letra maiúscula',
          },
          {
            pattern: /^(?=.*[0-9])/,
            message: 'A senha deve conter ao menos um número',
          },
          {
            pattern: /^(?=.*[!@#$%^&*])/,
            message: 'A senha deve conter ao menos um caractére especial',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="ConfirmPassword"
        label="Confirmar nova senha"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('Password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('As senhas não conferem!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Button loading={sending} block type="primary" htmlType="submit">
        Redefinir
      </Button>
      <Text type="danger">{msg}</Text>
    </Form>
  );
}

export default ChangePasswordForm;
