import { useEffect } from 'react';
import { Modal, Input, Select, Form, Button, Switch, Popconfirm } from 'antd';
import { HideUnidadeForm, SaveUnidade } from '@actions/empresaActions';
import { Permission } from '@hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { UnidadeNegocio } from '@models/UnidadeNegocio';

function UnidadeForm() {
  const dispatch = useAppDispatch();
  const { unidades } = useAppSelector((state) => state.general);
  const { unidadeformvisible, unidade, savingloading, empresa } = useAppSelector((state) => state.empresa);
  const [form] = Form.useForm<UnidadeNegocio>();
  useEffect(() => {
    form.setFieldsValue({ empresaID: empresa });
  }, [empresa, unidadeformvisible]);
  useEffect(() => {
    if (unidade) {
      const u = unidades.find((c) => c.id === unidade);
      form.setFieldsValue(u);
    } else form.resetFields();
  }, [unidade, form, unidades]);
  return (
    <Modal
      title="Cadastro de Unidade de Negócio"
      open={unidadeformvisible}
      width="50em"
      onCancel={() => dispatch(HideUnidadeForm(null))}
      footer={[
        <Permission key="3" permission="unidade.delete">
          <Popconfirm title="Está certo disso?">
            <Button type="default" disabled>
              Excluir
            </Button>
          </Popconfirm>
        </Permission>,
        <Permission key="1" permission={form.getFieldValue('id') ? 'unidade.edit' : 'unidade.add'}>
          <Button type="primary" loading={savingloading} onClick={() => form.submit()}>
            Salvar
          </Button>
        </Permission>,
        <Button key="2" type="default" onClick={() => dispatch(HideUnidadeForm(null))}>
          Fechar
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={(values) => {
          dispatch(SaveUnidade(values));
        }}
        layout="vertical"
        initialValues={{ ativo: true }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="empresaID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="nome" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        {unidade === null && (
          <Form.Item
            name="planobase"
            label="Plano de contas base (copiar contas)"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select showSearch optionFilterProp="label">
              <Select.Option label="Em Branco" value="-1">
                Em Branco
              </Select.Option>
              {unidades.map((c, index) => (
                <Select.Option key={index} label={c.nome} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="ativo" label="Ativo" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UnidadeForm;
