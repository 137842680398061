import { notification } from 'antd';
import moment from 'moment';
import { extendedAction } from '@config/reduxStore';
import { BalancoReducer } from '@reducers/reducers';

var initialState: BalancoReducer = {
  unidades: [],
  competencia: moment(new Date()),
  tipo: 0,
  balanco: [],
  validate: '',
  formview: true,
  lancamentosvisible: false,
  lancamentosid: null,
  lancamentosnivel: null,
  lancamentostempo: null,
  lancamentos: [],
  selectedlancamento: -1,
  lancamentospagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  lancamentosfilters: null,
  lancamentossorter: [],
  lancamentosloading: false,
  waiting: false,
  lancamentosresultado: [],
  deleteids: [],
  confirmdeletemodal: false,
  balancoatualizado: false,
};

const balancoReducer = function (state: BalancoReducer = initialState, action: extendedAction) {
  switch (action.type) {
    case 'RESET':
      state = initialState;
      break;
    case 'SHOW_BALANCO_FORM':
      state = { ...state, formview: !state.formview };
      break;
    case 'SET_UNIDADE_ONLY':
      state.unidades.push(action.payload);
      break;
    case 'SET_UNIDADES_BALANCO':
      state = {
        ...state,
        unidades: action.payload,
        validate: action.payload.length > 0 ? '' : 'Escolha uma unidade',
      };
      break;
    case 'SET_COMPETENCIA_BALANCO':
      state = {
        ...state,
        competencia: action.payload,
      };
      break;
    case 'GERAR_BALANCO_PENDING':
      state = {
        ...state,
        balanco: initialState.balanco,
        waiting: true,
        balancoatualizado: false,
      };
      break;
    case 'GERAR_BALANCO':
      state = {
        ...state,
        balanco: action.payload.result,
        waiting: action.payload.loading,
      };
      break;
    case 'GERAR_BALANCO_REJECTED':
      notification.error({ message: 'Erro ao gerar balanço' });
      state = {
        ...state,
        waiting: action.payload.loading,
      };
      break;
    case 'SET_ERROR_BALANCO':
      state = {
        ...state,
        validate: 'Escolha uma unidade',
        waiting: false,
      };
      break;
    case 'SHOW_LANCAMENTOS_BALANCO_FULFILLED':
      state = {
        ...state,
        lancamentosvisible: true,
        lancamentos: action.payload.data,
        waiting: false,
      };
      break;
    case 'SHOW_LANCAMENTOS_BALANCO_REJECTED':
      notification.error({ message: 'Erro ao carregar lançamentos!' });
      state = {
        ...state,
        waiting: false,
      };
      break;
    case 'SHOW_LANCAMENTOS_BALANCO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'HIDE_LANCAMENTOS_BALANCO':
      state = {
        ...state,
        lancamentosvisible: false,
        lancamentos: [],
        lancamentospagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        waiting: false,
      };
      break;
    case 'FETCH_LANCAMENTOS_BALANCO_PENDING':
      state = {
        ...state,
        waiting: true,
      };
      break;
    case 'BALANCO_NOT_UPTODATE':
      state = {
        ...state,
        balancoatualizado: true,
      };
      break;
    case 'FETCH_LANCAMENTOS_BALANCO_FULFILLED':
      state = {
        ...state,
        lancamentos: action.payload.data.result,
        lancamentosloading: false,
        lancamentospagination: {
          ...state.lancamentospagination,
          total: action.payload.data.totalCount,
        },
        waiting: false,
      };
      break;
    case 'SET_LANCAMENTOS_BALANCO_DATA':
      state = {
        ...state,
        lancamentosnivel: action.payload.nivel,
        lancamentosid: action.payload.id,
        lancamentostempo: action.payload.tempo,
        waiting: false,
      };
      break;
    case 'RESET_BALANCO':
      state = {
        ...initialState,
      };
      break;
    case 'EXCLUIR_LANCAMENTO_BALANCO_PENDING':
      state = {
        ...state,
        deleteids: action.payload,
        waiting: true,
      };
      break;
    case 'EXCLUIR_LANCAMENTO_BALANCO_REJECTED':
      notification.error({ message: 'Erro ao excluir lançamento!' });
      state = {
        ...state,
        deleteids: action.payload,
        waiting: false,
      };
      break;
    case 'CONFIRM_DELETE_LANCAMENTOS_BALANCO':
      notification.success({ message: 'Lançamento excluído com sucesso!' });
      state = {
        ...state,
        waiting: false,
        lancamentos: state.lancamentos.filter((c) => !action.payload.data.includes(c.id)),
        confirmdeletemodal: true,
      };
      break;
    case 'CLOSE_MODAL_CONFIRM_DELETE_EXTRATO':
      state = {
        ...state,
        waiting: false,
        confirmdeletemodal: false,
        selectedlancamento: [],
      };
      break;
    default:
      return state;
  }
  if (action.type.includes('REJECTED')) {
    state = {
      ...state,
      waiting: false,
      lancamentosloading: false,
    };
  }
  return state;
};

export default balancoReducer;
