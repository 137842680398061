import { Form, Input, Button, Typography, Modal } from 'antd';
import { ChangePassword } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { UserControllerInterface } from '@controllers/controllers';

const { Text } = Typography;

function ChangePasswordForm({ controller }: { controller: UserControllerInterface }) {
  const { data, actions } = controller();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  return (
    <Modal
      title="Redefinir Senha"
      open={data.usuario.redefinirsenhamodal}
      onCancel={() => actions.ResendSenha(false)}
      footer={[
        <Button type="primary" onClick={() => form.submit()} loading={data.usuario.changepasswordloading}>
          Salvar
        </Button>,
        <Button type="default" onClick={() => actions.ResendSenha(false)}>
          Fechar
        </Button>,
      ]}
    >
      <div>
        <Form
          form={form}
          onFinish={(values) => dispatch(ChangePassword(values))}
          layout="horizontal"
          wrapperCol={{ span: 16 }}
          labelCol={{ span: 8 }}
        >
          <Form.Item name="OldPassword" label="Senha atual" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="NewPassword"
            label="Nova senha"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              {
                pattern: /^(?=.{6,})/,
                message: 'A senha deve conter no mínimo 6 dígitos',
              },
              {
                pattern: /^(?=.*[a-z])/,
                message: 'A senha deve conter ao menos uma letra minúscula',
              },
              {
                pattern: /^(?=.*[A-Z])/,
                message: 'A senha deve conter ao menos uma letra maiúscula',
              },
              {
                pattern: /^(?=.*[0-9])/,
                message: 'A senha deve conter ao menos um número',
              },
              {
                pattern: /^(?=.*[!@#$%^&*])/,
                message: 'A senha deve conter ao menos um caractére especial',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="ConfirmPassword"
            label="Confirmar nova senha"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('NewPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('As senhas não conferem!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Text type="danger">{data.usuario.changepasswordresult}</Text>
        </Form>
      </div>
    </Modal>
  );
}

export default ChangePasswordForm;
