import './text.scss';
function About() {
  return (
    <div className="textBox">
      <h3>QUEM SOMOS</h3>
      <article>
        <p>
          A Nambi - Gestão Financeira Inteligente é um sistema financeiro web desenvolvido para ajudar empresas de todos
          os portes a gerenciar suas finanças de forma mais eficiente e inteligente.
        </p>
        <p>
          O sistema permite que os usuários façam lançamentos financeiros, controlem suas receitas e despesas, gerenciem
          suas contas a pagar e a receber, emitam relatórios de balanço, DRE e fluxo de caixa, entre outras
          funcionalidades.
        </p>
        <p>
          A Nambi é operada pela empresa NAMBI EFICIENCIA EM GESTAO LTDA, inscrita no CNPJ 29.823.743/0001-43, que conta
          com uma equipe altamente qualificada e experiente em finanças e tecnologia.
        </p>
        <p>
          Nosso objetivo é simplificar a gestão financeira das empresas, tornando-a mais fácil, acessível e eficiente.
          Acreditamos que a tecnologia pode ser uma aliada poderosa na tomada de decisões financeiras mais acertadas e
          estamos empenhados em oferecer um sistema completo e confiável para nossos usuários.
        </p>
        <p>
          Com a Nambi, nossos usuários podem ter maior controle sobre suas finanças, tomar decisões mais embasadas e ter
          mais tempo e recursos para se dedicar a outras áreas do seu negócio.
        </p>
        <p>Junte-se a nós e comece a gerenciar suas finanças de forma mais inteligente!</p>
      </article>
    </div>
  );
}
export default About;
