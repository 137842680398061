import { GetConciliacao, UpdateOFXValues } from '@actions/conciliarActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import ConciliarImportButton from './Conciliar.ImportButton';
import ConciliarMenu from './Conciliar.Menu';
import ConciliarTable from './Conciliar.Table';
import { useParams } from 'react-router-dom';

function ConciliarView() {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.conciliar);
  const [viewMenu, setViewMenu] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(GetConciliacao(id));
    }
    dispatch(
      SetPageHeader({
        backButton: true,
        title: 'Conciliação bancária',
        subTitle: 'Importe o arquivo OFX para realizar a importação dos lançamentos',
        extras: [
          <Button size="small" key={0} type="primary" onClick={() => setViewMenu(true)}>
            Configurar
          </Button>,
        ],
      })
    );
  }, []);
  useEffect(() => {
    if (data) {
      dispatch(UpdateOFXValues({ data, id }));
    }
  }, [data]);
  return (
    <div>
      <ConciliarMenu visible={viewMenu} setVisible={setViewMenu} />
      {data.length === 0 && <ConciliarImportButton />}
      {data.length > 0 && <ConciliarTable />}
    </div>
  );
}

export default ConciliarView;
