import { Form, Input, InputNumber, Modal, Switch } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { SalvarPlano } from '@actions/sistemaActions';
import { Plano } from '@models/Plano';
import { useAppDispatch } from '@config/reduxStore';
import { moneyMask } from '@utils/masks';

interface PlanoFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  item: Plano;
  setItem: Dispatch<SetStateAction<Plano>>;
}

function PlanoForm({ visible, setVisible, item, setItem }: PlanoFormProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<Plano>();

  useEffect(() => {
    if (item) {
      form.setFieldsValue({ ...item, valor: moneyMask.format(item.valor) });
    } else form.resetFields();
  }, [item, form]);

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setVisible(false);
        setItem(null);
      }}
      title="Plano"
      okText="Salvar"
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        onFinish={(values) =>
          dispatch(SalvarPlano({ ...values, valor: moneyMask.normalize(values.valor as string, '') }))
        }
        size="small"
        labelCol={{ span: 6 }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="unidades"
          label="Unidades"
          initialValue={0}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <InputNumber min={0} precision={0} />
        </Form.Item>
        <Form.Item
          name="empresas"
          label="Empresas"
          initialValue={0}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <InputNumber min={0} precision={0} />
        </Form.Item>
        <Form.Item name="planoID" label="PlanoID" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="valor"
          label="Descrição"
          initialValue={moneyMask.format(0)}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          normalize={(v) => moneyMask.format(moneyMask.normalize(v, ''))}
        >
          <Input />
        </Form.Item>
        <Form.Item name="ativo" label="Ativo" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PlanoForm;
